import axios from "axios";
import { baseUrl, baseUrl2 } from "../constants/BaseUrl";

export const getShopOpenCloseService = async (shop_id, login_key) => {
  return await axios.put(`${baseUrl}sitesapies/shopOpenCloseStatus/`, {
    ShopId: shop_id,
    LoginKey: login_key,
  });
};
export const getDeliveryTimeService = async (
  shop_id,
  login_key,
  sale_method
) => {
  return await axios.put(`${baseUrl}sitesapies/deliveryTimeList/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    Salemethod: sale_method,
  });
};
export const sendOrderService = async (shop_id, login_key, order_details) => {
  return await axios.put(`${baseUrl}sitesapies/orderPost/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    DeviceOrders: order_details,
  });
};
export const sendOrderEmailService = async (
  shop_id,
  login_key,
  order_id,
  role_id,
  invoice_html,
  customer_email,
  email_subject
) => {
  return await axios.put(`${baseUrl2}sitesapies/sendOrderEmail/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    OrderId: order_id,
    RoleId: role_id,
    InvoiceHtml: invoice_html,
    CustomerEmail: customer_email,
    EmailSubject: email_subject,
  });
};
export const sendPriceForPayService = async (
  shop_id,
  order_id,
  price,
  product_name,
  successful_link,
  failed_link,
  customer_email
) => {
  return await axios.post(`${baseUrl2}paymentsitesapies/checkout/`, {
    OrderId: order_id,
    ShopId: shop_id,
    price: price,
    product_name: product_name,
    FRONTEND_CHECKOUT_SUCCESS_URL: successful_link,
    FRONTEND_CHECKOUT_FAILED_URL: failed_link,
    CustomerEmail: customer_email,
  });
};
export const getShopInfoService = async (shop_id, login_key) => {
  return await axios.put(`${baseUrl}sitesapies/shopInfoGet/`, {
    ShopId: shop_id,
    LoginKey: login_key,
  });
};
export const getDeliveryFeeService = async (
  shop_id,
  login_key,
  customer_id,
  postcode
) => {
  return await axios.put(`${baseUrl}sitesapies/getDeliveryCharge/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    CustomerId: customer_id,
    Postcode: postcode,
  });
};
export const getPaymentSettingsService = async (shop_id, login_key) => {
  return await axios.put(`${baseUrl}sitesapies/paymentSetting/`, {
    ShopId: shop_id,
    LoginKey: login_key,
  });
};
export const getSixStepService = async (shop_id, login_key, order_id) => {
  return await axios.put(`${baseUrl}sitesapies/fiveStep/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    OrderId: order_id,
  });
};
export const getSuccessfulOrderDetailsService = async (
  shop_id,
  login_key,
  order_id
) => {
  return await axios.put(`${baseUrl}sitesapies/orderById/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    OrderId: order_id,
  });
};
export const getTerminalListService = async () => {
  return await axios.get(
    `https://se684k050000.test.connect.paymentsense.cloud/pac/terminals?status=BUSY&currency=GBP`,
    {
      headers: {
        accept: "application/connect.v2+json",
        Authorization: `Basic ${btoa(
          "se684k050000:196a54d5-4af8-454a-882d-5314a5bf5b1e"
        )}`,
        "Software-House-Id": "docsSWH",
        "Installer-Id": "docsInstaller",
      },
    }
  );
};
