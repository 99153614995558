import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import store from "store2";

import {
  emptyLoginInfoAction,
  setDropDownClickedAction,
} from "../redux/Profile/Actions";
import { getDeliveryFeeSuccessfull } from "../redux/settings/Actions";
import { setBasketListAction } from "../redux/basket/Actions";

const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user_info, userDropDownIsClicked } = useSelector(
    (state) => state.profileReduser
  );
  const { lang } = useSelector((state) => state.selectedLanguage);

  const [showProfileDropDown, setShowProfileDropDown] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

  const handleClickOnSignUp = () => {
    navigate("/profile");
  };
  const handleLogOut = () => {
    dispatch(emptyLoginInfoAction());
    dispatch(getDeliveryFeeSuccessfull(0));

    dispatch(setBasketListAction([], 0, 0, [], 0, 0, 0));
    store.remove("user_info");
    store.remove("postcode");
    store.remove("basket");
    store.remove("customer_info");
    store.remove("temp_basket");
    store.remove("temp_sub_total");
    store.remove("basket_last_item_id");
    navigate("/");
  };
  return (
    <Dropdown
      id="user_container"
      onClick={() => {
        dispatch(setDropDownClickedAction(true));
      }}
      // style={{
      //   minWidth: 85,
      //   height: "70%",
      //   border: "1px solid #ccc",
      //   marginLeft: 40,
      //   borderRadius: 3,
      // }}
    >
      <Dropdown.Toggle
        id="dropdown-button-dark-example1"
        variant="null"
        // className="user_drop_down_toggle"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <i
          id="user_icon"
          style={{
            fontSize: 20,
          }}
          class="fa fa-user-circle-o"
          aria-hidden="true"
        ></i>
        {user_info ? <h6 id="user_name">{user_info.FirstName}</h6> : null}
      </Dropdown.Toggle>
      {user_info ? (
        <Dropdown.Menu variant="light">
          <Dropdown.Item onClick={() => navigate("/profile")}>
            {lang.profile}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => {
              handleLogOut();
            }}
          >
            {lang.logout}
          </Dropdown.Item>
        </Dropdown.Menu>
      ) : (
        <Dropdown.Menu variant="light">
          <Dropdown.Item onClick={() => navigate("/login/1")}>
            {lang.sign_in}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => navigate("/signup/1")}>
            {lang.sign_up}
          </Dropdown.Item>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default User;
