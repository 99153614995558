import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const getAllProductsService = async (shop_id, login_key) => {
  return await axios.put(`${baseUrl}sitesapies/searchProduct/`, {
    ShopId: shop_id,
    LoginKey: login_key,
  });
};
export const productsServices = async (login_key, shop_id, category_id) => {
  return await axios.put(`${baseUrl}sitesapies/ProductList/`, {
    LoginKey: login_key,
    ShopId: shop_id,
    CategoryId: category_id,
  });
};

export const productSubmenuListServices = async (
  login_key,
  shop_id,
  product_id
) => {
  return await axios.put(`${baseUrl}sitesapies/ProductSubmenuList/`, {
    LoginKey: login_key,
    ShopId: shop_id,
    ProductId: product_id,
  });
};
export const getSubmenuServices = async (login_key, shop_id) => {
  return await axios.put(`${baseUrl}sitesapies/submenuList/`, {
    LoginKey: login_key,
    ShopId: shop_id,
  });
};
export const getPopularServices = async (shop_id, login_key) => {
  return await axios.put(`${baseUrl}sitesapies/populersList/`, {
    ShopId: shop_id,
    LoginKey: login_key,
  });
};
