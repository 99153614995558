import * as action_types from "../ActionType";

const initialStates = {
  collectionTime: "",
  deliveryTime: "",
  shop_id: 0,
  login_key: "",
  delivery_time_list: [],
  selected_delivery_time: null,
  special_note: null,
  selected_address: null,
  shop_name: "",
  user_entered_in: false,
  loading: false,
  stripe_payment_link: "",
  order_placed_successfull: false,
  order_placed_failed: false,
  shop_info: null,
  delivery_fee: 0,
  carrier_bag_visible: true,
  carrier_bag_amount: 0,
  carrier_bag_label: "Carrier Bag",
  adminfee_visible: true,
  adminfee_amount: 0,
  adminfee_label: "Admin Fee",
  cash_in_collection: true,
  cash_in_delivery: true,
  stripe_in_collection: true,
  stripe_in_delivery: true,
  paypal_in_collection: false,
  paypal_in_delivery: false,
  maintenance_mode: false,
  show_maintenance_mode_popup: false,
  maintenance_message: "",
  maintenance_mode_message: "",
  preorder_status: false,
  preorder_message: "",
  before_the_shop_opens: false,
  open_close_settings_details: null,
  delivery_from: false,
  delivery_unavailable: false,
  collection_from: false,
  collection_unavailable: false,
  show_preorder_button: false,
  preorder_pressed: false,
  go_to_next_route: false,
  six_step_status: 1000403,
  basket_button_title: "Order now",
  server_order_id: 0,
  successful_order_details: null,
  loading_successful_order: false,
};
const shopSettingsReduser = (state = initialStates, action) => {
  switch (action.type) {
    // SET DEFAULT ORDER PLACE STATUS
    case action_types.SET_DEFAULT_ORDER_PLACE_STATUS:
      return {
        ...state,
        loading: false,
        stripe_payment_link: "",
        order_placed_successfull: false,
        order_placed_failed: false,
        server_order_id: 0,
        go_to_next_route: false,
        six_step_status: 1000403,
      };

    case action_types.GET_SUCCESSFUL_ORDER_DETAILS:
      return {
        ...state,
        loading_successful_order: true,
      };
    case action_types.GET_SUCCESSFUL_ORDER_DETAILS_SUCCESSFULL:
      return {
        ...state,
        successful_order_details: action.payload,
        loading_successful_order: false,
      };
    case action_types.SET_SHOP_ID:
      return {
        ...state,
        shop_id: action.payload,
      };
    case action_types.GET_SIX_STEP_SUCCESSFULL:
      return {
        ...state,
        six_step_status: action.payload,
      };
    case action_types.SHOW_MAINTENANCE_MODE_POPUP:
      return {
        ...state,
        show_maintenance_mode_popup: action.payload,
      };
    case action_types.SET_PREORDER_PRESSESD:
      return {
        ...state,
        preorder_pressed: true,
      };
    case action_types.SET_MAINTENANCE_MODE_POPUP_MESSAGE:
      return {
        ...state,
        maintenance_mode_message: action.payload.message,
        show_preorder_button: action.payload.showPreorderButton,
      };
    // PAYMENT SETTINGS
    case action_types.GET_PAYMENT_SETTINGS_SUCCESSFULL:
      return {
        ...state,
        carrier_bag_visible: action.payload.ActiveWebCarrierBag,
        carrier_bag_amount: action.payload.CarrierBagAmount,
        carrier_bag_label: action.payload.CarrierBagTitle,
        adminfee_visible: action.payload.CreditCardSurchargeVisible,
        adminfee_amount: action.payload.CreditCardSurcharge,
        adminfee_label: action.payload.CreditCardSurchargeLabel,
        cash_in_collection: action.payload.CollectionPaymentMethodCash,
        cash_in_delivery: action.payload.DeliveryPaymentMethodCash,
        stripe_in_collection: action.payload.CollectionPaymentMethodStripe,
        stripe_in_delivery: action.payload.DeliveryPaymentMethodStripe,
        paypal_in_collection: action.payload.CollectionPaymentMethodPayPal,
        paypal_in_delivery: action.payload.DeliveryPaymentMethodPayPal,
      };
    // DELIVERY FEE
    case action_types.GET_DELIVERY_FEE_BY_POSTCODE_SUCCESSFULL:
      return {
        ...state,
        delivery_fee: action.payload,
      };
    // SHOP INFO
    case action_types.GET_SHOP_INFO_SUCCESSFULL:
      return {
        ...state,
        shop_info: action.payload,
      };
    // SEND PRICE FOR PAY
    case action_types.SEND_PRICE_FOR_PAY:
      return {
        ...state,
        loading: true,
      };
    case action_types.SEND_PRICE_FOR_PAY_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        stripe_payment_link: action.payload,
      };
    case action_types.SEND_PRICE_FOR_PAY_FAILED:
      return {
        ...state,
        loading: false,
        stripe_payment_link: "",
        order_placed_successfull: false,
        order_placed_failed: true,
      };
    // SEND ORDER
    case action_types.SEND_ORDER:
      return {
        ...state,
        loading: true,
      };
    case action_types.SEND_ORDER_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        order_placed_successfull:
          action.payload.pay_method == 2000102 ? true : false,
        order_placed_failed: false,
        server_order_id: action.payload.order_id,
      };
    case action_types.SEND_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        order_placed_successfull: false,
        order_placed_failed: true,
      };
    case action_types.GET_SHOP_OPEN_CLOSE:
      return {
        ...state,
        loading: action.payload.isInitialRequest ? false : true,
      };
    case action_types.GET_SHOP_OPEN_CLOSE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case action_types.GET_SHOP_OPEN_CLOSE_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        collectionTime: action.payload.data.CollectionTimeGap,
        deliveryTime: action.payload.data.DeliveryTimGap,
        maintenance_mode: action.payload.data.MaintenanceMode,
        show_maintenance_mode_popup: action.payload.showHidePopupStatus,
        maintenance_message: action.payload.data.MaintenanceMessage,
        preorder_message: action.payload.data.PreOrderMessage,
        preorder_status: action.payload.data.PreOrder,
        maintenance_mode_message: action.payload.popup_message,
        open_close_settings_details: action.payload.data,
        delivery_from: action.payload.delivery_from,
        delivery_unavailable: action.payload.delivery_unavailable,
        collection_from: action.payload.collection_from,
        collection_unavailable: action.payload.collection_unavailable,
        show_preorder_button: action.payload.showPreorderButton,
        go_to_next_route: action.payload.go_to_checkout,
        basket_button_title: action.payload.basket_button_title,
      };
    // case action_types.GET_SHOP_OPEN_CLOSE_FAILED:
    //   return {
    //     ...state,
    //     populars_list: [],
    //   };
    case action_types.GET_DELIVERY_TIME:
      return {
        ...state,
        loading: true,
      };
    case action_types.GET_DELIVERY_TIME_SUCCESSFULL:
      return {
        ...state,
        delivery_time_list: action.payload,
        loading: false,
      };
    case action_types.GET_DELIVERY_TIME_FAILED:
      return {
        ...state,
        delivery_time_list: [],
        loading: false,
      };
    case action_types.SET_USER_ENTERED_IN:
      return {
        ...state,
        user_entered_in: true,
      };
    case action_types.SET_ORDER_REQUIRED_INFO:
      return {
        ...state,
        selected_delivery_time: action.payload.delivery_time,
        special_note: action.payload.special_note,
        selected_address: action.payload.selected_address,
      };
    default:
      return state;
  }
};
export default shopSettingsReduser;
