import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast, Toaster } from "react-hot-toast";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

import LoaderMini from "../Componenets/LoderMini";
import Loader from "../Componenets/Loader";
import {
  addAddressAction,
  setDefaultAction,
  getAddressesListAction,
  updateAddressAction,
} from "../redux/Profile/Actions";
import {
  primary_color,
  primary_light,
  product_description_color,
  white,
  white_dark,
  black_product_title,
  black_dark,
  light_border_color,
} from "../constants/Colors";
import { google_api_key } from "../constants/Keys";
import { sign_login } from "../constants/myList";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Addresses = ({
  hide_addresses_list,
  show_address_list,
  isCheckout,
  set_selected_address,
  selected_address,
  add_address_button_clicked,
}) => {
  const address_ref = useRef();
  const postcode_ref = useRef();
  const dispatch = useDispatch();

  const [address, setAddress] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [flat, setFlat] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [addressesList, setAddressesList] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(1);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [muiInputFontSize, setMuiInputFontSize] = useState(16);
  const [select, setSelect] = useState(null);
  const [inputValue, setInputValue] = useState("fgfgfg");
  const [disableInputs, setDisableInputs] = useState(true);

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { shop_id, login_key } = useSelector(
    (state) => state.shopSettingsReduser
  );
  const {
    user_info,
    sign_up_loading,
    addresses_list,
    result_successfull,
    result_failed,
    result_message,
  } = useSelector((state) => state.profileReduser);

  useEffect(() => {
    if (showAddAddress) {
      if (!address && !city && !postcode) {
        setDisableInputs(true);
        postcode_ref.current.focus();
      } else {
        setDisableInputs(false);
      }
    }
  }, [address, city, postcode, showAddAddress]);

  useEffect(() => {
    setShowAddAddress(!show_address_list);
  }, []);

  const handleAddAddress = () => {
    if (address && postcode && city) {
      add_address_button_clicked();
      if (selectedAddress) {
        dispatch(
          updateAddressAction(
            shop_id,
            login_key,
            user_info.CustomerId,
            selectedAddress.CustomerAddressId,
            address,
            postcode,
            city,
            flat
          )
        );
      } else {
        dispatch(
          addAddressAction(
            shop_id,
            login_key,
            user_info.CustomerId,
            address,
            postcode,
            city,
            flat
          )
        );
      }
    } else {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    }
  };

  const handleAreaClicked = () => {
    if (!sign_up_loading) {
      hide_addresses_list();
    }
  };

  useEffect(() => {
    if (isCheckout) {
      dispatch(
        getAddressesListAction(shop_id, login_key, user_info.CustomerId)
      );
    }
  }, []);

  useEffect(() => {
    if (addresses_list.length > 0) {
      if (selected_address) {
        let temp = [...addresses_list];
        for (let i = 0; i < temp.length; i++) {
          if (selected_address.CustomerAddressId == temp[i].CustomerAddressId) {
            temp[i].checked = true;
          } else {
            temp[i].checked = false;
          }
        }
        setAddressesList(temp);
      } else {
        if (isCheckout) {
          if (addresses_list.length == 1) {
            set_selected_address(addresses_list[0]);
            setAddressesList(addresses_list);
            hide_addresses_list();
          } else {
            setAddressesList(addresses_list);
          }
        } else {
          setAddressesList(addresses_list);
        }
      }
      setShowAddAddress(false);
    }
  }, [addresses_list]);

  // useEffect(() => {
  //   if (result_successfull) {
  //     toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
  //       position: "top-center",
  //       duration: 3000,
  //       style: { backgroundColor: "green", color: "white" },
  //       icon: (
  //         <i
  //           style={{ fontSize: 40 }}
  //           class="fa fa-check-circle"
  //           aria-hidden="true"
  //         ></i>
  //       ),
  //     });
  //     dispatch(setDefaultAction());
  //   } else if (result_failed) {
  //     toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
  //       position: "top-center",
  //       duration: 3000,
  //       style: { backgroundColor: "red", color: "white" },
  //       icon: (
  //         <i
  //           style={{ fontSize: 40 }}
  //           class="fa fa-times-circle"
  //           aria-hidden="true"
  //         ></i>
  //       ),
  //     });
  //     dispatch(setDefaultAction());
  //   }
  // }, [result_successfull, result_failed]);

  const handleEditAddress = (item) => {
    setShowAddAddress(true);
    setAddress(item.Address ? item.Address : "");
    setPostcode(item.PostCode ? item.PostCode : "");
    // setInputValue({ label: item.PostCode, value: 2 });
    setCity(item.City ? item.City : "");
    setFlat(item.Flat ? item.Flat : "");
    setSelectedAddress(item);
  };
  const handleAddNewAddress = () => {
    setShowAddAddress(true);
    setAddress("");
    setPostcode("");
    setCity("");
    setFlat("");
    setSelectedAddress(null);
  };

  const handleAddressClicked = (address_id) => {
    const tempList = [...addressesList];
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].CustomerAddressId == address_id) {
        tempList[i].checked = true;
        set_selected_address(tempList[i]);
      } else {
        tempList[i].checked = false;
      }
    }
    setAddressesList(tempList);
    hide_addresses_list();
  };
  useEffect(() => {
    if (fullAddress) {
      console.log("Full address: ", fullAddress);
      geocodeByPlaceId(fullAddress.value.place_id)
        .then((results) => {
          console.log("Results: ", results);
          postcode_ref.current.blur();
          setInputValue({
            label: results[0].formatted_address,
            value: 1,
          });
          if (results[0].address_components) {
            let st_number = "";
            let my_address = "";
            setStreetNumber("");
            for (let i = 0; i < results[0].address_components.length; i++) {
              if (results[0].address_components[i].types[0] == "postal_code") {
                setPostcode(results[0].address_components[i].long_name);
              } else if (
                results[0].address_components[i].types[0] == "postal_town"
              ) {
                setCity(results[0].address_components[i].long_name);
              } else if (results[0].address_components[i].types[0] == "route") {
                my_address = results[0].address_components[i].long_name;
              } else if (
                results[0].address_components[i].types[0] == "street_number"
              ) {
                st_number = results[0].address_components[i].long_name;
                setStreetNumber(results[0].address_components[i].long_name);
              }
            }
            if (st_number) {
              setAddress(`${st_number} ${my_address}`);
            } else {
              setAddress(my_address);
            }
          }
        })
        .catch((error) => {
          alert(String(error.message));
        });
    }
  }, [fullAddress]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 761) {
        setMuiInputFontSize(12);
      } else {
        setMuiInputFontSize(15);
      }
    }

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 761) {
      setMuiInputFontSize(12);
    } else {
      setMuiInputFontSize(15);
    }
  }, []);

  return (
    <div className="create_shop_popup_container" onClick={handleAreaClicked}>
      <div
        className="create_shop_popup_content_container"
        onClick={(i) => i.stopPropagation()}
        id="create_shop_popup_content_container_id"
      >
        {!showAddAddress ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "3%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {/* HEADER MOBILE*/}
            <div
              className="basket_header_container"
              style={{
                borderRadius: 3,
                width: "95%",
                alignSelf: "center",
                height: 50,
              }}
            >
              <h6>{lang.addresses_list}</h6>

              <div
                className="mobile_basket_close_button"
                onClick={handleAreaClicked}
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
            {/* ***** */}

            {/* ADDRESES LIST */}
            <div className="addresses_list_main_container">
              <div id="address_header_title">
                <h5>{lang.addresses_list}</h5>
              </div>
              {addressesList.map((item) => (
                <div className="address_list_item_container">
                  <div className="address_list_address_container">
                    {isCheckout ? (
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                      >
                        <FormControlLabel
                          value={item.CustomerAddressId}
                          control={
                            <Radio
                              checked={item.checked}
                              onChange={() =>
                                handleAddressClicked(item.CustomerAddressId)
                              }
                              sx={{
                                "&.Mui-checked": {
                                  color: "red",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                fontSize: muiInputFontSize,
                              }}
                              className="addresses_list_radio_button_label"
                            >
                              {item.Flat
                                ? `${item.Flat} ${item.Address} (${item.PostCode})`
                                : `${item.Address} (${item.PostCode})`}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    ) : (
                      <p>
                        {item.Flat
                          ? `${item.Flat} ${item.Address} (${item.PostCode})`
                          : `${item.Address} (${item.PostCode})`}
                      </p>
                    )}
                  </div>
                  <div className="address_list_edit_container">
                    <i
                      class="fa fa-pencil-square-o"
                      aria-hidden="true"
                      onClick={() => handleEditAddress(item)}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
            {/* ADD NEW ADDRESS */}
            <div className="address_list_button_container">
              <div id="add_address_button" onClick={handleAddNewAddress}>
                <i class="fa fa-plus-square" aria-hidden="true"></i>
                <h6>{lang.add_new_address}</h6>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="create_shop_popup_content_container"
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "flex-start",
              overflowY: "auto",
            }}
          >
            <div className="addresses_inputs_container">
              <div className="add_address_title_container">
                {addresses_list.length > 0 ? (
                  <i
                    class="fa fa-arrow-left"
                    aria-hidden="true"
                    onClick={() => setShowAddAddress(false)}
                  ></i>
                ) : (
                  <div></div>
                )}

                <h5>
                  {selectedAddress ? lang.edit_address : lang.add_address}
                </h5>
                <div></div>
              </div>
              <div className="address_input_container">
                <h6>{lang.address_postcode}</h6>
                <GooglePlacesAutocomplete
                  // apiKey="AIzaSyA_8Z77kXX6ZqEL1TJfamz-k-kkACH0lMA"
                  apiKey={google_api_key}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["uk"],
                    },
                  }}
                  // onLoadFailed={(error) =>
                  //   // alert("Could not inject Google script", error)
                  // }
                  selectProps={{
                    ref: postcode_ref,
                    // ref: (ref) => {
                    //   setSelect(ref);
                    // },
                    // inputValue,
                    onFocus: () => setInputValue(null),
                    // onInputChange: (newInputValue, meta) => {
                    //   setInputValue({ label: newInputValue, value: 4 });
                    //   console.log("input value: ", newInputValue);
                    //   console.log("meta: ", meta);
                    // },
                    value: inputValue,
                    onChange: (newValue, meta) => {
                      setFullAddress(newValue);
                    },
                    placeholder: "Address / Postcode ...",

                    styles: {
                      input: (provided) => ({
                        ...provided,
                        // backgroundColor: primary_color,
                        height: 50,
                        margin: 0,
                        fontSize: muiInputFontSize,
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 656565565665656,
                      }),
                      menuList: (base) => ({
                        ...base,

                        backgroundColor: "white",
                      }),
                      control: (base, state) => ({
                        ...base,
                        boxShadow: "none",
                        border: state.isFocused
                          ? `1px solid ${primary_color}`
                          : `1px solid ${light_border_color}`,
                        "&:hover": {
                          border: `1px solid ${primary_color}`,
                          fontSize: muiInputFontSize,
                        },
                      }),
                      option: (provided) => ({
                        ...provided,
                        // color: "blue",
                        // backgroundColor: "yellow",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        // color: "blue",
                      }),
                    },
                  }}
                />
              </div>
              <div className="change_password_textfield_container">
                {/* <div className="address_input_container"> */}
                {/* <h6>{lang.address}</h6>
                <input
                  ref={address_ref}
                  type="text"
                  placeholder={`${lang.address} ...`}
                  name="name"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                /> */}

                <TextField
                  value={address}
                  disabled={disableInputs}
                  onChange={(e) => setAddress(e.target.value)}
                  label={lang.address}
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: disableInputs ? null : primary_color,
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: disableInputs ? null : primary_color,
                      },
                    },
                  }}
                  InputProps={{
                    className: "profile_input",
                    sx: {
                      fontSize: muiInputFontSize,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: muiInputFontSize,

                      top: window.innerWidth > 1400 ? -4 : -4,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primary_color,
                      },
                    },
                  }}
                />
              </div>
              <div className="change_password_textfield_container">
                <TextField
                  value={postcode}
                  onChange={(e) => setPostcode(e.target.value)}
                  disabled={disableInputs}
                  label={lang.post_code}
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: disableInputs ? null : primary_color,
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: disableInputs ? null : primary_color,
                      },
                    },
                  }}
                  InputProps={{
                    className: "profile_input",
                    sx: {
                      fontSize: muiInputFontSize,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: muiInputFontSize,

                      top: window.innerWidth > 1400 ? -4 : -4,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primary_color,
                      },
                    },
                  }}
                />
              </div>
              <div className="change_password_textfield_container">
                {/* <div className="address_input_container"> */}
                {/* <h6>{lang.city}</h6>
                <input
                  type="text"
                  placeholder={`${lang.city} ...`}
                  name="name"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                /> */}
                <TextField
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  disabled={disableInputs}
                  label={lang.city}
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: disableInputs ? null : primary_color,
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: disableInputs ? null : primary_color,
                      },
                    },
                  }}
                  InputProps={{
                    className: "profile_input",
                    sx: {
                      fontSize: muiInputFontSize,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: muiInputFontSize,

                      top: window.innerWidth > 1400 ? -4 : -4,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primary_color,
                      },
                    },
                  }}
                />
              </div>
              <div className="change_password_textfield_container">
                <TextField
                  value={flat}
                  onChange={(e) => setFlat(e.target.value)}
                  disabled={disableInputs}
                  label={lang.flat}
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: disableInputs ? null : primary_color,
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: disableInputs ? null : primary_color,
                      },
                    },
                  }}
                  InputProps={{
                    className: "profile_input",
                    sx: {
                      fontSize: muiInputFontSize,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: muiInputFontSize,
                      top: window.innerWidth > 1400 ? -4 : -4,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primary_color,
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="add_address_button_container">
              <button onClick={handleAddAddress}>
                {sign_up_loading ? <LoaderMini /> : lang.save}
              </button>
            </div>
            <div
              className="mobile_add_address_button_container"
              onClick={(i) => i.stopPropagation()}
            >
              <Button onClick={handleAddAddress}>
                {sign_up_loading ? <LoaderMini /> : lang.save}
              </Button>
            </div>
          </div>
        )}
      </div>
      <Toaster />
      {/* {!showAddAddress ? (
        <div
          className="mobile_address_list_button_container"
          onClick={(i) => i.stopPropagation()}
        >
          <div id="add_address_button" onClick={handleAddNewAddress}>
            <i class="fa fa-plus-square" aria-hidden="true"></i>
            <h6>{lang.add_new_address}</h6>
          </div>
        </div>
      ) : (
        <div
          className="mobile_add_address_button_container"
          onClick={(i) => i.stopPropagation()}
        >
          <Button onClick={handleAddAddress}>
            {sign_up_loading ? <LoaderMini /> : lang.save}
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default Addresses;
