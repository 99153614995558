import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { toast, Toaster } from "react-hot-toast";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";

import { pound_sign } from "../../constants/String";
import LoderMini from "../../Componenets/LoderMini";
import MainHeader from "../../Componenets/MainHeader";
import {
  primary_color,
  white,
  dark_border_color,
} from "../../constants/Colors";
import {
  loginAction,
  setDefaultAction,
  editAccountAction,
  getAddressesListAction,
  getCustomerOrdersListAction,
  getCustomerOrderDetailsAction,
  orderDetailsOpenAction,
  selectedCustomerOrderAction,
} from "../../redux/Profile/Actions";
import OrderDetails from "../../Componenets/OrderDetails";

const MyOrdersMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { shop_id, login_key } = useSelector(
    (state) => state.shopSettingsReduser
  );
  const {
    user_info,
    sign_up_loading,
    result_successfull,
    result_failed,
    result_message,
    address_result_successfull,
    address_result_failed,
    address_loading,
    addresses_list,
    customer_orders_list,
    login_loading,
    result_successfull_details,
  } = useSelector((state) => state.profileReduser);

  const [selectedOrderId, setSelectedOrderId] = useState(0);
  const [showOrderDetails, setShowOrderDetails] = useState(false);

  useEffect(() => {
    if (user_info) {
      dispatch(
        getCustomerOrdersListAction(shop_id, login_key, user_info.CustomerId)
      );
    }
  }, []);
  const handleGetOrderDetails = (item) => {
    dispatch(selectedCustomerOrderAction(item));
    setSelectedOrderId(item.OrderId);
    dispatch(getCustomerOrderDetailsAction(shop_id, login_key, item.OrderId));
  };
  useEffect(() => {
    if (result_successfull_details) {
      setShowOrderDetails(true);
      dispatch(orderDetailsOpenAction(true));
      dispatch(setDefaultAction());
    }
  }, [result_successfull_details]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 760) {
        navigate("/profile");
      }
    }

    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <div className="myorders_main_container">
      <MainHeader
        show_all_items={true}
        show_basket={false}
        show_profile={true}
        show_menu_title={window.innerWidth < 760 ? false : true}
      />

      {/* CONTENT */}
      {customer_orders_list.length > 0 ? (
        <div style={{ marginTop: 65 }}>
          {customer_orders_list.map((item) => (
            <div className="myorders_content_container">
              <div className="myorders_mobile_item_container">
                <div>
                  <p>#{item.InvoiceId}</p>
                  <p>
                    {pound_sign}
                    {item.ForPay.toFixed(2)}
                  </p>
                </div>
                <div>
                  <p>{item.SaleMethodName}</p>
                  <p>{item.PayMethodName}</p>
                </div>
                <div>
                  <p>{moment(item.OrderDate).format("YYYY-MM-DD")}</p>
                  <p>{moment(item.OrderDate).format("HH:mm:ss")}</p>
                </div>
              </div>
              <div className="myorders_mobile_button_container">
                <Button
                  className="profile-create-account-button"
                  onClick={() => handleGetOrderDetails(item)}
                  style={{
                    backgroundColor: primary_color,
                    color: white,
                    textTransform: "none",
                  }}
                >
                  {login_loading && selectedOrderId == item.OrderId ? (
                    <LoderMini />
                  ) : (
                    lang.details
                  )}
                </Button>
              </div>
            </div>
          ))}
          {/* {showOrderDetails ? (
            <OrderDetails
              hide_order_dateils={() => {
                setShowOrderDetails(false);
                dispatch(orderDetailsOpenAction(false));
              }}
            />
          ) : null} */}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {login_loading ? <LoderMini color="red" /> : <h4>No Order</h4>}
        </div>
      )}
    </div>
  );
};

export default MyOrdersMobile;
