import React from "react";

import { PaymentElement } from "@stripe/react-stripe-js";
import { Navigate, useNavigate } from "react-router-dom";
import { CardElement } from "@stripe/react-stripe-js";

const CheckoutForm = () => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: "{{CLIENT_SECRET}}",
  };
  const CARD_ELEMENT_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
      base: {
        iconColor: "rgb(240, 57, 122)",
        color: "rgb(240, 57, 122)",
        fontSize: "16px",
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#CFD7DF",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#303238",
        },
      },
    },
  };

  return (
    <div>
      <div class="product-info">
        <h3 className="product-title">Apple MacBook Pro</h3>
        <h4 className="product-price">$999</h4>
      </div>
      <form
      // onSubmit={handleSubmit}
      >
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        {/* <CardSection /> */}
        <button className="btn-pay">Buy Now</button>
      </form>
    </div>
  );
};

export default CheckoutForm;
