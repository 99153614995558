import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";

import { pound_sign } from "../constants/String";
import { primary_color, white, dark_border_color } from "../constants/Colors";
import { collection, delivery } from "../constants/SaleMethodsId";
const OrderDetails = ({ hide_order_dateils }) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { basket_content, basket_item_id, sub_total, sale_method } =
    useSelector((state) => state.basketReduser);
  const {
    user_info,
    sign_up_loading,
    result_successfull,
    result_failed,
    result_message,
    address_result_successfull,
    address_result_failed,
    address_loading,
    addresses_list,
    customer_orders_list,
    login_loading,
    order_details,
    selected_customer_order,
  } = useSelector((state) => state.profileReduser);
  const {
    collectionTime,
    deliveryTime,
    delivery_time_list,
    shop_id,
    login_key,
    delivery_fee,
    carrier_bag_visible,
    carrier_bag_amount,
    carrier_bag_label,
    adminfee_visible,
    adminfee_amount,
    adminfee_label,
    cash_in_collection,
    cash_in_delivery,
    stripe_in_collection,
    stripe_in_delivery,
    paypal_in_collection,
    paypal_in_delivery,
    maintenance_mode,
    delivery_unavailable,
    collection_unavailable,
    go_to_next_route,
    loading,
    user_entered_in,
  } = useSelector((state) => state.shopSettingsReduser);

  return (
    <div
      className="order_details_main_container"
      onClick={() => hide_order_dateils()}
    >
      <div
        className="order_details_container"
        onClick={(i) => i.stopPropagation()}
      >
        {/* HEADER */}
        <div
          className="review_basket_header_container"
          style={{ justifyContent: "space-between" }}
        >
          <div></div>
          <h6 style={{ marginRight: -25 }}>{lang.your_order}</h6>
          {/* MOBILE CLOSE BUTTON */}

          <div
            onClick={() => hide_order_dateils()}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              padding: "0 15px",
            }}
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
        {/* BASKET LIST */}
        <div className="review_basket_list_container">
          {order_details.map((item, index) => (
            // <div style={{ display: "flex" }}>
            //   <div id="product_number_container">
            //     <h6>{item.number}</h6>
            //   </div>

            //   <div
            //     style={{
            //       marginBottom: 20,
            //       marginRight: 5,
            //       flex: 1,
            //       display: "flex",
            //       flexDirection: "column",
            //     }}
            //   >
            //     <div className="basket_product_container">
            //       <div className="basket_product_title_container">
            //         <h6>{item.title}</h6>
            //       </div>
            //       <h6>
            //         {pound_sign}

            //         {item.total_basket_items_price.toFixed(2)}
            //       </h6>
            //     </div>
            //     {item.sub_list.length > 0 ? (
            //       <div className="basket_sublist_container">
            //         {item.sub_list.map((sub_item) => (
            //           <div>
            //             <h6>{sub_item.ItemName}</h6>
            //             <h6>
            //               {pound_sign}
            //               {sub_item.ItemAmount.toFixed(2)}
            //             </h6>
            //           </div>
            //         ))}
            //       </div>
            //     ) : null}
            //   </div>
            //   {order_details.length - 1 > index ? <hr></hr> : null}
            // </div>
            <div>
              <div style={{ display: "flex" }}>
                <div
                  className="mobile_basket_trash_container"
                  style={{
                    width: 32,
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: 6,
                  }}
                >
                  <div id="product_number_container">
                    <h6>{item.number}</h6>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div className="basket_product_container">
                    <div className="basket_product_title_container">
                      <p>{item.title}</p>
                    </div>
                    <p>
                      {pound_sign}
                      {item.total_basket_items_price.toFixed(2)}
                    </p>
                  </div>
                  {item.crust_title ? (
                    <div className="basket_product_container">
                      <div className="basket_product_title_container">
                        <p>{item.crust_title}</p>
                      </div>
                      <p>
                        {pound_sign}
                        {item.crust_amount.toFixed(2)}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>

              {item.sub_list.length > 0 ? (
                <div>
                  {item.sub_list.map((sub_item) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 32,
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingRight: 12,
                          height: "100%",
                        }}
                      >
                        {sub_item.Count / item.number > 1 ? (
                          <p style={{ margin: 0 }}>
                            {sub_item.Count / item.number}
                          </p>
                        ) : null}
                      </div>
                      <p style={{ margin: 0 }}>{sub_item.ItemName}</p>
                    </div>
                  ))}
                </div>
              ) : null}
              {order_details.length - 1 > index ? <hr></hr> : null}
            </div>
          ))}
        </div>
        {/* FOOTER */}
        <div
          className="mobile_basket_footter_container"
          style={{
            backgroundColor: "#f0f0f0",
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          <div className="mobile_basket_footer_content_container">
            <div>
              <h6>{lang.subtotal}</h6>
              <h6>
                {pound_sign}
                {selected_customer_order.SubTotal.toFixed(2)}
              </h6>
            </div>
            {/* CARRIER BAG*/}
            {selected_customer_order.CarrierBag ? (
              <div>
                <h6>{carrier_bag_label}</h6>
                <h6>
                  {pound_sign}
                  {selected_customer_order.CarrierBag.toFixed(2)}
                </h6>
              </div>
            ) : null}
            {/* ****** */}
            {/* ADMIN FEE*/}
            {selected_customer_order.AdminFee ? (
              <div>
                <h6>{adminfee_label}</h6>
                <h6>
                  {pound_sign}
                  {selected_customer_order.AdminFee.toFixed(2)}
                </h6>
              </div>
            ) : null}
            {/* ****** */}
            {/* DELIVERY FEE */}
            {selected_customer_order.SaleMethod == delivery &&
            selected_customer_order.DeliveryCharge ? (
              <div>
                <h6>{lang.delivey_fee}</h6>
                <h6>
                  {pound_sign}
                  {selected_customer_order.DeliveryCharge.toFixed(2)}
                </h6>
              </div>
            ) : null}
            {/* DISCOUNT */}
            {selected_customer_order.TotalDiscount ? (
              <div>
                <h6>{lang.discount}</h6>
                <h6>
                  {pound_sign}
                  {selected_customer_order.TotalDiscount.toFixed(2)}
                </h6>
              </div>
            ) : null}
            {/* ****** */}
            <div>
              <h5>{lang.total}</h5>
              <h5>
                {pound_sign}
                {selected_customer_order.ForPay.toFixed(2)}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
