import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "white",
};
const LoaderMini = ({ color, myWidth, myHeight }) => {
  const [myColor, setMyColor] = useState("");
  useEffect(() => {
    if (color) {
      setMyColor(color);
    }
  }, [color]);

  return (
    <div
      className="loader_mini_component_container"
      style={{
        width: myWidth ? myHeight : "100%",
        height: myHeight ? myHeight : "100%",
      }}
    >
      <ClipLoader
        color={myColor ? myColor : "white"}
        loading={true}
        cssOverride={override}
        size={25}
      />
    </div>
  );
};

export default LoaderMini;
