import * as action_type from "../ActionType";
import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import store from "store2";
import {
  productsServices,
  productSubmenuListServices,
  getSubmenuServices,
  getPopularServices,
  getAllProductsService,
} from "../../services/ProductServices";
import {
  productsListAction,
  productsListSuccessfullAction,
  productsListFailedAction,
  productSubmenuListFailedAction,
  productSubmenuListSuccessfullAction,
  productOptionCrustAction,
  getSubmenuListSuccessfullAction,
  getSubmenuListFailedAction,
  getPopularsListSuccessfull,
  getPopularsListFailed,
  getAllProductsSuccessfull,
  getAllProductsFailed,
} from "../Products/Actions";

function* getAllProductsList({ payload }) {
  try {
    const response = yield call(
      getAllProductsService,
      payload.shop_id,
      payload.login_key
    );
    // console.log("Response all products list");

    if (response.status == 200) {
      if (response.data.search_product) {
        if (response.data.search_product.length > 0) {
          let final_products = [];

          for (let i = 0; i < response.data.search_product.length; i++) {
            if (parseInt(response.data.search_product[i].WebVisible)) {
              if (response.data.search_product[i].AmountDelivery) {
                // final_products.push(response.data.search_product[i]);
                if (response.data.search_product[i].VatId) {
                  for (let j = 0; j < response.data.vat_list.length; j++) {
                    if (
                      response.data.search_product[i].VatId ==
                      response.data.vat_list[j].VatId
                    ) {
                      final_products.push({
                        ...response.data.search_product[i],
                        vat_value: response.data.vat_list[j].VatValue,
                      });
                    }
                  }
                } else {
                  final_products.push({
                    ...response.data.search_product[i],
                    vat_value: 0,
                  });
                }
              } else if (response.data.search_product[i].Options) {
                let options = response.data.search_product[i].Options;
                let temp_product_vat = 0;

                if (response.data.search_product[i].VatId) {
                  for (let j = 0; j < response.data.vat_list.length; j++) {
                    if (
                      response.data.search_product[i].VatId ==
                      response.data.vat_list[j].VatId
                    ) {
                      temp_product_vat = response.data.vat_list[j].VatValue;
                    }
                  }
                } else {
                  temp_product_vat = 0;
                }

                let final_options = [];
                for (let j = 0; j < options.length; j++) {
                  if (options[j].AmountDelivery && options[j].WebVisible) {
                    // final_options.push(options[j]);
                    if (options[j].VatId) {
                      for (let h = 0; h < response.data.vat_list.length; h++) {
                        if (
                          options[j].VatId == response.data.vat_list[h].VatId
                        ) {
                          final_options.push({
                            ...options[j],
                            vat_value: response.data.vat_list[h].VatValue,
                          });
                        }
                      }
                    } else {
                      final_options.push({ ...options[j], vat_value: 0 });
                    }
                  }
                }

                if (final_options.length > 0) {
                  final_products.push({
                    ...response.data.search_product[i],
                    Options: final_options,
                    vat_value: temp_product_vat,
                  });
                }
              }
            }
          }

          // console.log("final_products: ", final_products);
          store.set("all_product_get_time", new Date());
          yield put(getAllProductsSuccessfull(final_products));
        } else {
          yield put(getAllProductsFailed(null));
        }
      } else {
        yield put(getAllProductsFailed(null));
      }
    } else {
      yield put(getAllProductsFailed(null));
    }
  } catch (error) {
    // console.log("Catch all products");

    yield put(getAllProductsFailed(error.message));
  }
}
function* productList({ payload }) {
  try {
    const response = yield call(
      productsServices,
      payload.login_key,
      payload.shop_id,
      payload.category_id
    );
    // console.log("response product list");
    // console.log(response);
    if (response.status == 200) {
      if (response.data.product_List.length > 0) {
        let final_products = [];

        for (let i = 0; i < response.data.product_List.length; i++) {
          if (parseInt(response.data.product_List[i].WebVisible)) {
            if (response.data.product_List[i].AmountDelivery) {
              if (response.data.product_List[i].VatId) {
                for (let j = 0; j < response.data.vat_list.length; j++) {
                  if (
                    response.data.product_List[i].VatId ==
                    response.data.vat_list[j].VatId
                  ) {
                    final_products.push({
                      ...response.data.product_List[i],
                      vat_value: response.data.vat_list[j].VatValue,
                    });
                  }
                }
              } else {
                final_products.push({
                  ...response.data.product_List[i],
                  vat_value: 0,
                });
              }
            } else if (response.data.product_List[i].Options) {
              let options = response.data.product_List[i].Options;
              let temp_product_vat = 0;

              if (response.data.product_List[i].VatId) {
                for (let j = 0; j < response.data.vat_list.length; j++) {
                  if (
                    response.data.product_List[i].VatId ==
                    response.data.vat_list[j].VatId
                  ) {
                    temp_product_vat = response.data.vat_list[j].VatValue;
                  }
                }
              } else {
                temp_product_vat = 0;
              }

              let final_options = [];
              for (let j = 0; j < options.length; j++) {
                if (options[j].AmountDelivery && options[j].WebVisible) {
                  if (options[j].VatId) {
                    for (let h = 0; h < response.data.vat_list.length; h++) {
                      if (options[j].VatId == response.data.vat_list[h].VatId) {
                        final_options.push({
                          ...options[j],
                          vat_value: response.data.vat_list[h].VatValue,
                        });
                      }
                    }
                  } else {
                    final_options.push({ ...options[j], vat_value: 0 });
                  }
                }
              }
              if (final_options.length > 0) {
                final_products.push({
                  ...response.data.product_List[i],
                  Options: final_options,
                  vat_value: temp_product_vat,
                });
              }
            }
          }
        }

        yield put(productsListSuccessfullAction(final_products));
      } else {
        yield put(productsListFailedAction());
      }
    } else {
      yield put(productsListFailedAction());
    }
  } catch (error) {
    yield put(productsListFailedAction(error.message));
  }
}
function* productSubmenuList({ payload }) {
  try {
    const response = yield call(
      productSubmenuListServices,
      payload.login_key,
      payload.shop_id,
      payload.product_id,
      payload.category_id
    );

    // console.log("Response product submenu: ", response);

    if (response.status == 200) {
      const myList = [];
      let items = [];
      if (response.data.product_submenu_List.length > 0) {
        let firstMinSelectMoreThanZeroDetected = false;
        let temSubMenuList = response.data.product_submenu_List;
        for (let i = 0; i < temSubMenuList.length; i++) {
          let arrey = [];

          if (temSubMenuList[i].MinSelect > 0) {
            if (!firstMinSelectMoreThanZeroDetected) {
              if (temSubMenuList[i].Items) {
                let tempItemsList = temSubMenuList[i].Items;
                for (let index = 0; index < tempItemsList.length; index++) {
                  arrey.push({
                    ...tempItemsList[index],
                    checked: false,
                    number: 0,
                    showNumberSelector: false,
                    minusIconStyles: true,
                    plusIconStyles: true,
                    disable: false,
                    plus: false,
                    minus: false,
                  });
                }

                items[i] = {
                  ...temSubMenuList[i],
                  Items: arrey,
                  subNumber: 0,
                  disable: true,
                };
                firstMinSelectMoreThanZeroDetected = true;
              }
            } else {
              if (temSubMenuList[i].Items) {
                let tempItemsList = temSubMenuList[i].Items;
                for (let index = 0; index < tempItemsList.length; index++) {
                  arrey.push({
                    ...tempItemsList[index],
                    checked: false,
                    number: 0,
                    showNumberSelector: false,
                    minusIconStyles: true,
                    plusIconStyles: true,
                    disable: true,
                    plus: false,
                    minus: false,
                  });
                }

                items[i] = {
                  ...temSubMenuList[i],
                  Items: arrey,
                  subNumber: 0,
                  disable: true,
                };
              }
            }
          } else {
            if (!firstMinSelectMoreThanZeroDetected) {
              if (temSubMenuList[i].Items) {
                let tempItemsList = temSubMenuList[i].Items;
                for (let index = 0; index < tempItemsList.length; index++) {
                  arrey.push({
                    ...tempItemsList[index],
                    checked: false,
                    number: 0,
                    showNumberSelector: false,
                    minusIconStyles: true,
                    plusIconStyles: true,
                    disable: false,
                    plus: false,
                    minus: false,
                  });
                }

                items[i] = {
                  ...temSubMenuList[i],
                  Items: arrey,
                  subNumber: 0,
                  disable: true,
                };
              }
            } else {
              if (temSubMenuList[i].Items) {
                let tempItemsList = temSubMenuList[i].Items;
                for (let index = 0; index < tempItemsList.length; index++) {
                  arrey.push({
                    ...tempItemsList[index],
                    checked: false,
                    number: 0,
                    showNumberSelector: false,
                    minusIconStyles: true,
                    plusIconStyles: true,
                    disable: true,
                    plus: false,
                    minus: false,
                  });
                }

                items[i] = {
                  ...temSubMenuList[i],
                  Items: arrey,
                  subNumber: 0,
                  disable: true,
                };
              }
            }
          }
        }

        let final_submenu = [];
        for (let i = 0; i < items.length; i++) {
          let final_items = [];
          if (items[i].WebVisible && items[i].Items) {
            let temp_items = items[i].Items;
            for (let j = 0; j < temp_items.length; j++) {
              if (temp_items[j].WebVisible) {
                if (temp_items[j].VatId) {
                  for (let h = 0; h < response.data.vat_list.length; h++) {
                    if (
                      temp_items[j].VatId == response.data.vat_list[h].VatId
                    ) {
                      final_items.push({
                        ...temp_items[j],
                        vat_value: response.data.vat_list[h].VatValue,
                        vat_item: 0,
                      });
                    }
                  }
                } else {
                  final_items.push({
                    ...temp_items[j],
                    vat_value: 0,
                    vat_item: 0,
                  });
                }
              }
            }
            final_submenu.push({ ...items[i], Items: final_items });
          }
        }

        // console.log("Final_submenu saga:", final_submenu);

        yield put(productSubmenuListSuccessfullAction(final_submenu));
      } else {
        yield put(productSubmenuListFailedAction());
      }
    } else {
      yield put(productSubmenuListFailedAction());
    }
  } catch (error) {
    yield put(productSubmenuListFailedAction(error.message));
  }
}
function* getSubmenuList({ payload }) {
  try {
    const response = yield call(
      getSubmenuServices,
      payload.login_key,
      payload.shop_id
    );

    // console.log("Response submenu: ", response);

    if (response.status == 200) {
      if (response.data.submenu_List) {
        yield put(getSubmenuListSuccessfullAction(response.data.submenu_List));
      } else {
        yield put(getSubmenuListFailedAction(null));
      }
    } else {
      yield put(getSubmenuListFailedAction(null));
    }
  } catch (error) {
    yield put(getSubmenuListFailedAction(error.message));
  }
}
function* getPopularList({ payload }) {
  try {
    const response = yield call(
      getPopularServices,
      payload.shop_id,
      payload.login_key
    );

    // console.log("response popular list");
    // console.log(response);

    if (response.status == 200) {
      if (response.data.populers_list) {
        if (response.data.populers_list.length > 0) {
          let final_populars = [];

          for (let i = 0; i < response.data.populers_list.length; i++) {
            if (parseInt(response.data.populers_list[i].WebVisible)) {
              if (response.data.populers_list[i].AmountDelivery) {
                // final_populars.push(response.data.populers_list[i]);

                if (response.data.populers_list[i].VatId) {
                  for (let j = 0; j < response.data.vat_list.length; j++) {
                    if (
                      response.data.populers_list[i].VatId ==
                      response.data.vat_list[j].VatId
                    ) {
                      final_populars.push({
                        ...response.data.populers_list[i],
                        vat_value: response.data.vat_list[j].VatValue,
                      });
                    }
                  }
                } else {
                  final_populars.push({
                    ...response.data.populers_list[i],
                    vat_value: 0,
                  });
                }
              } else if (response.data.populers_list[i].Options) {
                let options = response.data.populers_list[i].Options;

                let temp_product_vat = 0;

                if (response.data.populers_list[i].VatId) {
                  for (let j = 0; j < response.data.vat_list.length; j++) {
                    if (
                      response.data.populers_list[i].VatId ==
                      response.data.vat_list[j].VatId
                    ) {
                      temp_product_vat = response.data.vat_list[j].VatValue;
                    }
                  }
                } else {
                  temp_product_vat = 0;
                }

                let final_options = [];
                for (let j = 0; j < options.length; j++) {
                  if (options[j].AmountDelivery && options[j].WebVisible) {
                    // final_options.push(options[j]);
                    if (options[j].VatId) {
                      for (let h = 0; h < response.data.vat_list.length; h++) {
                        if (
                          options[j].VatId == response.data.vat_list[h].VatId
                        ) {
                          final_options.push({
                            ...options[j],
                            vat_value: response.data.vat_list[h].VatValue,
                          });
                        }
                      }
                    } else {
                      final_options.push({ ...options[j], vat_value: 0 });
                    }
                  }
                }
                if (final_options.length > 0) {
                  final_populars.push({
                    ...response.data.populers_list[i],
                    Options: final_options,
                    vat_value: temp_product_vat,
                  });
                }
              }
            }
          }

          yield put(getPopularsListSuccessfull(final_populars));
        } else {
          yield put(getPopularsListFailed(null));
        }
      } else {
        yield put(getPopularsListFailed(null));
      }
    } else {
      yield put(getPopularsListFailed(null));
    }
  } catch (error) {
    // console.log("catch popular list");
    // console.log(error.message);
    yield put(getPopularsListFailed(error.message));
  }
}
export function* watchProductSubmenuList() {
  yield takeLatest(action_type.PRODUCT_SUBMENU_LIST, productSubmenuList);
}
export function* watchProductList() {
  yield takeLatest(action_type.PRODUCT_LIST, productList);
}
export function* watchGetSubmenuList() {
  yield takeLatest(action_type.GET_SUBMENU_LIST, getSubmenuList);
}
export function* watchGetPopularsList() {
  yield takeLatest(action_type.GET_POPULARS_LIST, getPopularList);
}
export function* watchGetAllProductsList() {
  yield takeLatest(action_type.GET_ALL_PRODUCTS, getAllProductsList);
}
export default function* rootProduct() {
  yield all([
    fork(watchProductList),
    fork(watchProductSubmenuList),
    fork(watchGetSubmenuList),
    fork(watchGetPopularsList),
    fork(watchGetAllProductsList),
  ]);
}
