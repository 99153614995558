import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { toast, Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import store from "store2";

import sha256 from "crypto-js/sha256";

import MainHeader from "../../Componenets/MainHeader";
import {
  loginAction,
  setDefaultAction,
  editAccountAction,
  getAddressesListAction,
  getCustomerOrdersListAction,
  getCustomerOrderDetailsAction,
  orderDetailsOpenAction,
} from "../../redux/Profile/Actions";
import LoaderMini from "../../Componenets/LoderMini";
import {
  light_border_color,
  primary_color,
  white,
} from "../../constants/Colors";
import ChangePassword from "../../Componenets/ChangePassword";
import Addresses from "../../Componenets/Addresses";
import MyOrders from "./MyOrders";
import OrderDetails from "../../Componenets/OrderDetails";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { user_entered_in } = useSelector((state) => state.shopSettingsReduser);
  const { shop_id, login_key } = useSelector(
    (state) => state.shopSettingsReduser
  );
  const {
    user_info,
    sign_up_loading,
    result_successfull,
    result_failed,
    result_message,
    address_result_successfull,
    address_result_failed,
    address_loading,
    addresses_list,
    customer_orders_list,
    result_successfull_details,
    result_failed_details,
  } = useSelector((state) => state.profileReduser);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showAddressesList, setShowAddressesList] = useState(false);
  const [addressClicked, setAddressClicked] = useState(false);
  const [show_address_list, set_show_address_list] = useState(false);
  const [clickedSidebarItemId, setClickedSidebarItemId] = useState(1);
  const [sideBarUserName, setSideBarUserName] = useState("");
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);

  useEffect(() => {
    if (user_info) {
      setEmail(user_info.Mail);
      setFirstName(user_info.FirstName);
      setLastName(user_info.LastName);
      setPhone(user_info.Mobile);
      setPassword(user_info.Pass);
      setSideBarUserName(`${user_info.FirstName} ${user_info.LastName}`);
      store.set("user_info", user_info);
      // if (cookies.userInfo) {
      //   setCookie("userInfo", JSON.stringify(user_info), {
      //     path: "/",
      //     maxAge: 31536000,
      //   });
      // }
    }
  }, [user_info]);

  const handleEditProfile = (pass) => {
    dispatch(
      editAccountAction(
        shop_id,
        login_key,
        user_info.CustomerId,
        firstName,
        lastName,
        phone,
        "",
        email,
        pass ? pass : password,
        user_info.IsVerified
      )
    );
  };

  useEffect(() => {
    if (result_successfull) {
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
      dispatch(setDefaultAction());
      setShowChangePassword(false);
    } else if (result_failed) {
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
      dispatch(setDefaultAction());
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    if (address_result_successfull && addressClicked) {
      setAddressClicked(false);
      setShowAddressesList(true);
      if (addresses_list) {
        if (addresses_list.length > 0) {
          set_show_address_list(true);
        } else {
          set_show_address_list(false);
        }
      } else {
        set_show_address_list(false);
      }
      dispatch(setDefaultAction());
    } else if (address_result_failed) {
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
      dispatch(setDefaultAction());
      setAddressClicked(false);
    }
  }, [
    address_result_successfull,
    address_result_failed,
    addresses_list,
    addressClicked,
  ]);

  useEffect(() => {
    if (user_info) {
      dispatch(
        getCustomerOrdersListAction(shop_id, login_key, user_info.CustomerId)
      );
    }
    if (!user_entered_in) {
      navigate("/");
    }
  }, []);

  const handleSetPhone = (i) => {
    if (phone.split("").length < 11) {
      setPhone(i);
    }
  };

  useEffect(() => {
    if (result_successfull_details) {
      setShowOrderDetails(true);
      dispatch(orderDetailsOpenAction(true));
      dispatch(setDefaultAction());
    }
  }, [result_successfull_details]);

  useEffect(() => {
    function handleResize() {
      if (clickedSidebarItemId == 2) {
        if (window.innerWidth < 761) {
          navigate("/MyOrdersMobile");
        }
      }
    }

    window.addEventListener("resize", handleResize);
  }, [clickedSidebarItemId]);
  const cryptoData = () => {
    var CryptoJS = require("crypto-js");
    var ciphertext = CryptoJS.AES.encrypt("67", "1367").toString();

    var bytes = CryptoJS.AES.decrypt(ciphertext, "1367");
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
  };

  return (
    <div className="menu-container-div">
      <MainHeader
        show_all_items={true}
        show_basket={false}
        show_profile={false}
        show_menu_title={true}
      />
      <div className="profile_main_container" style={{}}>
        {/* SIDE BAR */}
        <div className="profile-sidebar_container">
          <div
            className="profile-sidebar_item_container"
            key={1}
            onClick={() => setClickedSidebarItemId(1)}
            style={{
              backgroundColor:
                clickedSidebarItemId == 1 ? light_border_color : null,
            }}
          >
            <div className="profile_side_bar_title_container">
              <i
                id="profile_user_icon"
                class="fa fa-user-circle-o"
                aria-hidden="true"
              ></i>
              <h5>{sideBarUserName}</h5>
            </div>
            <div>
              {clickedSidebarItemId == 1 ? (
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              ) : null}
            </div>
          </div>

          <div
            className="profile-sidebar_item_container"
            key={2}
            onClick={() => setClickedSidebarItemId(2)}
            style={{
              backgroundColor:
                clickedSidebarItemId == 2 ? light_border_color : null,
            }}
          >
            <div className="profile_side_bar_title_container">
              <i
                id="profile_user_icon"
                class="fa fa-list"
                aria-hidden="true"
              ></i>
              <h5>{lang.my_orders}</h5>
            </div>

            <div>
              {clickedSidebarItemId == 2 ? (
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              ) : null}
            </div>
          </div>
        </div>
        {/* ACCOUNT */}
        {clickedSidebarItemId == 1 ? (
          <div className="profile-create-acount">
            <div className="profile-container-secifications">
              <h4>{lang.user_details}</h4>
              <div className="profile-container-inputs">
                <TextField
                  value={firstName}
                  id="outlined-basic"
                  label={lang.first_name}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setFirstName(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: primary_color,
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: primary_color,
                      },
                    },
                  }}
                  InputProps={{
                    className: "profile_input",
                    // sx: {
                    //   fontSize: 16,
                    //   height: 50,
                    // },
                  }}
                  InputLabelProps={{
                    className: "profile_input_label",
                    sx: {
                      fontSize: window.innerWidth > 1400 ? 16 : 15,
                      top: window.innerWidth > 1400 ? -1 : -4,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primary_color,
                      },
                    },
                  }}
                />
              </div>
              <div className="profile-container-inputs">
                <TextField
                  value={lastName}
                  id="outlined-basic"
                  label={lang.last_name}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setLastName(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: primary_color,
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: primary_color,
                      },
                    },
                  }}
                  InputProps={{
                    className: "profile_input",
                    // sx: {
                    //   fontSize: 16,
                    //   height: 50,
                    // },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: window.innerWidth > 1400 ? 16 : 15,
                      top: window.innerWidth > 1400 ? -1 : -4,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primary_color,
                      },
                    },
                  }}
                />
              </div>
              {/* EMAIL */}
              <div className="profile-container-inputs">
                <TextField
                  value={email}
                  id="outlined-basic"
                  label={lang.email}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: primary_color,
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: primary_color,
                      },
                    },
                  }}
                  InputProps={{
                    className: "profile_input",
                    // sx: {
                    //   fontSize: 16,
                    //   height: 50,
                    // },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: window.innerWidth > 1400 ? 16 : 15,
                      top: window.innerWidth > 1400 ? -1 : -4,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primary_color,
                      },
                    },
                  }}
                />
              </div>
              {/* PHONE NUMBER */}
              <div className="profile-container-inputs">
                <TextField
                  value={phone}
                  id="outlined-basic"
                  label={lang.phone_number}
                  variant="outlined"
                  fullWidth
                  // onInput={(e) => {
                  //   e.target.value = Math.max(0, parseInt(e.target.value))
                  //     .toString()
                  //     .slice(0, 11);
                  // }}
                  onChange={(e) => setPhone(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: primary_color,
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: primary_color,
                      },
                    },
                  }}
                  InputProps={{
                    className: "profile_input",
                    // sx: {
                    //   fontSize: 16,
                    //   height: 50,
                    // },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: window.innerWidth > 1400 ? 16 : 15,
                      top: window.innerWidth > 1400 ? -1 : -4,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primary_color,
                      },
                    },
                  }}
                />
              </div>
              <div className="buttons_container">
                <Button
                  id="change_password"
                  className="profile-create-account-button"
                  onClick={() => setShowChangePassword(true)}
                >
                  {lang.change_password}
                </Button>
                <Button
                  id="add_address"
                  className="profile-create-account-button"
                  onClick={() => {
                    setAddressClicked(true);
                    dispatch(
                      getAddressesListAction(
                        shop_id,
                        login_key,
                        user_info.CustomerId
                      )
                    );
                  }}
                >
                  {address_loading ? <LoaderMini /> : lang.addresses}
                </Button>
              </div>
              <Button
                className="profile-create-account-button"
                onClick={() => handleEditProfile(null)}
                style={{
                  backgroundColor: primary_color,
                  color: white,
                  margin: "15px 0",
                  textTransform: "none",
                }}
              >
                {sign_up_loading ? <LoaderMini /> : lang.save}
              </Button>

              <Toaster />
            </div>
          </div>
        ) : (
          <MyOrders />
        )}

        <div></div>
      </div>
      {showChangePassword ? (
        <ChangePassword
          hide_change_password={() => setShowChangePassword(false)}
          change_password={(pass) => handleEditProfile(pass)}
        />
      ) : null}
      {showAddressesList ? (
        <Addresses
          hide_addresses_list={() => {
            setShowAddressesList(false);
            setAddressClicked(false);
            dispatch(setDefaultAction());
          }}
          show_address_list={show_address_list}
          isCheckout={false}
          add_address_button_clicked={() => console.log("")}
          // change_password={(pass) => handleEditProfile(pass)}
        />
      ) : null}
      {/* {showOrderDetails ? (
        <OrderDetails
          hide_order_dateils={() => {
            setShowOrderDetails(false);
            dispatch(orderDetailsOpenAction(false));
          }}
        />
      ) : null} */}
    </div>
  );
};

export default Profile;
