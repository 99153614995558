import React from "react";
const ForTest = () => {
  return (
    <div className="for_test_container">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{ width: "45%", height: 100, backgroundColor: "yellow" }}
        ></div>
        <hr className="search_vert" />
        <div
          style={{ width: "45%", height: 100, backgroundColor: "pink" }}
        ></div>
      </div>
    </div>
  );
};

export default ForTest;
