export const baseUrl2 = "https://api.fastorderonline.com/";

export const baseUrl = "https://api.fastorderonline.com/";

export const baseUrlForImagesPrefix = "https://";

export const placeHolderPopularImageUrl = "";
export const successful_link = "";
export const failed_link = "";

export const google_client_id = "";
export const facebook_app_id = "";
