import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import * as action_types from "../../redux/ActionType";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../SliderStyles.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import { useCookies } from "react-cookie";
import Carousel from "react-elastic-carousel";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useInView } from "react-intersection-observer";
import store from "store2";
import moment from "moment";

import { list, dellivery_collection_button } from "../../constants/List";
import { json, Link } from "react-router-dom";
import Select, { components } from "react-select";
import Categories from "../../Componenets/Categories";
import Products from "../../Componenets/Products";
import Loader from "../../Componenets/Loader";
import LoderMini from "../../Componenets/LoderMini";
import User from "../../Componenets/User";
import {
  selectedProductAction,
  showProductSubmenuPopupAction,
  productsListAction,
} from "../../redux/Products/Actions";
import {
  showBasketAction,
  setSaleMethodAction,
  setBasketListAction,
  addToBasketAction,
} from "../../redux/basket/Actions";
import {
  categoryListAction,
  selectedCategoryAction,
} from "../../redux/Categories/Actions";
import {
  getSubmenuListAction,
  getPopularsListAction,
  getAllProductsAction,
} from "../../redux/Products/Actions";
import {
  loginSuccessfullAction,
  setDropDownClickedAction,
} from "../../redux/Profile/Actions";
import {
  setUserEnteredIn,
  setDefaultOrderPlaceStatusAction,
  getShopInfoAction,
  getShopOpenCloseAction,
  getDeliveryFeeAction,
  getPaymentSettingsAction,
  setShopIdAction,
  showMaintenanceModePopup,
  setMaintenanceModePopupMessage,
  getTerminalsAction,
} from "../../redux/settings/Actions";
import BasketLogo from "../../assets/images/basket_logo.jfif";
import { pound_sign } from "../../constants/String";
import {
  placeHolderPopularImageUrl,
  baseUrlForImages,
} from "../../constants/BaseUrl";
import MainHeader from "../../Componenets/MainHeader";
import Alert from "react-bootstrap/Alert";
import {
  primary_color,
  light_border_color,
  dark_border_color,
  primary_light,
  product_description_color,
  white,
  white_dark,
  black_product_title,
  black_dark,
} from "../../constants/Colors";
import BasketEmptyIcon from "../../assets/images/icon_empty_basket.png";
import MobileViewBasketButton from "../../Componenets/MobileViewBasketButton";
import { delivery, collection } from "../../constants/SaleMethodsId";

import DeliveryLight from "../../assets/images/delivery_image.png";
import DeliveryDark from "../../assets/images/delivery_dark_image.png";
import DeliveryMobile from "../../assets/images/delivery_icon_mobile.png";

import { baseUrlForImagesPrefix } from "../../constants/BaseUrl";

const breakPoints = [
  { width: 150, itemsToShow: 1, itemsToScroll: 1, pagination: false },
  { width: 250, itemsToShow: 2, itemsToScroll: 1, pagination: false },
  { width: 450, itemsToShow: 3, itemsToScroll: 1, pagination: false },
  { width: 580, itemsToShow: 4, itemsToScroll: 1, pagination: false },
  { width: 1150, itemsToShow: 5, itemsToScroll: 1, pagination: false },
];

const options = [
  { name: "Swedish", value: "sv" },
  { name: "English", value: "en" },
  {
    type: "group",
    name: "Group name",
    items: [{ name: "Spanish", value: "es" }],
  },
];

const customStyles = {
  dropdownIndicator: (base) => ({
    ...base,
    // display: "none",
    color: "red",
  }),
  control: (base, state) => ({
    ...base,

    minHeight: 30,

    padding: 0,
    boxShadow: "none",
    height: "100%",
    border: state.isFocused
      ? `1px solid ${primary_color}`
      : `1px solid ${light_border_color}`,
    borderRadius: 5,
    "&:hover": {
      border: `1px solid ${primary_color}`,
    },
  }),
  menuList: (base, state) => ({
    ...base,
    backgroundColor: "yellow",
    // height: 40,
    maxHeight: 200,

    // backgroundColor: state.isSelected ? primaryColor : "white",
  }),
  menu: (provided, state) => ({
    ...provided,
    maxHeight: 300,
  }),
  option: (provided, state) => ({
    ...provided,
    // borderBottom: "1px dashed #ccc",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? primary_color : "white",

    // padding: 2,
    // fontSize: "20px",
    "&:hover": {
      backgroundColor: state.isSelected ? primary_color : light_border_color,
    },
  }),
};
const slilder_test = [
  { id: 1, title: "item #1" },
  { id: 2, title: "item #2" },
  { id: 3, title: "item #3" },
  { id: 4, title: "item #4" },
  { id: 5, title: "item #5" },
];

const Menu = () => {
  const ws = new WebSocket(
    `wss://se684k050000.test.connect.paymentsense.cloud/pac?token=196a54d5-4af8-454a-882d-5314a5bf5b1e&api-version=v1&software-house-id=docsSWH&installer-id=docsInstaller`
  );

  const dispatch = useDispatch();
  const searchInputRef = useRef(null);
  const productRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [elRefs, setElRefs] = React.useState([]);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const { selected_category, category_list, promotion_list } = useSelector(
    (state) => state.categoriesRedusers
  );

  const {
    loading_product,
    products_list,
    populars_list,
    all_populars_list,
    show_product_submenu_popup,
    all_product_loader,
  } = useSelector((state) => state.productReduser);
  const { user_info, userDropDownIsClicked } = useSelector(
    (state) => state.profileReduser
  );
  const {
    sub_total,
    sale_method,
    basket_content,
    show_basket,
    basket_item_id,
    basket_product_id_list,
    discount_with_mealdeals,
    discount_without_mealdeals,
    sub_total_without_mealdeal,
  } = useSelector((state) => state.basketReduser);
  const {
    collectionTime,
    deliveryTime,
    delivery_time_list,
    shop_id,
    login_key,
    delivery_fee,
    carrier_bag_visible,
    carrier_bag_amount,
    carrier_bag_label,
    adminfee_visible,
    adminfee_amount,
    adminfee_label,
    cash_in_collection,
    cash_in_delivery,
    stripe_in_collection,
    stripe_in_delivery,
    paypal_in_collection,
    paypal_in_delivery,
    maintenance_mode,
    delivery_unavailable,
    collection_unavailable,
    go_to_next_route,
    loading,
    shop_info,
    basket_button_title,
    open_close_settings_details,
    preorder_status,
    preorder_pressed,
    collection_from,
    delivery_from,
  } = useSelector((state) => state.shopSettingsReduser);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <i
          className="fa fa-search"
          style={{ position: "absolute", left: "10px", top: "10px" }}
        ></i>
      </components.DropdownIndicator>
    );
  };

  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [clicked, setclicked] = useState(1);
  const [showPromotionNotice, setShowPromotionNotice] = useState(false);
  const [promotionDescription, setPromotionDescription] = useState("");
  const [productsLists, setProductsLists] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [defaultValues, setDefaultValue] = useState();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [orderButtonTitle, setOrderButtonTitle] = useState("");
  const [nextRouteAfterBasket, setNextRouteAfterBasket] = useState("");
  const [shopLogo, setShopLogo] = useState("");
  const [bkSize, setBkSize] = useState("cover");
  const [selectCategoryId, setSelectCategoryId] = useState(0);
  const [mobileSelectedCategoryIndex, setMobileSelectedCategoryIndex] =
    useState(0);
  const [changingSelectedCategoey, setChangingSelectedCategory] =
    useState(null);

  useEffect(() => {
    ws.onopen = () => {
      // Connection opened
      console.log("WebSocket connection opened");
      // ws.send('Hello, server!');
    };
    ws.onmessage = (e) => {
      // Receive a message from the server
      console.log(e.data);
    };
    ws.onerror = (e) => {
      // An error occurred
      console.log("error", e.message);
    };
    ws.onclose = (e) => {
      // Connection closed
      console.log("close", e.code, e.reason);
    };
  }, []);
  const payByDojo = () => {
    ws.send({
      jsonrpc: "2.0",
      id: 1,
      method: "CreatePayment",
      params: {
        baseAmount: 0,
        captureMode: "AUTO",
        captureModeOptions: {
          captureModeOptionsAuto: {
            cashbackAmount: 0,
            gratuityAmount: 0,
          },
        },
        cardHolderNotPresent: true,
        currency: "EUR",
        paymentId: "88884eaa-14dd-11ee-be56-0242ac120002",
        reference: "Deposit for table 4 under Mr Jones",
        terminalId: "63212973",
      },
    });
  };

  // useEffect(() => {
  //   if (sub_total && basket_content) {
  //     console.log("11111");
  //     console.log("basket_content 111: ", basket_content);
  //     store.set("temp_basket", basket_content);
  //     store.set("temp_sub_total", sub_total);
  //     store.set("saved_basket_time", new Date());
  //   }
  // }, [sub_total, basket_content]);

  useEffect(() => {
    if (userDropDownIsClicked) {
      setMobileSelectedCategoryIndex(0);
      // elRefs[0].current.scrollIntoView(true, {
      //   behavior: "smooth",
      //   block: "start",
      // });
      dispatch(setDropDownClickedAction(false));
    }
  }, [userDropDownIsClicked]);

  const customStyles = {
    placeholder: (base, state) => ({
      ...base,
      color: "yellow",
    }),
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused ? `1px solid gray` : `1px solid red`,
      paddingLeft: "22px",
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid red`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 500,
      backgroundColor: state.isSelected ? "red" : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "red" : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? "red" : "red",
      },
    }),
  };

  const handleClicked = (item, index) => {
    dellivery_collection_button.forEach((element, myindex) => {
      if (index == myindex) {
        setclicked(myindex);
      }
    });
  };

  // const handleClickedSearchBox = (list, item) => {
  //   setDefaultValue(list);
  //   setChangingSelectedCategory(list);
  // };
  const handleSearchBox = (e) => {
    let productList = [...all_populars_list];
    let newList = [];
    if (e.target.value !== "") {
      // productList = products_list;
      newList = productList.filter((item) => {
        let lc = item.WebTitle.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return lc.includes(filter);
      });
      setSearchList(newList);
    } else {
      setSearchList([]);
      // setProductsLists(products_list);
    }
  };
  useEffect(() => {
    // dispatch(getTerminalsAction());
    dispatch(setShopIdAction(window.SHOP_ID));
    dispatch(setDefaultOrderPlaceStatusAction());
    // store.remove("basket");
    // store.remove("customer_info");
    // store.remove("invoice_id");
    const user_info_temp = store.get("user_info");
    const temp_basket = store.get("temp_basket");
    const discount = store.get("discount");
    const discount_no_mealdeal = store.get("discount_no_mealdeal");
    const temp_sale_method = store.get("sale_method");
    const basket_last_item_id = store.get("basket_last_item_id");
    const temp_sub_total = store.get("temp_sub_total");
    const temp_no_mealdeal_sub_total = store.get("temp_no_mealdeal_sub_total");
    const saved_basket_time = store.get("saved_basket_time");
    const temp_basket_product_id_list = store.get(
      "temp_basket_product_id_list"
    );
    if (temp_sale_method) {
      dispatch(setSaleMethodAction(temp_sale_method));
    } else {
      store.set("sale_method", 1000105);
      dispatch(setSaleMethodAction(1000105));
    }
    if (user_info_temp) {
      dispatch(loginSuccessfullAction(user_info_temp, false));
      const postcode = store.get("postcode");

      if (postcode) {
        dispatch(
          getDeliveryFeeAction(
            user_info_temp.ShopId,
            login_key,
            user_info_temp.CustomerId,
            postcode
          )
        );
      }
    }
    if (temp_basket && saved_basket_time && basket_last_item_id) {
      if (temp_basket.length > 0) {
        if (
          moment(saved_basket_time)
            .add(59, "minutes")
            .format("DD-MM-YYYY HH:mm") >
          moment(new Date()).format("DD-MM-YYYY HH:mm")
        ) {
          dispatch(
            setBasketListAction(
              temp_basket,
              temp_sub_total,
              basket_last_item_id,
              temp_basket_product_id_list,
              discount,
              discount_no_mealdeal,
              temp_no_mealdeal_sub_total
            )
          );
          // dispatch(addToBasketAction(null, basket_last_item_id));
        } else {
          dispatch(setBasketListAction([], 0, null, [], 0, 0, 0));
          store.remove("temp_basket");
          store.remove("temp_sub_total");
          store.remove("basket_last_item_id");
          store.remove("temp_basket_product_id_list");
          store.remove("temp_no_mealdeal_sub_total");
          store.remove("discount");
          store.remove("discount_no_mealdeal");
        }
      }
    }
    // if (cookies.userInfo) {
    //   console.log("cookies.userInfo");
    //   console.log(cookies.userInfo);
    //   dispatch(loginSuccessfullAction(cookies.userInfo, false));
    //   const postcode = store.get("postcode");
    //   console.log("Saved postcode: ", postcode);
    //   if (postcode) {
    //     dispatch(
    //       getDeliveryFeeAction(
    //         cookies.userInfo.ShopId,
    //         login_key,
    //         cookies.userInfo.CustomerId,
    //         postcode
    //       )
    //     );
    //   }
    // }
    dispatch(setUserEnteredIn());
    const category = [];
    for (let i = 0; i < category_list.length; i++) {
      category.push({
        label: category_list[i].WebTitle,
        value: category_list[i].Id,
      });
      setCategories(category);
    }
  }, []);

  useEffect(() => {
    let selectedCategory;
    if (selected_category) {
      selectedCategory = {
        value: selected_category.Id,
        label: selected_category.WebTitle,
      };
    }
    setDefaultValue(selectedCategory);
  }, [selected_category]);

  useEffect(() => {
    if (products_list.length > 0) {
      setProductsLists(products_list);
    } else {
      setProductsLists([]);
    }
  }, [products_list]);

  const handleClickedPopular = (item, index) => {
    const saved_get_all_product_time = store.get("all_product_get_time");

    if (saved_get_all_product_time) {
      if (
        moment(saved_get_all_product_time)
          .add(59, "minutes")
          .format("DD-MM-YYYY HH:mm") >
        moment(new Date()).format("DD-MM-YYYY HH:mm")
      ) {
        for (let i = 0; i < category_list.length; i++) {
          if (category_list[i].Id == item.CategoryId) {
            dispatch(selectedCategoryAction(category_list[i]));
            setSelectCategoryId(category_list[i].Id);
            setMobileSelectedCategoryIndex(i);
            break;
          }
        }

        let del = open_close_settings_details.DeliveryTimGap.split(" ");
        let col = open_close_settings_details.CollectionTimeGap.split(" ");

        if (maintenance_mode) {
          dispatch(showMaintenanceModePopup(true));
        } else if (delivery_unavailable && collection_unavailable) {
          dispatch(showMaintenanceModePopup(true));
          dispatch(
            setMaintenanceModePopupMessage(
              `This shop currently doesn't accept order`,
              false
            )
          );
        } else if (preorder_status) {
          if (delivery_unavailable && collection_from) {
            if (preorder_pressed) {
              // dispatch(showProductSubmenuPopupAction(true));
              setSearchInputValue("");
              searchInputRef.current.value = "";
              setSearchList([]);
              dispatch(selectedProductAction(item));
              dispatch(showProductSubmenuPopupAction(true));
            } else {
              dispatch(showMaintenanceModePopup(true));
            }
            dispatch(
              setMaintenanceModePopupMessage(
                `Collection starts at ${col[1]}`,
                true
              )
            );
            dispatch(selectedProductAction(item));
          } else if (
            delivery_unavailable &&
            !collection_from &&
            !collection_unavailable
          ) {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(collection));
          } else if (collection_unavailable && delivery_from) {
            if (preorder_pressed) {
              // dispatch(showProductSubmenuPopupAction(true));
              setSearchInputValue("");
              searchInputRef.current.value = "";
              setSearchList([]);
              dispatch(selectedProductAction(item));
              dispatch(showProductSubmenuPopupAction(true));
            } else {
              dispatch(showMaintenanceModePopup(true));
            }

            dispatch(
              setMaintenanceModePopupMessage(
                `Delivery starts at ${del[1]}`,
                true
              )
            );
            dispatch(selectedProductAction(item));
          } else if (
            collection_unavailable &&
            !delivery_from &&
            !delivery_unavailable
          ) {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(delivery));
          } else if (delivery_from && collection_from) {
            if (preorder_pressed) {
              // dispatch(showProductSubmenuPopupAction(true));
              setSearchInputValue("");
              searchInputRef.current.value = "";
              setSearchList([]);
              dispatch(selectedProductAction(item));
              dispatch(showProductSubmenuPopupAction(true));
            } else {
              dispatch(showMaintenanceModePopup(true));
            }
            dispatch(selectedProductAction(item));
          } else {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
          }
        } else if (!preorder_status) {
          if (delivery_unavailable && collection_from) {
            dispatch(showMaintenanceModePopup(true));
            dispatch(
              setMaintenanceModePopupMessage(
                `Collection starts at ${col[1]}`,
                false
              )
            );
          } else if (
            delivery_unavailable &&
            !collection_from &&
            !collection_unavailable
          ) {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(collection));
          } else if (collection_unavailable && delivery_from) {
            dispatch(showMaintenanceModePopup(true));
            dispatch(
              setMaintenanceModePopupMessage(
                `Delivery starts at ${del[1]}`,
                false
              )
            );
          } else if (
            collection_unavailable &&
            !delivery_from &&
            !delivery_unavailable
          ) {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(delivery));
          } else if (delivery_from && collection_from) {
            dispatch(
              setMaintenanceModePopupMessage(
                `Delivery starts at ${del[1]}`,
                false
              )
            );
            dispatch(showMaintenanceModePopup(true));
          } else {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
          }
        }
      } else {
        getInitialData();
        dispatch(setBasketListAction([], 0, null, [], 0, 0, 0));
        store.remove("temp_basket");
        store.remove("temp_sub_total");
        store.remove("basket_last_item_id");
        store.remove("temp_basket_product_id_list");
        store.remove("temp_no_mealdeal_sub_total");
        store.remove("discount");
        store.remove("discount_no_mealdeal");
      }
    } else {
      getInitialData();
      dispatch(setBasketListAction([], 0, null, [], 0, 0, 0));
      store.remove("temp_basket");
      store.remove("temp_sub_total");
      store.remove("basket_last_item_id");
      store.remove("temp_basket_product_id_list");
      store.remove("temp_no_mealdeal_sub_total");
      store.remove("discount");
      store.remove("discount_no_mealdeal");
    }
  };

  const handleClciledProduct = (item, index) => {
    setSearchInputValue("");
    searchInputRef.current.value = "";
    setSearchList([]);
    dispatch(selectedProductAction(item));
    dispatch(showProductSubmenuPopupAction(true));
  };
  const handleClickedProductInSearchList = (item, index) => {
    const saved_get_all_product_time = store.get("all_product_get_time");

    if (saved_get_all_product_time) {
      if (
        moment(saved_get_all_product_time)
          .add(59, "minutes")
          .format("DD-MM-YYYY HH:mm") >
        moment(new Date()).format("DD-MM-YYYY HH:mm")
      ) {
        let del = open_close_settings_details.DeliveryTimGap.split(" ");
        let col = open_close_settings_details.CollectionTimeGap.split(" ");

        if (maintenance_mode) {
          dispatch(showMaintenanceModePopup(true));
        } else if (delivery_unavailable && collection_unavailable) {
          dispatch(showMaintenanceModePopup(true));
          dispatch(
            setMaintenanceModePopupMessage(
              `This shop currently doesn't accept order`,
              false
            )
          );
        } else if (preorder_status) {
          if (delivery_unavailable && collection_from) {
            if (preorder_pressed) {
              // dispatch(showProductSubmenuPopupAction(true));
              setSearchInputValue("");
              searchInputRef.current.value = "";
              setSearchList([]);
              dispatch(selectedProductAction(item));
              dispatch(showProductSubmenuPopupAction(true));
            } else {
              dispatch(showMaintenanceModePopup(true));
            }
            dispatch(
              setMaintenanceModePopupMessage(
                `Collection starts at ${col[1]}`,
                true
              )
            );
            dispatch(selectedProductAction(item));
          } else if (
            delivery_unavailable &&
            !collection_from &&
            !collection_unavailable
          ) {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(collection));
          } else if (collection_unavailable && delivery_from) {
            if (preorder_pressed) {
              // dispatch(showProductSubmenuPopupAction(true));
              setSearchInputValue("");
              searchInputRef.current.value = "";
              setSearchList([]);
              dispatch(selectedProductAction(item));
              dispatch(showProductSubmenuPopupAction(true));
            } else {
              dispatch(showMaintenanceModePopup(true));
            }

            dispatch(
              setMaintenanceModePopupMessage(
                `Delivery starts at ${del[1]}`,
                true
              )
            );
            dispatch(selectedProductAction(item));
          } else if (
            collection_unavailable &&
            !delivery_from &&
            !delivery_unavailable
          ) {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(delivery));
          } else if (delivery_from && collection_from) {
            if (preorder_pressed) {
              // dispatch(showProductSubmenuPopupAction(true));
              setSearchInputValue("");
              searchInputRef.current.value = "";
              setSearchList([]);
              dispatch(selectedProductAction(item));
              dispatch(showProductSubmenuPopupAction(true));
            } else {
              dispatch(showMaintenanceModePopup(true));
            }
            dispatch(selectedProductAction(item));
          } else {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
          }
        } else if (!preorder_status) {
          if (delivery_unavailable && collection_from) {
            dispatch(showMaintenanceModePopup(true));
            dispatch(
              setMaintenanceModePopupMessage(
                `Collection starts at ${col[1]}`,
                false
              )
            );
          } else if (
            delivery_unavailable &&
            !collection_from &&
            !collection_unavailable
          ) {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(collection));
          } else if (collection_unavailable && delivery_from) {
            dispatch(showMaintenanceModePopup(true));
            dispatch(
              setMaintenanceModePopupMessage(
                `Delivery starts at ${del[1]}`,
                false
              )
            );
          } else if (
            collection_unavailable &&
            !delivery_from &&
            !delivery_unavailable
          ) {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(delivery));
          } else if (delivery_from && collection_from) {
            dispatch(
              setMaintenanceModePopupMessage(
                `Delivery starts at ${del[1]}`,
                false
              )
            );
            dispatch(showMaintenanceModePopup(true));
          } else {
            setSearchInputValue("");
            searchInputRef.current.value = "";
            setSearchList([]);
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
          }
        }
      } else {
        getInitialData();
        dispatch(setBasketListAction([], 0, null, [], 0, 0, 0));
        store.remove("temp_basket");
        store.remove("temp_sub_total");
        store.remove("basket_last_item_id");
        store.remove("temp_basket_product_id_list");
        store.remove("temp_no_mealdeal_sub_total");
        store.remove("discount");
        store.remove("discount_no_mealdeal");
      }
    } else {
      getInitialData();
      dispatch(setBasketListAction([], 0, null, [], 0, 0, 0));
      store.remove("temp_basket");
      store.remove("temp_sub_total");
      store.remove("basket_last_item_id");
      store.remove("temp_basket_product_id_list");
      store.remove("temp_no_mealdeal_sub_total");
      store.remove("discount");
      store.remove("discount_no_mealdeal");
    }

    for (let i = 0; i < category_list.length; i++) {
      if (category_list[i].Id == item.CategoryId) {
        dispatch(selectedCategoryAction(category_list[i]));
        setSelectCategoryId(category_list[i].Id);
        setMobileSelectedCategoryIndex(i);
        break;
      }
    }

    // setSearchInputValue("");
    // searchInputRef.current.value = "";
    // setSearchList([]);
    // dispatch(selectedProductAction(item));
    // dispatch(showProductSubmenuPopupAction(true));
  };

  const get_data_ref = useRef(true);
  useEffect(() => {
    if (shop_id && !all_populars_list.length > 0) {
      getInitialData();
    }
  }, [shop_id]);
  useEffect(() => {
    if (!category_list.length > 0 && shop_id) {
      dispatch(categoryListAction(login_key, shop_id, 0));
    }
  }, [category_list, shop_id]);

  const handleSelectedCategories = (item, myId) => {
    setSearchInputValue("");
    searchInputRef.current.value = "";
    setSearchList([]);
    dispatch(selectedCategoryAction(item));
    setSelectCategoryId(myId);
    dispatch(productsListAction(login_key, shop_id, myId));
    // category_list.forEach((element, index) => {
    //   if (element.Id == myId) {
    //   }
    // });
  };
  const handleSelectMobileCategory = (item, index) => {
    dispatch(selectedCategoryAction(item));
    setSelectCategoryId(item.Id);
    productRef.current.scrollToView(index);
    setMobileSelectedCategoryIndex(index);
  };
  const handleDeleteBasketItem = (item) => {
    let final_subtotal_without_mealdeals = sub_total_without_mealdeal;
    let final_discount_without_mealdeals = discount_without_mealdeals;
    let final_sub_total = sub_total;
    let final_discount_with_mealdeals = discount_with_mealdeals;
    if (discount_without_mealdeals) {
      let my_day = new Date().getDay();
      for (let i = 0; i < promotion_list.length; i++) {
        if (
          promotion_list[i].day_id == my_day &&
          promotion_list[i].day_promotions.length > 0
        ) {
          let dayPromotion = promotion_list[i].day_promotions;
          for (let j = 0; j < dayPromotion.length; j++) {
            if (dayPromotion[j].ExcludeMealDeals) {
              if (item.is_mealdeal) {
                final_sub_total = sub_total - item.total_basket_items_price;
              } else {
                final_sub_total = sub_total - item.total_basket_items_price;
                final_subtotal_without_mealdeals =
                  sub_total_without_mealdeal - item.total_basket_items_price;
                if (dayPromotion[j].MinOrder) {
                  if (
                    sub_total - item.total_basket_items_price >
                    dayPromotion[j].MinOrder
                  ) {
                    if (dayPromotion[j].RateOption) {
                      final_discount_without_mealdeals =
                        ((sub_total_without_mealdeal -
                          item.total_basket_items_price) *
                          dayPromotion[j].Rate) /
                        100;
                    } else {
                      final_discount_without_mealdeals = dayPromotion[j].Rate;
                    }
                  } else {
                    final_discount_without_mealdeals = 0;
                  }
                } else {
                  if (dayPromotion[j].RateOption) {
                    final_discount_without_mealdeals =
                      ((sub_total_without_mealdeal -
                        item.total_basket_items_price) *
                        dayPromotion[j].Rate) /
                      100;
                  } else {
                    final_discount_without_mealdeals = dayPromotion[j].Rate;
                  }
                }
              }
            } else {
              if (item.is_mealdeal) {
                final_sub_total = sub_total - item.total_basket_items_price;
              } else {
                final_sub_total = sub_total - item.total_basket_items_price;
                final_subtotal_without_mealdeals =
                  sub_total_without_mealdeal - item.total_basket_items_price;
              }

              if (dayPromotion[j].MinOrder) {
                if (
                  sub_total - item.total_basket_items_price >
                  dayPromotion[j].MinOrder
                ) {
                  if (dayPromotion[j].RateOption) {
                    final_discount_with_mealdeals =
                      ((sub_total - item.total_basket_items_price) *
                        dayPromotion[j].Rate) /
                      100;
                  } else {
                    final_discount_with_mealdeals = dayPromotion[j].Rate;
                  }
                } else {
                  final_discount_with_mealdeals = 0;
                }
              } else {
                if (dayPromotion[j].RateOption) {
                  final_discount_with_mealdeals =
                    ((sub_total - item.total_basket_items_price) *
                      dayPromotion[j].Rate) /
                    100;
                } else {
                  final_discount_with_mealdeals = dayPromotion[j].Rate;
                }
              }
            }
          }
        }
      }
    } else if (discount_with_mealdeals) {
      let my_day = new Date().getDay();
      for (let i = 0; i < promotion_list.length; i++) {
        if (
          promotion_list[i].day_id == my_day &&
          promotion_list[i].day_promotions.length > 0
        ) {
          let dayPromotion = promotion_list[i].day_promotions;
          for (let j = 0; j < dayPromotion.length; j++) {
            final_sub_total = sub_total - item.total_basket_items_price;

            if (dayPromotion[j].MinOrder) {
              if (
                sub_total - item.total_basket_items_price >
                dayPromotion[j].MinOrder
              ) {
                if (dayPromotion[j].RateOption) {
                  final_discount_with_mealdeals =
                    ((sub_total - item.total_basket_items_price) *
                      dayPromotion[j].Rate) /
                    100;
                } else {
                  final_discount_with_mealdeals = dayPromotion[j].Rate;
                }
              } else {
                final_discount_with_mealdeals = 0;
              }
            } else {
              if (dayPromotion[j].RateOption) {
                final_discount_with_mealdeals =
                  ((sub_total - item.total_basket_items_price) *
                    dayPromotion[j].Rate) /
                  100;
              } else {
                final_discount_with_mealdeals = dayPromotion[j].Rate;
              }
            }
          }
        }
      }
    } else {
      if (item.is_mealdeal) {
        final_sub_total = sub_total - item.total_basket_items_price;
      } else {
        final_sub_total = sub_total - item.total_basket_items_price;
        final_subtotal_without_mealdeals =
          sub_total_without_mealdeal - item.total_basket_items_price;
      }
    }

    // console.log(
    //   "final_discount_with_mealdeals: ",
    //   final_discount_with_mealdeals
    // );
    // console.log(
    //   "final_discount_without_mealdeals: ",
    //   final_discount_without_mealdeals
    // );
    // console.log("final_sub_total: ", final_sub_total);
    // console.log(
    //   "final_subtotal_without_mealdeals: ",
    //   final_subtotal_without_mealdeals
    // );

    let tempBasketList = [...basket_content];
    let temp_basket_product_id_list = [...basket_product_id_list];
    let final_basket_product_id_list = [];
    let is_one_time_equal = true;
    for (let i = 0; i < temp_basket_product_id_list.length; i++) {
      if (
        temp_basket_product_id_list[i] == item.product_id &&
        is_one_time_equal
      ) {
        is_one_time_equal = false;
      } else {
        final_basket_product_id_list.push(temp_basket_product_id_list[i]);
      }
    }

    tempBasketList = tempBasketList.filter((i) => i.id != item.id);

    dispatch(
      setBasketListAction(
        tempBasketList,
        final_sub_total,
        null,
        final_basket_product_id_list,
        final_discount_with_mealdeals,
        final_discount_without_mealdeals,
        final_subtotal_without_mealdeals
      )
    );
    store.set("discount", final_discount_with_mealdeals);
    store.set("discount_no_mealdeal", final_discount_without_mealdeals);
    store.set("temp_basket", tempBasketList);
    store.set("temp_sub_total", final_sub_total);
    store.set("temp_basket_product_id_list", final_basket_product_id_list);
    store.set("temp_no_mealdeal_sub_total", final_subtotal_without_mealdeals);
  };

  const handleCheckout = () => {
    console.log("000000");
    if (user_info) {
      dispatch(
        getShopOpenCloseAction(shop_id, login_key, false, navigate, "checkout")
      );
      setNextRouteAfterBasket("checkout");

      // navigate("checkout");
      // navigate("paymentfailed");
      // navigate("paymentsuccessful");
    } else {
      console.log("1111");
      dispatch(
        getShopOpenCloseAction(shop_id, login_key, false, navigate, "login/2")
      );

      setNextRouteAfterBasket("login/2");
      // navigate("login/2");
    }
    dispatch(showBasketAction(false));
  };
  useEffect(() => {
    if (selected_category != null) {
      setSelectCategoryId(selected_category.Id);
    }
  }, [selected_category]);

  // useLayoutEffect(() => {
  //   setPassName(location.pathname);
  // }, [location.pathname]);

  useEffect(() => {
    if (category_list.length > 0) {
      setElRefs((elRefs) =>
        Array(category_list.length)
          .fill()
          .map((_, i) => elRefs[i] || React.createRef())
      );
    }
  }, [category_list]);

  const scrollToDesiredCategory = (index) => {
    let ind = mobileSelectedCategoryIndex;

    setMobileSelectedCategoryIndex(ind + 1);
    // handleSelectMobileCategory(null, ind + 1);
    elRefs[ind + 1].current.scrollIntoView(true, {
      behavior: "smooth",
      block: "start",
    });
  };
  useEffect(() => {
    if (go_to_next_route) {
      dispatch(setDefaultOrderPlaceStatusAction());
      navigate(nextRouteAfterBasket);
    }
  }, [go_to_next_route]);

  const emptySearchList = () => {
    setSearchInputValue("");
    searchInputRef.current.value = "";
    setSearchList([]);
  };

  useEffect(() => {
    if (shop_info) {
      function handleResize() {
        if (window.innerWidth > 760) {
          setBkSize("cover");
          setShopLogo(
            `${baseUrlForImagesPrefix}${shop_info.Domain}/${shop_info.BannerImage}`
          );
        } else {
          setBkSize("contain");
          setShopLogo(
            `${baseUrlForImagesPrefix}${shop_info.Domain}/${shop_info.BackgroundImage}`
          );
        }
      }

      window.addEventListener("resize", handleResize);
    }
  }, [shop_info]);

  useEffect(() => {
    if (shop_info) {
      if (window.innerWidth > 760) {
        setShopLogo(
          `${baseUrlForImagesPrefix}${shop_info.Domain}/${shop_info.BannerImage}`
        );
      } else {
        setShopLogo(
          `${baseUrlForImagesPrefix}${shop_info.Domain}/${shop_info.BackgroundImage}`
        );
      }
    }
  }, [shop_info]);

  useEffect(() => {
    if (promotion_list) {
      if (promotion_list.length > 0) {
        let my_day = new Date().getDay();
        for (let i = 0; i < promotion_list.length; i++) {
          if (
            promotion_list[i].day_id == my_day &&
            promotion_list[i].day_promotions.length > 0
          ) {
            setShowPromotionNotice(
              promotion_list[i].day_promotions[0].ShowInSite
            );
            setPromotionDescription(
              promotion_list[i].day_promotions[0].Description
            );
          }
        }
      }
    }
  }, [promotion_list]);

  const handleChangeSaleMethod = (selected_sale_method) => {
    dispatch(setSaleMethodAction(selected_sale_method));
    store.set("sale_method", selected_sale_method);

    let my_day = new Date().getDay();
    let productFinalPromotionWithMealdeals = 0;
    let productFinalPromotionWithoutMealdeals = 0;
    for (let i = 0; i < promotion_list.length; i++) {
      if (
        promotion_list[i].day_id == my_day &&
        promotion_list[i].day_promotions.length > 0
      ) {
        let dayPromotion = promotion_list[i].day_promotions;
        for (let j = 0; j < dayPromotion.length; j++) {
          if (dayPromotion[j].RateOption) {
            if (dayPromotion[j].ExcludeMealDeals) {
              if (dayPromotion[j].MinOrder) {
                if (sub_total_without_mealdeal > dayPromotion[j].MinOrder) {
                  if (dayPromotion[j].SaleMethod == 1) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                  } else if (
                    dayPromotion[j].SaleMethod == collection &&
                    selected_sale_method == collection
                  ) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                  } else if (
                    dayPromotion[j].SaleMethod == delivery &&
                    selected_sale_method == delivery
                  ) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                  }
                }
              } else {
                if (dayPromotion[j].SaleMethod == 1) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                } else if (
                  dayPromotion[j].SaleMethod == collection &&
                  selected_sale_method == collection
                ) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                } else if (
                  dayPromotion[j].SaleMethod == delivery &&
                  selected_sale_method == delivery
                ) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                }
              }
            } else {
              if (dayPromotion[j].MinOrder) {
                if (sub_total > dayPromotion[j].MinOrder) {
                  if (dayPromotion[j].SaleMethod == 1) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals +
                      (sub_total * dayPromotion[j].Rate) / 100;
                  } else if (
                    dayPromotion[j].SaleMethod == collection &&
                    selected_sale_method == collection
                  ) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals +
                      (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                  } else if (
                    dayPromotion[j].SaleMethod == delivery &&
                    selected_sale_method == delivery
                  ) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals +
                      (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                  }
                }
              } else {
                if (dayPromotion[j].SaleMethod == 1) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals +
                    (sub_total * dayPromotion[j].Rate) / 100;
                } else if (
                  dayPromotion[j].SaleMethod == collection &&
                  selected_sale_method == collection
                ) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals +
                    (sub_total * dayPromotion[j].Rate) / 100;
                } else if (
                  dayPromotion[j].SaleMethod == delivery &&
                  selected_sale_method == delivery
                ) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals +
                    (sub_total * dayPromotion[j].Rate) / 100;
                }
              }
            }
          } else {
            if (dayPromotion[j].ExcludeMealDeals) {
              if (dayPromotion[j].MinOrder) {
                if (sub_total_without_mealdeal > dayPromotion[j].MinOrder) {
                  if (dayPromotion[j].SaleMethod == 1) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      dayPromotion[j].Rate;
                  } else if (
                    dayPromotion[j].SaleMethod == collection &&
                    selected_sale_method == collection
                  ) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      dayPromotion[j].Rate;
                  } else if (
                    dayPromotion[j].SaleMethod == delivery &&
                    selected_sale_method == delivery
                  ) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      dayPromotion[j].Rate;
                  }
                }
              } else {
                if (dayPromotion[j].SaleMethod == 1) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    dayPromotion[j].Rate;
                } else if (
                  dayPromotion[j].SaleMethod == collection &&
                  selected_sale_method == collection
                ) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    dayPromotion[j].Rate;
                } else if (
                  dayPromotion[j].SaleMethod == delivery &&
                  selected_sale_method == delivery
                ) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    dayPromotion[j].Rate;
                }
              }
            } else {
              if (dayPromotion[j].MinOrder) {
                if (sub_total > dayPromotion[j].MinOrder) {
                  if (dayPromotion[j].SaleMethod == 1) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                  } else if (
                    dayPromotion[j].SaleMethod == collection &&
                    selected_sale_method == collection
                  ) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                  } else if (
                    dayPromotion[j].SaleMethod == delivery &&
                    selected_sale_method == delivery
                  ) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                  }
                }
              } else {
                if (dayPromotion[j].SaleMethod == 1) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                } else if (
                  dayPromotion[j].SaleMethod == collection &&
                  selected_sale_method == collection
                ) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                } else if (
                  dayPromotion[j].SaleMethod == delivery &&
                  selected_sale_method == delivery
                ) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                }
              }
            }
          }
        }
      }
    }
    // console.log(
    //   "productFinalPromotionWithMealdeals: ",
    //   productFinalPromotionWithMealdeals
    // );
    // console.log(
    //   "productFinalPromotionWithoutMealdeals: ",
    //   productFinalPromotionWithoutMealdeals
    // );

    store.set("discount", productFinalPromotionWithMealdeals);
    store.set("discount_no_mealdeal", productFinalPromotionWithoutMealdeals);

    dispatch(
      setBasketListAction(
        basket_content,
        sub_total,
        null,
        basket_product_id_list,
        productFinalPromotionWithMealdeals,
        productFinalPromotionWithoutMealdeals,
        sub_total_without_mealdeal
      )
    );
  };

  const getInitialData = () => {
    dispatch(categoryListAction(login_key, shop_id, 0));
    dispatch(getShopInfoAction(shop_id, login_key));
    dispatch(getAllProductsAction(shop_id, login_key));
    dispatch(getSubmenuListAction(login_key, shop_id));
    dispatch(getPopularsListAction(shop_id, login_key));
    dispatch(
      getShopOpenCloseAction(shop_id, login_key, true, navigate, "checkout")
    );
    dispatch(getPaymentSettingsAction(shop_id, login_key));
  };

  return (
    <div className="menu-container-div">
      <MainHeader
        show_all_items={true}
        mobile_show_logo={true}
        show_basket={true}
        show_profile={true}
        show_menu_title={false}
      />

      <div
        className="menu-container-image"
        style={{
          backgroundImage: shopLogo ? `url('${shopLogo}')` : "",
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {showPromotionNotice ? (
          <div className="discount_notice_container">
            <h1>{promotionDescription}</h1>
            <h5>{promotionDescription}</h5>
          </div>
        ) : null}
        <div className="mobile_time_gap_container">
          {/* DELIVERY COLLECTION */}
          <div
            className="mobile_basket_delivery_collection_container"
            style={{
              height: 45,
              backgroundColor: "rgba(0,0,0,0.5)",
              border: "0px solid red",
              padding: 0,
              width: "65%",
            }}
          >
            <div
              onClick={() =>
                !delivery_unavailable ? handleChangeSaleMethod(delivery) : null
              }
              style={{
                border: sale_method == delivery ? "1px solid #fff" : null,
                display: "flex",
                // flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              <img src={DeliveryMobile} />
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontSize: 11,
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {lang.delivery}
                </p>

                <p style={{ margin: 0, fontSize: 11, color: "white" }}>
                  {deliveryTime}
                </p>
              </span>
            </div>
            <div
              onClick={() =>
                !collection_unavailable
                  ? handleChangeSaleMethod(collection)
                  : null
              }
              style={{
                border: sale_method == collection ? "1px solid #fff" : null,
                display: "flex",
                // flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              <i
                class="fa fa-shopping-basket"
                aria-hidden="true"
                style={{
                  fontSize: 12,
                  marginRight: 5,
                  color: "white",
                }}
              ></i>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: 0,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontSize: 11,
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {lang.collection}
                </p>

                <p style={{ margin: 0, fontSize: 11, color: "white" }}>
                  {collectionTime}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="menu-main-section">
        {/* SLIDER */}
        {populars_list.length > 4 ? (
          <div className="menu-container-list">
            {window.innerWidth > 330 ? (
              <Carousel
                breakPoints={breakPoints}
                // renderPagination={({ pages, activePage, onClick }) => {
                //   return (
                //     <div direction="row">
                //       {pages.map((page) => {
                //         return (
                //           <div
                //             style={{ display: "none" }}
                //             key={page}
                //             onClick={() => onClick(page)}
                //           />
                //         );
                //       })}
                //     </div>
                //   );
                // }}
              >
                {/* <Carousel
                centerMode={true}
                centerSlidePercentage={19}
                showArrows={true}
                showThumbs={false}
                showIndicators={false}
                swipeable={true}
                autoPlay={true}
                infiniteLoop={true}
                onClickItem={(i) => {
                  console.log("click slider");
                  console.log(i);
                }}
              > */}
                {populars_list.map((item, index) => (
                  <div
                    className="menu-get-list-details"
                    onClick={() => handleClickedPopular(item, index)}
                    style={{ width: "100%" }}
                    key={item.Id}
                  >
                    <div>
                      <img
                        src={
                          item.WebImageUrl && item.WebVisibleImage && shop_info
                            ? `${baseUrlForImagesPrefix}${shop_info.Domain}/${item.WebImageUrl}`
                            : placeHolderPopularImageUrl
                        }
                      />
                      <div>
                        <h5>{item.WebTitle}</h5>
                        <h6>{item.WebDescription}</h6>
                      </div>
                    </div>
                    <div className="popular_price_container">
                      <h5>
                        {pound_sign}
                        {item.AmountDelivery
                          ? item.AmountDelivery.toFixed(2)
                          : item.Options[0].AmountDelivery.toFixed(2)}
                      </h5>
                      <div>
                        <p>{item.IsPopular ? lang.popular : lang.suggested}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            ) : null}
          </div>
        ) : null}

        {/* SEARCH DELIVERY COLLECTION */}
        <div
          ref={ref}
          className="menu-container-search-and-dellivery-collection"
        >
          {/* <h5>{`Header inside viewport ${inView}.`}</h5> */}
          {/* SEARCH */}
          <div className="search_box_container">
            <div
              className="menu-container-serachbox"
              style={{ height: "100%" }}
            >
              <i className="fa fa-search"></i>
              <input
                type="search"
                ref={searchInputRef}
                placeholder="Search..."
                onChange={(e) => {
                  handleSearchBox(e);
                  setSearchInputValue(e.target.value);
                }}
              />
            </div>
            {searchInputValue ? (
              <div>
                <div className="search_container">
                  <div className="search_result_title_container">
                    <h4>
                      {searchList.length > 1
                        ? `${searchList.length} ${lang.results_for} "${searchInputValue}"`
                        : `${searchList.length} ${lang.result_for} "${searchInputValue}"`}
                    </h4>
                  </div>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: light_border_color,
                    }}
                  >
                    <div
                      className="product_list_half_container"
                      style={{
                        marginBottom: 20,
                        backgroundColor: light_border_color,
                      }}
                    >
                      {searchList.map((item, index) => (
                        <div key={item.Id}>
                          {index % 2 == 0 ? (
                            <div
                              className="search_product_main_item_container"
                              style={{
                                borderRight:
                                  searchList.length > 1
                                    ? `1px solid ${light_border_color}`
                                    : null,
                                backgroundColor: light_border_color,
                              }}
                            >
                              <div
                                className="search_product_container"
                                style={{
                                  borderBottom:
                                    searchList.length % 2 == 0
                                      ? searchList.length - 2 > index
                                        ? `1px solid ${dark_border_color}`
                                        : null
                                      : searchList.length - 1 > index
                                      ? `1px solid ${dark_border_color}`
                                      : null,
                                  backgroundColor: light_border_color,
                                }}
                                onClick={() =>
                                  handleClickedProductInSearchList(item, index)
                                }
                              >
                                {item.WebImageUrl && item.WebVisibleImage ? (
                                  <div
                                    className="products-container-image"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={
                                        shop_info
                                          ? `${baseUrlForImagesPrefix}${shop_info.Domain}/${item.WebImageUrl}`
                                          : null
                                      }
                                    />
                                  </div>
                                ) : null}
                                <div
                                  className="products-container-texts"
                                  id="search_products-container-texts"
                                >
                                  <div>
                                    <h6>{item.WebTitle}</h6>
                                    <p>{item.WebDescription}</p>
                                  </div>
                                  <h6>
                                    £
                                    {item.AmountDelivery
                                      ? item.AmountDelivery.toFixed(2)
                                      : item.Options
                                      ? item.Options[0].AmountDelivery.toFixed(
                                          2
                                        )
                                      : 0}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>

                    <div
                      className="product_list_half_container"
                      style={{ backgroundColor: light_border_color }}
                    >
                      {searchList.map((item, index) => (
                        <div key={item.Id}>
                          {index % 2 == 1 ? (
                            <div
                              className="search_product_main_item_container"
                              style={{ backgroundColor: light_border_color }}
                            >
                              <div
                                className="search_product_container"
                                style={{
                                  borderBottom:
                                    searchList.length % 2 == 0
                                      ? searchList.length - 1 > index
                                        ? `1px solid ${dark_border_color}`
                                        : null
                                      : searchList.length - 2 > index
                                      ? `1px solid ${dark_border_color}`
                                      : null,
                                  backgroundColor: light_border_color,
                                }}
                                onClick={() =>
                                  handleClickedProductInSearchList(item, index)
                                }
                              >
                                {item.WebImageUrl && item.WebVisibleImage ? (
                                  <div
                                    className="products-container-image"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={
                                        shop_info
                                          ? `${baseUrlForImagesPrefix}${shop_info.Domain}/${item.WebImageUrl}`
                                          : null
                                      }
                                    />
                                  </div>
                                ) : null}
                                <div
                                  className="products-container-texts"
                                  id="search_products-container-texts"
                                >
                                  <div>
                                    <h6>{item.WebTitle}</h6>
                                    <p>{item.WebDescription}</p>
                                  </div>
                                  <h6>
                                    £
                                    {item.AmountDelivery
                                      ? item.AmountDelivery.toFixed(2)
                                      : item.Options
                                      ? item.Options[0].AmountDelivery.toFixed(
                                          2
                                        )
                                      : 0}
                                  </h6>
                                </div>
                              </div>
                              {/* {searchList.length % 2 == 0 ? (
                                searchList.length - 2 > index ? (
                                  <hr />
                                ) : null
                              ) : searchList.length - 1 > index ? (
                                <hr />
                              ) : null} */}
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                    {/* MOBILE VIEW */}
                    <div className="product_list_mobile_view">
                      {searchList.map((item, index) => (
                        <div key={item.Id}>
                          <div
                            className="search_product_main_item_container"
                            style={{ backgroundColor: light_border_color }}
                          >
                            <div
                              className="search_product_container"
                              style={{
                                borderBottom:
                                  searchList.length % 2 == 0
                                    ? searchList.length - 1 > index
                                      ? `1px solid ${dark_border_color}`
                                      : null
                                    : searchList.length - 2 > index
                                    ? `1px solid ${dark_border_color}`
                                    : null,
                                backgroundColor: light_border_color,
                              }}
                              onClick={() =>
                                handleClickedProductInSearchList(item, index)
                              }
                            >
                              {item.WebImageUrl && item.WebVisibleImage ? (
                                <div
                                  className="products-container-image"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={
                                      shop_info
                                        ? `${baseUrlForImagesPrefix}${shop_info.Domain}/${item.WebImageUrl}`
                                        : null
                                    }
                                  />
                                </div>
                              ) : null}
                              <div
                                className="products-container-texts"
                                id="search_products-container-texts"
                              >
                                <div>
                                  <h6>{item.WebTitle}</h6>
                                  <p>{item.WebDescription}</p>
                                </div>
                                <h6 id="product_price">
                                  £
                                  {item.AmountDelivery
                                    ? item.AmountDelivery.toFixed(2)
                                    : item.Options
                                    ? item.Options[0].AmountDelivery.toFixed(2)
                                    : 0}
                                </h6>
                              </div>
                            </div>
                            {/* {searchList.length % 2 == 0 ? (
                                searchList.length - 2 > index ? (
                                  <hr />
                                ) : null
                              ) : searchList.length - 1 > index ? (
                                <hr />
                              ) : null} */}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {/* DELIVERY COLLECTION */}
          <div
            className="basket_delivery_collection_container"
            style={{ width: "25%" }}
          >
            <div
              onClick={() =>
                !delivery_unavailable ? handleChangeSaleMethod(delivery) : null
              }
              style={{
                backgroundColor: sale_method == delivery ? "#fff" : null,
                border: sale_method == delivery ? "1px solid #ddd" : null,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {sale_method == delivery ? (
                <img src={DeliveryLight} />
              ) : (
                <img src={DeliveryDark} />
              )}

              {/* <i
                class="fa fa-motorcycle"
                aria-hidden="true"
                style={{
                  fontSize: 20,
                  color: sale_method == delivery ? primary_color : null,
                }}
              ></i> */}

              <div style={{ margin: 0 }}>
                <h6>{lang.delivery}</h6>
                <p>{deliveryTime}</p>
              </div>
            </div>
            <div
              onClick={() =>
                !collection_unavailable
                  ? handleChangeSaleMethod(collection)
                  : null
              }
              style={{
                backgroundColor: sale_method == collection ? "#fff" : null,
                border: sale_method == collection ? "1px solid #ddd" : null,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <i
                class="fa fa-shopping-basket"
                aria-hidden="true"
                style={{
                  fontSize: 14,
                  color: sale_method == collection ? primary_color : null,
                  marginRight: 5,
                }}
              ></i>
              <div>
                <h6>{lang.collection}</h6>
                <p>{collectionTime}</p>
              </div>
            </div>
          </div>
        </div>
        {/* CATEGORY MOBILE */}

        {!show_product_submenu_popup ? (
          <div className="mobile_category">
            <ScrollMenu>
              {category_list.map((item, index) => (
                <div
                  key={index}
                  ref={elRefs[index]}
                  onClick={() => handleSelectMobileCategory(item, index)}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor:
                      index == mobileSelectedCategoryIndex
                        ? primary_color
                        : "white",
                    margin: "15px 20px 15px 0",

                    padding: "5px 20px",
                    color:
                      index == mobileSelectedCategoryIndex
                        ? "white"
                        : primary_color,
                    borderRadius: 100,
                  }}
                >
                  {item.WebTitle.split(" ").map((i, ind) => (
                    <h6 style={{ margin: 0, marginRight: 5 }}>{i}</h6>
                  ))}
                </div>
              ))}
            </ScrollMenu>
          </div>
        ) : null}

        <div className="menu-container-product-category-tags">
          {/* CATEGORIES */}
          <div
            className="category_sticky_container"
            style={{ direction: "rtl" }}
          >
            <div
              className="categories_title_container"
              style={{ direction: "ltr" }}
            >
              <h6>{lang.categories}</h6>
            </div>
            {category_list.map((item, index) => (
              <div
                key={index}
                className="categories-container-categories-list"
                onClick={() => handleSelectedCategories(item, item.Id)}
                style={{
                  borderRight:
                    selectCategoryId == item.Id
                      ? `4px solid ${primary_color}`
                      : null,

                  // backgroundColor: selectCategoryId == item.Id ? "red" : null,
                  // color: selectCategoryId == item.Id ? "white" : null,
                  direction: "ltr",
                }}
              >
                <h6>{item.WebTitle}</h6>
              </div>
            ))}
          </div>

          {/* <Categories changinCategory={changingSelectedCategoey} /> */}
          {/* PRODUCTS */}
          <div className="menu-container-producs">
            <Products
              productLists={productsLists}
              pd_selectedCategoryIndex={mobileSelectedCategoryIndex}
              ref={productRef}
              pd_scroll_to_category={(i) => scrollToDesiredCategory(i)}
              pd_setSelectedCategoryIndex={(i) =>
                setMobileSelectedCategoryIndex(i)
              }
              emptySearchList={() => {
                setSearchInputValue("");
                searchInputRef.current.value = "";
                setSearchList([]);
              }}
              pd_get_initial_data={() => getInitialData()}
            />
            <div className="loading_product">
              {loading_product ? <Loader /> : null}
            </div>
            <div className="loading_all_product">
              {all_product_loader ? <Loader /> : null}
            </div>
          </div>
          {/* BASKET */}
          <div className="basket_sticky">
            <div className="basket_sticky_content">
              {basket_content.length > 0 ? (
                <div className="basket_sticky_list-content">
                  {basket_content.map((item, index) => (
                    <div>
                      <div style={{ display: "flex" }}>
                        <div
                          className="mobile_basket_trash_container"
                          style={{ width: 48 }}
                        >
                          <i
                            onClick={() => handleDeleteBasketItem(item)}
                            class="fa fa-trash"
                            aria-hidden="true"
                            style={{ marginTop: 3 }}
                          ></i>
                          <div
                            id="product_number_container"
                            style={{ backgroundColor: "#eee" }}
                          >
                            <h6>{item.number}</h6>
                          </div>
                        </div>
                        <div style={{ flex: 1 }}>
                          <div className="basket_product_container">
                            <div className="basket_product_title_container">
                              <p>{item.title}</p>
                            </div>
                            <p>
                              {pound_sign}
                              {item.price.toFixed(2)}
                            </p>
                          </div>
                          {item.crust_title ? (
                            <div className="basket_product_container">
                              <div className="basket_product_title_container">
                                <p>{item.crust_title}</p>
                              </div>
                              <p>
                                {pound_sign}
                                {item.crust_amount.toFixed(2)}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {item.sub_list.length > 0 ? (
                        <div>
                          {item.sub_list.map((sub_item) => (
                            <div
                              style={{
                                display: "flex",

                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: 48,
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  paddingRight: 12,
                                  height: "100%",
                                }}
                              >
                                {sub_item.number > 1 ? (
                                  <p style={{ margin: 0 }}>{sub_item.number}</p>
                                ) : null}
                              </div>
                              <p style={{ margin: 0 }}>{sub_item.WebTitle}</p>
                            </div>
                          ))}
                        </div>
                      ) : null}
                      {basket_content.length - 1 > index ? <hr></hr> : null}
                    </div>
                  ))}
                </div>
              ) : null}
              {/* FOOTER */}
              <div
                className="product-submenu-popup-contsiner-footter"
                style={{
                  backgroundColor: "white",
                  borderTop:
                    basket_content.length > 0 ? ".5px solid #eee" : null,
                }}
              >
                {basket_content.length > 0 ? (
                  <div className="basket_footer_container">
                    <div>
                      <h6>{lang.subtotal}</h6>
                      <h6>
                        {pound_sign}
                        {sub_total.toFixed(2)}
                      </h6>
                    </div>

                    {/* CARRIER BAG*/}
                    {carrier_bag_visible ? (
                      <div>
                        <h6>{carrier_bag_label}</h6>
                        <h6>
                          {pound_sign}
                          {carrier_bag_amount.toFixed(2)}
                        </h6>
                      </div>
                    ) : null}
                    {/* ****** */}
                    {/* ADMIN FEE*/}
                    {adminfee_visible ? (
                      <div>
                        <h6>{adminfee_label}</h6>
                        <h6>
                          {pound_sign}
                          {adminfee_amount.toFixed(2)}
                        </h6>
                      </div>
                    ) : null}
                    {/* ****** */}
                    {/* DELIVERY FEE */}
                    {sale_method == delivery ? (
                      <div>
                        <h6>{lang.delivey_fee}</h6>
                        <h6>
                          {pound_sign}
                          {delivery_fee.toFixed(2)}
                        </h6>
                      </div>
                    ) : null}
                    {/* ****** */}
                    {/* DISCOUNT */}
                    {discount_with_mealdeals > 0 ||
                    discount_without_mealdeals > 0 ? (
                      <div>
                        <h6>{lang.discount}</h6>
                        <h6>
                          {pound_sign}
                          {(
                            discount_without_mealdeals + discount_with_mealdeals
                          ).toFixed(2)}
                        </h6>
                      </div>
                    ) : null}
                    {/* ****** */}
                    <div>
                      <h5>{lang.total}</h5>
                      <h5>
                        {pound_sign}
                        {sale_method == delivery
                          ? adminfee_visible
                            ? carrier_bag_visible
                              ? (
                                  carrier_bag_amount +
                                  adminfee_amount +
                                  delivery_fee +
                                  sub_total -
                                  discount_without_mealdeals -
                                  discount_with_mealdeals
                                ).toFixed(2)
                              : (
                                  adminfee_amount +
                                  delivery_fee +
                                  sub_total -
                                  discount_without_mealdeals -
                                  discount_with_mealdeals
                                ).toFixed(2)
                            : carrier_bag_visible
                            ? (
                                carrier_bag_amount +
                                delivery_fee +
                                sub_total -
                                discount_without_mealdeals -
                                discount_with_mealdeals
                              ).toFixed(2)
                            : (
                                delivery_fee +
                                sub_total -
                                discount_without_mealdeals -
                                discount_with_mealdeals
                              ).toFixed(2)
                          : adminfee_visible
                          ? carrier_bag_visible
                            ? (
                                carrier_bag_amount +
                                adminfee_amount +
                                sub_total -
                                discount_without_mealdeals -
                                discount_with_mealdeals
                              ).toFixed(2)
                            : (
                                adminfee_amount +
                                sub_total -
                                discount_without_mealdeals -
                                discount_with_mealdeals
                              ).toFixed(2)
                          : carrier_bag_visible
                          ? (
                              carrier_bag_amount +
                              sub_total -
                              discount_without_mealdeals -
                              discount_with_mealdeals
                            ).toFixed(2)
                          : (
                              sub_total -
                              discount_without_mealdeals -
                              discount_with_mealdeals
                            ).toFixed(2)}
                      </h5>
                    </div>
                    <button
                      disabled={loading ? true : false}
                      onClick={handleCheckout}
                      style={{
                        backgroundColor:
                          basket_content.length > 0
                            ? primary_color
                            : white_dark,
                        color: basket_content.length > 0 ? white : black_dark,
                      }}
                    >
                      {loading ? <LoderMini /> : basket_button_title}
                    </button>
                  </div>
                ) : (
                  <div className="basket_empty_message_container">
                    <img src={BasketEmptyIcon} width="70%" />
                    <h4>{lang.basket_is_empty}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Toaster />
      </div>
      {basket_content.length > 0 &&
      !show_product_submenu_popup &&
      !maintenance_mode ? (
        <MobileViewBasketButton pd_get_initial_data={getInitialData} />
      ) : null}
    </div>
  );
};

export default Menu;
