import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import Stripe from "../assets/images/stripe.jpg";
import Google from "../assets/images/google.jpg";
import Apple from "../assets/images/apple.jpg";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { shop_info } = useSelector((state) => state.shopSettingsReduser);

  return (
    <div>
      <div className="footer_container">
        <div></div>
        <div className="footer_content">
          {/* FIRST */}
          <div id="footer_first_section">
            <h3>{shop_info ? shop_info.Title : ""}</h3>
            <h6>{lang.about_us}</h6>
            <h6>{lang.contact_us}</h6>
            <h6>{lang.our_location}</h6>
          </div>
          {/* SECOND */}
          <div id="footer_second_section">
            <h3>{lang.feed_back}</h3>
            <h6>{lang.help_us}</h6>
          </div>
          {/* THIRD */}
          <div id="footer_third_section">
            <h3>{lang.download_our_apps}</h3>
            <div>
              <img
                onClick={() =>
                  window.open(
                    shop_info
                      ? shop_info.AppStoreUrl
                        ? shop_info.AppStoreUrl
                        : `https://apps.apple.com/`
                      : `https://apps.apple.com/`,
                    "_blank"
                  )
                }
                src={Apple}
              />
              <img
                onClick={() =>
                  window.open(
                    shop_info
                      ? shop_info.GooglePlayUrl
                        ? shop_info.GooglePlayUrl
                        : `https://play.google.com/store/`
                      : `https://play.google.com/store/`,
                    "_blank"
                  )
                }
                src={Google}
              />
            </div>
          </div>
          {/* FOURTH */}
          <div id="footer_fourth_section">
            <div>
              <h3>{lang.follow_us}</h3>
              <div>
                <i
                  onClick={() =>
                    window.open(
                      shop_info
                        ? shop_info.FacebookUrl
                          ? shop_info.FacebookUrl
                          : `https://www.facebook.com/`
                        : `https://www.facebook.com/`,
                      "_blank"
                    )
                  }
                  id="footer_facebook"
                  class="fa fa-facebook-square"
                  aria-hidden="true"
                ></i>
                <i
                  onClick={() =>
                    window.open(
                      shop_info
                        ? shop_info.InstagramUrl
                          ? shop_info.InstagramUrl
                          : `https://www.instagram.com/`
                        : `https://www.instagram.com/`,
                      "_blank"
                    )
                  }
                  class="fa fa-instagram"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MOBILE VIEW FOOTER */}
      <div className="footer_container_mobile">
        <section>
          <h3>{shop_info ? shop_info.Title : ""}</h3>
          <div>
            <h6>{lang.about_us}</h6>
          </div>
          <div>
            <h6>{lang.contact_us}</h6>
          </div>
          <div>
            <h6>{lang.our_location}</h6>
          </div>
        </section>
        <section>
          <h3>{lang.feed_back}</h3>
          <div>
            <h6>{lang.help_us}</h6>
          </div>
        </section>
        <section>
          <h3>{lang.follow_us}</h3>
          <div>
            <i
              onClick={() =>
                window.open(
                  shop_info
                    ? shop_info.FacebookUrl
                      ? shop_info.FacebookUrl
                      : `https://www.facebook.com/`
                    : `https://www.facebook.com/`,
                  "_blank"
                )
              }
              id="footer_facebook"
              class="fa fa-facebook-square"
              aria-hidden="true"
              style={{ marginRight: 20 }}
            ></i>
            <i
              onClick={() =>
                window.open(
                  shop_info
                    ? shop_info.InstagramUrl
                      ? shop_info.InstagramUrl
                      : `https://www.instagram.com/`
                    : `https://www.instagram.com/`,
                  "_blank"
                )
              }
              class="fa fa-instagram"
              aria-hidden="true"
            ></i>
          </div>
        </section>
        <section>
          <h3>{lang.download_our_apps}</h3>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              onClick={() =>
                window.open(
                  shop_info
                    ? shop_info.AppStoreUrl
                      ? shop_info.AppStoreUrl
                      : `https://apps.apple.com/`
                    : `https://apps.apple.com/`,
                  "_blank"
                )
              }
              src={Apple}
            />
            <img
              onClick={() =>
                window.open(
                  shop_info
                    ? shop_info.GooglePlayUrl
                      ? shop_info.GooglePlayUrl
                      : `https://play.google.com/store/`
                    : `https://play.google.com/store/`,
                  "_blank"
                )
              }
              src={Google}
            />
          </div>
        </section>
      </div>
      <div className="footer_copy_right_container">
        <p>{lang.copy_right}</p>
        {/* <h6>{lang.copy_right}</h6> */}
      </div>
    </div>
  );
};

export default Footer;
