import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Button from "@mui/material/Button";
import store from "store2";
import moment from "moment";

import LoderMini from "../../Componenets/LoderMini";
// import Loader from "../Componenets/Loader";
import { pound_sign } from "../../constants/String";
import { delivery, collection } from "../../constants/SaleMethodsId";
import {
  primary_color,
  primary_light,
  product_description_color,
  white,
  white_dark,
  black_product_title,
  black_dark,
} from "../../constants/Colors";
import {
  addToBasketAction,
  showBasketAction,
  setSaleMethodAction,
  setBasketListAction,
} from "../../redux/basket/Actions";
import {
  getShopOpenCloseAction,
  setDefaultOrderPlaceStatusAction,
} from "../../redux/settings/Actions";
import { getAllProductsSuccessfull } from "../../redux/Products/Actions";
import BasketLogo from "../../assets/images/basket_logo.jfif";
import DeliveryIconDark from "../../assets/images/delivery_mobile_dark.png";
import DeliveryIconRed3 from "../../assets/images/delivery_mobile_red3.png";

import { EUR } from "../../constants/Currency";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Basket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user"]);
  const [nextRouteAfterBasket, setNextRouteAfterBasket] = useState("");

  const {
    product_submenu_list,
    selected_product,
    products_list,
    product_option_crust,
    show_submenu_loader,
    global_submenu_list,
  } = useSelector((state) => state.productReduser);
  const { selected_category, promotion_list } = useSelector(
    (state) => state.categoriesRedusers
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    collectionTime,
    deliveryTime,
    delivery_time_list,
    shop_id,
    login_key,
    delivery_fee,
    carrier_bag_visible,
    carrier_bag_amount,
    carrier_bag_label,
    adminfee_visible,
    adminfee_amount,
    adminfee_label,
    cash_in_collection,
    cash_in_delivery,
    stripe_in_collection,
    stripe_in_delivery,
    paypal_in_collection,
    paypal_in_delivery,
    maintenance_mode,
    delivery_unavailable,
    collection_unavailable,
    go_to_next_route,
    loading,
    user_entered_in,
    basket_button_title,
  } = useSelector((state) => state.shopSettingsReduser);
  const {
    basket_content,
    basket_item_id,
    sub_total,
    basket_product_id_list,
    sale_method,
    discount_with_mealdeals,
    discount_without_mealdeals,
    sub_total_without_mealdeal,
  } = useSelector((state) => state.basketReduser);
  const {
    user_info,
    sign_up_loading,
    result_successfull,
    result_failed,
    result_message,
    address_result_successfull,
    address_result_failed,
    address_loading,
    addresses_list,
  } = useSelector((state) => state.profileReduser);

  const handleDeleteBasketItem = (item) => {
    let final_subtotal_without_mealdeals = sub_total_without_mealdeal;
    let final_discount_without_mealdeals = discount_without_mealdeals;
    let final_sub_total = sub_total;
    let final_discount_with_mealdeals = discount_with_mealdeals;
    if (discount_without_mealdeals) {
      let my_day = new Date().getDay();
      for (let i = 0; i < promotion_list.length; i++) {
        if (
          promotion_list[i].day_id == my_day &&
          promotion_list[i].day_promotions.length > 0
        ) {
          let dayPromotion = promotion_list[i].day_promotions;
          for (let j = 0; j < dayPromotion.length; j++) {
            if (dayPromotion[j].ExcludeMealDeals) {
              if (item.is_mealdeal) {
                final_sub_total = sub_total - item.total_basket_items_price;
              } else {
                final_sub_total = sub_total - item.total_basket_items_price;
                final_subtotal_without_mealdeals =
                  sub_total_without_mealdeal - item.total_basket_items_price;
                if (dayPromotion[j].MinOrder) {
                  if (
                    sub_total - item.total_basket_items_price >
                    dayPromotion[j].MinOrder
                  ) {
                    if (dayPromotion[j].RateOption) {
                      final_discount_without_mealdeals =
                        ((sub_total_without_mealdeal -
                          item.total_basket_items_price) *
                          dayPromotion[j].Rate) /
                        100;
                    } else {
                      final_discount_without_mealdeals = dayPromotion[j].Rate;
                    }
                  } else {
                    final_discount_without_mealdeals = 0;
                  }
                } else {
                  if (dayPromotion[j].RateOption) {
                    final_discount_without_mealdeals =
                      ((sub_total_without_mealdeal -
                        item.total_basket_items_price) *
                        dayPromotion[j].Rate) /
                      100;
                  } else {
                    final_discount_without_mealdeals = dayPromotion[j].Rate;
                  }
                }
              }
            } else {
              if (item.is_mealdeal) {
                final_sub_total = sub_total - item.total_basket_items_price;
              } else {
                final_sub_total = sub_total - item.total_basket_items_price;
                final_subtotal_without_mealdeals =
                  sub_total_without_mealdeal - item.total_basket_items_price;
              }

              if (dayPromotion[j].MinOrder) {
                if (
                  sub_total - item.total_basket_items_price >
                  dayPromotion[j].MinOrder
                ) {
                  if (dayPromotion[j].RateOption) {
                    final_discount_with_mealdeals =
                      ((sub_total - item.total_basket_items_price) *
                        dayPromotion[j].Rate) /
                      100;
                  } else {
                    final_discount_with_mealdeals = dayPromotion[j].Rate;
                  }
                } else {
                  final_discount_with_mealdeals = 0;
                }
              } else {
                if (dayPromotion[j].RateOption) {
                  final_discount_with_mealdeals =
                    ((sub_total - item.total_basket_items_price) *
                      dayPromotion[j].Rate) /
                    100;
                } else {
                  final_discount_with_mealdeals = dayPromotion[j].Rate;
                }
              }
            }
          }
        }
      }
    } else if (discount_with_mealdeals) {
      let my_day = new Date().getDay();
      for (let i = 0; i < promotion_list.length; i++) {
        if (
          promotion_list[i].day_id == my_day &&
          promotion_list[i].day_promotions.length > 0
        ) {
          let dayPromotion = promotion_list[i].day_promotions;
          for (let j = 0; j < dayPromotion.length; j++) {
            final_sub_total = sub_total - item.total_basket_items_price;

            if (dayPromotion[j].MinOrder) {
              if (
                sub_total - item.total_basket_items_price >
                dayPromotion[j].MinOrder
              ) {
                if (dayPromotion[j].RateOption) {
                  final_discount_with_mealdeals =
                    ((sub_total - item.total_basket_items_price) *
                      dayPromotion[j].Rate) /
                    100;
                } else {
                  final_discount_with_mealdeals = dayPromotion[j].Rate;
                }
              } else {
                final_discount_with_mealdeals = 0;
              }
            } else {
              if (dayPromotion[j].RateOption) {
                final_discount_with_mealdeals =
                  ((sub_total - item.total_basket_items_price) *
                    dayPromotion[j].Rate) /
                  100;
              } else {
                final_discount_with_mealdeals = dayPromotion[j].Rate;
              }
            }
          }
        }
      }
    } else {
      if (item.is_mealdeal) {
        final_sub_total = sub_total - item.total_basket_items_price;
      } else {
        final_sub_total = sub_total - item.total_basket_items_price;
        final_subtotal_without_mealdeals =
          sub_total_without_mealdeal - item.total_basket_items_price;
      }
    }

    let tempBasketList = [...basket_content];
    let temp_basket_product_id_list = [...basket_product_id_list];
    let final_basket_product_id_list = [];
    let is_one_time_equal = true;
    for (let i = 0; i < temp_basket_product_id_list.length; i++) {
      if (
        temp_basket_product_id_list[i] == item.product_id &&
        is_one_time_equal
      ) {
        is_one_time_equal = false;
      } else {
        final_basket_product_id_list.push(temp_basket_product_id_list[i]);
      }
    }
    tempBasketList = tempBasketList.filter((i) => i.id != item.id);
    // dispatch(
    //   setBasketListAction(
    //     tempBasketList,
    //     sub_total - item.price,
    //     null,
    //     final_basket_product_id_list
    //   )
    // );
    // store.set("temp_basket", tempBasketList);
    // store.set("temp_sub_total", sub_total - item.price);
    // store.set("temp_basket_product_id_list", final_basket_product_id_list);

    dispatch(
      setBasketListAction(
        tempBasketList,
        final_sub_total,
        null,
        final_basket_product_id_list,
        final_discount_with_mealdeals,
        final_discount_without_mealdeals,
        final_subtotal_without_mealdeals
      )
    );
    store.set("discount", final_discount_with_mealdeals);
    store.set("discount_no_mealdeal", final_discount_without_mealdeals);
    store.set("temp_basket", tempBasketList);
    store.set("temp_sub_total", final_sub_total);
    store.set("temp_basket_product_id_list", final_basket_product_id_list);
    store.set("temp_no_mealdeal_sub_total", final_subtotal_without_mealdeals);

    if (tempBasketList.length == 0) {
      navigate(-1);
    }
  };

  const handleCheckout = () => {
    if (user_info) {
      dispatch(
        getShopOpenCloseAction(shop_id, login_key, false, navigate, "checkout")
      );
      setNextRouteAfterBasket("/checkout");
    } else {
      dispatch(
        getShopOpenCloseAction(shop_id, login_key, false, navigate, "login/2")
      );

      setNextRouteAfterBasket("/login/2");
    }
    dispatch(showBasketAction(false));
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    if (!user_entered_in) {
      navigate("/");
    }
  }, []);

  // OLD NAVIGATE
  // useEffect(() => {
  //   if (go_to_next_route) {
  //     dispatch(setDefaultOrderPlaceStatusAction());
  //     navigate(nextRouteAfterBasket);
  //   }
  // }, [go_to_next_route]);

  // NEW NAVIGATE
  useEffect(() => {
    if (go_to_next_route) {
      const saved_basket_time = store.get("saved_basket_time");

      if (
        moment(saved_basket_time)
          .add(59, "minutes")
          .format("DD-MM-YYYY HH:mm") >
        moment(new Date()).format("DD-MM-YYYY HH:mm")
      ) {
        dispatch(setDefaultOrderPlaceStatusAction());
        navigate(nextRouteAfterBasket);
      } else {
        dispatch(setBasketListAction([], 0, null, [], 0, 0, 0));
        dispatch(getAllProductsSuccessfull([]));
        store.remove("temp_basket");
        store.remove("temp_sub_total");
        store.remove("basket_last_item_id");
        store.remove("temp_basket_product_id_list");
        store.remove("temp_no_mealdeal_sub_total");
        store.remove("discount");
        store.remove("discount_no_mealdeal");
        navigate("/");
      }
    }
  }, [go_to_next_route]);

  const handleChangeSaleMethod = (selected_sale_method) => {
    dispatch(setSaleMethodAction(selected_sale_method));
    store.set("sale_method", selected_sale_method);

    let my_day = new Date().getDay();
    let productFinalPromotionWithMealdeals = 0;
    let productFinalPromotionWithoutMealdeals = 0;
    for (let i = 0; i < promotion_list.length; i++) {
      if (
        promotion_list[i].day_id == my_day &&
        promotion_list[i].day_promotions.length > 0
      ) {
        let dayPromotion = promotion_list[i].day_promotions;
        for (let j = 0; j < dayPromotion.length; j++) {
          if (dayPromotion[j].RateOption) {
            if (dayPromotion[j].ExcludeMealDeals) {
              if (dayPromotion[j].MinOrder) {
                if (sub_total_without_mealdeal > dayPromotion[j].MinOrder) {
                  if (dayPromotion[j].SaleMethod == 1) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                  } else if (
                    dayPromotion[j].SaleMethod == collection &&
                    selected_sale_method == collection
                  ) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                  } else if (
                    dayPromotion[j].SaleMethod == delivery &&
                    selected_sale_method == delivery
                  ) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                  }
                }
              } else {
                if (dayPromotion[j].SaleMethod == 1) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                } else if (
                  dayPromotion[j].SaleMethod == collection &&
                  selected_sale_method == collection
                ) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                } else if (
                  dayPromotion[j].SaleMethod == delivery &&
                  selected_sale_method == delivery
                ) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                }
              }
            } else {
              if (dayPromotion[j].MinOrder) {
                if (sub_total > dayPromotion[j].MinOrder) {
                  if (dayPromotion[j].SaleMethod == 1) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals +
                      (sub_total * dayPromotion[j].Rate) / 100;
                  } else if (
                    dayPromotion[j].SaleMethod == collection &&
                    selected_sale_method == collection
                  ) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals +
                      (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                  } else if (
                    dayPromotion[j].SaleMethod == delivery &&
                    selected_sale_method == delivery
                  ) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals +
                      (sub_total_without_mealdeal * dayPromotion[j].Rate) / 100;
                  }
                }
              } else {
                if (dayPromotion[j].SaleMethod == 1) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals +
                    (sub_total * dayPromotion[j].Rate) / 100;
                } else if (
                  dayPromotion[j].SaleMethod == collection &&
                  selected_sale_method == collection
                ) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals +
                    (sub_total * dayPromotion[j].Rate) / 100;
                } else if (
                  dayPromotion[j].SaleMethod == delivery &&
                  selected_sale_method == delivery
                ) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals +
                    (sub_total * dayPromotion[j].Rate) / 100;
                }
              }
            }
          } else {
            if (dayPromotion[j].ExcludeMealDeals) {
              if (dayPromotion[j].MinOrder) {
                if (sub_total_without_mealdeal > dayPromotion[j].MinOrder) {
                  if (dayPromotion[j].SaleMethod == 1) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      dayPromotion[j].Rate;
                  } else if (
                    dayPromotion[j].SaleMethod == collection &&
                    selected_sale_method == collection
                  ) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      dayPromotion[j].Rate;
                  } else if (
                    dayPromotion[j].SaleMethod == delivery &&
                    selected_sale_method == delivery
                  ) {
                    productFinalPromotionWithoutMealdeals =
                      productFinalPromotionWithoutMealdeals +
                      dayPromotion[j].Rate;
                  }
                }
              } else {
                if (dayPromotion[j].SaleMethod == 1) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    dayPromotion[j].Rate;
                } else if (
                  dayPromotion[j].SaleMethod == collection &&
                  selected_sale_method == collection
                ) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    dayPromotion[j].Rate;
                } else if (
                  dayPromotion[j].SaleMethod == delivery &&
                  selected_sale_method == delivery
                ) {
                  productFinalPromotionWithoutMealdeals =
                    productFinalPromotionWithoutMealdeals +
                    dayPromotion[j].Rate;
                }
              }
            } else {
              if (dayPromotion[j].MinOrder) {
                if (sub_total > dayPromotion[j].MinOrder) {
                  if (dayPromotion[j].SaleMethod == 1) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                  } else if (
                    dayPromotion[j].SaleMethod == collection &&
                    selected_sale_method == collection
                  ) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                  } else if (
                    dayPromotion[j].SaleMethod == delivery &&
                    selected_sale_method == delivery
                  ) {
                    productFinalPromotionWithMealdeals =
                      productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                  }
                }
              } else {
                if (dayPromotion[j].SaleMethod == 1) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                } else if (
                  dayPromotion[j].SaleMethod == collection &&
                  selected_sale_method == collection
                ) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                } else if (
                  dayPromotion[j].SaleMethod == delivery &&
                  selected_sale_method == delivery
                ) {
                  productFinalPromotionWithMealdeals =
                    productFinalPromotionWithMealdeals + dayPromotion[j].Rate;
                }
              }
            }
          }
        }
      }
    }

    store.set("discount", productFinalPromotionWithMealdeals);
    store.set("discount_no_mealdeal", productFinalPromotionWithoutMealdeals);

    dispatch(
      setBasketListAction(
        basket_content,
        sub_total,
        null,
        basket_product_id_list,
        productFinalPromotionWithMealdeals,
        productFinalPromotionWithoutMealdeals,
        sub_total_without_mealdeal
      )
    );
  };

  return (
    <div className="mobile_basket_main_container">
      {/* HEADER */}
      <div className="basket_header_container">
        {/* DELIVERY COLLECTION */}
        <div className="mobile_basket_delivery_collection_container">
          <div
            onClick={() =>
              !delivery_unavailable ? handleChangeSaleMethod(delivery) : null
            }
            style={{
              backgroundColor: sale_method == 1000105 ? "#fff" : null,
              border: sale_method == 1000105 ? "1px solid #ddd" : null,
            }}
          >
            {sale_method == 1000105 ? (
              <img src={DeliveryIconRed3} />
            ) : (
              <img src={DeliveryIconDark} />
            )}
            {/* <i
              class="fa fa-bicycle"
              aria-hidden="true"
              style={{
                fontSize: 17,
                marginRight: 5,
                color: sale_method == delivery ? primary_color : null,
              }}
            ></i> */}
            <h6>{lang.delivery}</h6>
            {/* 
            <p>{deliveryTime}</p> */}
          </div>
          <div
            onClick={() =>
              !collection_unavailable
                ? handleChangeSaleMethod(collection)
                : null
            }
            style={{
              backgroundColor: sale_method == 1000106 ? "#fff" : null,
              border: sale_method == 1000106 ? "1px solid #ddd" : null,
            }}
          >
            <i
              class="fa fa-shopping-basket"
              aria-hidden="true"
              style={{
                fontSize: 13,
                marginRight: 5,
                color: sale_method == collection ? primary_color : null,
              }}
            ></i>
            <h6>{lang.collection}</h6>
            {/* 
            <p>{collectionTime}</p> */}
          </div>
        </div>

        {/* MOBILE CLOSE BUTTON */}
        <div
          className="mobile_basket_close_button"
          onClick={() => navigate(-1)}
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>
      {/* BASKET */}

      <div className="mobile_basket_list_container">
        <div>
          {basket_content.length > 0 ? (
            <div>
              {basket_content.map((item, index) => (
                // <div>
                //   <div style={{ marginBottom: 25, display: "flex" }}>
                //     <div className="mobile_basket_trash_container">
                //       <i
                //         onClick={() => handleDeleteBasketItem(item)}
                //         class="fa fa-trash"
                //         aria-hidden="true"
                //         style={{ marginTop: 2 }}
                //       ></i>
                //       <div id="product_number_container">
                //         <h6>{item.number}</h6>
                //       </div>
                //     </div>
                //     <div className="basket_product_subitem_container">
                //       <div
                //         className="basket_product_container"

                //         // style={{ backgroundColor: "pink", width: "100%" }}
                //       >

                //         <h6>{item.title}</h6>
                //         <h6>
                //           {EUR}
                //           {item.price.toFixed(2)}
                //         </h6>
                //       </div>
                //       {item.crust_title ? (
                //         <div className="basket_product_container">
                //           <h6>{item.crust_title}</h6>
                //           <h6>
                //             {EUR}
                //             {item.crust_amount.toFixed(2)}
                //           </h6>
                //         </div>
                //       ) : null}
                //       {item.sub_list.length > 0 ? (
                //         <div className="basket_sublist_container">
                //           {item.sub_list.map((sub_item) => (
                //             <div>
                //               <h6>{sub_item.WebTitle}</h6>
                //               <h6>
                //                 {EUR}
                //                 {sub_item.AmountMain.toFixed(2)}
                //               </h6>
                //             </div>
                //           ))}
                //         </div>
                //       ) : null}
                //     </div>
                //   </div>
                //   {basket_content.length - 1 > index ? <hr></hr> : null}
                // </div>
                <div>
                  <div style={{ display: "flex" }}>
                    <div
                      className="mobile_basket_trash_container"
                      style={{ width: 48 }}
                    >
                      <i
                        onClick={() => handleDeleteBasketItem(item)}
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{ marginTop: 3 }}
                      ></i>
                      <div
                        id="product_number_container"
                        style={{ backgroundColor: "#eee" }}
                      >
                        <h6>{item.number}</h6>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div className="basket_product_container">
                        <div className="basket_product_title_container">
                          <p>{item.title}</p>
                        </div>
                        <p>
                          {EUR}
                          {item.price.toFixed(2)}
                        </p>
                      </div>
                      {item.crust_title ? (
                        <div className="basket_product_container">
                          <div className="basket_product_title_container">
                            <p>{item.crust_title}</p>
                          </div>
                          <p>
                            {EUR}
                            {item.crust_amount.toFixed(2)}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {item.sub_list.length > 0 ? (
                    <div>
                      {item.sub_list.map((sub_item) => (
                        <div
                          style={{
                            display: "flex",

                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 48,
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: 12,
                              height: "100%",
                            }}
                          >
                            {sub_item.number > 1 ? (
                              <p style={{ margin: 0 }}>{sub_item.number}</p>
                            ) : null}
                          </div>
                          <p style={{ margin: 0 }}>{sub_item.WebTitle}</p>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {basket_content.length - 1 > index ? <hr></hr> : null}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {basket_content.length > 0 ? null : (
          <div className="basket_empty_message_container">
            <h4>{lang.basket_is_empty}</h4>
          </div>
        )}
      </div>

      {/* FOOTER */}
      <div className="mobile_basket_footter_container">
        <div className="mobile_basket_footer_content_container">
          <div>
            <h6>{lang.subtotal}</h6>
            <h6>
              {EUR}
              {sub_total.toFixed(2)}
            </h6>
          </div>
          {/* CARRIER BAG*/}
          {carrier_bag_visible ? (
            <div>
              <h6>{carrier_bag_label}</h6>
              <h6>
                {EUR}
                {carrier_bag_amount.toFixed(2)}
              </h6>
            </div>
          ) : null}
          {/* ****** */}
          {/* ADMIN FEE*/}
          {adminfee_visible ? (
            <div>
              <h6>{adminfee_label}</h6>
              <h6>
                {EUR}
                {adminfee_amount.toFixed(2)}
              </h6>
            </div>
          ) : null}
          {/* ****** */}

          {/* DELIVERY FEE */}
          {sale_method == delivery ? (
            <div>
              <h6>{lang.delivey_fee}</h6>
              <h6>
                {EUR}
                {delivery_fee.toFixed(2)}
              </h6>
            </div>
          ) : null}

          {/* DISCOUNT */}
          {discount_with_mealdeals > 0 || discount_without_mealdeals > 0 ? (
            <div>
              <h6>{lang.discount}</h6>
              <h6>
                {EUR}
                {(discount_without_mealdeals + discount_with_mealdeals).toFixed(
                  2
                )}
              </h6>
            </div>
          ) : null}
          {/* ****** */}

          <div>
            <h5>{lang.total}</h5>
            <h5>
              {EUR}
              {sale_method == delivery
                ? adminfee_visible
                  ? carrier_bag_visible
                    ? (
                        carrier_bag_amount +
                        adminfee_amount +
                        delivery_fee +
                        sub_total -
                        discount_without_mealdeals -
                        discount_with_mealdeals
                      ).toFixed(2)
                    : (
                        adminfee_amount +
                        delivery_fee +
                        sub_total -
                        discount_without_mealdeals -
                        discount_with_mealdeals
                      ).toFixed(2)
                  : carrier_bag_visible
                  ? (
                      carrier_bag_amount +
                      delivery_fee +
                      sub_total -
                      discount_without_mealdeals -
                      discount_with_mealdeals
                    ).toFixed(2)
                  : (
                      delivery_fee +
                      sub_total -
                      discount_without_mealdeals -
                      discount_with_mealdeals
                    ).toFixed(2)
                : adminfee_visible
                ? carrier_bag_visible
                  ? (
                      carrier_bag_amount +
                      adminfee_amount +
                      sub_total -
                      discount_without_mealdeals -
                      discount_with_mealdeals
                    ).toFixed(2)
                  : (
                      adminfee_amount +
                      sub_total -
                      discount_without_mealdeals -
                      discount_with_mealdeals
                    ).toFixed(2)
                : carrier_bag_visible
                ? (
                    carrier_bag_amount +
                    sub_total -
                    discount_without_mealdeals -
                    discount_with_mealdeals
                  ).toFixed(2)
                : (
                    sub_total -
                    discount_without_mealdeals -
                    discount_with_mealdeals
                  ).toFixed(2)}
            </h5>
          </div>
        </div>
        <Button
          disabled={basket_content.length > 0 ? false : true}
          onClick={handleCheckout}
          style={{
            backgroundColor:
              basket_content.length > 0 ? primary_color : white_dark,
            color: basket_content.length > 0 ? white : black_dark,
            height: (75 * 55) / 100,
            marginBottom: (125 * 55) / 1000,
          }}
        >
          {loading ? <LoderMini /> : basket_button_title}
        </Button>
      </div>
    </div>
  );
};

export default Basket;
