import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Sad from "../../../assets/images/sorry.gif";

const RejectedMessage = ({ pd_hide_rejected_message }) => {
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.selectedLanguage);

  return (
    <div className="maintenance_mode_message_main_container">
      <div
        className="maintenance_mode_message_content_container"
        onClick={(i) => i.stopPropagation()}
        style={{ paddingTop: 60, paddingBottom: 60 }}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={Sad} />
          <h4>Oops</h4>
          <h5>Oops</h5>
        </div>
        <h4>{lang.rejected_message}</h4>
        <h5>{lang.rejected_message}</h5>

        <i
          class="fa fa-window-close"
          aria-hidden="true"
          onClick={() => pd_hide_rejected_message()}
        ></i>
        {/* {show_preorder_button ? (
          <Button
            onClick={handlePreorder}
            variant="outlined"
            style={{
              width: "90%",
              color: "white",
              borderColor: primary_color,
              textTransform: "none",
              backgroundColor: primary_color,
            }}
          >
            Order for later
          </Button>
        ) : null} */}
      </div>
    </div>
  );
};

export default RejectedMessage;
