import * as action_types from "../ActionType";

export const addToBasketAction = (basketContent, id) => {
  return {
    type: action_types.ADD_TO_BASKET,
    payload: { basketContent, id },
  };
};
export const showBasketAction = (data) => {
  return {
    type: action_types.SHOW_BASKET,
    payload: data,
  };
};
export const setSaleMethodAction = (data) => {
  return {
    type: action_types.SET_SALE_METHOD,
    payload: data,
  };
};
export const setBasketListAction = (
  basketList,
  subTotal,
  id,
  basket_product_id_list,
  discount_with_mealdeals,
  discount_without_mealdeals,
  sub_total_without_mealdeal
) => {
  return {
    type: action_types.SET_BASKET_LIST,
    payload: {
      basketList,
      subTotal,
      id,
      basket_product_id_list,
      discount_with_mealdeals,
      discount_without_mealdeals,
      sub_total_without_mealdeal,
    },
  };
};
