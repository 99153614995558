import * as action_types from "../ActionType";
import * as string from "../../constants/String";

const initialStates = {
  lang: string.english,
};

const selectedLanguage = (state = initialStates, action) => {
  switch (action.type) {
    case action_types.ENGLISH:
      return {
        ...state,
        lang: string.english,
      };
    case action_types.TURKISH:
      return {
        ...state,
        lang: string.turkish,
      };

    default:
      return state;
  }
};
export default selectedLanguage;
