import * as action_types from "../ActionType";
const initialStates = {
  result_successfull: false,
  result_successfull_details: false,
  result_failed_details: false,
  address_result_successfull: false,
  otp_successfull: false,
  otp_failed: false,
  address_result_failed: false,
  result_failed: false,
  result_message: "",
  login_loading: false,
  google_login_loading: false,
  sign_up_loading: false,
  address_loading: false,
  send_otp_code_loading: false,
  send_otp_code_successfull: false,
  send_otp_code_failed: false,
  user_info: null,
  addresses_list: [],
  customer_orders_list: [],
  order_details: [],
  reset_password_link_expired: false,
  reset_password_link_verified: false,
  userDropDownIsClicked: false,
  order_details_is_open: false,
  selected_customer_order: null,
  is_old_customer: false,
};

const profileReduser = (state = initialStates, action) => {
  switch (action.type) {
    case action_types.SET_DEFAULT:
      return {
        ...state,
        login_loading: false,
        address_loading: false,
        sign_up_loading: false,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        address_result_successfull: false,
        address_result_failed: false,
        otp_successfull: false,
        send_otp_code_successfull: false,
        send_otp_code_failed: false,
        google_login_loading: false,
        result_successfull_details: false,
        result_failed_details: false,
        reset_password_link_expired: false,
        reset_password_link_verified: false,
        userDropDownIsClicked: false,
        is_old_customer: false,
      };
    case action_types.SELECTED_CUSTOMER_ORDER:
      return {
        ...state,
        selected_customer_order: action.payload,
      };
    case action_types.ORDER_DETAILS_OPENED:
      return {
        ...state,
        order_details_is_open: action.payload,
      };
    case action_types.DROPDOWN_CLICKED:
      return {
        ...state,
        userDropDownIsClicked: action.payload,
      };
    case action_types.CHECK_POSTCODE_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case action_types.CHECK_POSTCODE_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case action_types.VERIFY_RESET_PASSWORD_LINK_SUCCESSFULL:
      return {
        ...state,
        reset_password_link_expired: false,
        reset_password_link_verified: true,
      };
    case action_types.VERIFY_RESET_PASSWORD_LINK_FAILED:
      return {
        ...state,
        reset_password_link_expired: true,
        reset_password_link_verified: true,
      };
    case action_types.RESET_PASSWORD:
      return {
        ...state,
        login_loading: true,
      };
    case action_types.SEND_ORDER_EMAIL_SUCCESSFULL:
      return {
        ...state,
        login_loading: false,
        result_successfull: true,
        result_failed: false,
      };
    case action_types.SEND_ORDER_EMAIL_FAILED:
      return {
        ...state,
        login_loading: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case action_types.RESET_PASSWORD_SUCCESSFULL:
      return {
        ...state,
        login_loading: false,
        result_successfull: true,
        result_failed: false,
      };
    case action_types.RESET_PASSWORD_FAILED:
      return {
        ...state,
        login_loading: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case action_types.GET_CUSTOMER_ORDER_DETAILS:
      return {
        ...state,
        login_loading: true,
      };
    case action_types.GET_CUSTOMER_ORDER_DETAILS_SUCCESSFULL:
      return {
        ...state,
        login_loading: false,
        order_details: action.payload,
        result_successfull_details: true,
        result_failed_details: false,
      };
    case action_types.GET_CUSTOMER_ORDER_DETAILS_FAILED:
      return {
        ...state,
        login_loading: false,
        result_successfull_details: false,
        result_failed_details: true,
      };
    case action_types.GET_CUSTOMER_ORDERS_LIST:
      return {
        ...state,
        login_loading: true,
      };
    case action_types.GET_CUSTOMER_ORDERS_LIST_SUCCESSFULL:
      return {
        ...state,
        customer_orders_list: action.payload,
        login_loading: false,
      };

    case action_types.GET_CUSTOMER_ORDERS_LIST_FAILED:
      return {
        ...state,
        login_loading: false,
      };
    case action_types.GET_GOOGLE_USER_INFO:
      return {
        ...state,
        google_login_loading: true,
      };
    case action_types.GET_GOOGLE_USER_INFO_SUCCESSFULL:
      return {
        ...state,
        google_login_loading: false,
      };
    case action_types.GET_GOOGLE_USER_INFO_FAILED:
      return {
        ...state,
        google_login_loading: false,
      };
    case action_types.CREATE_ACCOUNT:
      return {
        ...state,
        sign_up_loading: true,
        login_loading: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case action_types.CREATE_ACCOUNT_SUCCESSFULL:
      return {
        ...state,
        sign_up_loading: false,
        login_loading: false,
        result_successfull: true,
        result_failed: false,
        user_info: action.payload.user_info,
        result_message: action.payload.message,
        google_login_loading: false,
      };
    case action_types.CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        sign_up_loading: false,
        login_loading: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case action_types.LOGIN:
      return {
        ...state,
        login_loading: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        user_info: null,
      };
    case action_types.LOGIN_SUCCESSFULL:
      return {
        ...state,
        login_loading: false,
        result_successfull: action.payload.isFromSaga ? true : false,
        result_failed: false,
        user_info: action.payload.data,
      };
    case action_types.EMPTY_LOGIN_INFO:
      return {
        ...state,
        user_info: null,
      };
    case action_types.LOGIN_FAILED:
      return {
        ...state,
        login_loading: false,
        result_successfull: false,
        result_failed: action.payload.isOldCustomer ? false : true,
        user_info: null,
        result_message: action.payload.data,
        is_old_customer: action.payload.isOldCustomer,
      };
    // EDIT ACCOUNT
    case action_types.EDIT_ACCOUNT:
      return {
        ...state,
        sign_up_loading: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case action_types.EDIT_ACCOUNT_SUCCESSFULL:
      return {
        ...state,
        sign_up_loading: false,
        result_successfull: true,
        result_failed: false,
        user_info: action.payload.customer_info,
        result_message: action.payload.result_message,
      };
    case action_types.EDIT_ACCOUNT_FAILED:
      return {
        ...state,
        sign_up_loading: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    // ADD ADDRESS
    case action_types.ADD_ADDRESS:
      return {
        ...state,
        sign_up_loading: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case action_types.ADD_ADDRESS_SUCCESSFULL:
      return {
        ...state,
        sign_up_loading: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload.message,
        addresses_list: action.payload.list,
      };
    case action_types.ADD_ADDRESS_FAILED:
      return {
        ...state,
        sign_up_loading: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    // UPDATE ADDRESS
    case action_types.UPDATE_ADDRESS:
      return {
        ...state,
        sign_up_loading: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case action_types.UPDATE_ADDRESS_SUCCESSFULL:
      return {
        ...state,
        sign_up_loading: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload.message,
        addresses_list: action.payload.list,
      };
    case action_types.UPDATE_ADDRESS_FAILED:
      return {
        ...state,
        sign_up_loading: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    // GET ADDRESSES
    case action_types.GET_ADDRESSES:
      return {
        ...state,
        address_loading: true,
        address_result_successfull: false,
        address_result_failed: false,
        result_message: "",
      };
    case action_types.GET_ADDRESSES_SUCCESSFULL:
      return {
        ...state,
        address_loading: false,
        address_result_successfull: true,
        address_result_failed: false,
        addresses_list: action.payload,
      };
    case action_types.GET_ADDRESSES_FAILED:
      return {
        ...state,
        sign_up_loading: false,
        address_result_successfull: false,
        address_result_failed: true,
        result_message: action.payload,
      };
    case action_types.SEND_OTP_SUCCESSFULL:
      return {
        ...state,
        otp_successfull: true,
        otp_failed: false,
      };
    case action_types.SEND_OTP_FAILED:
      return {
        ...state,
        otp_successfull: false,
        otp_failed: true,
      };
    case action_types.SEND_VERIFICATION_CODE:
      return {
        ...state,
        send_otp_code_loading: true,
      };
    case action_types.SEND_VERIFICATION_CODE_SUCCESSFULL:
      return {
        ...state,
        send_otp_code_loading: false,
        send_otp_code_successfull: true,
        send_otp_code_failed: false,
      };
    case action_types.SEND_VERIFICATION_CODE_FAILED:
      return {
        ...state,
        send_otp_code_loading: false,
        send_otp_code_successfull: false,
        send_otp_code_failed: true,
        result_message: action.payload,
      };

    default:
      return state;
  }
};

export default profileReduser;
