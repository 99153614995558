import * as action_types from "../ActionType";

const initialStates = {
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading_product: false,
  products_list: [],
  product_submenu_list: [],
  selected_product: null,
  show_product_submenu_popup: false,
  product_option_crust: [],
  show_cache_popup: false,
  show_submenu_loader: true,
  global_submenu_list: [],
  populars_list: [],
  all_populars_list: [],
  mobile_product_list: [],
  vats_list: [],
  all_product_loader: false,
};
const productReduser = (state = initialStates, action) => {
  switch (action.type) {
    case action_types.SET_MOBILE_PRODUCT_LIST:
      return {
        ...state,
        mobile_product_list: action.payload,
      };
    case action_types.SET_DEFAULT_ACTION:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_product: false,
      };
    // PRODUCT
    case action_types.PRODUCT_LIST:
      return {
        ...state,
        loading_product: true,
      };
    case action_types.PRODUCT_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_product: false,
        products_list: action.payload.productsList,
        vats_list: action.payload.vatsList,
      };
    case action_types.PRODUCT_LIST_FAILED:
      return {
        ...state,
        loading_product: false,
        products_list: [],
      };
    //ALL PRODUCT
    case action_types.GET_ALL_PRODUCTS:
      return {
        ...state,
        all_product_loader: true,
      };
    case action_types.GET_ALL_PRODUCTS_SUCCESSFULL:
      return {
        ...state,
        all_populars_list: action.payload,
        all_product_loader: false,
      };
    case action_types.GET_ALL_PRODUCTS_FAILED:
      return {
        ...state,
        all_product_loader: false,
      };

    // PRODUCT SUBMENU
    case action_types.PRODUCT_SUBMENU_LIST:
      return {
        ...state,
        // loading_product: true,
        product_submenu_list: [],
        show_submenu_loader: true,
      };
    case action_types.PRODUCT_SUBMENU_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_product: false,
        product_submenu_list: action.payload,
        show_submenu_loader: false,
      };
    case action_types.PRODUCT_SUBMENU_LIST_FAILED:
      return {
        ...state,
        loading_product: false,
        product_submenu_list: [],
        show_submenu_loader: false,
      };
    // SUBMENU
    case action_types.GET_SUBMENU_LIST_SUCCESSFULL:
      return {
        ...state,
        global_submenu_list: action.payload,
      };
    case action_types.SELECTED_PRODUCT:
      return {
        ...state,
        selected_product: action.payload,
      };
    case action_types.SHOW_PRODUCT_SUBMENU_POPUP:
      return {
        ...state,
        show_product_submenu_popup: action.payload,
      };
    case action_types.PRODUCT_OPTION_CRUST:
      return {
        ...state,
        product_option_crust: action.payload,
      };
    case action_types.NEW_PRODUCT_SUBMENU:
      return {
        ...state,
        product_submenu_list: action.payload,
      };
    case action_types.NEW_PRODUCT_SUBMENU_1:
      return {
        ...state,
        product_submenu_list: action.payload,
      };
    case action_types.SHOW_CACHE_POPUP:
      return {
        ...state,
        show_cache_popup: action.payload,
      };
    case action_types.GET_POPULARS_LIST_SUCCESSFULL:
      return {
        ...state,
        populars_list: action.payload,
      };
    case action_types.GET_POPULARS_LIST_FAILED:
      return {
        ...state,
        populars_list: [],
      };
    default:
      return state;
  }
};
export default productReduser;
