import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";

import {
  sendVerificationCodeAction,
  setDefaultAction,
  sendOtpAction,
} from "../redux/Profile/Actions";

import {
  primary_color,
  white_dark,
  white,
  black_dark,
  dark_border_color,
} from "../constants/Colors";
import LoaderMini from "../Componenets/LoderMini";

const VerificationCode = ({ mobile, hide_verification, update_profile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [code, setCode] = useState("");
  const [activeResend, setActiveResend] = useState(false);
  const [count, setCount] = useState("01:00");

  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    otp_successfull,
    send_otp_code_successfull,
    send_otp_code_failed,
    result_message,
    send_otp_code_loading,
    user_info,
  } = useSelector((state) => state.profileReduser);

  const { shop_id, login_key, delivery_time_list } = useSelector(
    (state) => state.shopSettingsReduser
  );

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleSendVerification = () => {
    dispatch(sendVerificationCodeAction(code, mobile));
    // if (otp_successfull) {
    // }
  };

  useEffect(() => {
    if (send_otp_code_successfull) {
      update_profile();
      hide_verification();
      navigate("/review");
      dispatch(setDefaultAction());
    } else if (send_otp_code_failed) {
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
      dispatch(setDefaultAction());
    }
  }, [send_otp_code_successfull, send_otp_code_failed]);

  useEffect(() => {
    startTimer();
  }, []);

  const startTimer = () => {
    let myTime = 59;
    const timer = setInterval(() => {
      if (myTime > -1) {
        if (myTime > 9) {
          setCount(`00:${myTime}`);
          myTime = myTime - 1;
        } else {
          setCount(`00:0${myTime}`);
          myTime = myTime - 1;
        }
      } else {
        setActiveResend(true);
        clearInterval(timer);
      }
    }, 1000);
  };

  const handleResend = () => {
    if (activeResend) {
      startTimer();
      setActiveResend(false);
      dispatch(sendOtpAction(shop_id, mobile, user_info.CustomerId));
    }
  };

  return (
    <div
      className="create_shop_popup_container"
      onClick={() => hide_verification()}
    >
      <div
        className="change_pass_content_container"
        onClick={(i) => i.stopPropagation()}
      >
        <div className="verification_code_container">
          <div className="verification_code_header_container">
            <h6>{lang.enter_5_digit_code}</h6>
            <h5>{mobile}</h5>
          </div>

          <div className="count_down_resend_container">
            <h6>{count}</h6>

            <h6
              id="resend_otp_code"
              onClick={handleResend}
              style={{
                color: activeResend ? primary_color : dark_border_color,
                borderBottom: activeResend
                  ? `1px solid ${primary_color}`
                  : `1px solid ${dark_border_color}`,
              }}
            >
              Resend
            </h6>

            {/* <div id="count_down_container">
              <div id="count_down_decade">
                <h5>{count.split("")[0]}</h5>
              </div>
              <div id="count_down_one">
                <h5>{count.split("")[1]}</h5>
              </div>
            </div> */}
            {/* <button
              onClick={handleSendVerification}
              disabled={code.split("").length == 5 ? false : true}
              style={{
                backgroundColor:
                  code.split("").length == 5 ? primary_color : white_dark,
                color: code.split("").length == 5 ? white : black_dark,
                borderColor:
                  code.split("").length == 5 ? primary_color : white_dark,
              }}
            >
              {send_otp_code_loading ? <LoaderMini /> : lang.send}
            </button> */}
          </div>

          <input
            type="text"
            name="name"
            value={code}
            ref={inputRef}
            onChange={(e) => setCode(e.target.value.replace(/\D/g, ""))}
            maxLength={5}
          />
          {/* BUTTON */}

          <button
            onClick={handleSendVerification}
            disabled={code.split("").length == 5 ? false : true}
            style={{
              backgroundColor:
                code.split("").length == 5 ? primary_color : white_dark,
              color: code.split("").length == 5 ? white : black_dark,
              borderColor:
                code.split("").length == 5 ? primary_color : white_dark,
            }}
          >
            {send_otp_code_loading ? <LoaderMini /> : lang.send}
          </button>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default VerificationCode;
