import * as action_types from "../ActionType";

// SINGLE SETS
export const setUserEnteredIn = () => {
  return {
    type: action_types.SET_USER_ENTERED_IN,
  };
};
export const setShopIdAction = (data) => {
  return {
    type: action_types.SET_SHOP_ID,
    payload: data,
  };
};
export const getTerminalsAction = () => {
  return {
    type: action_types.GET_TERMINALS,
  };
};
// SHOW MAINTENANCE MODE POPUP
export const showMaintenanceModePopup = (data) => {
  return {
    type: action_types.SHOW_MAINTENANCE_MODE_POPUP,
    payload: data,
  };
};
// SET MAINTENANCE MODE POPUP MESSAGE
export const setMaintenanceModePopupMessage = (message, showPreorderButton) => {
  return {
    type: action_types.SET_MAINTENANCE_MODE_POPUP_MESSAGE,
    payload: { message, showPreorderButton },
  };
};
// SET PREEORDER PRESSED
export const setPreorderPressedAction = (navigate) => {
  return {
    type: action_types.SET_PREORDER_PRESSESD,
  };
};
//PASS NAVIGATE
export const passNavigateAction = () => {
  return {
    type: action_types.PASS_NAVIGATE,
  };
};
// SHOP CLOSE OPEN
export const getShopOpenCloseAction = (
  shop_id,
  login_key,
  isInitialRequest,
  navigate,
  pass
) => {
  return {
    type: action_types.GET_SHOP_OPEN_CLOSE,
    payload: { shop_id, login_key, isInitialRequest, navigate, pass },
  };
};

export const getShopOpenCloseSuccessfull = (
  data,
  showHidePopupStatus,
  popup_message,
  delivery_from,
  delivery_unavailable,
  collection_from,
  collection_unavailable,
  showPreorderButton,
  go_to_checkout,
  basket_button_title
) => {
  return {
    type: action_types.GET_SHOP_OPEN_CLOSE_SUCCESSFULL,
    payload: {
      data,
      showHidePopupStatus,
      popup_message,
      delivery_from,
      delivery_unavailable,
      collection_from,
      collection_unavailable,
      showPreorderButton,
      go_to_checkout,
      basket_button_title,
    },
  };
};
export const getShopOpenCloseFailed = (data) => {
  return {
    type: action_types.GET_SHOP_OPEN_CLOSE_FAILED,
    payload: data,
  };
};

// GET DELIVERY TIME
export const getDeliveryTimeAction = (shop_id, login_key, sale_method) => {
  return {
    type: action_types.GET_DELIVERY_TIME,
    payload: {
      shop_id,
      login_key,
      sale_method,
    },
  };
};
export const getDeliveryTimeSuccessfull = (data) => {
  return {
    type: action_types.GET_DELIVERY_TIME_SUCCESSFULL,
    payload: data,
  };
};
export const getDeliveryTimeFailed = (data) => {
  return {
    type: action_types.GET_DELIVERY_TIME_FAILED,
    payload: data,
  };
};
// GET SIX STEP
export const getSixStepAction = (shop_id, login_key, order_id) => {
  return {
    type: action_types.GET_SIX_STEP,
    payload: {
      shop_id,
      login_key,
      order_id,
    },
  };
};
export const getSixStepSuccessfull = (data) => {
  return {
    type: action_types.GET_SIX_STEP_SUCCESSFULL,
    payload: data,
  };
};
export const getSixStepFailed = (data) => {
  return {
    type: action_types.GET_SIX_STEP_FAILED,
    payload: data,
  };
};
// *****
// GET DELIVERY FEE
export const getDeliveryFeeAction = (
  shop_id,
  login_key,
  customer_id,
  postcode
) => {
  return {
    type: action_types.GET_DELIVERY_FEE_BY_POSTCODE,
    payload: {
      shop_id,
      login_key,
      customer_id,
      postcode,
    },
  };
};
export const getDeliveryFeeSuccessfull = (data) => {
  return {
    type: action_types.GET_DELIVERY_FEE_BY_POSTCODE_SUCCESSFULL,
    payload: data,
  };
};
export const getDeliveryFeeFailed = (data) => {
  return {
    type: action_types.GET_DELIVERY_FEE_BY_POSTCODE_FAILED,
    payload: data,
  };
};
// GET PAYMENT SETTINGS
export const getPaymentSettingsAction = (shop_id, login_key) => {
  return {
    type: action_types.GET_PAYMENT_SETTINGS,
    payload: {
      shop_id,
      login_key,
    },
  };
};
export const getPaymentSettingsSuccessfull = (data) => {
  return {
    type: action_types.GET_PAYMENT_SETTINGS_SUCCESSFULL,
    payload: data,
  };
};
export const getPaymentSettingsFailed = (data) => {
  return {
    type: action_types.GET_PAYMENT_SETTINGS_FAILED,
    payload: data,
  };
};
// GET SUCCESSFUL ORDER DETAILS
export const getSuccessfulOrderDetailsAction = (
  shop_id,
  login_key,
  order_id
) => {
  return {
    type: action_types.GET_SUCCESSFUL_ORDER_DETAILS,
    payload: {
      shop_id,
      login_key,
      order_id,
    },
  };
};
export const getSuccessfulOrderDetailsSuccessful = (data) => {
  return {
    type: action_types.GET_SUCCESSFUL_ORDER_DETAILS_SUCCESSFULL,
    payload: data,
  };
};
export const getSuccessfulOrderDetailsFailed = (data) => {
  return {
    type: action_types.GET_SUCCESSFUL_ORDER_DETAILS_FAILED,
    payload: data,
  };
};
// *****
// SEND ORDER
export const sendOrderAction = (
  shop_id,
  login_key,
  order_details,
  pay_method,
  price,
  product_name,
  successful_url,
  failed_url,
  customer_email
) => {
  return {
    type: action_types.SEND_ORDER,
    payload: {
      shop_id,
      login_key,
      order_details,
      pay_method,
      price,
      product_name,
      successful_url,
      failed_url,
      customer_email,
    },
  };
};
export const sendOrderSuccessfull = (pay_method, order_id) => {
  return {
    type: action_types.SEND_ORDER_SUCCESSFULL,
    payload: { pay_method, order_id },
  };
};
export const sendOrderFailed = (data) => {
  return {
    type: action_types.SEND_ORDER_FAILED,
    payload: data,
  };
};
// SEND ORDER EMAIL
export const sendOrderEmailAction = (
  shop_id,
  login_key,
  order_id,
  role_id,
  invoice_html,
  customer_email,
  email_subject
) => {
  return {
    type: action_types.SEND_ORDER_EMAIL,
    payload: {
      shop_id,
      login_key,
      order_id,
      role_id,
      invoice_html,
      customer_email,
      email_subject,
    },
  };
};
export const sendOrderEmailSuccessfull = (data) => {
  return {
    type: action_types.SEND_ORDER_EMAIL_SUCCESSFULL,
    payload: data,
  };
};
export const sendOrderEmailFailed = (data) => {
  return {
    type: action_types.SEND_ORDER_EMAIL_FAILED,
    payload: data,
  };
};
// *****
// SEND PRICE FOR PAY
export const sendPriceForPayAction = (
  shop_id,
  order_id,
  price,
  product_name,
  successful_url,
  failed_url,
  customer_email
) => {
  return {
    type: action_types.SEND_PRICE_FOR_PAY,
    payload: {
      shop_id,
      order_id,
      price,
      product_name,
      successful_url,
      failed_url,
      customer_email,
    },
  };
};
export const sendPriceForPaySuccessfull = (data) => {
  return {
    type: action_types.SEND_PRICE_FOR_PAY_SUCCESSFULL,
    payload: data,
  };
};
export const sendPriceForPayFailed = (data) => {
  return {
    type: action_types.SEND_PRICE_FOR_PAY_FAILED,
    payload: data,
  };
};
// *****
// GET SHOP INFO
export const getShopInfoAction = (shop_id, login_key) => {
  return {
    type: action_types.GET_SHOP_INFO,
    payload: {
      shop_id,
      login_key,
    },
  };
};
export const getShopInfoSuccessfull = (data) => {
  return {
    type: action_types.GET_SHOP_INFO_SUCCESSFULL,
    payload: data,
  };
};
export const getShopInfoFailed = (data) => {
  return {
    type: action_types.GET_SHOP_INFO_FAILED,
    payload: data,
  };
};
// *****

// ORDER REQUIRED INFO
export const setOrderRequiredInfoAction = (
  delivery_time,
  special_note,
  selected_address
) => {
  return {
    type: action_types.SET_ORDER_REQUIRED_INFO,
    payload: { delivery_time, special_note, selected_address },
  };
};
// DEFAULT ORDER PLACE STATUS
export const setDefaultOrderPlaceStatusAction = () => {
  return {
    type: action_types.SET_DEFAULT_ORDER_PLACE_STATUS,
  };
};
