import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";
// const https = require("https");
// const httpsAgent = new https.Agent({ rejectUnauthorized: false });

// const agent = new https.Agent({
//   rejectUnauthorized: false,
// });
// const instance = axios.create({
//   httpsAgent: new https.Agent({
//     rejectUnauthorized: false,
//   }),
// });

export const categoryListServices = async (login_key, shop_id) => {
  return await axios.put(`${baseUrl}sitesapies/CategoryList/`, {
    LoginKey: login_key,
    ShopId: shop_id,
  });
};
