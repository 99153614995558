import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import validator from "validator";
import TextField from "@mui/material/TextField";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";

import MainHeader from "../../Componenets/MainHeader";
import { primary_color, white } from "../../constants/Colors";
import {
  verifyResetPasswordLinkAction,
  editAccountAction,
  setDefaultAction,
} from "../../redux/Profile/Actions";
import LoaderMini from "../../Componenets/LoderMini";
import Sorry from "../../assets/images/sorry.gif";

const UpdatePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    login_loading,
    reset_password_link_expired,
    user_info,
    result_successfull,
    result_failed,
    result_message,
    sign_up_loading,
    reset_password_link_verified,
  } = useSelector((state) => state.profileReduser);
  const { user_entered_in, shop_id, login_key } = useSelector(
    (state) => state.shopSettingsReduser
  );

  const [email, setEmail] = useState("");
  const [passErrorMessage, setPassErrorMessage] = useState("");
  const [repeatErrorMessage, setRepeatErrorMessage] = useState("");
  const [resetPasswordLinkExpired, setResetPasswordLinkExpired] =
    useState(false);
  const [updatePasswordSuccessful, setUpdatePasswordSuccessful] =
    useState(false);
  const [resetPasswordLinkVerified, setResetPasswordLinkVerified] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [myWidth, setMyWidth] = useState("35%");
  const [myHeight, setMyHeight] = useState(460);

  const updatePassword = () => {
    if (newPassword) {
      if (newPassword.split("").length > 5) {
        if (newPassword == repeatNewPassword) {
          dispatch(
            editAccountAction(
              user_info.ShopId,
              "",
              user_info.CustomerId,
              user_info.FirstName,
              user_info.LastName,
              user_info.Mobile,
              user_info.Note,
              user_info.Mail,
              newPassword,
              user_info.IsVerified
            )
          );
        } else {
          setRepeatErrorMessage(`Repeat password incorrect`);
        }
      } else {
        setPassErrorMessage("At least 6 charachters long");
      }
    } else {
      setPassErrorMessage("Password is required");
    }
  };
  let callOnce = true;
  useEffect(() => {
    if (callOnce) {
      callOnce = false;

      dispatch(
        verifyResetPasswordLinkAction(
          params.ShopId,
          "",
          params.CustomerId,
          params.Token
        )
      );
    }
  }, []);

  useEffect(() => {
    if (reset_password_link_expired) {
      dispatch(setDefaultAction());
      setResetPasswordLinkExpired(true);
    }
  }, [reset_password_link_expired]);

  useEffect(() => {
    if (reset_password_link_verified) {
      dispatch(setDefaultAction());
      setResetPasswordLinkVerified(true);
    }
  }, [reset_password_link_verified]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      setUpdatePasswordSuccessful(true);
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 760) {
        setMyWidth("35%");
        setMyHeight(460);
      } else {
        setMyWidth("90%");
        setMyHeight(400);
      }
    }

    window.addEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (window.innerWidth > 760) {
      setMyWidth("35%");
      setMyHeight(460);
    } else {
      setMyWidth("90%");
      setMyHeight(400);
    }
  }, []);

  return (
    <div className="menu-container-div">
      <MainHeader
        show_all_items={true}
        show_basket={false}
        show_profile={false}
        show_menu_title={true}
      />
      <div className="forget_password_main_container">
        {resetPasswordLinkVerified ? (
          <div className="forget_password_main_container2">
            {resetPasswordLinkExpired ? (
              // LINK EXPIRED
              <div className="forget_password_content_container">
                <h3>The reset password link expired</h3>
                <Button
                  className="profile-create-account-button"
                  onClick={() => navigate("/ForgetPassword")}
                  style={{
                    backgroundColor: primary_color,
                    color: white,
                    margin: "15px 0",
                    textTransform: "none",
                    width: "80%",
                  }}
                >
                  {lang.request_new_link}
                </Button>
              </div>
            ) : (
              <div className="forget_password_inputs_main_container">
                {updatePasswordSuccessful ? (
                  <div className="forget_password_content_container">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    <h6>{lang.reset_password_successfull_message}</h6>
                    <Button
                      className="profile-create-account-button"
                      onClick={() => navigate("/login/1")}
                      style={{
                        backgroundColor: primary_color,
                        color: white,
                        margin: "15px 0",
                        textTransform: "none",
                        width: "80%",
                      }}
                    >
                      {lang.sign_in}
                    </Button>
                  </div>
                ) : (
                  <div
                    className="forget_password_content_container"
                    style={{
                      width: myWidth,
                      height: myHeight,
                      padding: 30,
                    }}
                  >
                    <h4>{lang.update_your_password}</h4>
                    <h6>{lang.update_password_message}</h6>

                    <div className="new_password_input_container">
                      <h6>{lang.new_password}</h6>

                      <input
                        value={newPassword}
                        onChange={(i) => {
                          setNewPassword(i.target.value);
                          setPassErrorMessage("");
                          setRepeatErrorMessage("");
                        }}
                        placeholder={lang.new_password}
                        type="password"
                      />
                      <div>
                        <p>{passErrorMessage}</p>
                      </div>
                    </div>

                    <div className="new_password_input_container">
                      <h6>{lang.repeat_new_password}</h6>

                      <input
                        value={repeatNewPassword}
                        onChange={(i) => {
                          setRepeatNewPassword(i.target.value);
                          setPassErrorMessage("");
                          setRepeatErrorMessage("");
                        }}
                        placeholder={lang.repeat_new_password}
                        type="password"
                      />
                      <div>
                        <p>{repeatErrorMessage}</p>
                      </div>
                    </div>
                    <Button
                      className="profile-create-account-button"
                      onClick={updatePassword}
                      style={{
                        backgroundColor: primary_color,
                        color: white,
                        margin: "15px 0",
                        textTransform: "none",
                        width: "80%",
                      }}
                    >
                      {sign_up_loading ? <LoaderMini /> : lang.save}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UpdatePassword;
