import { all, fork } from "redux-saga/effects";
import rootMenu from "./Categories/Sagas";
import rootProduct from "./Products/Sagas";
import rootPrfile from "./Profile/Sagas";
import rootShopOpenClose from "./settings/Sagas";
export default function* RootSaga() {
  yield all([
    fork(rootMenu),
    fork(rootProduct),
    fork(rootPrfile),
    fork(rootShopOpenClose),
  ]);
}
