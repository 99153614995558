import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { toast, Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { ViewportList } from "react-viewport-list";
import { useScrollDirection } from "react-use-scroll-direction";
import store from "store2";
import moment from "moment";

import {
  categoryListAction,
  selectedCategoryAction,
} from "../redux/Categories/Actions";
import { setDropDownClickedAction } from "../redux/Profile/Actions";
import {
  showMaintenanceModePopup,
  setMaintenanceModePopupMessage,
} from "../redux/settings/Actions";
import {
  setSaleMethodAction,
  setBasketListAction,
} from "../redux/basket/Actions";
import {
  productsListAction,
  selectedProductAction,
  productOptionCrustAction,
  showProductSubmenuPopupAction,
  setMobileProductListAction,
} from "../redux/Products/Actions";
import { baseUrlForImages } from "../constants/BaseUrl";
import { delivery, collection } from "../constants/SaleMethodsId";

import { baseUrlForImagesPrefix } from "../constants/BaseUrl";

import { EUR } from "../constants/Currency";

const Products = forwardRef((props, forwardedRef) => {
  // const Products = ({ productLists, pd_selectedCategoryIndex }) => {
  const dispatch = useDispatch();
  // const ref = useRef(null);
  const [elRefs, setElRefs] = useState([]);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const {
    isScrolling,
    isScrollingX,
    isScrollingY,
    isScrollingUp,
    isScrollingDown,
    isScrollingLeft,
    isScrollingRight,
    scrollDirection,
  } = useScrollDirection();

  const { sale_method, basket_product_id_list } = useSelector(
    (state) => state.basketReduser
  );
  const {
    products_list,
    selected_product,
    all_populars_list,
    mobile_product_list,
  } = useSelector((state) => state.productReduser);

  const { selected_category, category_list } = useSelector(
    (state) => state.categoriesRedusers
  );
  const { userDropDownIsClicked } = useSelector(
    (state) => state.profileReduser
  );
  const {
    maintenance_mode,
    show_maintenance_mode_popup,
    before_the_shop_opens,
    maintenance_mode_message,
    delivery_from,
    delivery_unavailable,
    collection_unavailable,
    collection_from,
    preorder_status,
    open_close_settings_details,
    preorder_pressed,
    shop_info,
  } = useSelector((state) => state.shopSettingsReduser);

  const [productsLists, setProductsLists] = useState([]);
  const [selectedCatIndex, setSelectedCatIndex] = useState(0);
  const [scrollDir, setScrollDir] = useState("");
  const [scrollCurrent, setScrollCurrent] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scrollStart, setScrollStart] = useState(0);
  const [elementInViewChanged, setElementInViewChanged] = useState(0);

  // useEffect(() => {
  //   if (elRefs.length > 0) {
  //     const observer = new IntersectionObserver((entries) => {
  //       const entry = entries[0];
  //       console.log("entry", entry);
  //       if (entry.isIntersecting) {
  //         console.log("bottom", entry.boundingClientRect.bottom);
  //         setElementInViewChanged(entry.boundingClientRect.bottom);
  //       }

  //     });
  //     for (let i = 0; i < elRefs.length; i++) {
  //       observer.observe(elRefs[i].current);
  //     }
  //   }
  // }, [elRefs]);
  useEffect(() => {
    if (scrollDirection != scrollDir && scrollDirection) {
      setScrollDir(scrollDirection);
    }
  }, [scrollDirection, scrollDir]);

  // WHEN ENTRY CHANGE SCROLL TO DESIRED CATEGORY
  // const scrollToCatRef = useRef(false);
  // const scrollToCatRef2 = useRef(false);
  // useEffect(() => {
  //   if (scrollToCatRef.current) {
  //     if (scrollToCatRef2.current) {
  //       console.log("elementInViewChanged", elementInViewChanged);
  //       props.pd_scroll_to_category(1);
  //     } else {
  //       scrollToCatRef2.current = true;
  //     }
  //   } else {
  //     scrollToCatRef.current = true;
  //   }
  // }, [elementInViewChanged]);

  let timer = null;
  const handleScroll = () => {
    // IN THIS FUNC IF SCROLL CONSIST, timeout CLEAR
    // IF FOR 500 MILI  SECENOD NO SCROLL FIRE LAST POSITION SET AS END OF SCROLL
    const scrollPosition = window.scrollY; // => scroll position

    setScrollCurrent(scrollPosition);

    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      setScrollStart(scrollPosition);
    }, 500);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClciledProduct = (item, index) => {
    const saved_get_all_product_time = store.get("all_product_get_time");

    if (saved_get_all_product_time) {
      if (
        moment(saved_get_all_product_time)
          .add(59, "minutes")
          .format("DD-MM-YYYY HH:mm") >
        moment(new Date()).format("DD-MM-YYYY HH:mm")
      ) {
        props.emptySearchList();
        for (let i = 0; i < category_list.length; i++) {
          if (category_list[i].Id == item.CategoryId) {
            dispatch(selectedCategoryAction(category_list[i]));
            props.pd_setSelectedCategoryIndex(i);
            break;
          }
        }

        let del = open_close_settings_details.DeliveryTimGap.split(" ");
        let col = open_close_settings_details.CollectionTimeGap.split(" ");

        if (maintenance_mode) {
          dispatch(showMaintenanceModePopup(true));
        } else if (delivery_unavailable && collection_unavailable) {
          dispatch(showMaintenanceModePopup(true));
          dispatch(
            setMaintenanceModePopupMessage(
              `This shop currently doesn't accept order`,
              false
            )
          );
        } else if (preorder_status) {
          if (delivery_unavailable && collection_from) {
            if (preorder_pressed) {
              dispatch(showProductSubmenuPopupAction(true));
            } else {
              dispatch(showMaintenanceModePopup(true));
            }
            dispatch(
              setMaintenanceModePopupMessage(
                `Collection starts at ${col[1]}`,
                true
              )
            );
            dispatch(selectedProductAction(item));
          } else if (
            delivery_unavailable &&
            !collection_from &&
            !collection_unavailable
          ) {
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(collection));
          } else if (collection_unavailable && delivery_from) {
            if (preorder_pressed) {
              dispatch(showProductSubmenuPopupAction(true));
            } else {
              dispatch(showMaintenanceModePopup(true));
            }

            dispatch(
              setMaintenanceModePopupMessage(
                `Delivery starts at ${del[1]}`,
                true
              )
            );
            dispatch(selectedProductAction(item));
          } else if (
            collection_unavailable &&
            !delivery_from &&
            !delivery_unavailable
          ) {
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(delivery));
          } else if (delivery_from && collection_from) {
            if (preorder_pressed) {
              dispatch(showProductSubmenuPopupAction(true));
            } else {
              dispatch(showMaintenanceModePopup(true));
            }
            dispatch(selectedProductAction(item));
          } else {
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
          }
        } else if (!preorder_status) {
          if (delivery_unavailable && collection_from) {
            dispatch(showMaintenanceModePopup(true));
            dispatch(
              setMaintenanceModePopupMessage(
                `Collection starts at ${col[1]}`,
                false
              )
            );
          } else if (
            delivery_unavailable &&
            !collection_from &&
            !collection_unavailable
          ) {
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(collection));
          } else if (collection_unavailable && delivery_from) {
            dispatch(showMaintenanceModePopup(true));
            dispatch(
              setMaintenanceModePopupMessage(
                `Delivery starts at ${del[1]}`,
                false
              )
            );
          } else if (
            collection_unavailable &&
            !delivery_from &&
            !delivery_unavailable
          ) {
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
            dispatch(setSaleMethodAction(delivery));
          } else if (delivery_from && collection_from) {
            dispatch(
              setMaintenanceModePopupMessage(
                `Delivery starts at ${del[1]}`,
                false
              )
            );
            dispatch(showMaintenanceModePopup(true));
          } else {
            dispatch(selectedProductAction(item));
            dispatch(showProductSubmenuPopupAction(true));
          }
        }
      } else {
        props.pd_get_initial_data();
        dispatch(setBasketListAction([], 0, null, [], 0, 0, 0));
        store.remove("temp_basket");
        store.remove("temp_sub_total");
        store.remove("basket_last_item_id");
        store.remove("temp_basket_product_id_list");
        store.remove("temp_no_mealdeal_sub_total");
        store.remove("discount");
        store.remove("discount_no_mealdeal");
      }
    } else {
      props.pd_get_initial_data();
      dispatch(setBasketListAction([], 0, null, [], 0, 0, 0));
      store.remove("temp_basket");
      store.remove("temp_sub_total");
      store.remove("basket_last_item_id");
      store.remove("temp_basket_product_id_list");
      store.remove("temp_no_mealdeal_sub_total");
      store.remove("discount");
      store.remove("discount_no_mealdeal");
    }
  };
  // useEffect(() => {
  //   console.log("pro pro");
  //   if (selected_category) {
  //     dispatch(productsListAction("", 1, selected_category.Id));
  //   }
  // }, [selected_category]);

  //   useEffect(() => {
  //     if(products_list.length>0){
  //  setProductsLists(products_list)
  //     }else{
  //       setProductsLists([])
  //     }
  //    }, [products_list])
  useEffect(() => {
    if (category_list.length > 0 && all_populars_list.length > 0) {
      let tempMobileProductList = [];
      for (let i = 0; i < category_list.length; i++) {
        let tempProductPerCategory = [];
        for (let j = 0; j < all_populars_list.length; j++) {
          if (category_list[i].Id == all_populars_list[j].CategoryId) {
            tempProductPerCategory.push(all_populars_list[j]);
          }
        }
        tempMobileProductList.push({
          id: category_list[i].Id,
          title: category_list[i].WebTitle,
          desc: category_list[i].WebDescription,
          products: tempProductPerCategory,
        });
      }

      dispatch(setMobileProductListAction(tempMobileProductList));
    }
  }, [category_list, all_populars_list]);

  useEffect(() => {
    if (mobile_product_list.length > 0) {
      setElRefs((elRefs) =>
        Array(mobile_product_list.length)
          .fill()
          .map((_, i) => elRefs[i] || React.createRef())
      );
    }
  }, [mobile_product_list]);

  useImperativeHandle(forwardedRef, () => ({
    scrollToView: (i) => {
      scrollToSelectedCategory(i);
    },
  }));
  const scrollToSelectedCategory = (index) => {
    setCurrentIndex(index);
    // console.log("offset", elRefs[index].current.offsetTop);
    // console.log("viewportHeight", window.innerHeight);
    // elRefs[index].current.scrollIntoView(true, {
    //   behavior: "smooth",
    //   block: "start",
    // });
    // setTimeout(() => {
    //   window.scrollBy(0, -115);
    // }, 700);
    // window.scrollTo(0, elRefs[index].current.offsetTop + 270);
    window.scrollTo({
      top: elRefs[index].current.offsetTop + 270,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (userDropDownIsClicked && window.innerWidth < 764 && elRefs.length > 0) {
      window.scrollTo({
        top: elRefs[0].current.offsetTop + 100,
        behavior: "smooth",
      });

      dispatch(setDropDownClickedAction(false));
    }
  }, [userDropDownIsClicked]);
  // useEffect(() => {
  //   window.addEventListener("scroll", setScroll);
  //   return () => {
  //     window.removeEventListener("scroll", setScroll);
  //   };

  // }, []);
  // const setScroll = () => {
  //   console.log("scrolled");
  // };

  return (
    <div>
      {props.productLists.length > 0 ? (
        <div className="product_list_container">
          <div
            className="product_list_half_container"
            style={{ marginRight: 15 }}
          >
            {props.productLists.map((item, index) => (
              <div key={index}>
                {index % 2 == 0 ? (
                  <div
                    className="products-container-any-product"
                    onClick={() => handleClciledProduct(item, index)}
                    style={{
                      borderRightWidth: basket_product_id_list
                        ? basket_product_id_list.includes(item.Id)
                          ? 5
                          : 0
                        : 0,
                      borderRightColor: basket_product_id_list
                        ? basket_product_id_list.includes(item.Id)
                          ? "red"
                          : null
                        : null,
                      borderRightStyle: basket_product_id_list
                        ? basket_product_id_list.includes(item.Id)
                          ? "solid"
                          : null
                        : null,
                    }}
                  >
                    {item.WebImageUrl && item.WebVisibleImage ? (
                      <div
                        className="products-container-image"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            shop_info
                              ? `${baseUrlForImagesPrefix}${shop_info.Domain}/${item.WebImageUrl}`
                              : null
                          }
                        />
                      </div>
                    ) : null}
                    <div className="products-container-texts">
                      <div>
                        <h6>{item.WebTitle}</h6>
                        <p>{item.WebDescription}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          margin: 0,
                        }}
                      >
                        <h6>
                          {EUR}
                          {item.AmountDelivery
                            ? item.AmountDelivery.toFixed(2)
                            : item.Options
                            ? item.Options[0].AmountDelivery.toFixed(2)
                            : 0}
                        </h6>
                        {item.Calories ? (
                          <h6 id="calories_text">{item.Calories} kcal</h6>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>

          <div className="product_list_half_container">
            {props.productLists.map((item, index) => (
              <div key={index}>
                {index % 2 == 1 ? (
                  <div
                    className="products-container-any-product"
                    onClick={() => handleClciledProduct(item, index)}
                    style={{
                      borderRightWidth: basket_product_id_list
                        ? basket_product_id_list.includes(item.Id)
                          ? 5
                          : 0
                        : 0,
                      borderRightColor: basket_product_id_list
                        ? basket_product_id_list.includes(item.Id)
                          ? "red"
                          : null
                        : null,
                      borderRightStyle: basket_product_id_list
                        ? basket_product_id_list.includes(item.Id)
                          ? "solid"
                          : null
                        : null,
                    }}
                  >
                    {item.WebImageUrl && item.WebVisibleImage ? (
                      <div
                        className="products-container-image"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* <img src={`${baseUrlForImages}${item.WebImageUrl}`} /> */}
                        <img
                          src={
                            shop_info
                              ? `${baseUrlForImagesPrefix}${shop_info.Domain}/${item.WebImageUrl}`
                              : null
                          }
                        />
                      </div>
                    ) : null}
                    <div
                      className="products-container-texts"
                      id="product_text_container"
                    >
                      <div>
                        <h6>{item.WebTitle}</h6>
                        <p>{item.WebDescription}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          margin: 0,
                        }}
                      >
                        <h6>
                          {EUR}
                          {item.AmountDelivery
                            ? item.AmountDelivery.toFixed(2)
                            : item.Options
                            ? item.Options[0].AmountDelivery.toFixed(2)
                            : 0}
                        </h6>
                        {item.Calories ? (
                          <h6 id="calories_text">{item.Calories} kcal</h6>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: 200,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3>No Product</h3>
        </div>
      )}

      {/* MOBILE VIEW */}
      <div className="product_list_mobile_view" ref={ref}>
        {/* <ViewportList
          viewportRef={ref}
          items={mobile_product_list}
          onViewportIndexesChange={(item, index) =>
            console.log("index changes", index)
          }
        >
          {(item) => (
            <div key={item.id}>
              <div style={{ flexDirection: "column", display: "flex" }}>
                <div className="mobile_product_list_category_header_container">
                  <h5>{item.title}</h5>
                  <h6>{item.desc}</h6>
                </div>
                {item.products.map((it, ind) => (
                  <div
                    className="products-container-any-product"
                    onClick={() => handleClciledProduct(it, ind)}
                  >
                    {it.WebImageUrl && it.WebVisibleImage ? (
                      <div
                        className="products-container-image"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img src={`${baseUrlForImages}${it.WebImageUrl}`} />
                      </div>
                    ) : null}
                    <div
                      className="products-container-texts"
                      id="product_text_container"
                    >
                      <div>
                        <h6>{it.WebTitle}</h6>
                        <p>{it.WebDescription}</p>
                      </div>
                      <h6 id="product_price">
                        {EUR}
                        {it.AmountDelivery
                          ? it.AmountDelivery.toFixed(2)
                          : it.Options
                          ? it.Options[0].AmountDelivery.toFixed(2)
                          : 0}
                      </h6>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </ViewportList> */}
        {mobile_product_list.map((item, index) => (
          <div key={index}>
            <div
              style={{ flexDirection: "column", display: "flex" }}
              ref={elRefs[index]}
            >
              <div className="mobile_product_list_category_header_container">
                <h5>{item.title}</h5>
                <h6>{item.desc}</h6>
              </div>
              {item.products.map((it, ind) => (
                <div
                  key={it.Id}
                  className="products-container-any-product"
                  onClick={() => handleClciledProduct(it, ind)}
                >
                  {it.WebImageUrl && it.WebVisibleImage ? (
                    <div
                      className="products-container-image"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          shop_info
                            ? `${baseUrlForImagesPrefix}${shop_info.Domain}/${it.WebImageUrl}`
                            : null
                        }
                      />
                    </div>
                  ) : null}
                  <div
                    className="products-container-texts"
                    id="product_text_container"
                  >
                    <div>
                      <h6>{it.WebTitle}</h6>
                      <p>{it.WebDescription}</p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: 0,
                      }}
                    >
                      <h6 id="product_price">
                        {EUR}
                        {it.AmountDelivery
                          ? it.AmountDelivery.toFixed(2)
                          : it.Options
                          ? it.Options[0].AmountDelivery.toFixed(2)
                          : 0}
                      </h6>
                      <h6 id="product_price" style={{ color: "#828585" }}></h6>
                      {it.Calories ? (
                        <h6
                          id="product_price"
                          style={{ color: "#828585", fontWeight: "lighter" }}
                        >
                          {it.Calories} kcal
                        </h6>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Products;
