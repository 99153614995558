import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, NavLink } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import store from "store2";

import { showBasketAction } from "../redux/basket/Actions";
import BasketLogo from "../assets/images/basket_logo.jfif";
import LogoPlaceholder from "../assets/images/logo_placeholder.jpg";
import { pound_sign } from "../constants/String";
import User from "./User";
import { baseUrlForImages } from "../constants/BaseUrl";
import { primary_color } from "../constants/Colors";

import {
  emptyLoginInfoAction,
  setDropDownClickedAction,
} from "../redux/Profile/Actions";
import { getDeliveryFeeSuccessfull } from "../redux/settings/Actions";
import { setBasketListAction } from "../redux/basket/Actions";
import { baseUrlForImagesPrefix } from "../constants/BaseUrl";

const MainHeader = ({
  show_basket,
  show_profile,
  show_menu_title,
  show_all_items,
  mobile_show_logo,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [shopLogo, setShopLogo] = useState("");

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { shop_info, shop_name } = useSelector(
    (state) => state.shopSettingsReduser
  );
  const { sub_total, delivery_fee, sale_method, basket_content } = useSelector(
    (state) => state.basketReduser
  );
  const { user_info, userDropDownIsClicked } = useSelector(
    (state) => state.profileReduser
  );

  const handleLogOut = () => {
    dispatch(emptyLoginInfoAction());
    dispatch(getDeliveryFeeSuccessfull(0));

    dispatch(setBasketListAction([], 0, 0, [], 0, 0, 0));
    store.remove("user_info");
    store.remove("postcode");
    store.remove("basket");
    store.remove("customer_info");
    store.remove("temp_basket");
    store.remove("temp_sub_total");
    store.remove("basket_last_item_id");
    store.remove("temp_basket_product_id_list");
    navigate("/");
  };
  useEffect(() => {
    if (shop_info) {
      setShopLogo(
        `${baseUrlForImagesPrefix}${shop_info.Domain}/${shop_info.Logo}`
      );
    }
  }, [shop_info]);

  return (
    <div className="menu-header-div">
      {/* MOBILE VIEW */}
      <div className="mobile_header_view">
        {mobile_show_logo ? (
          <div className="menu-container-logo" onClick={() => navigate("/")}>
            <img src={shop_info ? shopLogo : ""} />

            <div className="menu-container-tags">
              <h6>{shop_info ? shop_info.Title : shop_name}</h6>
              <h6 id="main_header_shop_address">
                {shop_info ? `${shop_info.Address} ${shop_info.Postcode}` : ""}
              </h6>
            </div>
          </div>
        ) : (
          <div
            id="main_header_menu_text_container"
            onClick={() => navigate("/")}
          >
            <h6>{lang.menu}</h6>
          </div>
        )}

        <div className="menu" onClick={() => setOpenDrawer(true)}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>

        {/* {show_profile ? <User /> : null} */}
      </div>
      <div className="menu-header-div2">
        <div className="menu-container-logo" onClick={() => navigate("/")}>
          <img src={shop_info ? shopLogo : ""} />

          <div className="menu-container-tags">
            <h6>{shop_info ? shop_info.Title : shop_name}</h6>
            <h6 id="main_header_shop_address">
              {shop_info ? `${shop_info.Address} ${shop_info.Postcode}` : ""}
            </h6>
          </div>
        </div>
        {show_all_items ? (
          <div className="menu-container-cache">
            {show_menu_title ? (
              <div style={{ display: "flex" }}>
                <div
                  id="main_header_menu_text_container"
                  onClick={() => navigate("/")}
                >
                  <h6>{lang.home}</h6>
                </div>
                <div
                  id="main_header_menu_text_container"
                  onClick={() => navigate("/")}
                >
                  <h6>{lang.menu}</h6>
                </div>
              </div>
            ) : null}
            {show_profile ? <User /> : null}
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        class="drawer_menu_mobile_view"
      >
        <List
          onClick={() => setOpenDrawer(false)}
          // class="drawer_menu_main_list"
          style={{
            width: 200,
            fontSize: 14,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            className="menu-container-logo"
            onClick={() => navigate("/")}
            style={{
              width: "90%",
              margin: "auto",
            }}
          >
            <img
              src={
                shop_info
                  ? `${baseUrlForImagesPrefix}${shop_info.Domain}/${shop_info.Logo}`
                  : ""
              }
            />

            <div className="menu-container-tags">
              <h6>{shop_info ? shop_info.Title : shop_name}</h6>
              <h6 id="main_header_shop_address">
                {shop_info ? `${shop_info.Address} ${shop_info.Postcode}` : ""}
              </h6>
            </div>
          </div>
          <hr className="drawer_menu_dvider"></hr>
          {user_info ? (
            <div className="drawer_menu_item_main_container">
              <div
                className="profile_side_bar_title_container"
                onClick={() => navigate("/profile")}
                style={{
                  padding: "0 5%",
                }}
              >
                <i
                  id="profile_user_icon"
                  class="fa fa-user-circle-o"
                  aria-hidden="true"
                ></i>
                <p>
                  {user_info.FirstName} {user_info.LastName}
                </p>
              </div>
              <hr className="drawer_menu_dvider"></hr>
              <div
                className="profile_side_bar_title_container"
                style={{
                  padding: "0 5%",
                }}
                onClick={() => navigate("/MyOrdersMobile")}
              >
                <i
                  id="profile_user_icon"
                  class="fa fa-list"
                  aria-hidden="true"
                ></i>
                <p>{lang.my_orders}</p>
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  left: 0,
                  zIndex: 656565,
                }}
              >
                <hr className="drawer_menu_dvider"></hr>
                <div
                  onClick={() => {
                    handleLogOut();
                  }}
                  className="profile_side_bar_title_container"
                  style={{
                    padding: "0 5%",
                    marginBottom: 20,
                  }}
                >
                  <i
                    id="profile_user_icon"
                    class="fa fa-sign-out"
                    aria-hidden="true"
                  ></i>
                  <p> {lang.logout}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="drawer_menu_item_main_container">
              <ListItem
                onClick={() => navigate("/login/1")}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    width: "90%",
                    height: 35,
                    margin: "auto",
                    backgroundColor: primary_color,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 3,
                  }}
                >
                  <p style={{ margin: 0, color: "white" }}>Login</p>
                </div>
              </ListItem>
              <ListItem
                onClick={() => navigate("/signup/1")}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    width: "90%",
                    height: 35,
                    margin: "auto",
                    borderRadius: 3,
                    border: "1px solid #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p style={{ margin: 0 }}>Sign up</p>
                </div>
              </ListItem>
            </div>
          )}
        </List>
      </Drawer>
    </div>
  );
};

export default MainHeader;
