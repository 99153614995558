import { applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import RootSaga from "./Sagas";
import { Redusers } from "./Redusers";
const sagaMiddleware = createSagaMiddleware();
const store = configureStore(
  {
    reducer: Redusers,
    middleware: [sagaMiddleware],
  }
  // , applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(RootSaga);

export default store;
