import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import store from "store2";
// import moment from "moment";

import ProductPlaceholder from "../assets/images/product_placeholder.jpg";
import {
  showProductSubmenuPopupAction,
  productSubmenuListAction,
  newProductSubmenuListAction,
  newProductSubmenuList1Action,
  selectedProductAction,
} from "../redux/Products/Actions";
import LoderMini from "../Componenets/LoderMini";
import Loader from "../Componenets/Loader";
import { pound_sign } from "../constants/String";
import {
  primary_color,
  primary_light,
  product_description_color,
  white,
  white_dark,
  black_product_title,
  black_dark,
} from "../constants/Colors";
import {
  addToBasketAction,
  setBasketListAction,
} from "../redux/basket/Actions";
import { baseUrlForImages } from "../constants/BaseUrl";
import { baseUrlForImagesPrefix } from "../constants/BaseUrl";
import { collection, delivery } from "../constants/SaleMethodsId";

import { EUR } from "../constants/Currency";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ProductSubmenuPopup = () => {
  const dispatch = useDispatch();
  const viewRef245 = useRef();
  const viewRef342 = useRef();
  const viewRef343 = useRef();
  const viewRef361 = useRef();
  const [elRefs, setElRefs] = React.useState([]);

  // const refs = sblist.reduce((acc, value) => {
  //   acc[value.Id] = useRef();
  //   return acc;
  // }, {});

  const {
    product_submenu_list,
    selected_product,
    products_list,
    product_option_crust,
    show_submenu_loader,
    global_submenu_list,
  } = useSelector((state) => state.productReduser);
  const { selected_category, promotion_list } = useSelector(
    (state) => state.categoriesRedusers
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { shop_id, login_key, shop_info } = useSelector(
    (state) => state.shopSettingsReduser
  );
  const {
    basket_content,
    basket_item_id,
    basket_subitem_id,
    sub_total,
    basket_product_id_list,
    total_discount,
    sale_method,
    sub_total_without_mealdeal,
    discount_with_mealdeals,
    discount_without_mealdeals,
  } = useSelector((state) => state.basketReduser);

  const [amount1, setAmount] = useState(1);
  const [clicledIcon1, setClickedIcons1] = useState(false);
  const [clicledIcon2, setClickedIcons2] = useState(false);
  const [optionId, setOptionId] = useState(0);
  const [crustId, setCrustId] = useState(0);
  const [selectedCheckbox, setSelectedCheckbox] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [finalBasketPrice, setFinalBasketPrice] = useState(0);
  const [defaultBasketPrice, setDefaultBasketPrice] = useState(0);
  const [basketContent, setBasketContent] = useState(null);
  const [optionAmount, setOptionAmount] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [crustList, setCrustList] = useState([]);
  const [optionTitle, setOptionTitle] = useState("");
  const [crustTitle, setCrustTitle] = useState("");
  const [crustAmount, setCrustAmount] = useState(0);
  const [productNumber, setProductNumber] = useState(1);
  // BELOW STATE IS PRODUCT SUB MENU LIST
  const [sblist, setSblist] = useState([]);

  // BELOW STATE IS SELECTED SUB ITESM LIST
  const [subList, setSubList] = useState([]);
  const [totalVat, setTotalVat] = useState(0);
  const [optionVat, setOptionVat] = useState(0);
  const [activeAddToBasket, setActiveAddToBasket] = useState(true);
  const [disableAdd, setDisableAdd] = useState(false);
  const [checkedState, setCheckedState] = useState(
    new Array(product_submenu_list.length).fill(false)
  );

  const handleCrustClicked = (item) => {
    let temp_crusts = [...crustList];
    let final_crusts = [];
    let final_price = 0;
    let subtract_price = 0;
    for (let i = 0; i < temp_crusts.length; i++) {
      if (item.Id == temp_crusts[i].Id) {
        if (item.checked) {
          final_crusts.splice(i, 0, { ...temp_crusts[i], checked: false });
          setCrustId(0);
          setCrustTitle("");
          setCrustAmount(0);
          final_price = finalBasketPrice - productNumber * item.Price;
        } else {
          final_crusts.splice(i, 0, { ...temp_crusts[i], checked: true });

          setCrustId(item.Id);
          setCrustTitle(item.WebTitle);
          setCrustAmount(item.Price);
          final_price = finalBasketPrice + productNumber * item.Price;
        }
      } else {
        if (temp_crusts[i].checked) {
          subtract_price = productNumber * temp_crusts[i].Price;
        }
        final_crusts.splice(i, 0, { ...temp_crusts[i], checked: false });
      }
    }
    setCrustList(final_crusts);
    setFinalBasketPrice(final_price - subtract_price);
  };

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };
  const handleIncreaseProductNumber = () => {
    if (!show_submenu_loader) {
      setFinalBasketPrice(finalBasketPrice + finalBasketPrice / productNumber);
      setTotalVat(totalVat + totalVat / productNumber);
      setProductNumber(productNumber + 1);
    }
  };

  const handleDecreaseProductNumber = () => {
    if (productNumber > 1 && !show_submenu_loader) {
      setFinalBasketPrice(finalBasketPrice - finalBasketPrice / productNumber);
      setTotalVat(totalVat - totalVat / productNumber);
      setProductNumber(productNumber - 1);
    }
  };
  const handleCloseIcon = () => {
    dispatch(showProductSubmenuPopupAction(false));
  };
  const handleCheckedCheckbox = (i, item, index1, ind) => {
    // SUBMENU LIST
    let tempSbList = [...sblist];
    let tempSubList = [...subList];

    if (item.MinSelect == 0) {
      if (item.MaxSelect == 0) {
        let allSubItems = item.Items;
        let finalSubItem;
        if (i.checked) {
          finalSubItem = {
            ...i,
            disable: false,
            number: 0,
            showNumberSelector: false,
            checked: false,
          };
          tempSbList[index1].subNumber =
            tempSbList[index1].subNumber - i.number;
          let subFinalList = tempSubList.filter((e) => e.Id != i.Id);

          let finalPrice =
            finalBasketPrice - i.number * i.AmountMain * basketContent.number;
          let finalVat =
            totalVat - i.number * i.vat_item * basketContent.number;
          setTotalVat(finalVat);
          setSubList(subFinalList);
          setFinalBasketPrice(finalPrice);
        } else {
          finalSubItem = {
            ...i,
            disable: false,
            number: 1,
            showNumberSelector: item.OneOfEachItem ? false : true,
            checked: true,
          };
          tempSbList[index1].subNumber = tempSbList[index1].subNumber + 1;
          subList.push(finalSubItem);

          let finalVat = totalVat + i.vat_item * basketContent.number;
          setTotalVat(finalVat);
          setFinalBasketPrice(
            finalBasketPrice + i.AmountMain * basketContent.number
          );
        }

        allSubItems[ind] = finalSubItem;
        tempSbList[index1].Items = allSubItems;

        setSblist(tempSbList);
      } else {
        let allSubItems = item.Items;
        let finalSubItem;
        if (i.checked) {
          finalSubItem = {
            ...i,
            disable: false,
            number: 0,
            showNumberSelector: false,
            checked: false,
          };
          tempSbList[index1].subNumber =
            tempSbList[index1].subNumber - i.number;
          allSubItems[ind] = finalSubItem;
          tempSbList[index1].Items = allSubItems;
          let subFinalList = tempSubList.filter((e) => e.Id != i.Id);
          let finalPrice =
            finalBasketPrice - i.number * i.AmountMain * basketContent.number;
          let finalVat =
            totalVat - i.number * i.vat_item * basketContent.number;
          setTotalVat(finalVat);
          setSubList(subFinalList);
          setFinalBasketPrice(finalPrice);

          setSblist(tempSbList);
        } else {
          if (item.subNumber < item.MaxSelect) {
            finalSubItem = {
              ...i,
              disable: false,
              number: 1,
              showNumberSelector: item.OneOfEachItem ? false : true,
              checked: true,
            };
            tempSbList[index1].subNumber = tempSbList[index1].subNumber + 1;
            subList.push(finalSubItem);
            let finalVat = totalVat + i.vat_item * basketContent.number;
            setTotalVat(finalVat);
            setFinalBasketPrice(
              finalBasketPrice + i.AmountMain * basketContent.number
            );

            allSubItems[ind] = finalSubItem;
            tempSbList[index1].Items = allSubItems;
          }

          setSblist(tempSbList);
        }
      }
    } else {
      if (item.subNumber <= item.MinSelect) {
        if (item.subNumber + 1 == item.MinSelect) {
          // MODIFY CURRENT ITEM
          let allSubItems = item.Items;
          let finalSubItem;
          if (i.checked) {
            finalSubItem = {
              ...i,
              disable: false,
              number: 0,
              showNumberSelector: false,
              checked: false,
            };
            allSubItems[ind] = finalSubItem;
            tempSbList[index1].Items = allSubItems;
            tempSbList[index1].subNumber =
              tempSbList[index1].subNumber - i.number;
            let subFinalList = tempSubList.filter((e) => e.Id != i.Id);
            let finalPrice =
              finalBasketPrice - i.number * i.AmountMain * basketContent.number;
            let finalVat =
              totalVat - i.number * i.vat_item * basketContent.number;
            setTotalVat(finalVat);
            setSubList(subFinalList);
            setFinalBasketPrice(finalPrice);
            setSblist(tempSbList);
          } else {
            finalSubItem = {
              ...i,
              disable: false,
              number: 1,
              showNumberSelector: item.OneOfEachItem ? false : true,
              checked: true,
            };
            allSubItems[ind] = finalSubItem;

            // MODIFY CURRENT SUBMENU ITEMS
            tempSbList[index1].Items = allSubItems;
            tempSbList[index1].subNumber = tempSbList[index1].subNumber + 1;
            subList.push(finalSubItem);
            let finalVat = totalVat + i.vat_item * basketContent.number;
            setTotalVat(finalVat);
            setFinalBasketPrice(
              finalBasketPrice + i.AmountMain * basketContent.number
            );

            // MOFIFY NEXT ITEMS
            for (let i = 0; i < tempSbList.length; i++) {
              if (i > index1) {
                let tempItemListHolder = [];
                if (tempSbList[i].MinSelect > 0) {
                  elRefs[i].current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                  let tempItemsList = tempSbList[i].Items;
                  for (let j = 0; j < tempItemsList.length; j++) {
                    tempItemListHolder.push({
                      ...tempItemsList[j],
                      checked: false,
                      number: 0,
                      showNumberSelector: false,
                      disable: false,
                    });
                  }
                  // MODIFY NEXT SUBMENU ITEMS
                  tempSbList[i].Items = tempItemListHolder;

                  // RETURN WHEN WE FIND FIRST MINSELECT THAT MORE THAN ZERO
                  break;
                } else {
                  let tempItemsList = tempSbList[i].Items;
                  for (let j = 0; j < tempItemsList.length; j++) {
                    tempItemListHolder.push({
                      ...tempItemsList[j],
                      checked: false,
                      number: 0,
                      showNumberSelector: false,
                      disable: false,
                    });
                  }
                  // MODIFY NEXT SUBMENU ITEMS
                  tempSbList[i].Items = tempItemListHolder;
                }
              }
            }

            setSblist(tempSbList);
          }
        } else if (item.subNumber == item.MinSelect) {
          let allSubItems = item.Items;
          let finalSubItem;
          if (i.checked) {
            finalSubItem = {
              ...i,
              disable: false,
              number: 0,
              showNumberSelector: false,
              checked: false,
            };
            allSubItems[ind] = finalSubItem;
            tempSbList[index1].Items = allSubItems;
            tempSbList[index1].subNumber =
              tempSbList[index1].subNumber - i.number;

            let sumOfSubtract = i.number * i.AmountMain;
            let sumOfSubtractVat = i.number * i.vat_value;
            tempSubList = tempSubList.filter((e) => e.Id != i.Id);
            for (let i = 0; i < tempSbList.length; i++) {
              if (i > index1) {
                let tempItemListHolder = [];

                let tempItemsList = tempSbList[i].Items;
                for (let j = 0; j < tempItemsList.length; j++) {
                  if (tempItemsList[j].number) {
                    tempSubList = tempSubList.filter(
                      (e) => e.Id != tempItemsList[j].Id
                    );
                    sumOfSubtract =
                      sumOfSubtract +
                      tempItemsList[j].number * tempItemsList[j].AmountMain;
                    sumOfSubtractVat =
                      sumOfSubtractVat +
                      tempItemsList[j].number * tempItemsList[j].vat_value;
                  }
                  tempItemListHolder.push({
                    ...tempItemsList[j],
                    checked: false,
                    number: 0,
                    showNumberSelector: false,
                    disable: true,
                  });
                }

                tempSbList[i].Items = tempItemListHolder;
                tempSbList[i].subNumber = 0;
              }
            }
            setSubList(tempSubList);
            let finalVat = totalVat - sumOfSubtractVat * basketContent.number;
            setTotalVat(finalVat);
            setFinalBasketPrice(
              finalBasketPrice - sumOfSubtract * basketContent.number
            );

            setSblist(tempSbList);
          } else {
            if (item.subNumber < item.MaxSelect || item.MaxSelect == 0) {
              finalSubItem = {
                ...i,
                disable: false,
                number: 1,
                showNumberSelector: item.OneOfEachItem ? false : true,
                checked: true,
              };
              allSubItems[ind] = finalSubItem;
              tempSbList[index1].Items = allSubItems;
              tempSbList[index1].subNumber = tempSbList[index1].subNumber + 1;

              subList.push(finalSubItem);
              let finalVat = totalVat + i.vat_item * basketContent.number;
              setTotalVat(finalVat);
              setFinalBasketPrice(
                finalBasketPrice + i.AmountMain * basketContent.number
              );

              setSblist(tempSbList);
            }
          }
        } else {
          let allSubItems = item.Items;
          let finalSubItem;

          if (i.checked) {
            finalSubItem = {
              ...i,
              disable: false,
              number: 0,
              showNumberSelector: false,
              checked: false,
            };
            tempSbList[index1].subNumber =
              tempSbList[index1].subNumber - i.number;

            let subFinalList = tempSubList.filter((e) => e.Id != i.Id);
            let finalPrice =
              finalBasketPrice - i.number * i.AmountMain * basketContent.number;
            setSubList(subFinalList);
            let finalVat =
              totalVat - i.number * i.vat_item * basketContent.number;
            setTotalVat(finalVat);
            setFinalBasketPrice(finalPrice);
          } else {
            finalSubItem = {
              ...i,
              disable: false,
              number: 1,
              showNumberSelector: item.OneOfEachItem ? false : true,
              checked: true,
            };
            tempSbList[index1].subNumber = tempSbList[index1].subNumber + 1;
            subList.push(finalSubItem);
            let finalVat = totalVat + i.vat_item * basketContent.number;
            setTotalVat(finalVat);
            setFinalBasketPrice(
              finalBasketPrice + i.AmountMain * basketContent.number
            );
          }

          allSubItems[ind] = finalSubItem;
          tempSbList[index1].Items = allSubItems;
          setSblist(tempSbList);
        }
      } else {
        if (item.MaxSelect == 0) {
          let allSubItems = item.Items;
          let finalSubItem;
          if (i.checked) {
            finalSubItem = {
              ...i,
              disable: false,
              number: 0,
              showNumberSelector: false,
              checked: false,
            };
            tempSbList[index1].subNumber = item.subNumber - i.number;
            allSubItems[ind] = finalSubItem;
            tempSbList[index1].Items = allSubItems;

            let sumOfSubtract = i.number * i.AmountMain;
            let sumOfSubtractVat = i.number * i.vat_value;
            tempSubList = tempSubList.filter((e) => e.Id != i.Id);

            if (tempSbList[index1].subNumber < item.MinSelect) {
              for (let i = 0; i < tempSbList.length; i++) {
                if (i > index1) {
                  let tempItemListHolder = [];

                  let tempItemsList = tempSbList[i].Items;
                  for (let j = 0; j < tempItemsList.length; j++) {
                    if (tempItemsList[j].number) {
                      tempSubList = tempSubList.filter(
                        (e) => e.Id != tempItemsList[j].Id
                      );
                      sumOfSubtract =
                        sumOfSubtract +
                        tempItemsList[j].number * tempItemsList[j].AmountMain;
                      sumOfSubtractVat =
                        sumOfSubtractVat +
                        tempItemsList[j].number * tempItemsList[j].vat_value;
                    }
                    tempItemListHolder.push({
                      ...tempItemsList[j],
                      checked: false,
                      number: 0,
                      showNumberSelector: false,
                      disable: true,
                    });
                  }

                  tempSbList[i].Items = tempItemListHolder;
                  tempSbList[i].subNumber = 0;
                }
              }
            }
            setSubList(tempSubList);
            let finalVat = totalVat - sumOfSubtractVat * basketContent.number;
            setTotalVat(finalVat);
            setFinalBasketPrice(
              finalBasketPrice - sumOfSubtract * basketContent.number
            );
            setSblist(tempSbList);
          } else {
            finalSubItem = {
              ...i,
              disable: false,
              number: +1,
              showNumberSelector: item.OneOfEachItem ? false : true,
              checked: true,
            };
            tempSbList[index1].subNumber = tempSbList[index1].subNumber + 1;
            subList.push(finalSubItem);
            let finalVat = totalVat + i.vat_item * basketContent.number;
            setTotalVat(finalVat);
            setFinalBasketPrice(
              finalBasketPrice + i.AmountMain * basketContent.number
            );
          }
          allSubItems[ind] = finalSubItem;
          tempSbList[index1].Items = allSubItems;
          setSblist(tempSbList);
        } else if (item.subNumber < item.MaxSelect) {
          let allSubItems = item.Items;
          let finalSubItem;
          if (i.checked) {
            finalSubItem = {
              ...i,
              disable: false,
              number: 0,
              showNumberSelector: false,
              checked: false,
            };
            tempSbList[index1].subNumber = item.subNumber - i.number;
            allSubItems[ind] = finalSubItem;
            tempSbList[index1].Items = allSubItems;

            let sumOfSubtract = i.number * i.AmountMain;
            let sumOfSubtractVat = i.number * i.AmountMain;
            tempSubList = tempSubList.filter((e) => e.Id != i.Id);

            if (tempSbList[index1].subNumber < item.MinSelect) {
              for (let i = 0; i < tempSbList.length; i++) {
                if (i > index1) {
                  let tempItemListHolder = [];

                  let tempItemsList = tempSbList[i].Items;
                  for (let j = 0; j < tempItemsList.length; j++) {
                    if (tempItemsList[j].number) {
                      tempSubList = tempSubList.filter(
                        (e) => e.Id != tempItemsList[j].Id
                      );
                      sumOfSubtract =
                        sumOfSubtract +
                        tempItemsList[j].number * tempItemsList[j].AmountMain;
                      sumOfSubtractVat =
                        sumOfSubtractVat +
                        tempItemsList[j].number * tempItemsList[j].vat_value;
                    }

                    tempItemListHolder.push({
                      ...tempItemsList[j],
                      checked: false,
                      number: 0,
                      showNumberSelector: false,
                      disable: true,
                    });
                  }

                  tempSbList[i].Items = tempItemListHolder;
                  tempSbList[i].subNumber = 0;
                }
              }
            }
            setSubList(tempSubList);
            let finalVat = totalVat - sumOfSubtractVat * basketContent.number;
            setTotalVat(finalVat);
            setFinalBasketPrice(
              finalBasketPrice - sumOfSubtract * basketContent.number
            );
            setSblist(tempSbList);
          } else {
            finalSubItem = {
              ...i,
              disable: false,
              number: i.number + 1,
              showNumberSelector: item.OneOfEachItem ? false : true,
              checked: true,
            };
            tempSbList[index1].subNumber = tempSbList[index1].subNumber + 1;
            subList.push(finalSubItem);
            let finalVat = totalVat + i.vat_item * basketContent.number;
            setTotalVat(finalVat);
            setFinalBasketPrice(
              finalBasketPrice + i.AmountMain * basketContent.number
            );
          }
          allSubItems[ind] = finalSubItem;
          tempSbList[index1].Items = allSubItems;
          setSblist(tempSbList);
        } else {
          let allSubItems = item.Items;
          let finalSubItem;

          finalSubItem = {
            ...i,
            disable: false,
            number: 0,
            showNumberSelector: false,
            checked: false,
          };
          tempSbList[index1].subNumber = item.subNumber - i.number;

          allSubItems[ind] = finalSubItem;
          tempSbList[index1].Items = allSubItems;

          let sumOfSubtract = i.number * i.AmountMain;
          let sumOfSubtractVat = i.number * i.vat_value;
          tempSubList = tempSubList.filter((e) => e.Id != i.Id);

          if (tempSbList[index1].subNumber < item.MinSelect) {
            for (let i = 0; i < tempSbList.length; i++) {
              if (i > index1) {
                let tempItemListHolder = [];

                let tempItemsList = tempSbList[i].Items;
                for (let j = 0; j < tempItemsList.length; j++) {
                  if (tempItemsList[j].number) {
                    tempSubList = tempSubList.filter(
                      (e) => e.Id != tempItemsList[j].Id
                    );
                    sumOfSubtract =
                      sumOfSubtract +
                      tempItemsList[j].number * tempItemsList[j].AmountMain;
                    sumOfSubtractVat =
                      sumOfSubtractVat +
                      tempItemsList[j].number * tempItemsList[j].vat_value;
                  }

                  tempItemListHolder.push({
                    ...tempItemsList[j],
                    checked: false,
                    number: 0,
                    showNumberSelector: false,
                    disable: true,
                  });
                }

                tempSbList[i].Items = tempItemListHolder;
                tempSbList[i].subNumber = 0;
              }
            }
          }
          setSubList(tempSubList);
          let finalVat = totalVat - sumOfSubtractVat * basketContent.number;
          setTotalVat(finalVat);
          setFinalBasketPrice(
            finalBasketPrice - sumOfSubtract * basketContent.number
          );
          setSblist(tempSbList);
        }
      }
    }

    // let newProductSubmenuList = [...product_submenu_list];
    // let itemsLength = [];
    // newProductSubmenuList.forEach((element, myindex) => {
    //   if (element.Items.length > 0) {
    //     if (item.Id == element.Id) {
    //       element.Items.forEach((el, ind) => {
    //         if (el.Id == i.Id) {
    //           if (!el.checked) {
    //             el.checked = true;
    //             el.showNumberSelector = true;
    //             el.number = 1;
    //           } else {
    //             el.checked = false;
    //             el.showNumberSelector = false;
    //             el.number = 0;
    //           }
    //         }
    //       });
    //     }
    //   }
    // });
    // let sum = 0;
    // newProductSubmenuList.forEach((element, myindex) => {
    //   if (element.Items.length > 0) {
    //     if (item.Id == element.Id) {
    //       element.Items.forEach((el, ind) => {
    //         sum = sum + el.number;
    //       });
    //     }
    //   }
    // });
    // item.number = sum;
    // if (item.MaxSelect != 0) {
    //   if (sum == item.MaxSelect) {
    //     newProductSubmenuList.forEach((element, myindex) => {
    //       if (element.Items.length > 0) {
    //         if (item.Id == element.Id) {
    //           element.Items.forEach((el, ind) => {
    //             if (!el.checked) {
    //               el.disable = true;
    //             } else {
    //               el.plusIconStyles = false;
    //             }
    //           });
    //         }
    //       }
    //     });
    //   } else if (sum < item.MaxSelect) {
    //     newProductSubmenuList.forEach((element, myindex) => {
    //       if (element.Items.length > 0) {
    //         if (item.Id == element.Id) {
    //           element.Items.forEach((el, ind) => {
    //             if (!el.checked) {
    //               el.disable = false;
    //             } else {
    //               el.plusIconStyles = true;
    //             }
    //           });
    //         }
    //       }
    //     });
    //   }
    // } else {
    //   newProductSubmenuList.forEach((element, myindex) => {
    //     if (element.Items.length > 0) {
    //       if (item.Id == element.Id) {
    //         element.Items.forEach((el, ind) => {
    //           if (!el.checked) {
    //             el.disable = false;
    //           } else {
    //             el.plusIconStyles = true;
    //           }
    //         });
    //       }
    //     }
    //   });
    // }
    // let last_item = newProductSubmenuList.length - 1;
    // if (item.MinSelect > sum) {
    //   newProductSubmenuList.forEach((element, myindex) => {
    //     if (index1 == myindex) {
    //       if (myindex == 0) {
    //         let filltered = newProductSubmenuList.filter(
    //           (element, index1) => index1 !== 0
    //         );
    //         filltered.forEach((element) => {
    //           element.disable = true;
    //           element.Items.forEach((el) => {
    //             el.disable = true;
    //             el.checked = false;
    //             el.showNumberSelector = false;
    //             el.number = 0;
    //           });
    //         });
    //       } else if (myindex > 0) {
    //         element.disable = false;
    //         element.Items.forEach((el) => {
    //           el.disable = false;
    //         });
    //         if (myindex < last_item) {
    //           let filltered = newProductSubmenuList.filter(
    //             (element, index11) => index11 > myindex
    //           );
    //           filltered.forEach((element) => {
    //             element.disable = true;
    //             element.Items.forEach((el) => {
    //               el.disable = true;
    //               el.checked = false;
    //               el.showNumberSelector = false;
    //               el.number = 0;
    //             });
    //           });
    //         }
    //       }
    //     }
    //   });
    // } else if (item.MinSelect == sum) {
    //   newProductSubmenuList.forEach((element, myindex) => {
    //     if (index1 == myindex) {
    //       if (myindex == 0) {
    //         const found = newProductSubmenuList.find(
    //           (element, index1) => index1 > myindex
    //         );
    //         found.disable = false;
    //         found.Items.forEach((el, ind) => {
    //           el.disable = false;
    //           el.checked = false;
    //           el.showNumberSelector = false;
    //           el.number = 0;
    //         });
    //         for (let i = 0; i < newProductSubmenuList.length; i++) {
    //           if (i > myindex) {
    //             if (newProductSubmenuList[i].MinSelect == 0) {
    //               if (
    //                 (newProductSubmenuList[i - 1].MinSelect == 0 &&
    //                   newProductSubmenuList[i - 1].disable == false) ||
    //                 newProductSubmenuList[i - 1] ==
    //                   newProductSubmenuList[myindex]
    //               ) {
    //                 const found = newProductSubmenuList.find(
    //                   (element, index1) => index1 > i
    //                 );
    //                 found.disable = false;
    //                 found.Items.forEach((el, ind) => {
    //                   el.disable = false;
    //                   el.checked = false;
    //                   el.showNumberSelector = false;
    //                   el.number = 0;
    //                 });
    //               }
    //             }
    //           }
    //         }
    //       } else if (myindex > 0) {
    //         element.disable = false;
    //         element.Items.forEach((el) => {
    //           el.disable = false;
    //         });
    //         if (myindex < last_item) {
    //           const found = newProductSubmenuList.find(
    //             (element, index1) => index1 > myindex
    //           );
    //           found.disable = false;
    //           found.Items.forEach((el, ind) => {
    //             el.disable = false;
    //             el.checked = false;
    //             el.showNumberSelector = false;
    //             el.number = 0;
    //           });
    //           for (let i = 0; i < newProductSubmenuList.length; i++) {
    //             if (i > myindex) {
    //               if (newProductSubmenuList[i].MinSelect == 0) {
    //                 if (
    //                   (newProductSubmenuList[i - 1].MinSelect == 0 &&
    //                     newProductSubmenuList[i - 1].disable == false) ||
    //                   newProductSubmenuList[i - 1] ==
    //                     newProductSubmenuList[myindex]
    //                 ) {
    //                   if (i < last_item) {
    //                     const found = newProductSubmenuList.find(
    //                       (element, index1) => index1 > i
    //                     );
    //                     found.disable = false;
    //                     found.Items.forEach((el, ind) => {
    //                       el.disable = false;
    //                     });
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   });
    // }
    // item.subNumber = sum;
    // setSblist(newProductSubmenuList);**
  };
  const handleDecreaseNumberOfSubmenuItems = (item, i, ind, index1) => {
    let tempSbList = [...sblist];
    let tempSubList = [...subList];

    if (item.MinSelect == 0) {
      if (item.subNumber - 1 == 0) {
        let allSubItems = item.Items;
        let finalSubItem;
        finalSubItem = {
          ...i,
          number: 0,
          showNumberSelector: false,
          checked: false,
        };
        tempSbList[index1].subNumber = tempSbList[index1].subNumber - 1;
        allSubItems[ind] = finalSubItem;
        tempSbList[index1].Items = allSubItems;

        tempSubList = tempSubList.filter((e) => e.Id != i.Id);
        setFinalBasketPrice(
          finalBasketPrice - i.AmountMain * basketContent.number
        );
        setTotalVat(totalVat - i.vat_item * basketContent.number);
        setSubList(tempSubList);
        setSblist(tempSbList);
      } else {
        let allSubItems = item.Items;
        let finalSubItem;
        finalSubItem = {
          ...i,
          number: i.number - 1,
          showNumberSelector: i.number - 1 ? true : false,
          checked: i.number - 1 ? true : false,
        };
        tempSbList[index1].subNumber = tempSbList[index1].subNumber - 1;
        allSubItems[ind] = finalSubItem;
        tempSbList[index1].Items = allSubItems;

        for (let j = 0; j < tempSubList.length; j++) {
          if (tempSubList[j].Id == i.Id) {
            tempSubList[j].number = i.number - 1;
          }
        }

        setFinalBasketPrice(
          finalBasketPrice - i.AmountMain * basketContent.number
        );
        setTotalVat(totalVat - i.vat_item * basketContent.number);
        setSubList(tempSubList);
        setSblist(tempSbList);
      }
    } else if (item.subNumber - 1 < item.MinSelect) {
      if (item.subNumber - 1 == 0) {
        let allSubItems = item.Items;
        let finalSubItem;
        finalSubItem = {
          ...i,
          number: 0,
          showNumberSelector: false,
          checked: false,
        };
        tempSbList[index1].subNumber = tempSbList[index1].subNumber - 1;
        allSubItems[ind] = finalSubItem;
        tempSbList[index1].Items = allSubItems;

        tempSubList = tempSubList.filter((e) => e.Id != i.Id);
        let sumOfSubtract = i.AmountMain;
        let sumOfSubtractVat = i.vat_item;

        for (let i = 0; i < tempSbList.length; i++) {
          if (i > index1) {
            let tempItemListHolder = [];

            let tempItemsList = tempSbList[i].Items;
            for (let j = 0; j < tempItemsList.length; j++) {
              if (tempItemsList[j].number) {
                tempSubList = tempSubList.filter(
                  (e) => e.Id != tempItemsList[j].Id
                );
                sumOfSubtract =
                  sumOfSubtract +
                  tempItemsList[j].number * tempItemsList[j].AmountMain;
                sumOfSubtractVat =
                  sumOfSubtractVat +
                  tempItemsList[j].number * tempItemsList[j].vat_item;
              }

              tempItemListHolder.push({
                ...tempItemsList[j],
                checked: false,
                number: 0,
                showNumberSelector: false,
                disable: true,
              });
            }
            tempSbList[i].Items = tempItemListHolder;
            tempSbList[i].subNumber = 0;
          }
        }

        setSubList(tempSubList);
        setFinalBasketPrice(
          finalBasketPrice - sumOfSubtract * basketContent.number
        );
        setTotalVat(totalVat - sumOfSubtractVat * basketContent.number);
        setSblist(tempSbList);
      } else {
        let allSubItems = item.Items;
        let finalSubItem;
        finalSubItem = {
          ...i,
          number: i.number - 1,
        };
        tempSbList[index1].subNumber = tempSbList[index1].subNumber - 1;
        allSubItems[ind] = finalSubItem;
        tempSbList[index1].Items = allSubItems;

        for (let j = 0; j < tempSubList.length; j++) {
          if (tempSubList[j].Id == i.Id) {
            tempSubList[j].number = i.number - 1;
          }
        }

        let sumOfSubtract = i.AmountMain;
        let sumOfSubtractVat = i.vat_item;

        for (let i = 0; i < tempSbList.length; i++) {
          if (i > index1) {
            let tempItemListHolder = [];

            let tempItemsList = tempSbList[i].Items;
            for (let j = 0; j < tempItemsList.length; j++) {
              if (tempItemsList[j].number) {
                tempSubList = tempSubList.filter(
                  (e) => e.Id != tempItemsList[j].Id
                );
                sumOfSubtract =
                  sumOfSubtract +
                  tempItemsList[j].number * tempItemsList[j].AmountMain;
                sumOfSubtractVat =
                  sumOfSubtractVat +
                  tempItemsList[j].number * tempItemsList[j].vat_item;
              }

              tempItemListHolder.push({
                ...tempItemsList[j],
                checked: false,
                number: 0,
                showNumberSelector: false,
                disable: true,
              });
            }
            tempSbList[i].Items = tempItemListHolder;
            tempSbList[i].subNumber = 0;
          }
        }

        setSubList(tempSubList);
        setFinalBasketPrice(
          finalBasketPrice - sumOfSubtract * basketContent.number
        );
        setTotalVat(totalVat - sumOfSubtractVat * basketContent.number);
        setSblist(tempSbList);
      }
    } else {
      // if (item.subNumber - 1 == 0) {
      //   let allSubItems = item.Items;
      //   let finalSubItem;
      //   finalSubItem = {
      //     ...i,
      //     number: 0,
      //     showNumberSelector: false,
      //     checked: false,
      //   };
      //   tempSbList[index1].subNumber = tempSbList[index1].subNumber - 1;
      //   allSubItems[ind] = finalSubItem;
      //   tempSbList[index1].Items = allSubItems;

      //   setSblist(tempSbList);**
      // }
      let allSubItems = item.Items;
      let finalSubItem;
      finalSubItem = {
        ...i,
        number: i.number - 1,
      };
      tempSbList[index1].subNumber = tempSbList[index1].subNumber - 1;
      allSubItems[ind] = finalSubItem;
      tempSbList[index1].Items = allSubItems;

      for (let j = 0; j < tempSubList.length; j++) {
        if (tempSubList[j].Id == i.Id) {
          tempSubList[j].number = i.number - 1;
        }
      }

      setFinalBasketPrice(
        finalBasketPrice - i.AmountMain * basketContent.number
      );
      setTotalVat(totalVat - i.vat_item * basketContent.number);
      setSubList(tempSubList);

      setSblist(tempSbList);
    }
    // let sum = 0;
    // newProductSubmenuList.forEach((element, myindex) => {
    //   if (item.Id == element.Id) {
    //     element.Items.forEach((el, itemIndex) => {
    //       if (i.Id == el.Id) {
    //         if (el.number > 1) {
    //           el.minus = true;
    //           el.plus = false;
    //           el.number = el.number - 1;
    //         } else if (el.number == 1) {
    //           el.checked = false;
    //           el.number = el.number - 1;
    //           el.showNumberSelector = false;
    //         }
    //       } else {
    //         el.minus = false;
    //         el.plus = false;
    //       }

    //       sum = sum + el.number;
    //       item.subNumber = sum;
    //     });
    //   } else {
    //     element.Items.forEach((el, itemIndex) => {
    //       el.minus = false;
    //       el.plus = false;
    //     });
    //   }
    // });
    // if (item.MaxSelect != 0) {
    //   if (item.subNumber < item.MaxSelect) {
    //     newProductSubmenuList.forEach((element, myindex) => {
    //       if (item.Id == element.Id) {
    //         element.Items.forEach((el, itemIndex) => {
    //           if (!el.checked) {
    //             el.disable = false;
    //           } else {
    //             el.plusIconStyles = true;
    //           }
    //         });
    //       }
    //     });
    //   }
    // } else {
    //   newProductSubmenuList.forEach((element, myindex) => {
    //     if (element.Items.length > 0) {
    //       if (item.Id == element.Id) {
    //         element.Items.forEach((el, ind) => {
    //           if (!el.checked) {
    //             el.disable = false;
    //           } else {
    //             el.plusIconStyles = true;
    //           }
    //         });
    //       }
    //     }
    //   });
    // }
    // let last_item = newProductSubmenuList.length - 1;
    // if (item.MinSelect > sum) {
    //   newProductSubmenuList.forEach((element, myindex) => {
    //     if (index1 == myindex) {
    //       if (myindex == 0) {
    //         let filltered = newProductSubmenuList.filter(
    //           (element, index1) => index1 !== 0
    //         );
    //         filltered.forEach((element) => {
    //           element.disable = true;
    //           element.Items.forEach((el) => {
    //             el.disable = true;
    //             el.checked = false;
    //             el.showNumberSelector = false;
    //             el.number = 0;
    //           });
    //         });
    //       } else if (myindex > 0) {
    //         element.disable = false;
    //         element.Items.forEach((el) => {
    //           el.disable = false;
    //         });
    //         if (myindex < last_item) {
    //           let filltered = newProductSubmenuList.filter(
    //             (element, index11) => index11 > myindex
    //           );
    //           filltered.forEach((element) => {
    //             element.disable = true;
    //             element.Items.forEach((el) => {
    //               el.disable = true;
    //               el.checked = false;
    //               el.showNumberSelector = false;
    //               el.number = 0;
    //             });
    //           });
    //         }
    //       }
    //     }
    //   });
    // }
    // setSblist(newProductSubmenuList);**
  };
  const handleIncecreaseNumberOfSubmenuItems = (item, i, ind, index1) => {
    let tempSbList = [...sblist];
    let tempSubList = [...subList];

    if (item.subNumber < item.MaxSelect || item.MaxSelect == 0) {
      if (item.subNumber + 1 == item.MinSelect) {
        let allSubItems = item.Items;
        let finalSubItem;
        finalSubItem = {
          ...i,
          number: i.number + 1,
        };
        tempSbList[index1].subNumber = item.subNumber + 1;
        allSubItems[ind] = finalSubItem;
        tempSbList[index1].Items = allSubItems;

        for (let i = 0; i < tempSbList.length; i++) {
          if (i > index1) {
            let tempItemListHolder = [];
            if (tempSbList[i].MinSelect > 0) {
              elRefs[i].current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
              let tempItemsList = tempSbList[i].Items;
              for (let j = 0; j < tempItemsList.length; j++) {
                tempItemListHolder.push({
                  ...tempItemsList[j],
                  checked: false,
                  number: 0,
                  showNumberSelector: false,
                  disable: false,
                });
              }

              tempSbList[i].Items = tempItemListHolder;
              break;
            } else {
              let tempItemsList = tempSbList[i].Items;
              for (let j = 0; j < tempItemsList.length; j++) {
                tempItemListHolder.push({
                  ...tempItemsList[j],
                  checked: false,
                  number: 0,
                  showNumberSelector: false,
                  disable: false,
                });
              }
              tempSbList[i].Items = tempItemListHolder;
            }
          }
        }
      } else {
        let allSubItems = item.Items;
        let finalSubItem;
        finalSubItem = {
          ...i,
          number: i.number + 1,
        };
        tempSbList[index1].subNumber = tempSbList[index1].subNumber + 1;
        allSubItems[ind] = finalSubItem;
        tempSbList[index1].Items = allSubItems;
      }
      for (let j = 0; j < tempSubList.length; j++) {
        if (tempSubList[j].Id == i.Id) {
          tempSubList[j].number = i.number + 1;
        }
      }

      setFinalBasketPrice(
        finalBasketPrice + i.AmountMain * basketContent.number
      );
      setTotalVat(totalVat + i.vat_item * basketContent.number);
      setSubList(tempSubList);
      setSblist(tempSbList);
    }
    // if (i.plusIconStyles) {
    //   let sum = 0;
    //   newProductSubmenuList.forEach((element, myindex) => {
    //     if (item.Id == element.Id) {
    //       element.Items.forEach((el, itemIndex) => {
    //         if (i.Id == el.Id) {
    //           el.number = el.number + 1;
    //           el.minus = false;
    //           el.plus = true;
    //         } else {
    //           el.plus = false;
    //           el.minus = false;
    //         }

    //         sum = sum + el.number;
    //         item.subNumber = sum;
    //       });
    //     } else {
    //       element.Items.forEach((el, itemIndex) => {
    //         el.minus = false;
    //         el.plus = false;
    //       });
    //     }
    //   });
    //   if (item.MaxSelect != 0) {
    //     if (item.subNumber == item.MaxSelect) {
    //       newProductSubmenuList.forEach((element, myindex) => {
    //         if (item.Id == element.Id) {
    //           element.Items.forEach((el, itemIndex) => {
    //             if (!el.checked) {
    //               el.disable = true;
    //               el.plusIconStyles = false;
    //             } else {
    //               el.plusIconStyles = false;
    //             }
    //           });
    //         }
    //       });
    //     } else if (item.subNumber < item.MaxSelect) {
    //       newProductSubmenuList.forEach((element, myindex) => {
    //         if (item.Id == element.Id) {
    //           element.Items.forEach((el, itemIndex) => {
    //             if (!el.checked) {
    //               el.disable = false;
    //               el.plusIconStyles = true;
    //             } else {
    //               el.plusIconStyles = true;
    //             }
    //           });
    //         }
    //       });
    //     }
    //   } else {
    //     newProductSubmenuList.forEach((element, myindex) => {
    //       if (element.Items.length > 0) {
    //         if (item.Id == element.Id) {
    //           element.Items.forEach((el, ind) => {
    //             if (!el.checked) {
    //               el.disable = false;
    //             } else {
    //               el.plusIconStyles = true;
    //             }
    //           });
    //         }
    //       }
    //     });
    //   }
    //   let last_item = newProductSubmenuList.length - 1;
    //   if (item.MinSelect > sum) {
    //     newProductSubmenuList.forEach((element, myindex) => {
    //       if (index1 == myindex) {
    //         if (myindex == 0) {
    //           let filltered = newProductSubmenuList.filter(
    //             (element, index1) => index1 !== 0
    //           );
    //           filltered.forEach((element) => {
    //             element.disable = true;
    //             element.Items.forEach((el) => {
    //               el.disable = true;
    //               el.checked = false;
    //               el.showNumberSelector = false;
    //               el.number = 0;
    //             });
    //           });
    //         } else if (myindex > 0) {
    //           element.disable = false;
    //           element.Items.forEach((el) => {
    //             el.disable = false;
    //           });
    //           if (myindex < last_item) {
    //             let filltered = newProductSubmenuList.filter(
    //               (element, index11) => index11 > myindex
    //             );
    //             filltered.forEach((element) => {
    //               element.disable = true;
    //               element.Items.forEach((el) => {
    //                 el.disable = true;
    //                 el.checked = false;
    //                 el.showNumberSelector = false;
    //                 el.number = 0;
    //               });
    //             });
    //           }
    //         }
    //       }
    //     });
    //   } else if (item.MinSelect == sum) {
    //     newProductSubmenuList.forEach((element, myindex) => {
    //       if (index1 == myindex) {
    //         if (myindex == 0) {
    //           const found = newProductSubmenuList.find(
    //             (element, index1) => index1 > myindex
    //           );
    //           found.disable = false;
    //           found.Items.forEach((el, ind) => {
    //             el.disable = false;
    //             el.checked = false;
    //             el.showNumberSelector = false;
    //             el.number = 0;
    //           });
    //           for (let i = 0; i < newProductSubmenuList.length; i++) {
    //             if (i > myindex) {
    //               if (newProductSubmenuList[i].MinSelect == 0) {
    //                 if (
    //                   (newProductSubmenuList[i - 1].MinSelect == 0 &&
    //                     newProductSubmenuList[i - 1].disable == false) ||
    //                   newProductSubmenuList[i - 1] ==
    //                     newProductSubmenuList[myindex]
    //                 ) {
    //                   const found = newProductSubmenuList.find(
    //                     (element, index1) => index1 > i
    //                   );
    //                   found.disable = false;
    //                   found.Items.forEach((el, ind) => {
    //                     el.disable = false;
    //                     el.checked = false;
    //                     el.showNumberSelector = false;
    //                     el.number = 0;
    //                   });
    //                 }
    //               }
    //             }
    //           }
    //         } else if (myindex > 0) {
    //           element.disable = false;
    //           element.Items.forEach((el) => {
    //             el.disable = false;
    //           });
    //           if (myindex < last_item) {
    //             const found = newProductSubmenuList.find(
    //               (element, index1) => index1 > myindex
    //             );
    //             found.disable = false;
    //             found.Items.forEach((el, ind) => {
    //               el.disable = false;
    //               el.checked = false;
    //               el.showNumberSelector = false;
    //               el.number = 0;
    //             });
    //             for (let i = 0; i < newProductSubmenuList.length; i++) {
    //               if (i > myindex) {
    //                 if (newProductSubmenuList[i].MinSelect == 0) {
    //                   if (
    //                     (newProductSubmenuList[i - 1].MinSelect == 0 &&
    //                       newProductSubmenuList[i - 1].disable == false) ||
    //                     newProductSubmenuList[i - 1] ==
    //                       newProductSubmenuList[myindex]
    //                   ) {
    //                     if (i < last_item) {
    //                       const found = newProductSubmenuList.find(
    //                         (element, index1) => index1 > i
    //                       );
    //                       found.disable = false;
    //                       found.Items.forEach((el, ind) => {
    //                         el.disable = false;
    //                       });
    //                     }
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     });
    //   }
    // }
    // setSblist(newProductSubmenuList); **
  };
  const handleoptionId = (item) => {
    if (item.Crusts) {
      item.Crusts.forEach((element, index) => {
        if (crustId) {
          setCrustId(null);
        }
      });
    }

    setOptionId(item.Id);
  };

  //  SET INITIAL VAT FROM PRODUCT OR OPTION
  useEffect(() => {
    if (selected_product) {
      if (selectedOption) {
        if (selected_product.SetDefaultVatForAllOptions) {
          setOptionVat(
            parseFloat(
              ((optionAmount * selected_product.vat_value) / 100).toFixed(2)
            )
          );
          setTotalVat(
            parseFloat(
              ((optionAmount * selected_product.vat_value) / 100).toFixed(2)
            )
          );
        } else {
          setOptionVat(
            parseFloat(
              ((optionAmount * selectedOption.vat_value) / 100).toFixed(2)
            )
          );
          setTotalVat(
            parseFloat(
              ((optionAmount * selectedOption.vat_value) / 100).toFixed(2)
            )
          );
        }
      } else {
        setOptionVat(0);
        setTotalVat(
          parseFloat(
            (
              (selected_product.AmountDelivery * selected_product.vat_value) /
              100
            ).toFixed(2)
          )
        );
      }
    }
  }, [selected_product, selectedOption]);

  // GET PRODUCT SUBMENU BASE SELECTED  PRODUCT
  useEffect(() => {
    if (selected_product) {
      dispatch(
        productSubmenuListAction(
          login_key,
          shop_id,
          selected_product.Id,
          selected_category.Id
        )
      );
    }
  }, [selected_product]);

  // SET INITIAL FINAL PRICE BASE PRODUCT PRICE AND FIRST OPTION PRICE
  useEffect(() => {
    if (selected_product.AmountDelivery) {
      setFinalBasketPrice(selected_product.AmountDelivery);
    } else if (selected_product.Options) {
      for (let i = 0; i < selected_product.Options.length; i++) {
        if (
          selected_product.Options[i].WebVisible &&
          selected_product.Options[i].AmountDelivery
        ) {
          setFinalBasketPrice(selected_product.Options[i].AmountDelivery);
          setOptionId(selected_product.Options[i].Id);
          setOptionTitle(selected_product.Options[i].WebTitle);
          setOptionAmount(selected_product.Options[i].AmountDelivery);
          setSelectedOption(selected_product.Options[i]);
          if (selected_product.Options[i].Crusts) {
            if (selected_product.Options[i].Crusts.length > 0) {
              let final_crusts = [];
              for (
                let j = 0;
                j < selected_product.Options[i].Crusts.length;
                j++
              ) {
                final_crusts.push({
                  ...selected_product.Options[i].Crusts[j],
                  checked: false,
                });
              }
              setCrustList(final_crusts);
            } else {
              setCrustList([]);
            }
          } else {
            setCrustList([]);
          }
          break;
        }
      }
    }
  }, [selected_product]);

  // SET SUBMENU LIST FROM PRODUCT SUBMENU
  useEffect(() => {
    if (product_submenu_list.length > 0) {
      setSblist(product_submenu_list);
    } else {
      setSblist([]);
    }
  }, [product_submenu_list]);

  // SET BASKET
  useEffect(() => {
    if (selected_product) {
      setBasketContent({
        id: basket_item_id + 1,
        title: optionTitle
          ? `${optionTitle} ${selected_product.WebTitle}`
          : selected_product.WebTitle,
        // title: optionTitle
        //   ? crustTitle
        //     ? `${crustTitle} ${optionTitle} ${selected_product.WebTitle}`
        //     : `${optionTitle} ${selected_product.WebTitle}`
        //   : selected_product.WebTitle,
        price: finalBasketPrice,
        default_price: finalBasketPrice,
        total_basket_items_price: finalBasketPrice,
        // 5
        product_amount: selected_product.AmountDelivery,
        number: productNumber,
        sub_list: subList,
        product_group: selected_product.ProductGroup,

        // 10
        crust_id: crustId,
        crust_amount: crustAmount,
        crust_title: crustTitle,
        option_amount: optionAmount,
        option_id: optionId,
        // 15
        print_label: selected_product.PrintLabel,
        vat_id: selected_product.VatId,
        vat_value: selected_product.vat_value,
        final_vat: totalVat,
        total_vat: totalVat,
        // 20
        option_vat: optionVat,
        product_vat: (
          (selected_product.AmountDelivery * selected_product.vat_value) /
          100
        ).toFixed(2),
        custom_title_in_last: selected_product.CustomTitleInLast,
        title_in_last: selected_product.TitleInLast,
        printer_id: selected_product.PrinterId,
        // 25
        report_section: selected_product.ReportSection,
        product_id: selected_product.Id,
        is_mealdeal: selected_category.MealDeal,
      });
    }
  }, [
    selected_product,
    finalBasketPrice,
    optionId,
    optionAmount,
    optionTitle,
    crustId,
    crustAmount,
    crustTitle,
    productNumber,
    subList,
    basket_item_id,
    selectedOption,
    totalVat,
    optionVat,
  ]);

  // SET INITIAL SUBITEMS PRICE BASE FIRST OPTION IF EXIST
  useEffect(() => {
    if (product_submenu_list) {
      let tempSbList = [...product_submenu_list];
      let tempFinalSublist = [];

      for (let i = 0; i < tempSbList.length; i++) {
        if (tempSbList[i].WebVisible) {
          if (tempSbList[i].IsFree) {
            let list = [];
            for (let k = 0; k < tempSbList[i].Items.length; k++) {
              list.push({ ...tempSbList[i].Items[k], AmountMain: 0 });
            }
            tempFinalSublist.push({ ...tempSbList[i], Items: list });
          } else if (tempSbList[i].Amount) {
            let list = [];
            for (let k = 0; k < tempSbList[i].Items.length; k++) {
              list.push({
                ...tempSbList[i].Items[k],
                AmountMain: tempSbList[i].Amount,
                vat_item: parseFloat(
                  (
                    (tempSbList[i].Amount * tempSbList[i].Items[k].vat_value) /
                    100
                  ).toFixed(2)
                ),
              });
            }
            tempFinalSublist.push({ ...tempSbList[i], Items: list });
          } else {
            if (selected_product.Options) {
              for (let y = 0; y < selected_category.options.length; y++) {
                if (
                  selected_category.options[y].Id ==
                  selected_product.Options[0].OptionId
                ) {
                  // FIND DESIRED SUBMENU FROM GLOBAL SUBMENU
                  for (let r = 0; r < global_submenu_list.length; r++) {
                    if (global_submenu_list[r].Id == tempSbList[i].SubMenuId) {
                      if (
                        global_submenu_list[r].IsOverridePrice &&
                        selected_category.options[y].MultiSelectPrice
                      ) {
                        let list = [];
                        for (let k = 0; k < tempSbList[i].Items.length; k++) {
                          list.push({
                            ...tempSbList[i].Items[k],
                            AmountMain:
                              selected_category.options[y].MultiSelectPrice,
                            vat_item: parseFloat(
                              (
                                (selected_category.options[y].MultiSelectPrice *
                                  tempSbList[i].Items[k].vat_value) /
                                100
                              ).toFixed(2)
                            ),
                          });
                        }
                        tempFinalSublist.push({
                          ...tempSbList[i],
                          Items: list,
                        });
                      } else {
                        let list = [];
                        for (let k = 0; k < tempSbList[i].Items.length; k++) {
                          if (tempSbList[i].Items[k].PriceLevelMain == 1) {
                            list.push({
                              ...tempSbList[i].Items[k],
                              AmountMain:
                                selected_category.options[y].PriceLevelMain1,
                              vat_item: parseFloat(
                                (
                                  (selected_category.options[y]
                                    .PriceLevelMain1 *
                                    tempSbList[i].Items[k].vat_value) /
                                  100
                                ).toFixed(2)
                              ),
                            });
                          } else if (
                            tempSbList[i].Items[k].PriceLevelMain == 2
                          ) {
                            list.push({
                              ...tempSbList[i].Items[k],
                              AmountMain:
                                selected_category.options[y].PriceLevelMain2,
                              vat_item: parseFloat(
                                (
                                  (selected_category.options[y]
                                    .PriceLevelMain2 *
                                    tempSbList[i].Items[k].vat_value) /
                                  100
                                ).toFixed(2)
                              ),
                            });
                          } else if (
                            tempSbList[i].Items[k].PriceLevelMain == 3
                          ) {
                            list.push({
                              ...tempSbList[i].Items[k],
                              AmountMain:
                                selected_category.options[y].PriceLevelMain3,
                              vat_item: parseFloat(
                                (
                                  (selected_category.options[y]
                                    .PriceLevelMain3 *
                                    tempSbList[i].Items[k].vat_value) /
                                  100
                                ).toFixed(2)
                              ),
                            });
                          } else if (
                            tempSbList[i].Items[k].PriceLevelMain == 4
                          ) {
                            list.push({
                              ...tempSbList[i].Items[k],
                              AmountMain:
                                selected_category.options[y].PriceLevelMain4,
                              vat_item: parseFloat(
                                (
                                  (selected_category.options[y]
                                    .PriceLevelMain4 *
                                    tempSbList[i].Items[k].vat_value) /
                                  100
                                ).toFixed(2)
                              ),
                            });
                          } else if (
                            tempSbList[i].Items[k].PriceLevelMain == 5
                          ) {
                            list.push({
                              ...tempSbList[i].Items[k],
                              AmountMain:
                                selected_category.options[y].PriceLevelMain5,
                              vat_item: parseFloat(
                                (
                                  (selected_category.options[y]
                                    .PriceLevelMain5 *
                                    tempSbList[i].Items[k].vat_value) /
                                  100
                                ).toFixed(2)
                              ),
                            });
                          } else if (
                            tempSbList[i].Items[k].PriceLevelMain == 6
                          ) {
                            list.push({
                              ...tempSbList[i].Items[k],
                              AmountMain:
                                selected_category.options[y].PriceLevelMain6,
                              vat_item: parseFloat(
                                (
                                  (selected_category.options[y]
                                    .PriceLevelMain6 *
                                    tempSbList[i].Items[k].vat_value) /
                                  100
                                ).toFixed(2)
                              ),
                            });
                          } else if (
                            tempSbList[i].Items[k].PriceLevelMain == 7
                          ) {
                            list.push({
                              ...tempSbList[i].Items[k],
                              AmountMain:
                                selected_category.options[y].PriceLevelMain7,
                              vat_item: parseFloat(
                                (
                                  (selected_category.options[y]
                                    .PriceLevelMain7 *
                                    tempSbList[i].Items[k].vat_value) /
                                  100
                                ).toFixed(2)
                              ),
                            });
                          } else if (
                            tempSbList[i].Items[k].PriceLevelMain == 8
                          ) {
                            list.push({
                              ...tempSbList[i].Items[k],
                              AmountMain:
                                selected_category.options[y].PriceLevelMain8,
                              vat_item: parseFloat(
                                (
                                  (selected_category.options[y]
                                    .PriceLevelMain8 *
                                    tempSbList[i].Items[k].vat_value) /
                                  100
                                ).toFixed(2)
                              ),
                            });
                          } else {
                            list.push({
                              ...tempSbList[i].Items[k],
                              vat_item: parseFloat(
                                (
                                  (tempSbList[i].Items[k].AmountMain *
                                    tempSbList[i].Items[k].vat_value) /
                                  100
                                ).toFixed(2)
                              ),
                            });
                          }
                        }
                        tempFinalSublist.push({
                          ...tempSbList[i],
                          Items: list,
                        });
                      }
                    }
                  }
                  break;
                }
              }
            } else {
              tempFinalSublist.push(tempSbList[i]);
            }
          }
        }
      }

      setSblist(tempFinalSublist);
    }
  }, [product_submenu_list]);

  const handleOptionClicked = (option_item) => {
    let tempSbList = [...product_submenu_list];
    let tempFinalSublist = [];
    setSubList([]);
    setFinalBasketPrice(option_item.AmountDelivery);
    setOptionAmount(option_item.AmountDelivery);
    setOptionTitle(option_item.WebTitle);
    setSelectedOption(option_item);
    setCrustId(0);
    setCrustTitle("");
    setCrustAmount(0);
    setProductNumber(1);
    if (option_item.Crusts) {
      if (option_item.Crusts.length > 0) {
        let final_crusts = [];
        for (let i = 0; i < option_item.Crusts.length; i++) {
          final_crusts.push({ ...option_item.Crusts[i], checked: false });
        }
        setCrustList(final_crusts);
      } else {
        setCrustList([]);
      }
    } else {
      setCrustList([]);
    }

    if (selected_product.SetDefaultVatForAllOptions) {
      setOptionVat(
        parseFloat(
          (
            (option_item.AmountDelivery * selected_product.vat_value) /
            100
          ).toFixed(2)
        )
      );
      setTotalVat(
        parseFloat(
          (
            (option_item.AmountDelivery * selected_product.vat_value) /
            100
          ).toFixed(2)
        )
      );
    } else {
      setOptionVat(
        parseFloat(
          ((option_item.AmountDelivery * option_item.vat_value) / 100).toFixed(
            2
          )
        )
      );
      setTotalVat(
        parseFloat(
          ((option_item.AmountDelivery * option_item.vat_value) / 100).toFixed(
            2
          )
        )
      );
    }

    // LOOP FOR FIND DESIRED CATEGORY OPTION
    if (tempSbList.length > 0) {
      for (let r = 0; r < selected_category.options.length; r++) {
        if (selected_category.options[r].Id == option_item.OptionId) {
          for (let i = 0; i < tempSbList.length; i++) {
            if (tempSbList[i].IsFree) {
              let list = [];
              for (let k = 0; k < tempSbList[i].Items.length; k++) {
                list.push({ ...tempSbList[i].Items[k], AmountMain: 0 });
              }
              tempFinalSublist.push({ ...tempSbList[i], Items: list });
            } else if (tempSbList[i].Amount) {
              let list = [];
              for (let k = 0; k < tempSbList[i].Items.length; k++) {
                list.push({
                  ...tempSbList[i].Items[k],
                  AmountMain: tempSbList[i].Amount,
                  vat_item: parseFloat(
                    (
                      (tempSbList[i].Amount *
                        tempSbList[i].Items[k].vat_value) /
                      100
                    ).toFixed(2)
                  ),
                });
              }
              tempFinalSublist.push({ ...tempSbList[i], Items: list });
            } else {
              // LOOP FOR FIND DESIRED GLOBAL SUBMENU
              for (let j = 0; j < global_submenu_list.length; j++) {
                if (global_submenu_list[j].Id == tempSbList[i].SubMenuId) {
                  if (
                    global_submenu_list[j].IsOverridePrice &&
                    selected_category.options[r].MultiSelectPrice
                  ) {
                    let list = [];
                    for (let k = 0; k < tempSbList[i].Items.length; k++) {
                      list.push({
                        ...tempSbList[i].Items[k],
                        AmountMain:
                          selected_category.options[r].MultiSelectPrice,
                        vat_item: parseFloat(
                          (
                            (selected_category.options[r].MultiSelectPrice *
                              tempSbList[i].Items[k].vat_value) /
                            100
                          ).toFixed(2)
                        ),
                      });
                    }
                    tempFinalSublist.push({
                      ...tempSbList[i],
                      Items: list,
                    });
                  } else {
                    let list = [];
                    for (let k = 0; k < tempSbList[i].Items.length; k++) {
                      if (tempSbList[i].Items[k].PriceLevelMain == 1) {
                        list.push({
                          ...tempSbList[i].Items[k],
                          AmountMain:
                            selected_category.options[r].PriceLevelMain1,
                          vat_item: parseFloat(
                            (
                              (selected_category.options[r].PriceLevelMain1 *
                                tempSbList[i].Items[k].vat_value) /
                              100
                            ).toFixed(2)
                          ),
                        });
                      } else if (tempSbList[i].Items[k].PriceLevelMain == 2) {
                        list.push({
                          ...tempSbList[i].Items[k],
                          AmountMain:
                            selected_category.options[r].PriceLevelMain2,
                          vat_item: parseFloat(
                            (
                              (selected_category.options[r].PriceLevelMain2 *
                                tempSbList[i].Items[k].vat_value) /
                              100
                            ).toFixed(2)
                          ),
                        });
                      } else if (tempSbList[i].Items[k].PriceLevelMain == 3) {
                        list.push({
                          ...tempSbList[i].Items[k],
                          AmountMain:
                            selected_category.options[r].PriceLevelMain3,
                          vat_item: parseFloat(
                            (
                              (selected_category.options[r].PriceLevelMain3 *
                                tempSbList[i].Items[k].vat_value) /
                              100
                            ).toFixed(2)
                          ),
                        });
                      } else if (tempSbList[i].Items[k].PriceLevelMain == 4) {
                        list.push({
                          ...tempSbList[i].Items[k],
                          AmountMain:
                            selected_category.options[r].PriceLevelMain4,
                          vat_item: parseFloat(
                            (
                              (selected_category.options[r].PriceLevelMain4 *
                                tempSbList[i].Items[k].vat_value) /
                              100
                            ).toFixed(2)
                          ),
                        });
                      } else if (tempSbList[i].Items[k].PriceLevelMain == 5) {
                        list.push({
                          ...tempSbList[i].Items[k],
                          AmountMain:
                            selected_category.options[r].PriceLevelMain5,
                          vat_item: parseFloat(
                            (
                              (selected_category.options[r].PriceLevelMain5 *
                                tempSbList[i].Items[k].vat_value) /
                              100
                            ).toFixed(2)
                          ),
                        });
                      } else if (tempSbList[i].Items[k].PriceLevelMain == 6) {
                        list.push({
                          ...tempSbList[i].Items[k],
                          AmountMain:
                            selected_category.options[r].PriceLevelMain6,
                          vat_item: parseFloat(
                            (
                              (selected_category.options[r].PriceLevelMain6 *
                                tempSbList[i].Items[k].vat_value) /
                              100
                            ).toFixed(2)
                          ),
                        });
                      } else if (tempSbList[i].Items[k].PriceLevelMain == 7) {
                        list.push({
                          ...tempSbList[i].Items[k],
                          AmountMain:
                            selected_category.options[r].PriceLevelMain7,
                          vat_item: parseFloat(
                            (
                              (selected_category.options[r].PriceLevelMain7 *
                                tempSbList[i].Items[k].vat_value) /
                              100
                            ).toFixed(2)
                          ),
                        });
                      } else if (tempSbList[i].Items[k].PriceLevelMain == 8) {
                        list.push({
                          ...tempSbList[i].Items[k],
                          AmountMain:
                            selected_category.options[r].PriceLevelMain8,
                          vat_item: parseFloat(
                            (
                              (selected_category.options[r].PriceLevelMain8 *
                                tempSbList[i].Items[k].vat_value) /
                              100
                            ).toFixed(2)
                          ),
                        });
                      } else {
                        list.push({
                          ...tempSbList[i].Items[k],
                          vat_item: parseFloat(
                            (
                              (tempSbList[i].Items[k].AmountMain *
                                tempSbList[i].Items[k].vat_value) /
                              100
                            ).toFixed(2)
                          ),
                        });
                      }
                    }
                    tempFinalSublist.push({
                      ...tempSbList[i],
                      Items: list,
                    });
                  }
                }
              }
            }
          }

          break;
        }
      }
    }

    setSblist(tempFinalSublist);
    setOptionId(option_item.Id);
  };

  // const handleCrustClicked = (crust_item) => {
  //   setCrustId(crust_item.Id);
  //   setCrustTitle(crust_item.WebTitle);
  //   setCrustAmount(crust_item.Price);
  //   setFinalBasketPrice(optionAmount + crust_item.Price);
  // };

  // HANDLE ACTIVE BUTTON OR NOT
  useEffect(() => {
    for (let i = 0; i < sblist.length; i++) {
      if (sblist[i].MinSelect > 0) {
        if (sblist[i].subNumber >= sblist[i].MinSelect) {
          setActiveAddToBasket(true);
        } else {
          setActiveAddToBasket(false);
          break;
        }
      }
    }
  }, [sblist]);

  const handleAddToBasket = () => {
    if (activeAddToBasket) {
      let my_day = new Date().getDay();
      let productFinalPromotionWithMealdeals = discount_with_mealdeals;
      let productFinalPromotionWithoutMealdeals = discount_without_mealdeals;
      // console.log(
      //   "productFinalPromotionWithMealdeals: ",
      //   productFinalPromotionWithMealdeals
      // );
      // console.log(
      //   "productFinalPromotionWithoutMealdeals: ",
      //   productFinalPromotionWithoutMealdeals
      // );

      if (promotion_list) {
        if (promotion_list.length > 0) {
          for (let i = 0; i < promotion_list.length; i++) {
            if (promotion_list[i].day_id == my_day) {
              if (promotion_list[i].day_promotions) {
                if (promotion_list[i].day_promotions.length > 0) {
                  let dayPromotion = promotion_list[i].day_promotions;
                  for (let j = 0; j < dayPromotion.length; j++) {
                    if (dayPromotion[j].RateOption) {
                      if (dayPromotion[j].ExcludeMealDeals) {
                        if (!selected_category.MealDeal) {
                          if (dayPromotion[j].MinOrder) {
                            if (
                              sub_total_without_mealdeal + basketContent.price >
                              dayPromotion[j].MinOrder
                            ) {
                              if (dayPromotion[j].SaleMethod == 1) {
                                productFinalPromotionWithoutMealdeals =
                                  ((sub_total_without_mealdeal +
                                    basketContent.price) *
                                    dayPromotion[j].Rate) /
                                  100;
                              } else if (
                                dayPromotion[j].SaleMethod == collection &&
                                sale_method == collection
                              ) {
                                productFinalPromotionWithoutMealdeals =
                                  ((sub_total_without_mealdeal +
                                    basketContent.price) *
                                    dayPromotion[j].Rate) /
                                  100;
                              } else if (
                                dayPromotion[j].SaleMethod == delivery &&
                                sale_method == delivery
                              ) {
                                productFinalPromotionWithoutMealdeals =
                                  ((sub_total_without_mealdeal +
                                    basketContent.price) *
                                    dayPromotion[j].Rate) /
                                  100;
                              }
                            }
                          } else {
                            if (dayPromotion[j].SaleMethod == 1) {
                              productFinalPromotionWithoutMealdeals =
                                ((sub_total_without_mealdeal +
                                  basketContent.price) *
                                  dayPromotion[j].Rate) /
                                100;
                            } else if (
                              dayPromotion[j].SaleMethod == collection &&
                              sale_method == collection
                            ) {
                              productFinalPromotionWithoutMealdeals =
                                ((sub_total_without_mealdeal +
                                  basketContent.price) *
                                  dayPromotion[j].Rate) /
                                100;
                            } else if (
                              dayPromotion[j].SaleMethod == delivery &&
                              sale_method == delivery
                            ) {
                              productFinalPromotionWithoutMealdeals =
                                ((sub_total_without_mealdeal +
                                  basketContent.price) *
                                  dayPromotion[j].Rate) /
                                100;
                            }
                          }
                        }
                      } else {
                        if (dayPromotion[j].MinOrder) {
                          if (
                            sub_total + basketContent.price >
                            dayPromotion[j].MinOrder
                          ) {
                            if (dayPromotion[j].SaleMethod == 1) {
                              productFinalPromotionWithMealdeals =
                                ((sub_total + basketContent.price) *
                                  dayPromotion[j].Rate) /
                                100;
                            } else if (
                              dayPromotion[j].SaleMethod == collection &&
                              sale_method == collection
                            ) {
                              productFinalPromotionWithMealdeals =
                                ((sub_total_without_mealdeal +
                                  basketContent.price) *
                                  dayPromotion[j].Rate) /
                                100;
                            } else if (
                              dayPromotion[j].SaleMethod == delivery &&
                              sale_method == delivery
                            ) {
                              productFinalPromotionWithMealdeals =
                                ((sub_total_without_mealdeal +
                                  basketContent.price) *
                                  dayPromotion[j].Rate) /
                                100;
                            }
                          }
                        } else {
                          if (dayPromotion[j].SaleMethod == 1) {
                            productFinalPromotionWithMealdeals =
                              ((sub_total + basketContent.price) *
                                dayPromotion[j].Rate) /
                              100;
                          } else if (
                            dayPromotion[j].SaleMethod == collection &&
                            sale_method == collection
                          ) {
                            productFinalPromotionWithMealdeals =
                              ((sub_total + basketContent.price) *
                                dayPromotion[j].Rate) /
                              100;
                          } else if (
                            dayPromotion[j].SaleMethod == delivery &&
                            sale_method == delivery
                          ) {
                            productFinalPromotionWithMealdeals =
                              ((sub_total + basketContent.price) *
                                dayPromotion[j].Rate) /
                              100;
                          }
                        }
                      }
                    } else {
                      if (dayPromotion[j].ExcludeMealDeals) {
                        if (!selected_category.MealDeal) {
                          if (dayPromotion[j].MinOrder) {
                            if (
                              sub_total_without_mealdeal + basketContent.price >
                              dayPromotion[j].MinOrder
                            ) {
                              if (dayPromotion[j].SaleMethod == 1) {
                                productFinalPromotionWithoutMealdeals =
                                  dayPromotion[j].Rate;
                              } else if (
                                dayPromotion[j].SaleMethod == collection &&
                                sale_method == collection
                              ) {
                                productFinalPromotionWithoutMealdeals =
                                  dayPromotion[j].Rate;
                              } else if (
                                dayPromotion[j].SaleMethod == delivery &&
                                sale_method == delivery
                              ) {
                                productFinalPromotionWithoutMealdeals =
                                  dayPromotion[j].Rate;
                              }
                            }
                          } else {
                            if (dayPromotion[j].SaleMethod == 1) {
                              productFinalPromotionWithoutMealdeals =
                                dayPromotion[j].Rate;
                            } else if (
                              dayPromotion[j].SaleMethod == collection &&
                              sale_method == collection
                            ) {
                              productFinalPromotionWithoutMealdeals =
                                dayPromotion[j].Rate;
                            } else if (
                              dayPromotion[j].SaleMethod == delivery &&
                              sale_method == delivery
                            ) {
                              productFinalPromotionWithoutMealdeals =
                                dayPromotion[j].Rate;
                            }
                          }
                        }
                      } else {
                        if (dayPromotion[j].MinOrder) {
                          if (
                            sub_total + basketContent.price >
                            dayPromotion[j].MinOrder
                          ) {
                            if (dayPromotion[j].SaleMethod == 1) {
                              productFinalPromotionWithMealdeals =
                                dayPromotion[j].Rate;
                            } else if (
                              dayPromotion[j].SaleMethod == collection &&
                              sale_method == collection
                            ) {
                              productFinalPromotionWithMealdeals =
                                dayPromotion[j].Rate;
                            } else if (
                              dayPromotion[j].SaleMethod == delivery &&
                              sale_method == delivery
                            ) {
                              productFinalPromotionWithMealdeals =
                                dayPromotion[j].Rate;
                            }
                          }
                        } else {
                          if (dayPromotion[j].SaleMethod == 1) {
                            productFinalPromotionWithMealdeals =
                              dayPromotion[j].Rate;
                          } else if (
                            dayPromotion[j].SaleMethod == collection &&
                            sale_method == collection
                          ) {
                            productFinalPromotionWithMealdeals =
                              dayPromotion[j].Rate;
                          } else if (
                            dayPromotion[j].SaleMethod == delivery &&
                            sale_method == delivery
                          ) {
                            productFinalPromotionWithMealdeals =
                              dayPromotion[j].Rate;
                          }
                        }
                      }
                    }

                    // if (dayPromotion[j].ExcludeMealDeals) {
                    //   if (!selected_category.MealDeal) {
                    //     if (dayPromotion[j].MinOrder) {
                    //       if (
                    //         sub_total + basketContent.price >
                    //         dayPromotion[j].MinOrder
                    //       ) {
                    //         if (dayPromotion[j].SaleMethod == 1) {
                    //           if (dayPromotion[j].RateOption) {
                    // product_final_promotion =
                    //   (sub_total +
                    //     basketContent.price * dayPromotion[j].Rate) /
                    //   100;
                    //           }else{
                    //             product_final_promotion =
                    //             (sub_total +
                    //               basketContent.price * dayPromotion[j].Rate) /
                    //             100;
                    //           }
                    //         }
                    //       }
                    //     }
                    //   }
                    // } else {
                    // }
                  }
                } else {
                  productFinalPromotionWithMealdeals = 0;
                  productFinalPromotionWithoutMealdeals = 0;
                  break;
                }
              } else {
                productFinalPromotionWithMealdeals = 0;
                productFinalPromotionWithoutMealdeals = 0;
                break;
              }
            }
          }
        } else {
          productFinalPromotionWithMealdeals = 0;
          productFinalPromotionWithoutMealdeals = 0;
        }
      } else {
        productFinalPromotionWithMealdeals = 0;
        productFinalPromotionWithoutMealdeals = 0;
      }
      let temp_basket = [...basket_content];
      let temp_basket_product_id_list = [...basket_product_id_list];
      temp_basket.push(basketContent);
      temp_basket_product_id_list.push(selected_product.Id);
      store.set("temp_basket", temp_basket);
      store.set("temp_basket_product_id_list", temp_basket_product_id_list);
      store.set("basket_last_item_id", basketContent.id);
      store.set("temp_sub_total", sub_total + basketContent.price);
      store.set("discount", productFinalPromotionWithMealdeals);
      store.set("discount_no_mealdeal", productFinalPromotionWithoutMealdeals);
      store.set(
        "temp_no_mealdeal_sub_total",
        selected_category.MealDeal
          ? sub_total_without_mealdeal
          : sub_total_without_mealdeal + basketContent.price
      );
      store.set("saved_basket_time", new Date());

      dispatch(
        setBasketListAction(
          temp_basket,
          sub_total + basketContent.price,
          basketContent.id,
          temp_basket_product_id_list,
          productFinalPromotionWithMealdeals,
          productFinalPromotionWithoutMealdeals,
          selected_category.MealDeal
            ? sub_total_without_mealdeal
            : sub_total_without_mealdeal + basketContent.price
        )
      );

      setTimeout(() => {
        dispatch(showProductSubmenuPopupAction(false));
      }, 300);
    } else {
      for (let i = 0; i < sblist.length; i++) {
        if (sblist[i].MinSelect > 0) {
          if (sblist[i].subNumber < sblist[i].MinSelect) {
            elRefs[i].current.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            return;
          }
        }
      }
    }
  };

  // SET REFS TO EATCH SUBMENU
  useEffect(() => {
    if (sblist.length > 0) {
      setElRefs((elRefs) =>
        Array(sblist.length)
          .fill()
          .map((_, i) => elRefs[i] || React.createRef())
      );
    }
  }, [sblist]);

  return (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(showProductSubmenuPopupAction(false))}
    >
      <div
        className="create_shop_popup_content_container"
        onClick={(i) => i.stopPropagation()}
      >
        {/* MOBILE CLOSE BUTTON */}
        <div
          className="close_in_mobile_container"
          onClick={() => dispatch(showProductSubmenuPopupAction(false))}
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </div>
        {/* ***** */}

        <div className="product-submenu-popup-container">
          {/* PRODUCT PHOTO */}
          <div className="product-submenu-popup-container-image-and-close-icon">
            <img
              src={
                selected_product.WebImageUrl &&
                selected_product.WebVisibleImage &&
                shop_info
                  ? `${baseUrlForImagesPrefix}${shop_info.Domain}/${selected_product.WebImageUrl}`
                  : ProductPlaceholder
              }
            />
          </div>

          {/* PRODUCT TITLE */}
          <div
            className="product-submenu-popup-container-size"
            style={{
              borderTop: product_submenu_list ? null : "10px solid green",
            }}
          >
            <div style={{ maxWidth: "85%" }}>
              <h5>{selected_product.WebTitle}</h5>
              <h6>{selected_product.WebDescription}</h6>
            </div>

            <h5>
              {selected_product.AmountDelivery
                ? `${pound_sign}${selected_product.AmountDelivery.toFixed(2)}`
                : null}
            </h5>
          </div>

          {show_submenu_loader ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Loader color="red" />
            </div>
          ) : (
            <div>
              {/* OPTIONS */}
              {!selected_product.AmountDelivery ? (
                <div>
                  {selected_product.Options ? (
                    <div>
                      <hr className="product-submenu-popup-hr" />
                      <div className="product_submenu_popup_optiones">
                        <h6>{lang.choose_size}</h6>
                        <p id="option_title">{lang.required}</p>

                        {selected_product.Options.map((item, index) => (
                          <div key={item.Id}>
                            {item.AmountDelivery && item.WebVisible ? (
                              // OPTIONS CRUSTS
                              <div className="product_submenu_popup_container_option_crust">
                                {/* OPTIONS */}
                                <div className="product_option_container">
                                  <div>
                                    <RadioGroup
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      name="controlled-radio-buttons-group"
                                    >
                                      <FormControlLabel
                                        value={item.Id}
                                        control={
                                          <Radio
                                            checked={optionId === item.Id}
                                            onChange={() =>
                                              handleOptionClicked(item)
                                            }
                                            sx={{
                                              "&.Mui-checked": {
                                                color: "red",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            style={{
                                              fontSize:
                                                window.innerWidth > 1400
                                                  ? 16
                                                  : 14,
                                            }}
                                          >
                                            {item.WebTitle}
                                          </Typography>
                                        }
                                      />
                                    </RadioGroup>
                                  </div>
                                  <h6>
                                    {pound_sign}
                                    {item.AmountDelivery.toFixed(2)}
                                  </h6>
                                </div>
                                {/* CRUSTS */}
                                {/* <div
                                  style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                  }}
                                >
                                  {optionId == item.Id
                                    ? item.Crusts
                                      ? item.Crusts.map((ite, ind) => (
                                          <div key={ite.Id}>
                                            {ite.WebVisible ? (
                                              <div className="option_title_price_container">
                                                <div>
                                                  <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                  >
                                                    <FormControlLabel
                                                      value={ite.Id}
                                                      control={
                                                        <Radio
                                                          checked={
                                                            crustId === ite.Id
                                                          }
                                                          onChange={() =>
                                                            handleCrustClicked(
                                                              ite
                                                            )
                                                          }
                                                          sx={{
                                                            "&.Mui-checked": {
                                                              color: "red",
                                                            },
                                                          }}
                                                        />
                                                      }
                                                      label={
                                                        <Typography
                                                          style={{
                                                            fontSize:
                                                              window.innerWidth >
                                                              1400
                                                                ? 16
                                                                : 14,
                                                          }}
                                                        >
                                                          {ite.WebTitle}
                                                        </Typography>
                                                      }
                                                    />
                                                  </RadioGroup>
                                                </div>
                                                <h6>
                                                  {pound_sign}
                                                  {ite.Price.toFixed(2)}
                                                </h6>
                                              </div>
                                            ) : null}
                                          </div>
                                        ))
                                      : null
                                    : null}
                                </div> */}
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                      <hr className="product-submenu-popup-hr" />

                      {/* CRUST */}
                      {crustList.length > 0 ? (
                        <div className="product_submenu_popup_optiones">
                          <h6 style={{ marginBottom: 10 }}>Options</h6>
                          {crustList.map((item, index) => (
                            <div key={item.Id}>
                              {item.WebVisible ? (
                                <div className="product_option_container">
                                  <div>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={item.checked}
                                            // disabled={i.disable}
                                            onChange={() =>
                                              handleCrustClicked(item)
                                            }
                                            sx={{
                                              "&.Mui-checked": {
                                                color: primary_color,
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography id="subitem_style">
                                            {item.WebTitle}
                                          </Typography>
                                        }
                                      />
                                    </FormGroup>
                                  </div>
                                  <h6>
                                    {pound_sign}
                                    {item.Price.toFixed(2)}
                                  </h6>
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ) : null}

              {/* SUBMENU */}
              {sblist.length > 0 ? (
                <div>
                  <hr className="product-submenu-popup-hr" />
                  <div
                    className="product-submenu-popup-container-submenus-informations"
                    style={{ paddingBottom: 30 }}
                  >
                    {sblist.map((item, index) => (
                      <div key={index}>
                        {/* SUBMENU */}
                        <div
                          className="product-submenu-popup-container-title-and-min-max-select"
                          ref={elRefs[index]}
                        >
                          <div
                            className="submenu_title_container"
                            style={{ width: "80%" }}
                          >
                            <h6>{item.WebTitle}</h6>
                            <p id="option_required">
                              {item.MinSelect ? lang.required : null}
                            </p>
                          </div>
                          {/* <div className="min_max_container">
                            <h6>
                              {lang.min_select} : {item.MinSelect}
                            </h6>
                            <h6>
                              {lang.max_select} : {item.MaxSelect}
                            </h6>
                          </div> */}
                        </div>

                        {item.Items
                          ? item.Items.map((i, ind) => (
                              <div
                                key={i.Id}
                                className="product-submenu-popup-submenu-item"
                                style={{
                                  opacity: i.disable == true ? ".5" : null,
                                }}
                              >
                                <div
                                  className="product-submenu-popup-container-titles"
                                  style={{
                                    justifyContent: "flex-start",

                                    width:
                                      i.showNumberSelector == true
                                        ? "36%"
                                        : "90%",
                                  }}
                                >
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={i.checked}
                                          disabled={i.disable}
                                          onChange={() =>
                                            handleCheckedCheckbox(
                                              i,
                                              item,
                                              index,
                                              ind
                                            )
                                          }
                                          sx={{
                                            "&.Mui-checked": {
                                              color: primary_color,
                                            },
                                          }}
                                        />
                                      }
                                      // label={i.WebTitle}
                                      label={
                                        <Typography
                                          id="subitem_style"
                                          // style={{
                                          //   fontSize:
                                          //     window.innerWidth > 1400
                                          //       ? 16
                                          //       : 12,
                                          // }}
                                        >
                                          {i.WebTitle}
                                        </Typography>
                                      }
                                    />
                                  </FormGroup>
                                </div>
                                {i.showNumberSelector == true ? (
                                  <div className="item_number_selector">
                                    <div
                                      style={{
                                        border: i.minus
                                          ? "1px solid red"
                                          : null,
                                      }}
                                      className="product-submenu-popup-contsiner-icon"
                                    >
                                      <i
                                        className="fa fa-minus-circle"
                                        onClick={() =>
                                          handleDecreaseNumberOfSubmenuItems(
                                            item,
                                            i,
                                            ind,
                                            index
                                          )
                                        }
                                      ></i>
                                    </div>
                                    <h5>{i.number}</h5>
                                    <div
                                      className="product-submenu-popup-contsiner-icon"
                                      style={{
                                        border: i.plus ? "1px solid red" : null,
                                        opacity:
                                          i.plusIconStyles == false
                                            ? ".5"
                                            : null,
                                      }}
                                    >
                                      <i
                                        className="fa fa-plus-circle"
                                        onClick={() =>
                                          handleIncecreaseNumberOfSubmenuItems(
                                            item,
                                            i,
                                            ind,
                                            index
                                          )
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="item_price_container">
                                  <h6>
                                    {pound_sign}
                                    {i.AmountMain.toFixed(2)}
                                  </h6>
                                </div>
                              </div>
                            ))
                          : null}
                        {sblist.length - 1 > index ? <hr /> : null}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>

        {/* FOOTER */}
        <div
          className="product-submenu-popup-contsiner-footter"
          style={{ borderTop: "1px solid #ccc", backgroundColor: "white" }}
        >
          <div className="product_detail_minus_plus_product">
            <div className="product-submenu-popup-contsiner-icons">
              <div
                style={{
                  border: clicledIcon1 ? "1px solid red" : null,
                }}
                className="product-submenu-popup-contsiner-icon"
              >
                <i
                  className="fa fa-minus-circle"
                  onClick={handleDecreaseProductNumber}
                ></i>
              </div>
              <h3>{productNumber}</h3>
              <div
                className="product-submenu-popup-contsiner-icon"
                style={{
                  border: clicledIcon2 ? "1px solid red" : null,
                }}
              >
                <i
                  className="fa fa-plus-circle"
                  onClick={handleIncreaseProductNumber}
                ></i>
              </div>
            </div>
          </div>

          <Button
            disabled={show_submenu_loader ? true : false}
            onClick={handleAddToBasket}
            style={{
              backgroundColor:
                activeAddToBasket && !show_submenu_loader
                  ? primary_color
                  : white_dark,
              color:
                activeAddToBasket && !show_submenu_loader ? white : black_dark,
              width: "90%",
            }}
          >
            {lang.add} {EUR}
            {finalBasketPrice.toFixed(2)}
          </Button>
        </div>
        {/* FOOTER MOBILE*/}
        <div
          className="mobile_product-submenu-popup-contsiner-footter"
          style={{ borderTop: "1px solid #ccc" }}
        >
          <div className="product_detail_minus_plus_product">
            <div className="product-submenu-popup-contsiner-icons">
              <div
                style={{
                  border: clicledIcon1 ? "1px solid red" : null,
                }}
                className="product-submenu-popup-contsiner-icon"
              >
                <i
                  className="fa fa-minus-circle"
                  onClick={handleDecreaseProductNumber}
                ></i>
              </div>
              <h3>{productNumber}</h3>
              <div
                className="product-submenu-popup-contsiner-icon"
                style={{
                  border: clicledIcon2 ? "1px solid red" : null,
                }}
              >
                <i
                  className="fa fa-plus-circle"
                  onClick={handleIncreaseProductNumber}
                ></i>
              </div>
            </div>
          </div>

          <Button
            disabled={show_submenu_loader ? true : false}
            onClick={handleAddToBasket}
            style={{
              backgroundColor:
                activeAddToBasket && !show_submenu_loader
                  ? primary_color
                  : white_dark,
              color:
                activeAddToBasket && !show_submenu_loader ? white : black_dark,
              width: "90%",
            }}
          >
            {lang.add} {EUR}
            {finalBasketPrice.toFixed(2)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductSubmenuPopup;
