import * as action_types from "../ActionType";

// GET ALL PRODUCTS
export const getAllProductsAction = (shop_id, login_key) => {
  return {
    type: action_types.GET_ALL_PRODUCTS,
    payload: { shop_id, login_key },
  };
};

export const getAllProductsSuccessfull = (data) => {
  return {
    type: action_types.GET_ALL_PRODUCTS_SUCCESSFULL,
    payload: data,
  };
};
export const getAllProductsFailed = (data) => {
  return {
    type: action_types.GET_ALL_PRODUCTS_FAILED,
    payload: data,
  };
};
// ********
export const productsListAction = (login_key, shop_id, category_id) => {
  return {
    type: action_types.PRODUCT_LIST,
    payload: { login_key, shop_id, category_id },
  };
};

export const productsListSuccessfullAction = (productsList, vatsList) => {
  return {
    type: action_types.PRODUCT_LIST_SUCCESSFULL,
    payload: { productsList, vatsList },
  };
};
export const productsListFailedAction = () => {
  return {
    type: action_types.PRODUCT_LIST_FAILED,
  };
};

// PRODUCT SUBMENU
export const productSubmenuListAction = (
  login_key,
  shop_id,
  product_id,
  category_id
) => {
  return {
    type: action_types.PRODUCT_SUBMENU_LIST,
    payload: {
      login_key,
      shop_id,
      product_id,
      category_id,
    },
  };
};
export const productSubmenuListSuccessfullAction = (data) => {
  return {
    type: action_types.PRODUCT_SUBMENU_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const productSubmenuListFailedAction = () => {
  return {
    type: action_types.PRODUCT_SUBMENU_LIST_FAILED,
  };
};
// POPULARS
export const getPopularsListAction = (shop_id, login_key) => {
  return {
    type: action_types.GET_POPULARS_LIST,
    payload: {
      shop_id,
      login_key,
    },
  };
};
export const getPopularsListSuccessfull = (data) => {
  return {
    type: action_types.GET_POPULARS_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getPopularsListFailed = (data) => {
  return {
    type: action_types.GET_POPULARS_LIST_FAILED,
    payload: data,
  };
};

// SUBMENU

export const getSubmenuListAction = (login_key, shop_id) => {
  return {
    type: action_types.GET_SUBMENU_LIST,
    payload: {
      login_key,
      shop_id,
    },
  };
};
export const getSubmenuListSuccessfullAction = (data) => {
  return {
    type: action_types.GET_SUBMENU_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getSubmenuListFailedAction = (data) => {
  return {
    type: action_types.GET_SUBMENU_LIST_FAILED,
    payload: data,
  };
};

export const selectedProductAction = (data) => {
  return {
    type: action_types.SELECTED_PRODUCT,
    payload: data,
  };
};
export const showProductSubmenuPopupAction = (data) => {
  return {
    type: action_types.SHOW_PRODUCT_SUBMENU_POPUP,
    payload: data,
  };
};
export const productOptionCrustAction = (data) => {
  return {
    type: action_types.PRODUCT_OPTION_CRUST,
    payload: data,
  };
};
export const newProductSubmenuListAction = (data) => {
  return {
    type: action_types.NEW_PRODUCT_SUBMENU,
    payload: data,
  };
};
export const newProductSubmenuList1Action = (data) => {
  return {
    type: action_types.NEW_PRODUCT_SUBMENU_1,
    payload: data,
  };
};

export const showCachePopupAction = (data) => {
  return {
    type: action_types.SHOW_CACHE_POPUP,
    payload: data,
  };
};
export const setMobileProductListAction = (data) => {
  return {
    type: action_types.SET_MOBILE_PRODUCT_LIST,
    payload: data,
  };
};
