import * as action_types from "../ActionType";

const initialStates = {
  basket_content: [],
  basket_item_id: 0,
  basket_subitem_id: 0,
  sub_total: 0,
  sub_total_without_mealdeal: 0,
  show_basket: false,
  delivery_fee: 2,
  sale_method: 1000105,
  basket_product_id_list: [],
  discount_with_mealdeals: 0,
  discount_without_mealdeals: 0,
};

const basketReduser = (state = initialStates, action) => {
  switch (action.type) {
    case action_types.ADD_TO_BASKET:
      let subTotal = state.sub_total;
      if (action.payload.basketContent) {
        state.basket_content.push(action.payload.basketContent);
        subTotal = state.sub_total + action.payload.basketContent.price;
      }
      return {
        ...state,
        basket_item_id: action.payload.id,
        sub_total: subTotal,
      };
    case action_types.SHOW_BASKET:
      return {
        ...state,
        show_basket: action.payload,
      };
    case action_types.SET_SALE_METHOD:
      return {
        ...state,
        sale_method: action.payload,
      };
    case action_types.SET_BASKET_LIST:
      return {
        ...state,
        basket_content: action.payload.basketList,
        discount_with_mealdeals: action.payload.discount_with_mealdeals,
        discount_without_mealdeals: action.payload.discount_without_mealdeals,
        sub_total: action.payload.subTotal,
        basket_item_id: action.payload.id
          ? action.payload.id
          : state.basket_item_id,
        sub_total_without_mealdeal: action.payload.sub_total_without_mealdeal,
        basket_product_id_list: action.payload.basket_product_id_list,
      };

    default:
      return state;
  }
};
export default basketReduser;
