import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";

import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import validator from "validator";

import {
  createAccountAction,
  setDefaultAction,
} from "../../redux/Profile/Actions";
import LoaderMini from "../../Componenets/LoderMini";
import { primary_color } from "../../constants/Colors";
import MainHeader from "../../Componenets/MainHeader";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  inputLabel: {
    // color: "lightgray",
    "&$inputFocused": {
      color: primary_color,
    },
  },
  inputFocused: {},
  select: {
    color: "black",
    "&:before": {
      // changes the bottom textbox border when not focused
      borderColor: "red",
    },
    "&:after": {
      // changes the bottom textbox border when clicked/focused.  thought it would be the same with input label
      borderColor: "green",
    },
  },
});

const SignUp = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { user_entered_in, shop_id, login_key } = useSelector(
    (state) => state.shopSettingsReduser
  );
  const { result_successfull, result_failed, result_message, sign_up_loading } =
    useSelector((state) => state.profileReduser);

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [postCode, setPostCode] = useState("");
  const [lastName, setLastName] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleCreateAccount = () => {
    if (firstName == "" || lastName == "" || email == "" || password == "") {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (!validator.isEmail(String(email))) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.icorrect_email_format}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (password.split("").length < 6) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.update_password_message}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        createAccountAction(
          login_key,
          shop_id,
          firstName,
          lastName,
          email,
          "sdfgfg",
          password,
          0
        )
      );
    }
  };
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());

      if (parseInt(params.ID) == 1) {
        navigate("/");
      } else if (parseInt(params.ID) == 2) {
        navigate("/checkout");
      }

      // toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
      //   position: "top-center",
      //   duration: 3000,
      //   style: { backgroundColor: "green", color: "white" },
      //   icon: (
      //     <i
      //       style={{ fontSize: 40 }}
      //       class="fa fa-check-circle"
      //       aria-hidden="true"
      //     ></i>
      //   ),
      // });
      // setTimeout(() => {
      //   if (parseInt(params.ID) == 1) {
      //     navigate(-1);
      //   } else if (parseInt(params.ID) == 2) {
      //     navigate("/checkout");
      //   }
      // }, 1000);
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    if (!user_entered_in) {
      navigate("/");
    }
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className="menu-container-div">
      <MainHeader
        show_all_items={true}
        show_basket={false}
        show_profile={false}
        show_menu_title={true}
      />
      <div className="profile-container">
        <div className="profile-create-acount">
          <div className="profile-container-secifications">
            <h4>{lang.create_account}</h4>
            <div className="profile-container-inputs">
              <TextField
                value={firstName}
                id="outlined-basic"
                label={lang.first_name}
                variant="outlined"
                fullWidth
                onChange={(e) => setFirstName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                }}
                InputProps={{
                  className: "profile_input",
                  // sx: {
                  //   fontSize: 16,
                  //   height: 50,
                  // },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: window.innerWidth > 1400 ? -1 : -6,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primary_color,
                    },
                  },
                }}
              />
            </div>
            <div className="profile-container-inputs">
              <TextField
                value={lastName}
                id="outlined-basic"
                label={lang.last_name}
                variant="outlined"
                fullWidth
                onChange={(e) => setLastName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                }}
                InputProps={{
                  className: "profile_input",
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: window.innerWidth > 1400 ? -1 : -6,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primary_color,
                    },
                  },
                }}
              />
            </div>
            {/* EMAIL */}
            <div className="profile-container-inputs">
              <TextField
                value={email}
                id="outlined-basic"
                label={lang.email}
                variant="outlined"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                }}
                InputProps={{
                  className: "profile_input",
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: window.innerWidth > 1400 ? -1 : -6,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primary_color,
                    },
                  },
                }}
              />
            </div>

            {/* PASSWORD */}
            <div className="profile-container-inputs">
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                }}
                variant="outlined"
              >
                <InputLabel
                  classes={{ focused: classes.inputFocused }}
                  className={classes.inputLabel}
                  sx={{
                    fontSize: 16,
                    margin: 0,
                    padding: 0,
                  }}
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  // classes={classes}
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{
                    height: 45,
                    top: 2,
                    textAlign: "center",
                    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ccc",
                      },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: primary_color,
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: primary_color,
                      },
                  }}
                  InputLabelProps={{
                    // id: "mui_input",
                    sx: {
                      fontSize: 16,
                      top: window.innerWidth > 1400 ? -4 : -7,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primary_color,
                      },
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {/* <TextField
                value={password}
                id="outlined-basic"
                label={lang.password}
                variant="outlined"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                }}
                InputProps={{
                  className: "profile_input",
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: window.innerWidth > 1400 ? -1 : -6,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primary_color,
                    },
                  },
                }}
              /> */}
            </div>
            <button
              className="profile-create-account-button"
              onClick={() => handleCreateAccount()}
            >
              {sign_up_loading ? <LoaderMini /> : null}
              {lang.create_account}
            </button>
            <Toaster />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
