import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import {
  showMaintenanceModePopup,
  setPreorderPressedAction,
} from "../redux/settings/Actions";
import { showProductSubmenuPopupAction } from "../redux/Products/Actions";

import { primary_color } from "../constants/Colors";

const MaintenanceModePopup = () => {
  const dispatch = useDispatch();
  const {
    maintenance_message,
    preorder_message,
    preorder_status,
    maintenance_mode,
    collectionTime,
    deliveryTime,
    before_the_shop_opens,
    maintenance_mode_message,
    show_preorder_button,
  } = useSelector((state) => state.shopSettingsReduser);
  const { shop_info, shop_name } = useSelector(
    (state) => state.shopSettingsReduser
  );
  const { selected_product } = useSelector((state) => state.productReduser);
  const handlePreorder = () => {
    dispatch(setPreorderPressedAction());
    dispatch(showMaintenanceModePopup(false));
    if (selected_product) {
      dispatch(showProductSubmenuPopupAction(true));
    }
  };
  return (
    <div
      className="maintenance_mode_message_main_container"
      onClick={() => dispatch(showMaintenanceModePopup(false))}
    >
      <div
        className="maintenance_mode_message_content_container"
        onClick={(i) => i.stopPropagation()}
      >
        <h4>{maintenance_mode_message}</h4>
        <h5>{maintenance_mode_message}</h5>
        {/* <FontAwesomeIcon icon={faEnvelope} /> */}
        <i
          class="fa fa-window-close"
          aria-hidden="true"
          onClick={() => dispatch(showMaintenanceModePopup(false))}
        ></i>
        {show_preorder_button ? (
          <Button
            onClick={handlePreorder}
            variant="outlined"
            style={{
              width: "90%",
              color: "white",
              borderColor: primary_color,
              textTransform: "none",
              backgroundColor: primary_color,
            }}
          >
            Order for later
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default MaintenanceModePopup;
