export const primary_color = "#ff1e00";
export const primary_light = "#d4442b";
export const primary_dark = "#660000";
export const product_description_color = "#828585";
export const product_title_color = "#1d232c";
export const white = "#ffffff";
export const white_dark = "#dddddd";
export const black_dark = "#1b1b1b";
export const black_product_title = "#000000";
export const light_border_color = "#eee";
export const dark_border_color = "#ccc";
export const footer_subtitle_color = "#616161";
