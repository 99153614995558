import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { toast, Toaster } from "react-hot-toast";
import moment from "moment";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { pound_sign } from "../../constants/String";
import LoderMini from "../../Componenets/LoderMini";
import {
  primary_color,
  white,
  dark_border_color,
} from "../../constants/Colors";
import {
  loginAction,
  setDefaultAction,
  editAccountAction,
  getAddressesListAction,
  getCustomerOrdersListAction,
  getCustomerOrderDetailsAction,
  selectedCustomerOrderAction,
} from "../../redux/Profile/Actions";
const tiraxtur = [
  {
    id: 1,
    date: "2021-10-12",
    amount: 25,
    pay_method: "Cash",
    sale_method: "Delivery",
  },
  {
    id: 2,
    date: "2021-10-12",
    amount: 25,
    pay_method: "Cash",
    sale_method: "Delivery",
  },
  {
    id: 3,
    date: "2021-10-12",
    amount: 25,
    pay_method: "Cash",
    sale_method: "Delivery",
  },
  {
    id: 4,
    date: "2021-10-12",
    amount: 25,
    pay_method: "Cash",
    sale_method: "Delivery",
  },
  {
    id: 5,
    date: "2021-10-12",
    amount: 25,
    pay_method: "Cash",
    sale_method: "Delivery",
  },
  {
    id: 6,
    date: "2021-10-12",
    amount: 25,
    pay_method: "Cash",
    sale_method: "Delivery",
  },
  {
    id: 7,
    date: "2021-10-12",
    amount: 25,
    pay_method: "Cash",
    sale_method: "Delivery",
  },
  {
    id: 9,
    date: "2021-10-12",
    amount: 25,
    pay_method: "Cash",
    sale_method: "Delivery",
  },
  {
    id: 10,
    date: "2021-10-12",
    amount: 25,
    pay_method: "Cash",
    sale_method: "Delivery",
  },
  {
    id: 11,
    date: "2021-10-12",
    amount: 25,
    pay_method: "Cash",
    sale_method: "Delivery",
  },
  {
    id: 12,
    date: "2021-10-12",
    amount: 25,
    pay_method: "Cash",
    sale_method: "Delivery",
  },
];
const MyOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { shop_id, login_key } = useSelector(
    (state) => state.shopSettingsReduser
  );
  const {
    user_info,
    sign_up_loading,
    result_successfull,
    result_failed,
    result_message,
    address_result_successfull,
    address_result_failed,
    address_loading,
    addresses_list,
    customer_orders_list,
    login_loading,
  } = useSelector((state) => state.profileReduser);

  const [selectedOrderId, setSelectedOrderId] = useState(0);

  useEffect(() => {
    if (user_info) {
      dispatch(
        getCustomerOrdersListAction(shop_id, login_key, user_info.CustomerId)
      );
    }
  }, []);
  const handleGetOrderDetails = (item) => {
    dispatch(selectedCustomerOrderAction(item));
    setSelectedOrderId(item.OrderId);
    dispatch(getCustomerOrderDetailsAction(shop_id, login_key, item.OrderId));
  };

  return (
    <div className="myorders_main_container">
      {/* HEADER */}
      <div className="myorders_header_container">
        <div className="myorders_item_container">
          <h6>{lang.order_id}</h6>
        </div>
        <div className="myorders_item_container">
          <h6>{lang.order_date}</h6>
        </div>
        <div className="myorders_item_container">
          <h6>{lang.order_amount}</h6>
        </div>
        <div className="myorders_item_container">
          <h6>{lang.payment_method}</h6>
        </div>
        <div className="myorders_item_container">
          <h6>{lang.sale_method}</h6>
        </div>
        <div style={{ width: 220 }}></div>
      </div>
      {/* CONTENT */}
      {customer_orders_list.length > 0 ? (
        <div>
          {customer_orders_list.map((item) => (
            <div className="myorders_content_container">
              <div className="myorders_item_container">
                <h6>{item.InvoiceId}</h6>
              </div>
              <div className="myorders_item_container">
                <h6>{moment(item.OrderDate).format("YYYY-MM-DD HH:mm:ss")}</h6>
              </div>
              <div className="myorders_item_container">
                <h6>
                  {pound_sign}
                  {item.ForPay.toFixed(2)}
                </h6>
              </div>
              <div className="myorders_item_container">
                <h6>{item.PayMethodName}</h6>
              </div>
              <div className="myorders_item_container">
                <h6>{item.SaleMethodName}</h6>
              </div>
              <div className="myorders_button_container" style={{ width: 220 }}>
                <div></div>
                {/* <Button
                  className="profile-create-account-button"
                 
                  style={{
                    backgroundColor: "white",
                    color: white,
                    border: `1px solid ${dark_border_color}`,
                    textTransform: "none",
                    color: primary_color,
                  }}
                >
                  {lang.re_order}
                </Button> */}
                <Button
                  className="profile-create-account-button"
                  onClick={() => handleGetOrderDetails(item)}
                  style={{
                    backgroundColor: primary_color,
                    color: white,
                    textTransform: "none",
                  }}
                >
                  {login_loading && selectedOrderId == item.OrderId ? (
                    <LoderMini />
                  ) : (
                    lang.details
                  )}
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {login_loading ? (
            <LoderMini color="red" />
          ) : (
            <div>
              <h4>No Order</h4>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyOrders;
