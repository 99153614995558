import * as action_types from "../ActionType";

export const categoryListAction = (login_key, shop_id, category_id) => {
  return {
    type: action_types.CATEGORY_LIST,
    payload: { login_key, shop_id, category_id },
  };
};
export const categoryListSuccessfullAction = (
  category_list,
  promotion_list
) => {
  return {
    type: action_types.CATEGORY_LIST_SUCCESSFULL,
    payload: { category_list, promotion_list },
  };
};
export const categoryListFailedAction = () => {
  return {
    type: action_types.CATEGORY_LIST_FAILED,
  };
};
export const selectedCategoryAction = (data) => {
  return {
    type: action_types.SELECTED_CATEGORY,
    payload: data,
  };
};
export const setDefaultAction = (data) => {
  return {
    type: action_types.SET_DEFAULT_ACTION,
    payload: data,
  };
};
