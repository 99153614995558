import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
// import { NavLink } from "react-router-dom";
import validator from "validator";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@mui/styles";
import {
  useLocation,
  useParams,
  useNavigate,
  NavLink,
  json,
} from "react-router-dom";
import { useCookies } from "react-cookie";
import { GoogleLogin, googleLogout, useGoogleLogin } from "@react-oauth/google";
import Button from "@mui/material/Button";
import AppleLogin from "react-apple-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import store from "store2";
// import FacebookLogin from "react-facebook-login";

import {
  loginAction,
  setDefaultAction,
  getGoogleUserInfoAction,
  createAccountAction,
} from "../../redux/Profile/Actions";
import LoaderMini from "../../Componenets/LoderMini";
import { primary_color } from "../../constants/Colors";
import { facebook_app_id } from "../../constants/BaseUrl";
import MainHeader from "../../Componenets/MainHeader";
import GoogleIcon from "../../assets/images/google_icon.png";
import AppleIconBlack from "../../assets/images/apple_icon_black.png";
import AppleIconBlue from "../../assets/images/apple_icon_blue2.png";
import FacebookIcon from "../../assets/images/facebook_icon.png";

const facebook_login_failed = "Facebook login failed, plase try again";
const google_login_failed = "Google login failed, plase try again";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  inputLabel: {
    // color: "lightgray",
    "&$inputFocused": {
      color: primary_color,
    },
  },
  inputFocused: {},
  select: {
    color: "black",
    "&:before": {
      // changes the bottom textbox border when not focused
      borderColor: "red",
    },
    "&:after": {
      // changes the bottom textbox border when clicked/focused.  thought it would be the same with input label
      borderColor: "green",
    },
  },
});

const Login = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const classes = useStyles();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { user_entered_in, shop_id, login_key } = useSelector(
    (state) => state.shopSettingsReduser
  );

  const {
    result_successfull,
    result_failed,
    login_loading,
    user_info,
    google_login_loading,
    result_message,
    is_old_customer,
  } = useSelector((state) => state.profileReduser);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [cookies, setCookie] = useCookies(["user"]);
  const [user, setUser] = useState([]);
  const [faceBookLogin, setFaceBookLogin] = useState(false);
  const [userPassLogin, setUserPassLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleLogin = () => {
    setFaceBookLogin(false);
    setUserPassLogin(true);
    if (password == "" || email == "") {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (!validator.isEmail(String(email))) {
      // toast.custom(
      //   <div style={{ backgroundColor: "red", padding: 20 }}>
      //     <i
      //       style={{ fontSize: 40 }}
      //       class="fa fa-times-circle"
      //       aria-hidden="true"
      //     ></i>
      //     Hello World Hello World Hello World Hello World Hello World
      //   </div>,
      //   {
      //     position: "top-center",
      //     duration: 3000,
      //   }
      // );
      toast.error(<h5> {lang.icorrect_email_format} </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      dispatch(loginAction(login_key, shop_id, email, password, 0));
    }
  };
  useEffect(() => {
    setFaceBookLogin(false);
    setUserPassLogin(false);
    if (result_successfull) {
      dispatch(setDefaultAction());
      if (parseInt(params.ID) == 1) {
        if (rememberMe && user_info) {
          store.set("user_info", user_info);
        }
        navigate("/");
      } else if (parseInt(params.ID) == 2) {
        console.log("333");
        if (rememberMe && user_info) {
          store.set("user_info", user_info);
        }
        navigate("/checkout");
      }
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed, user_info, rememberMe]);
  // useEffect(() => {
  //   if (!user_entered_in) {
  //     navigate("/");
  //   }
  // }, []);

  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse) => {
  //     setUser(codeResponse);

  //     setFaceBookLogin(false);
  //     setUserPassLogin(false);
  //     dispatch(
  //       getGoogleUserInfoAction(codeResponse.access_token, login_key, shop_id)
  //     );
  //   },
  //   onError: (error) => {
  //     toast.error(<h5> &nbsp;&nbsp;{error}&nbsp;&nbsp; </h5>, {
  //       position: "top-center",
  //       duration: 3000,
  //       style: { backgroundColor: "red", color: "white" },
  //       icon: (
  //         <i
  //           style={{ fontSize: 40 }}
  //           class="fa fa-times-circle"
  //           aria-hidden="true"
  //         ></i>
  //       ),
  //     });
  //   },
  // });

  // const appleResponse = (response) => {
  //   // console.log("response apple: ", response);

  //   if (response.error) {
  //     // alert(response.error.error);
  //     // console.log("response apple failed: ", response);
  //   } else {
  //     // alert("apple Succesfull");
  //     // console.log("response apple success: ", response);
  //     // axios
  //     //   .post("/auth", response)
  //     //   .then((res) => this.setState({ authResponse: res.data }))
  //     //   .catch((err) => console.log(err));
  //   }
  // };
  // const responseFacebook = (response) => {
  //   setUserPassLogin(false);
  //   // console.log("Response facebook: ", response);
  //   if (response.accessToken) {
  //     setFaceBookLogin(true);
  //     let first_name = "";
  //     let last_name = "";
  //     let full_name = response.name.split(" ");
  //     // console.log("full_name: ", full_name);
  //     if (full_name.length > 0) {
  //       for (let i = 0; i < full_name.length; i++) {
  //         if (i == 0) {
  //           first_name = full_name[0];
  //         } else {
  //           last_name = `${last_name} ${full_name[i]}`;
  //         }
  //       }
  //     }

  //     dispatch(
  //       createAccountAction(
  //         login_key,
  //         shop_id,
  //         first_name,
  //         last_name,
  //         response.email,
  //         "",
  //         "1234",
  //         2
  //       )
  //     );
  //   }
  // };
  // const faceBookLoginFailed = (response) => {
  //   // console.log("Response facebook failed: ", response);
  //   setFaceBookLogin(false);
  //   setUserPassLogin(false);
  //   toast.error(<h5> &nbsp;&nbsp;{facebook_login_failed}&nbsp;&nbsp; </h5>, {
  //     position: "top-center",
  //     duration: 3000,
  //     style: { backgroundColor: "red", color: "white" },
  //     icon: (
  //       <i
  //         style={{ fontSize: 40 }}
  //         class="fa fa-times-circle"
  //         aria-hidden="true"
  //       ></i>
  //     ),
  //   });
  // };

  // useEffect(() => {
  //   if (is_old_customer) {
  //     dispatch(setDefaultAction());
  //     navigate("/ForgetPassword");
  //   }
  // }, [is_old_customer]);

  return (
    <div className="menu-container-div">
      <MainHeader
        show_all_items={true}
        show_basket={false}
        show_profile={false}
        show_menu_title={true}
      />

      <div className="profile-container">
        <div className="profile-create-acount">
          <div className="profile-container-secifications">
            <h4>{lang.log_in}</h4>
            <div className="login_with_google_main_container">
              {/* FACEBOOK LOGIN */}
              {/* <div className="login_with_google_container profile-container-inputs">
                <FacebookLogin
                  btnContent="LOGIN With Facebook"
                  appId="3171527163137354"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  onFailure={faceBookLoginFailed}
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      variant="outlined"
                      disabled={google_login_loading ? true : false}
                    >
                      {login_loading && faceBookLogin && !userPassLogin ? (
                        <LoaderMini color={primary_color} myWidth="30%" />
                      ) : null}
                      <div className="login_with_google_icon_title_container">
                        <img src={FacebookIcon} style={{ width: "14%" }} />

                        <p> {lang.continue_with_facebook}</p>
                      </div>
                    </Button>
                  )}
                />
              </div> */}
              {/* APPLE LOGIN */}
              {/* <div className="login_with_google_container profile-container-inputs">
                <AppleLogin
                  clientId="com.everythingtakeaway.marmaris.et.sid"
                  redirectURI="https://otopartnercentre.com/"
                  usePopup={true}
                  callback={appleResponse}
                  scope="email name"
                  responseMode="query"
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      variant="outlined"
                      disabled={google_login_loading ? true : false}
                    >
                      <div className="login_with_google_icon_title_container">
                        <img src={AppleIconBlue} />

                        <p> {lang.continue_with_apple}</p>
                      </div>
                    </Button>
                  )}
                />
              </div> */}
              {/* GOOGLE LOGIN */}
              {/* <div className="login_with_google_container profile-container-inputs">
                <Button
                  onClick={() => login()}
                  variant="outlined"
                  disabled={google_login_loading ? true : false}
                >
                  {google_login_loading ? (
                    <LoaderMini color={primary_color} myWidth="30%" />
                  ) : null}
                  <div className="login_with_google_icon_title_container">
                    <img src={GoogleIcon} style={{ width: "14%" }} />
                    <p>{lang.continue_with_google}</p>
                  </div>
                </Button>
              </div> */}
            </div>

            <div className="profile-container-inputs">
              <TextField
                id="outlined-basic"
                label={lang.email}
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                }}
                InputProps={{
                  className: "profile_input",
                }}
                InputLabelProps={{
                  // id: "mui_input",
                  sx: {
                    fontSize: 16,
                    top: window.innerWidth > 1400 ? -4 : -7,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primary_color,
                    },
                  },
                }}
              />
            </div>
            <div className="profile-container-inputs">
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                }}
                variant="outlined"
              >
                <InputLabel
                  classes={{ focused: classes.inputFocused }}
                  className={classes.inputLabel}
                  sx={{
                    fontSize: 16,
                    margin: 0,
                    padding: 0,
                  }}
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  // classes={classes}
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{
                    height: 45,
                    top: 2,
                    textAlign: "center",
                    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ccc",
                      },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: primary_color,
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: primary_color,
                      },
                  }}
                  InputLabelProps={{
                    // id: "mui_input",
                    sx: {
                      fontSize: 16,
                      top: window.innerWidth > 1400 ? -4 : -7,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primary_color,
                      },
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {/* <TextField
                value={password}
                id="outlined-basic"
                label={lang.password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                }}
                InputProps={{
                  className: "profile_input",
                }}
                InputLabelProps={{
                 
                  sx: {
                    fontSize: 16,
                    top: window.innerWidth > 1400 ? -4 : -7,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primary_color,
                    },
                  },
                }}
              /> */}
            </div>
            <div className="remember_me_forgot_pass_container">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                      sx={{
                        "&.Mui-checked": {
                          color: primary_color,
                        },
                      }}
                    />
                  }
                  // label={lang.remember_me}
                  label={
                    <Typography
                      style={{
                        fontSize: window.innerWidth > 1400 ? 16 : 14,
                        margin: 0,
                      }}
                    >
                      {lang.remember_me}
                    </Typography>
                  }
                />
              </FormGroup>
              <h6
                id="sign_up_in_login"
                onClick={() => navigate("/ForgetPassword")}
              >
                {lang.forgot_pass}
              </h6>

              {/* <NavLink>{lang.forgot_pass}</NavLink> */}
            </div>
            <button
              type="submit"
              className="profile-create-account-button"
              onClick={() => handleLogin()}
              disabled={google_login_loading ? true : false}
            >
              {login_loading && userPassLogin && !faceBookLogin ? (
                <LoaderMini />
              ) : null}
              {lang.log_in}
            </button>
            <div className="remember_me_forgot_pass_container">
              <h6>{lang.have_account}</h6>
              <h6
                id="sign_up_in_login"
                onClick={() => navigate(`/signup/${params.ID}`)}
              >
                {lang.sign_up}
              </h6>
              {/* <NavLink to={`/signup/${params.ID}`}>{lang.sign_up}</NavLink> */}
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </div>
  );
};

export default Login;
