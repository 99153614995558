import * as action_types from "../ActionType";

const initialStates = {
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading: false,
  category_list: [],
  promotion_list: [],
  selected_category: null,
};

const categoriesRedusers = (state = initialStates, action) => {
  switch (action.type) {
    case action_types.CATEGORY_LIST:
      return {
        ...state,
        loading: true,
      };
    case action_types.CATEGORY_LIST_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        category_list: action.payload.category_list,
        promotion_list: action.payload.promotion_list,
      };
    case action_types.CATEGORY_LIST_FAILED:
      return {
        ...state,
        loading: false,
      };
    case action_types.SELECTED_CATEGORY:
      return {
        ...state,
        selected_category: action.payload,
      };
    default:
      return state;
  }
};
export default categoriesRedusers;
