import * as action_type from "../ActionType";
import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import store from "store2";
import {
  getShopOpenCloseService,
  getDeliveryTimeService,
  sendOrderService,
  sendPriceForPayService,
  getShopInfoService,
  getDeliveryFeeService,
  getPaymentSettingsService,
  sendOrderEmailService,
  getSixStepService,
  getSuccessfulOrderDetailsService,
  getTerminalListService,
} from "../../services/Settings";
import {
  getShopOpenCloseSuccessfull,
  getShopOpenCloseFailed,
  getDeliveryTimeSuccessfull,
  getDeliveryTimeFailed,
  sendOrderSuccessfull,
  sendOrderFailed,
  sendPriceForPaySuccessfull,
  sendPriceForPayFailed,
  sendPriceForPayAction,
  getShopInfoSuccessfull,
  getShopInfoFailed,
  getDeliveryFeeSuccessfull,
  getDeliveryFeeFailed,
  getPaymentSettingsSuccessfull,
  getPaymentSettingsFailed,
  setMaintenanceModePopupMessage,
  sendOrderEmailSuccessfull,
  sendOrderEmailFailed,
  getSixStepSuccessfull,
  getSixStepFailed,
  getSuccessfulOrderDetailsSuccessful,
  getSuccessfulOrderDetailsFailed,
} from "./Actions";
import { setSaleMethodAction } from "../basket/Actions";
import { successful_link, failed_link } from "../../constants/BaseUrl";
import moment from "moment";

import { delivery, collection } from "../../constants/SaleMethodsId";

function* getShopInfo({ payload }) {
  try {
    const response = yield call(
      getShopInfoService,
      payload.shop_id,
      payload.login_key
    );

    // console.log("Response shop info: ", response);

    if (response.status == 200) {
      if (response.data.ShopInfo) {
        if (response.data.ShopInfo.length > 0) {
          yield put(getShopInfoSuccessfull(response.data.ShopInfo[0]));
        } else {
          yield put(getShopInfoSuccessfull(null));
        }
      } else {
        yield put(getShopInfoSuccessfull(null));
      }
    } else {
      yield put(getShopInfoSuccessfull(null));
    }
  } catch (error) {
    // console.log("Catch shop info: ", error.message);

    yield put(getShopInfoFailed(error.message));
  }
}
function* getShopOpenClose({ payload }) {
  try {
    const response = yield call(
      getShopOpenCloseService,
      payload.shop_id,
      payload.login_key
    );

    // console.log("Response shop open close: ", response);

    if (response.status == 200) {
      if (response.data.open_close_status) {
        if (response.data.open_close_status.length > 0) {
          let del =
            response.data.open_close_status[0].DeliveryTimGap.split(" ");
          let col =
            response.data.open_close_status[0].CollectionTimeGap.split(" ");

          let show_hide_popup_status_temp = false;
          let before_the_shop_opens_temp = false;

          let delivery_from = false;
          let delivery_unavailable = false;
          let collection_from = false;
          let collection_unavailable = false;
          let showPreorderButton = false;
          let goToCheckout = false;

          let message = "";
          let basket_button_title = "Order now";

          // DELIVERY STATUS
          if (del[0] == "From") {
            delivery_from = true;
            delivery_unavailable = false;
          } else if (del[0] == "unavailable") {
            delivery_from = false;
            delivery_unavailable = true;
          } else {
            delivery_from = false;
            delivery_unavailable = false;
          }

          // COLLECTION STATUS
          if (col[0] == "From") {
            collection_from = true;
            collection_unavailable = false;
          } else if (col[0] == "unavailable") {
            collection_from = false;
            collection_unavailable = true;
          } else {
            collection_from = false;
            collection_unavailable = false;
          }

          if (payload.isInitialRequest) {
            goToCheckout = false;
            if (response.data.open_close_status[0].MaintenanceMode) {
              basket_button_title = "Order now";
              show_hide_popup_status_temp = true;
              message = response.data.open_close_status[0].MaintenanceMessage;
            } else if (delivery_unavailable && collection_unavailable) {
              basket_button_title = "Order now";
              show_hide_popup_status_temp = true;
              message = `This shop currently doesn't accept order`;
            } else if (response.data.open_close_status[0].PreOrder) {
              if (delivery_unavailable && collection_from) {
                show_hide_popup_status_temp = true;
                message = `Collection starts at ${col[1]}`;
                basket_button_title = "Order for later";
                showPreorderButton = true;
                yield put(setSaleMethodAction(collection));
                store.set("sale_method", collection);
              } else if (
                delivery_unavailable &&
                !collection_from &&
                !collection_unavailable
              ) {
                yield put(setSaleMethodAction(collection));
                store.set("sale_method", collection);
                basket_button_title = "Order now";
              } else if (collection_unavailable && delivery_from) {
                show_hide_popup_status_temp = true;
                message = `Delivery starts at ${del[1]}`;
                basket_button_title = "Order for later";
                showPreorderButton = true;
              } else if (delivery_from && collection_from) {
                show_hide_popup_status_temp = true;
                message = `Delivery starts at ${del[1]}`;
                basket_button_title = "Order for later";
                showPreorderButton = true;
              } else {
                basket_button_title = "Order now";
              }
            } else if (!response.data.open_close_status[0].PreOrder) {
              basket_button_title = "Order now";
              if (delivery_unavailable && collection_from) {
                show_hide_popup_status_temp = true;
                showPreorderButton = false;
                message = `Collection starts at ${col[1]}`;
              } else if (
                delivery_unavailable &&
                !collection_from &&
                !collection_unavailable
              ) {
                yield put(setSaleMethodAction(collection));
                store.set("sale_method", collection);
              } else if (collection_unavailable && delivery_from) {
                show_hide_popup_status_temp = true;
                message = `Delivery starts at ${del[1]}`;
                showPreorderButton = false;
              } else if (delivery_from && collection_from) {
                show_hide_popup_status_temp = true;
                message = `Delivery starts at ${del[1]}`;
                showPreorderButton = false;
              }
            }
          } else {
            if (response.data.open_close_status[0].MaintenanceMode) {
              show_hide_popup_status_temp = true;
              message = response.data.open_close_status[0].MaintenanceMessage;
              basket_button_title = "Order now";
            } else if (delivery_unavailable && collection_unavailable) {
              show_hide_popup_status_temp = true;
              message = `This shop currently doesn't accept order`;
              basket_button_title = "Order now";
            } else if (!response.data.open_close_status[0].PreOrder) {
              basket_button_title = "Order now";
              if (delivery_unavailable && collection_from) {
                show_hide_popup_status_temp = true;
                showPreorderButton = false;
                message = `Collection starts at ${col[1]}`;
              } else if (
                delivery_unavailable &&
                !collection_from &&
                !collection_unavailable
              ) {
                yield put(setSaleMethodAction(collection));
                store.set("sale_method", collection);
                goToCheckout = true;
              } else if (collection_unavailable && delivery_from) {
                show_hide_popup_status_temp = true;
                message = `Delivery starts at ${del[1]}`;
                showPreorderButton = false;
              } else if (delivery_from && collection_from) {
                show_hide_popup_status_temp = true;
                message = `Delivery starts at ${del[1]}`;
                showPreorderButton = false;
              } else {
                goToCheckout = true;
              }
            } else if (response.data.open_close_status[0].PreOrder) {
              goToCheckout = true;
              if (delivery_unavailable && collection_from) {
                basket_button_title = "Order for later";
              } else if (
                delivery_unavailable &&
                !collection_from &&
                !collection_unavailable
              ) {
                basket_button_title = "Order now";
              } else if (collection_unavailable && delivery_from) {
                basket_button_title = "Order for later";
              } else if (delivery_from && collection_from) {
                basket_button_title = "Order for later";
              } else {
                basket_button_title = "Order now";
              }
            }
          }

          yield put(
            getShopOpenCloseSuccessfull(
              response.data.open_close_status[0],
              show_hide_popup_status_temp,
              message,
              delivery_from,
              delivery_unavailable,
              collection_from,
              collection_unavailable,
              showPreorderButton,
              goToCheckout,
              basket_button_title
            )
          );
        } else {
          yield put(getShopOpenCloseFailed(null));
        }
      } else {
        yield put(getShopOpenCloseFailed(null));
      }
    } else {
      yield put(getShopOpenCloseFailed(null));
    }
  } catch (error) {
    // console.log("catch shop open close: ", error.message);

    yield put(getShopOpenCloseFailed(error.message));
  }
}
function* getDeliveryTime({ payload }) {
  try {
    const response = yield call(
      getDeliveryTimeService,
      payload.shop_id,
      payload.login_key,
      payload.sale_method
    );

    // console.log("Response delivery time: ", response);

    if (response.status == 200) {
      if (response.data.delivery_time_list) {
        if (response.data.delivery_time_list.length > 0) {
          let temp = [];
          for (let i = 0; i < response.data.delivery_time_list.length; i++) {
            let week_day = response.data.delivery_time_list[i].WeekDays.trim();
            temp.push({
              ...response.data.delivery_time_list[i],
              WeekDays: week_day,
              value: response.data.delivery_time_list[i].Id,
              label:
                week_day == "ASAP"
                  ? "As soon as possible"
                  : `${response.data.delivery_time_list[i].times}  ${response.data.delivery_time_list[i].WeekDays}`,
            });
          }
          yield put(getDeliveryTimeSuccessfull(temp));
        } else {
          yield put(getDeliveryTimeFailed(null));
        }
      } else {
        yield put(getDeliveryTimeFailed(null));
      }
    } else {
      yield put(getDeliveryTimeFailed(null));
    }
  } catch (error) {
    // console.log("Catch delivery time: ", error.message);

    yield put(getDeliveryTimeFailed(error.message));
  }
}
function* sendOrder({ payload }) {
  try {
    const response = yield call(
      sendOrderService,
      payload.shop_id,
      payload.login_key,
      payload.order_details
    );

    // console.log("Response Send order: ", response);

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (payload.pay_method == 2000105 && response.data.DeviceOrdersResult) {
          if (response.data.DeviceOrdersResult.length > 0) {
            store.set(
              "invoice_id",
              response.data.DeviceOrdersResult[0].ServerInvoiceId
            );

            store.set(
              "server_orderid",
              response.data.DeviceOrdersResult[0].ServerOrderId
            );
            store.set("send_price_api_call_number", 1);
            let crypto_res = "";
            var CryptoJS = require("crypto-js");

            crypto_res = CryptoJS.AES.encrypt(
              String(response.data.DeviceOrdersResult[0].ServerOrderId),
              "2023$1403@@_"
            )
              .toString()
              .replaceAll("/", "=+MmNn+=");
            yield put(
              sendPriceForPayAction(
                payload.shop_id,
                response.data.DeviceOrdersResult[0].ServerOrderId,
                payload.price,
                payload.product_name,
                `${payload.successful_url}${crypto_res}`,
                payload.failed_url,
                payload.customer_email
              )
            );
          } else {
            yield put(sendOrderFailed(null));
          }
        } else if (
          payload.pay_method == 2000102 &&
          response.data.DeviceOrdersResult
        ) {
          if (response.data.DeviceOrdersResult.length > 0) {
            store.set(
              "invoice_id",
              response.data.DeviceOrdersResult[0].ServerInvoiceId
            );
            store.set(
              "server_orderid",
              response.data.DeviceOrdersResult[0].ServerOrderId
            );
            yield put(
              sendOrderSuccessfull(
                payload.pay_method,
                response.data.DeviceOrdersResult[0].ServerOrderId
              )
            );
          } else {
            yield put(sendOrderFailed(null));
          }
        }
      } else {
        yield put(sendOrderFailed(null));
      }
    } else {
      yield put(sendOrderFailed(null));
    }
  } catch (error) {
    // console.log("Catch send order: ", error.message);

    yield put(sendOrderFailed(error.message));
  }
}
function* sendOrderEmail({ payload }) {
  try {
    const response = yield call(
      sendOrderEmailService,
      payload.shop_id,
      payload.login_key,
      payload.order_id,
      payload.role_id,
      payload.invoice_html,
      payload.customer_email,
      payload.email_subject
    );

    // console.log("Response order email: ", response);

    if (response.status == 200) {
      if (response.data) {
        if (response.data.length > 0) {
          yield put(sendOrderEmailSuccessfull(null));
        } else {
          yield put(sendOrderEmailFailed("Failed, please try again later"));
        }
      } else {
        yield put(sendOrderEmailFailed("Failed, please try again later"));
      }
    } else {
      yield put(sendOrderEmailFailed("Failed, please try again later"));
    }
  } catch (error) {
    // console.log("Catch send order email: ", error.message);
    yield put(sendOrderEmailFailed(error.message));
  }
}
function* sendPriceForPay({ payload }) {
  try {
    const response = yield call(
      sendPriceForPayService,
      payload.shop_id,
      payload.order_id,
      payload.price,
      payload.product_name,
      payload.successful_url,
      payload.failed_url,
      payload.customer_email
    );

    // console.log("Response Send price: ", response);

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(sendPriceForPaySuccessfull(response.data.url));
      } else {
        yield put(sendPriceForPayFailed(null));
      }
    } else {
      yield put(sendPriceForPayFailed(null));
    }
  } catch (error) {
    // console.log("Catch send price: ", error.message);

    yield put(sendPriceForPayFailed(error.message));
  }
}
function* getDeliveryFee({ payload }) {
  try {
    const response = yield call(
      getDeliveryFeeService,
      payload.shop_id,
      payload.login_key,
      payload.customer_id,
      payload.postcode
    );

    // console.log("Response get delivery fee: ", response);

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.delivery_fee) {
          if (response.data.delivery_fee.length > 0) {
            yield put(
              getDeliveryFeeSuccessfull(
                response.data.delivery_fee[0].DeliveryCharge
              )
            );
          } else {
            yield put(getDeliveryFeeFailed(0));
          }
        } else {
          yield put(getDeliveryFeeFailed(0));
        }
      } else {
        yield put(getDeliveryFeeFailed(0));
      }
    } else {
      yield put(getDeliveryFeeFailed(0));
    }
  } catch (error) {
    // console.log("Catch get delivery fee: ", error.message);

    yield put(getDeliveryFeeFailed(error.message));
  }
}
function* getPaymentSettings({ payload }) {
  try {
    const response = yield call(
      getPaymentSettingsService,
      payload.shop_id,
      payload.login_key
    );

    // console.log("Response get payment settings: ", response);

    if (response.status == 200) {
      if (response.data.Payment_settting) {
        if (response.data.Payment_settting.length > 0) {
          yield put(
            getPaymentSettingsSuccessfull(response.data.Payment_settting[0])
          );
        } else {
          yield put(getPaymentSettingsFailed(0));
        }
      } else {
        yield put(getPaymentSettingsFailed(0));
      }
    } else {
      yield put(getPaymentSettingsFailed(0));
    }
  } catch (error) {
    // console.log("Catch get payment settings: ", error.message);

    yield put(getPaymentSettingsFailed(error.message));
  }
}
function* getSixStep({ payload }) {
  try {
    const response = yield call(
      getSixStepService,
      payload.shop_id,
      payload.login_key,
      payload.order_id
    );

    // console.log("Response get six step: ", response);

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(getSixStepSuccessfull(response.data.Id));
      } else {
        yield put(getSixStepFailed(null));
      }
    } else {
      yield put(getSixStepFailed(null));
    }
  } catch (error) {
    // console.log("Catch get six step: ", error.message);

    yield put(getSixStepFailed(error.message));
  }
}
function* getSuccessfulOrderDetails({ payload }) {
  try {
    const response = yield call(
      getSuccessfulOrderDetailsService,
      payload.shop_id,
      payload.login_key,
      payload.order_id
    );

    // console.log("Response get successful order details: ", response);

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.order) {
          if (response.data.order.length > 0) {
            let temp_details = response.data.order[0].OrderDetail;
            let final_details = [];
            for (let i = 0; i < temp_details.length; i++) {
              let final_sub_item = [];
              // GET SUBITEM LIST
              if (temp_details[i].order_detail_subitem_list) {
                if (temp_details[i].order_detail_subitem_list.length > 0) {
                  let temp_sub_item = temp_details[i].order_detail_subitem_list;
                  for (let j = 0; j < temp_sub_item.length; j++) {
                    final_sub_item.push(temp_sub_item[j]);
                  }
                }
              }

              final_details.push({
                id: temp_details[i].Id,
                title: temp_details[i].OptionName
                  ? `${temp_details[i].OptionName} ${temp_details[i].ProductName}`
                  : temp_details[i].ProductName,
                price: temp_details[i].ProudctAmount
                  ? temp_details[i].ProudctAmount
                  : temp_details[i].OptionAmount,
                default_price: temp_details[i].ProudctAmount
                  ? temp_details[i].ProudctAmount
                  : temp_details[i].OptionAmount,
                total_basket_items_price: temp_details[i].Total,
                // 5
                product_amount: temp_details[i].ProudctAmount,
                number: temp_details[i].Count,
                sub_list: final_sub_item,
                product_group: temp_details[i].ProductGroup,

                // 10
                crust_id: temp_details[i].CrustId,
                crust_amount: temp_details[i].CrustAmount,
                crust_title: temp_details[i].CrustName,
                option_amount: temp_details[i].OptionAmount,
                option_id: temp_details[i].OptionId,
                // 15
                print_label: temp_details[i].PrintLabel,
                vat_id: temp_details[i].VatProduct,
                vat_value: temp_details[i].VatProduct,
                final_vat: temp_details[i].TotalVat,
                total_vat: temp_details[i].TotalVat,
                // 20
                option_vat: temp_details[i].VatOption,
                product_vat: temp_details[i].VatProduct,
                custom_title_in_last: temp_details[i].CustomTitleInLast,
                title_in_last: temp_details[i].TitleInLast,
                printer_id: temp_details[i].PrinterId,
                // 25
                report_section: temp_details[i].ReportSection,
                product_id: temp_details[i].ProudctId,
              });
            }

            yield put(
              getSuccessfulOrderDetailsSuccessful({
                ...response.data.order[0],
                OrderDetail: final_details,
              })
            );
          } else {
            yield put(getSuccessfulOrderDetailsFailed(null));
          }
        } else {
          yield put(getSuccessfulOrderDetailsFailed(null));
        }
      } else {
        yield put(getSuccessfulOrderDetailsFailed(null));
      }
    } else {
      yield put(getSuccessfulOrderDetailsFailed(null));
    }
  } catch (error) {
    // console.log("Catch get successful order details: ", error.message);

    yield put(getSuccessfulOrderDetailsFailed(error.message));
  }
}
function* getTerminals({ payload }) {
  try {
    const response = yield call(getTerminalListService);

    console.log("Response get get terminals: ", response);

    // if (response.status == 200) {
    //   if (response.data.ResultCode == 1) {
    //     if (response.data.order) {
    //       if (response.data.order.length > 0) {
    //         let temp_details = response.data.order[0].OrderDetail;
    //         let final_details = [];
    //         for (let i = 0; i < temp_details.length; i++) {
    //           let final_sub_item = [];
    //           // GET SUBITEM LIST
    //           if (temp_details[i].order_detail_subitem_list) {
    //             if (temp_details[i].order_detail_subitem_list.length > 0) {
    //               let temp_sub_item = temp_details[i].order_detail_subitem_list;
    //               for (let j = 0; j < temp_sub_item.length; j++) {
    //                 final_sub_item.push(temp_sub_item[j]);
    //               }
    //             }
    //           }

    //           final_details.push({
    //             id: temp_details[i].Id,
    //             title: temp_details[i].OptionName
    //               ? `${temp_details[i].OptionName} ${temp_details[i].ProductName}`
    //               : temp_details[i].ProductName,
    //             price: temp_details[i].ProudctAmount
    //               ? temp_details[i].ProudctAmount
    //               : temp_details[i].OptionAmount,
    //             default_price: temp_details[i].ProudctAmount
    //               ? temp_details[i].ProudctAmount
    //               : temp_details[i].OptionAmount,
    //             total_basket_items_price: temp_details[i].Total,
    //             // 5
    //             product_amount: temp_details[i].ProudctAmount,
    //             number: temp_details[i].Count,
    //             sub_list: final_sub_item,
    //             product_group: temp_details[i].ProductGroup,

    //             // 10
    //             crust_id: temp_details[i].CrustId,
    //             crust_amount: temp_details[i].CrustAmount,
    //             crust_title: temp_details[i].CrustName,
    //             option_amount: temp_details[i].OptionAmount,
    //             option_id: temp_details[i].OptionId,
    //             // 15
    //             print_label: temp_details[i].PrintLabel,
    //             vat_id: temp_details[i].VatProduct,
    //             vat_value: temp_details[i].VatProduct,
    //             final_vat: temp_details[i].TotalVat,
    //             total_vat: temp_details[i].TotalVat,
    //             // 20
    //             option_vat: temp_details[i].VatOption,
    //             product_vat: temp_details[i].VatProduct,
    //             custom_title_in_last: temp_details[i].CustomTitleInLast,
    //             title_in_last: temp_details[i].TitleInLast,
    //             printer_id: temp_details[i].PrinterId,
    //             // 25
    //             report_section: temp_details[i].ReportSection,
    //             product_id: temp_details[i].ProudctId,
    //           });
    //         }

    //         yield put(
    //           getSuccessfulOrderDetailsSuccessful({
    //             ...response.data.order[0],
    //             OrderDetail: final_details,
    //           })
    //         );
    //       } else {
    //         yield put(getSuccessfulOrderDetailsFailed(null));
    //       }
    //     } else {
    //       yield put(getSuccessfulOrderDetailsFailed(null));
    //     }
    //   } else {
    //     yield put(getSuccessfulOrderDetailsFailed(null));
    //   }
    // } else {
    //   yield put(getSuccessfulOrderDetailsFailed(null));
    // }
  } catch (error) {
    console.log("Catch get terminals: ", error.message);

    // yield put(getSuccessfulOrderDetailsFailed(error.message));
  }
}

export function* watchGetShopOpenClose() {
  yield takeLatest(action_type.GET_SHOP_OPEN_CLOSE, getShopOpenClose);
}
export function* watchGetDeliveryTime() {
  yield takeLatest(action_type.GET_DELIVERY_TIME, getDeliveryTime);
}
export function* watchSendOrder() {
  yield takeLatest(action_type.SEND_ORDER, sendOrder);
}
export function* watchSendPriceForPay() {
  yield takeLatest(action_type.SEND_PRICE_FOR_PAY, sendPriceForPay);
}
export function* watchGetShopInfo() {
  yield takeLatest(action_type.GET_SHOP_INFO, getShopInfo);
}
export function* watchGetDeliveryFee() {
  yield takeLatest(action_type.GET_DELIVERY_FEE_BY_POSTCODE, getDeliveryFee);
}

export function* watchGetPaymentSettings() {
  yield takeLatest(action_type.GET_PAYMENT_SETTINGS, getPaymentSettings);
}
export function* watchSendOrderEmail() {
  yield takeLatest(action_type.SEND_ORDER_EMAIL, sendOrderEmail);
}
export function* watchGetSixStep() {
  yield takeLatest(action_type.GET_SIX_STEP, getSixStep);
}
export function* watchGetSuccessfulOrderDetails() {
  yield takeLatest(
    action_type.GET_SUCCESSFUL_ORDER_DETAILS,
    getSuccessfulOrderDetails
  );
}
export function* watchGetTerminals() {
  yield takeLatest(action_type.GET_TERMINALS, getTerminals);
}

export default function* rootShopOpenClose() {
  yield all([
    fork(watchGetShopOpenClose),
    fork(watchGetDeliveryTime),
    fork(watchSendOrder),
    fork(watchSendPriceForPay),
    fork(watchGetShopInfo),
    fork(watchGetDeliveryFee),
    fork(watchGetPaymentSettings),
    fork(watchSendOrderEmail),
    fork(watchGetSixStep),
    fork(watchGetSuccessfulOrderDetails),
    fork(watchGetTerminals),
  ]);
}
