import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import store from "store2";
import moment from "moment";

import Button from "@mui/material/Button";
import {
  primary_color,
  primary_light,
  product_description_color,
  white,
  white_dark,
  black_product_title,
  black_dark,
} from "../constants/Colors";
import { pound_sign } from "../constants/String";
import { showBasketAction, setBasketListAction } from "../redux/basket/Actions";
import { delivery, collection } from "../constants/SaleMethodsId";
import { setShopIdAction } from "../redux/settings/Actions";

const MobileViewBasketButton = ({ pd_get_initial_data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    basket_content,
    basket_item_id,
    sub_total,
    sale_method,
    discount_with_mealdeals,
    discount_without_mealdeals,
  } = useSelector((state) => state.basketReduser);

  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    collectionTime,
    deliveryTime,
    delivery_time_list,
    shop_id,
    login_key,
    delivery_fee,
    carrier_bag_visible,
    carrier_bag_amount,
    carrier_bag_label,
    adminfee_visible,
    adminfee_amount,
    adminfee_label,
    cash_in_collection,
    cash_in_delivery,
    stripe_in_collection,
    stripe_in_delivery,
    paypal_in_collection,
    paypal_in_delivery,
    maintenance_mode,
    delivery_unavailable,
    collection_unavailable,
    go_to_next_route,
    loading,
  } = useSelector((state) => state.shopSettingsReduser);
  const checkBasketDataTime = () => {
    const saved_basket_time = store.get("saved_basket_time");
    if (
      moment(saved_basket_time).add(59, "minutes").format("DD-MM-YYYY HH:mm") >
      moment(new Date()).format("DD-MM-YYYY HH:mm")
    ) {
      navigate("/basket");
    } else {
      dispatch(setBasketListAction([], 0, null, [], 0, 0, 0));
      store.remove("temp_basket");
      store.remove("temp_sub_total");
      store.remove("basket_last_item_id");
      store.remove("temp_basket_product_id_list");
      store.remove("temp_no_mealdeal_sub_total");
      store.remove("discount");
      store.remove("discount_no_mealdeal");
      if (shop_id) {
        pd_get_initial_data();
      } else {
        dispatch(setShopIdAction(window.SHOP_ID));
      }
    }
  };

  return (
    <div className="button_view_basket_mobile">
      <Button
        onClick={checkBasketDataTime}
        style={{ backgroundColor: primary_color, color: white }}
      >
        <div id="mobile_view_basket_item_count">{basket_content.length}</div>
        <div>{lang.view_basket}</div>
        <div>
          {pound_sign}
          {sale_method == delivery
            ? adminfee_visible
              ? carrier_bag_visible
                ? (
                    carrier_bag_amount +
                    adminfee_amount +
                    delivery_fee +
                    sub_total -
                    discount_with_mealdeals -
                    discount_without_mealdeals
                  ).toFixed(2)
                : (
                    adminfee_amount +
                    delivery_fee +
                    sub_total -
                    discount_with_mealdeals -
                    discount_without_mealdeals
                  ).toFixed(2)
              : carrier_bag_visible
              ? (
                  carrier_bag_amount +
                  delivery_fee +
                  sub_total -
                  discount_with_mealdeals -
                  discount_without_mealdeals
                ).toFixed(2)
              : (
                  delivery_fee +
                  sub_total -
                  discount_with_mealdeals -
                  discount_without_mealdeals
                ).toFixed(2)
            : adminfee_visible
            ? carrier_bag_visible
              ? (
                  carrier_bag_amount +
                  adminfee_amount +
                  sub_total -
                  discount_with_mealdeals -
                  discount_without_mealdeals
                ).toFixed(2)
              : (
                  adminfee_amount +
                  sub_total -
                  discount_with_mealdeals -
                  discount_without_mealdeals
                ).toFixed(2)
            : carrier_bag_visible
            ? (
                carrier_bag_amount +
                sub_total -
                discount_with_mealdeals -
                discount_without_mealdeals
              ).toFixed(2)
            : (
                sub_total -
                discount_with_mealdeals -
                discount_without_mealdeals
              ).toFixed(2)}
        </div>
      </Button>
    </div>
  );
};

export default MobileViewBasketButton;
