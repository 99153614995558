export const list = [
  {
    id: 1,
    title: "Set Meal",
    text: "text text text text text text text text text text text text text text text text text text text text text text text text ",
    price: 30.0,
    url: "./image/shutterstock_1541846453.jpg",
  },
  {
    id: 2,
    title: "Tea Time Special For 1",
    text: " text text text text text text text text text text text text text text text text texttext text text text text text text text text text text text text text text text text texttext text text text text text text text text text text text text text text text text text",
    price: 8.75,
    url: "./image/30791366091261.5b0ac6a9b682a.jpg",
  },
  {
    id: 3,
    title: "All Day Special 1",
    text: "text text text text text text text text text text text text text text text text text text",
    price: 10.7,
    url: "./image/assorted-indian-recipes-food-various.jpg",
  },
  {
    id: 4,
    title: "Family Feast",
    text: " text text text text text text text text text text text text text text text texttext text text text text text text text text text text text text text text text  text text text text text text text text text text text text",
    price: 20.4,
    url: "./image/shutterstock_496658527 (2).jpg",
  },
  {
    id: 5,
    title: "Pizza Deal",
    text: "text text text text text text text text text text text text text text text text text text text text text text text text",
    price: 17.6,
    url: "./image/shutterstock_496658527.jpg",
  },
  //   {id:6,
  // title:"Pizza Deal",
  // text:"text text text text text text",
  // price:17.60,
  // url:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu3enLP9t55Tcz87Du3gz1-n9cVFKGkfembQ&usqp=CAU"
  //   }
];

export const dellivery_collection_button = [
  { id: 1, title: "Delivery", random: "40-55 mins", expanded: false },
  { id: 2, title: "Collection", random: "40-55 mins", expanded: false },
];

export const category_list = [
  { id: 1, title: "Meal Deals" },

  { id: 2, title: "Starters" },

  { id: 3, title: "Combo meals" },

  { id: 4, title: "Pizzas" },

  { id: 5, title: "Kebabs" },

  { id: 6, title: "Calzone" },

  { id: 7, title: "Popular dishes" },

  { id: 7, title: "Komo Dishes" },

  { id: 8, title: "Pizzas" },

  { id: 9, title: "Meal Deals" },

  { id: 10, title: "Pizzas" },

  { id: 11, title: "Starters" },
  { id: 12, title: "Calzone" },

  { id: 13, title: "Popular dishes" },

  { id: 14, title: "Komo Dishes" },

  { id: 15, title: "Pizzas" },

  { id: 16, title: "Meal Deals" },

  { id: 17, title: "Pizzas" },

  { id: 18, title: "Starters" },
];

export const product_list = [
  {
    id: 1,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 2,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion ",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 3,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion ",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 4,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 5,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 15,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 6,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion ",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 7,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 9,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 10,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 11,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 12,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 13,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
  {
    id: 14,
    title: "chicken",
    describtion:
      "describtion describtion describtion describtion describtion describtion describtion describtion",
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwltuPpnTfMBHA2gaBxeWv3FdVmUCQs4plttK-jKALnxZ1-dMoO7DJRLfAl-K8nJgBD10&usqp=CAU",
    price: 19.98,
  },
];
{
  /* <div className="container-serachbox">
            <Select
              options={categories}
              defaultValue={defaultValues}
              value={defaultValues}
              onChange={(list, item) => handleClickedSearchBox(list, item)}
              placeholder={<div className="select-placeholder-text"></div>}
              isSearchable={true}
              isClearable={false}
              styles={customStyles} className="select" components={{ IndicatorSeparator: () => null, DropdownIndicator }}
            />
          </div> */
}

// {product_submenu_list.map((item,index)=>(
//   <div className="product-submenu-popup-contaimer-submenus">
//        <h4>{item.WebTitle}</h4>
//  <div>
//  <input
//                    id={index}
//                    type="checkbox"
//                    value={item}
//                    style={{ margin: 0 }}
//                    checked={item.checked}
//                    onClick={() => handleCheckedCheckbox(item, index)}
//                    // checked={checkedState[index]}
//                    // onChange={() => handleOnChange(index)}
//                  />
//                  <label for={index} className="checkmark"></label>
//    </div>
//             </div>
//            ))}
{
  /* <div className="product-submenu-popup-container-radio-inputs">
              <input
                type="radio"
                name="soundsignal"
                id="soundsignal1"
                value="8"
                checked={clickedRadioButton === "8"}
                onChange={(e) => setClickedRadioButton(e.target.value)}
              />
              <label
                for="soundsignal1"
                className="product-subneu-popup-checkbox-h4"
              ></label>
              <span style={{ fontSize: "17px" }}>8"</span>
              <br />

              <input
                type="radio"
                name="soundsignal"
                id="soundsignal2"
                value="10"
                checked={clickedRadioButton === "10"}
                onChange={(e) => setClickedRadioButton(e.target.value)}
              />
              <label
                for="soundsignal2"
                className="product-subneu-popup-checkbox-h4"
              ></label>
              <span style={{ fontSize: "17px" }}>10"</span>
            </div> */
}

{
  /* <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {selectedCheckbox ? (
                            <div className="product-submenu-popup-contsiner-icons ">
                              <div
                                style={{
                                  border: clicledIcon1 ? "1px solid red" : null,
                                }}
                                className="product-submenu-popup-contsiner-icon"
                              >
                                <i
                                  className="fa fa-minus-circle"
                                  onClick={handleDecreaseAmount}
                                ></i>
                              </div>
                              <h3 style={{ margin: "0 60px" }}>{amount}</h3>
                              <div
                                className="product-submenu-popup-contsiner-icon"
                                style={{
                                  border: clicledIcon2 ? "1px solid red" : null,
                                }}
                              >
                                <i
                                  className="fa fa-plus-circle"
                                  onClick={handleIncreaseAmount}
                                ></i>
                              </div>
                            </div>
                          ) : null}
                        </div> */
}
