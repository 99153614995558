export const ENGLISH = "ENGLISH";
export const TURKISH = "TURKISH";
export const GET_TERMINALS = "GET_TERMINALS";
//CATEGORY LIST
export const CATEGORY_LIST = "CATEGORY_LIST";
export const CATEGORY_LIST_SUCCESSFULL = "CATEGORY_LIST_SUCCESSFULL";
export const CATEGORY_LIST_FAILED = "CATEGORY_LIST_FAILED";
export const SELECTED_CATEGORY = "ELECTED_CATEGORY";
//PRODUCT LIST
export const PRODUCT_LIST = "PRODUCT_LIST";
export const PRODUCT_LIST_SUCCESSFULL = "PRODUCT_LIST_SUCCESSFULL";
export const PRODUCT_LIST_FAILED = "PRODUCT_LIST_FAILED";

//PRODUCT SUBMENU LIST
export const PRODUCT_SUBMENU_LIST = "PRODUCT_SUBMENU_LIST";
export const PRODUCT_SUBMENU_LIST_SUCCESSFULL =
  "PRODUCT_SUBMENU_LIST_SUCCESSFULL";
export const PRODUCT_SUBMENU_LIST_FAILED = "PRODUCT_SUBMENU_LIST_FAILED";
export const SELECTED_PRODUCT = "SELECTED_PRODUCT";
export const NEW_PRODUCT_SUBMENU = "NEW_PRODUCT_SUBMENU";
export const NEW_PRODUCT_SUBMENU_1 = "NEW_PRODUCT_SUBMENU_1";
//PRODUCT SUBMENU LIST
export const PRODUCT_SUBMEU_LIST = "PRODUCT_SUBMEU_LIST";
export const PRODUCT_SUBMEU_LIST_SUCCESSFULL =
  "PRODUCT_SUBMEU_LIST_SUCCESSFULL";
export const PRODUCT_SUBMEU_LIST_FAILED = "PRODUCT_SUBMEU_LIST_FAILED";
//GET SUBMENU LIST
export const GET_SUBMENU_LIST = "GET_SUBMENU_LIST";
export const GET_SUBMENU_LIST_SUCCESSFULL = "GET_SUBMENU_LIST_SUCCESSFULL";
export const GET_SUBMENU_LIST_FAILED = "GET_SUBMENU_LIST_FAILED";

export const SHOW_PRODUCT_SUBMENU_POPUP = "SHOW_PRODUCT_SUBMENU_POPUP";
export const PRODUCT_OPTION_CRUST = "PRODUCT_OPTION_CRUST";

//SET DEFAULT
export const SET_DEFAULT_ACTION = "SET_DEFAULT_ACTION";

//CREATE ACCOUNT
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_ACCOUNT_SUCCESSFULL = "CREATE_ACCOUNT_SUCCESSFULL ";
export const CREATE_ACCOUNT_FAILED = "CREATE_ACCOUNT_FAILED";
//EDIT ACCOUNT
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const EDIT_ACCOUNT_SUCCESSFULL = "EDIT_ACCOUNT_SUCCESSFULL ";
export const EDIT_ACCOUNT_FAILED = "EDIT_ACCOUNT_FAILED";
//GET ADDRESSES
export const GET_ADDRESSES = "GET_ADDRESSES";
export const GET_ADDRESSES_SUCCESSFULL = "GET_ADDRESSES_SUCCESSFULL ";
export const GET_ADDRESSES_FAILED = "GET_ADDRESSES_FAILED";
//UPDATE ADDRESS
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_ADDRESS_SUCCESSFULL = "UPDATE_ADDRESS_SUCCESSFULL ";
export const UPDATE_ADDRESS_FAILED = "UPDATE_ADDRESS_FAILED";
//ADD ADDRESS
export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_ADDRESS_SUCCESSFULL = "ADD_ADDRESS_SUCCESSFULL ";
export const ADD_ADDRESS_FAILED = "ADD_ADDRESS_FAILED";

//LOGIN
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESSFULL = "LOGIN_SUCCESSFULL";
export const LOGIN_FAILED = "LOGIN_FAILED";
//LOGIN GOOGLE
export const GET_GOOGLE_USER_INFO = "GET_GOOGLE_USER_INFO";
export const GET_GOOGLE_USER_INFO_SUCCESSFULL =
  "GET_GOOGLE_USER_INFO_SUCCESSFULL";
export const GET_GOOGLE_USER_INFO_FAILED = "GET_GOOGLE_USER_INFO_FAILED";
//LOGIN FACEBOOK
export const GET_FACEBOOK_USER_INFO = "GET_FACEBOOK_USER_INFO";
export const GET_FACEBOOK_USER_INFO_SUCCESSFULL =
  "GET_FACEBOOK_USER_INFO_SUCCESSFULL";
export const GET_FACEBOOK_USER_INFO_FAILED = "GET_FACEBOOK_USER_INFO_FAILED";

// SHOW CACHE POPUP
export const SHOW_CACHE_POPUP = "SHOW_CACHE_POPUP";

// SET DEFAULT
export const SET_DEFAULT = "SET_DEFAULT";

// EPTY THE LOGIN INFO
export const EMPTY_LOGIN_INFO = "EMPTY_LOGIN_INFO";

// BASKET
export const ADD_TO_BASKET = "ADD_TO_BASKET";
export const SHOW_BASKET = "SHOW_BASKET";
export const SET_BASKET_LIST = "SET_BASKET_LIST";

export const SET_SALE_METHOD = "SET_SALE_METHOD";

// POPULAR
export const GET_POPULARS_LIST = "GET_POPULARS_LIST";
export const GET_POPULARS_LIST_SUCCESSFULL = "GET_POPULARS_LIST_SUCCESSFULL ";
export const GET_POPULARS_LIST_FAILED = "GET_POPULARS_LIST_FAILED";
// DELIVERY TIME
export const GET_DELIVERY_TIME = "GET_DELIVERY_TIME";
export const GET_DELIVERY_TIME_SUCCESSFULL = "GET_DELIVERY_TIME_SUCCESSFULL ";
export const GET_DELIVERY_TIME_FAILED = "GET_DELIVERY_TIME_FAILED";
// VERIFICATION CODE
export const SEND_VERIFICATION_CODE = "SEND_VERIFICATION_CODE";
export const SEND_VERIFICATION_CODE_SUCCESSFULL =
  "SEND_VERIFICATION_CODE_SUCCESSFULL ";
export const SEND_VERIFICATION_CODE_FAILED = "SEND_VERIFICATION_CODE_FAILED";
// GET SHOP OPEN CLOSE STATUS
export const GET_SHOP_OPEN_CLOSE = "GET_SHOP_OPEN_CLOSE";
export const GET_SHOP_OPEN_CLOSE_SUCCESSFULL =
  "GET_SHOP_OPEN_CLOSE_SUCCESSFULL ";
export const GET_SHOP_OPEN_CLOSE_FAILED = "GET_SHOP_OPEN_CLOSE_FAILED";
// GET ALL PRODUCTS
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_SUCCESSFULL = "GET_ALL_PRODUCTS_SUCCESSFULL";
export const GET_ALL_PRODUCTS_FAILED = "GET_ALL_PRODUCTS_FAILED";
// GET PAYMENT SETTINGS
export const GET_PAYMENT_SETTINGS = "GET_PAYMENT_SETTINGS";
export const GET_PAYMENT_SETTINGS_SUCCESSFULL =
  "GET_PAYMENT_SETTINGS_SUCCESSFULL";
export const GET_PAYMENT_SETTINGS_FAILED = "GET_PAYMENT_SETTINGS_FAILED";
// SEND ORDER
export const SEND_ORDER = "SEND_ORDER";
export const SEND_ORDER_SUCCESSFULL = "SEND_ORDER_SUCCESSFULL ";
export const SEND_ORDER_FAILED = "SEND_ORDER_FAILED";
// SEND PRICE FOR  PAY
export const SEND_PRICE_FOR_PAY = "SEND_PRICE_FOR_PAY";
export const SEND_PRICE_FOR_PAY_SUCCESSFULL = "SEND_PRICE_FOR_PAY_SUCCESSFULL ";
export const SEND_PRICE_FOR_PAY_FAILED = "SEND_PRICE_FOR_PAY_FAILED";
//SET DEFAULT ORDER PLACE STATUS
export const SET_DEFAULT_ORDER_PLACE_STATUS = "SET_DEFAULT_ORDER_PLACE_STATUS";

// SEND OTP
export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_SUCCESSFULL = "SEND_OTP_SUCCESSFULL";
export const SEND_OTP_FAILED = "SEND_OTP_FAILED";
// GET SHOP INFO
export const GET_SHOP_INFO = "GET_SHOP_INFO";
export const GET_SHOP_INFO_SUCCESSFULL = "GET_SHOP_INFO_SUCCESSFULL";
export const GET_SHOP_INFO_FAILED = "GET_SHOP_INFO_FAILED";
// GET DELIVERY FEE BY POSTCODE
export const GET_DELIVERY_FEE_BY_POSTCODE = "GET_DELIVERY_FEE_BY_POSTCODE";
export const GET_DELIVERY_FEE_BY_POSTCODE_SUCCESSFULL =
  "GET_DELIVERY_FEE_BY_POSTCODE_SUCCESSFULL";
export const GET_DELIVERY_FEE_BY_POSTCODE_FAILED =
  "GET_DELIVERY_FEE_BY_POSTCODE_FAILED";
// GET CUSTOMER ORDERS LIST
export const GET_CUSTOMER_ORDERS_LIST = "GET_CUSTOMER_ORDERS_LIST";
export const GET_CUSTOMER_ORDERS_LIST_SUCCESSFULL =
  "GET_CUSTOMER_ORDERS_LIST_SUCCESSFULL";
export const GET_CUSTOMER_ORDERS_LIST_FAILED =
  "GET_CUSTOMER_ORDERS_LIST_FAILED";
// CHECK POSCTOCE
export const CHECK_POSTCODE = "CHECK_POSTCODE";
export const CHECK_POSTCODE_SUCCESSFULL = "CHECK_POSTCODE_SUCCESSFULL";
export const CHECK_POSTCODE_FAILED = "CHECK_POSTCODE_FAILED";
// RESET PASSWORD
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESSFULL = "RESET_PASSWORD_SUCCESSFULL";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
// GET SUCCESSFUL ORDER DETAILS
export const GET_SUCCESSFUL_ORDER_DETAILS = "GET_SUCCESSFUL_ORDER_DETAILS";
export const GET_SUCCESSFUL_ORDER_DETAILS_SUCCESSFULL =
  "GET_SUCCESSFUL_ORDER_DETAILS_SUCCESSFULL";
export const GET_SUCCESSFUL_ORDER_DETAILS_FAILED =
  "GET_SUCCESSFUL_ORDER_DETAILS_FAILED";
// GET CUSTOMER ORDERS LIST
export const GET_CUSTOMER_ORDER_DETAILS = "GET_CUSTOMER_ORDER_DETAILS";
export const GET_CUSTOMER_ORDER_DETAILS_SUCCESSFULL =
  "GET_CUSTOMER_ORDER_DETAILS_SUCCESSFULL";
export const GET_CUSTOMER_ORDER_DETAILS_FAILED =
  "GET_CUSTOMER_ORDER_DETAILS_FAILED";
//ORDE EMAIL
export const SEND_ORDER_EMAIL = "SEND_ORDER_EMAIL";
export const SEND_ORDER_EMAIL_SUCCESSFULL = "SEND_ORDER_EMAIL_SUCCESSFULL";
export const SEND_ORDER_EMAIL_FAILED = "SEND_ORDER_EMAIL_FAILED";
//ORDE FORGET PASSWORDEMAIL
export const SEND_FORGET_PASSWORD_EMAIL = "SEND_FORGET_PASSWORD_EMAILL";
export const SEND_FORGET_PASSWORD_EMAIL_SUCCESSFULL =
  "SEND_FORGET_PASSWORD_EMAIL_SUCCESSFULL";
export const SEND_FORGET_PASSWORD_EMAIL_FAILED =
  "SEND_FORGET_PASSWORD_EMAIL_FAILED";
//SIX STEP
export const GET_SIX_STEP = "GET_SIX_STEP";
export const GET_SIX_STEP_SUCCESSFULL = "GET_SIX_STEP_SUCCESSFULL";
export const GET_SIX_STEP_FAILED = "GET_SIX_STEP_FAILED";
//VERIFY RESET PASSWORD LINK
export const VERIFY_RESET_PASSWORD_LINK = "VERIFY_RESET_PASSWORD_LINK";
export const VERIFY_RESET_PASSWORD_LINK_SUCCESSFULL =
  "VERIFY_RESET_PASSWORD_LINK_SUCCESSFULL";
export const VERIFY_RESET_PASSWORD_LINK_FAILED =
  "VERIFY_RESET_PASSWORD_LINK_FAILED";
// SINGLE SET
export const SET_ORDER_REQUIRED_INFO = "SET_ORDER_REQUIRED_INFO";

export const SET_USER_ENTERED_IN = "SET_USER_ENTERED_IN";

export const SET_MOBILE_PRODUCT_LIST = "SET_MOBILE_PRODUCT_LIST";
export const SHOW_MAINTENANCE_MODE_POPUP = "SHOW_MAINTENANCE_MODE_POPUP";
export const SET_MAINTENANCE_MODE_POPUP_MESSAGE =
  "SET_MAINTENANCE_MODE_POPUP_MESSAGE";
export const SET_PREORDER_PRESSESD = "SET_PREORDER_PRESSESD";
export const PASS_NAVIGATE = "PASS_NAVIGATE";
export const DROPDOWN_CLICKED = "DROPDOWN_CLICKED";
export const ORDER_DETAILS_OPENED = "ORDER_DETAILS_OPENED";
export const SELECTED_CUSTOMER_ORDER = "SELECTED_CUSTOMER_ORDER";
export const SET_SHOP_ID = "SET_SHOP_ID";
