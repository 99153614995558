import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast, Toaster } from "react-hot-toast";

import LoaderMini from "../Componenets/LoderMini";
import Loader from "../Componenets/Loader";

import {
  primary_color,
  primary_light,
  product_description_color,
  white,
  white_dark,
  black_product_title,
  black_dark,
} from "../constants/Colors";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ChangePassword = ({ hide_change_password, change_password }) => {
  const dispatch = useDispatch();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { user_info, sign_up_loading } = useSelector(
    (state) => state.profileReduser
  );

  const handleChangePassword = () => {
    if (currentPassword == user_info.Pass) {
      if (newPassword.split("").length < 4) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.password_atleast_4_characters}&nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                class="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        if (newPassword == currentPassword) {
          toast.error(
            <h5>
              {" "}
              &nbsp;&nbsp;{lang.new_password_must_not_be_same_old_password}
              &nbsp;&nbsp;{" "}
            </h5>,
            {
              position: "top-center",
              duration: 3000,
              style: { backgroundColor: "red", color: "white" },
              icon: (
                <i
                  style={{ fontSize: 40 }}
                  class="fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              ),
            }
          );
        } else {
          if (newPassword == repeatNewPassword) {
            change_password(newPassword);
          } else {
            toast.error(
              <h5>
                {" "}
                &nbsp;&nbsp;{lang.new_password_is_not_same_repeating}
                &nbsp;&nbsp;{" "}
              </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    class="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          }
        }
      }
    } else {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.current_password_incorrect}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    }
  };

  return (
    <div
      className="create_shop_popup_container"
      onClick={() => hide_change_password()}
    >
      <div
        className="change_pass_content_container"
        onClick={(i) => i.stopPropagation()}
      >
        <div style={{ width: "100%" }}>
          <div className="change_password_inputs_container">
            <h6>{lang.change_password}</h6>
            <div className="change_password_textfield_container">
              <TextField
                value={currentPassword}
                id="outlined-basic"
                label={lang.current_password}
                variant="outlined"
                fullWidth
                onChange={(e) => setCurrentPassword(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                }}
                InputProps={{
                  className: "profile_input",
                  // sx: {
                  //   fontSize: 16,
                  //   height: 50,
                  // },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: window.innerWidth > 1400 ? 16 : 14,
                    top: window.innerWidth > 1400 ? -1 : -4,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primary_color,
                    },
                  },
                }}
              />
            </div>
            <div className="change_password_textfield_container">
              <TextField
                value={newPassword}
                id="outlined-basic"
                label={lang.new_password}
                variant="outlined"
                fullWidth
                onChange={(e) => setNewPassword(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                }}
                InputProps={{
                  className: "profile_input",
                  // sx: {
                  //   fontSize: 16,
                  //   height: 50,
                  // },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: window.innerWidth > 1400 ? 16 : 14,
                    top: window.innerWidth > 1400 ? -1 : -4,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primary_color,
                    },
                  },
                }}
              />
            </div>
            <div className="change_password_textfield_container">
              <TextField
                value={repeatNewPassword}
                id="outlined-basic"
                label={lang.repeat_new_password}
                variant="outlined"
                fullWidth
                onChange={(e) => setRepeatNewPassword(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: primary_color,
                    },
                  },
                }}
                InputProps={{
                  className: "profile_input",
                  // sx: {
                  //   fontSize: 16,
                  //   height: 50,
                  // },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: window.innerWidth > 1400 ? 16 : 14,
                    top: window.innerWidth > 1400 ? -1 : -4,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primary_color,
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        {/* FOOTER */}
        <div className="change_password_button_container">
          <button
            disabled={sign_up_loading ? true : false}
            onClick={handleChangePassword}
          >
            {sign_up_loading ? <LoaderMini /> : lang.save}
          </button>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default ChangePassword;
