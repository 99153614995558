import { combineReducers } from "redux";
import categoriesRedusers from "./Categories/Redusers";
import productReduser from "./Products/Redusers";
import selectedLanguage from "./language/Redusers";
import profileReduser from "./Profile/Redusers";
import basketReduser from "./basket/Reducers";
import shopSettingsReduser from "./settings/Reducers";
export const Redusers = combineReducers({
  categoriesRedusers,
  productReduser,
  selectedLanguage,
  productReduser,
  profileReduser,
  basketReduser,
  shopSettingsReduser,
});
