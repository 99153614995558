import axios from "axios";
import { baseUrl, baseUrl2 } from "../constants/BaseUrl";

export const signUpServices = async (
  login_key,
  shop_id,
  first_name,
  last_name,
  email,
  address,
  password,
  account_type
) => {
  return await axios.post(`${baseUrl}sitesapies/signUpCustomer/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    FirstName: first_name,
    LastName: last_name,
    Email: email,
    Address: address,
    Password: password,
    AccountType: account_type,
  });
};
export const loginServices = async (
  login_key,
  shop_id,
  email,
  password,
  account_type
) => {
  return await axios.put(`${baseUrl}sitesapies/loginCustomer/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    Email: email,
    Password: password,
    AccountType: account_type,
  });
};
export const editAccountService = async (
  shop_id,
  login_key,
  customer_id,
  first_name,
  last_name,
  mobile,
  addetional,
  email,
  password,
  is_verified
) => {
  return await axios.put(`${baseUrl}sitesapies/customerEditProfile/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    CustomerId: customer_id,
    FirstName: first_name,
    LastName: last_name,
    Mobile: mobile,
    Addetional: addetional,
    Email: email,
    Password: password,
    IsVerified: is_verified,
  });
};

export const addAddressService = async (
  shop_id,
  login_key,
  customer_id,
  address,
  postcode,
  city,
  flat
) => {
  return await axios.put(`${baseUrl}sitesapies/addCustomerAddress/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    CustomerId: customer_id,
    Address: address,
    Postcode: postcode,
    City: city,
    Flat: flat,
  });
};
export const updateAddressService = async (
  shop_id,
  login_key,
  customer_id,
  customer_address_id,
  address,
  postcode,
  city,
  flat
) => {
  return await axios.put(`${baseUrl}sitesapies/updateCustomerAddress/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    CustomerId: customer_id,
    CustomerAddressId: customer_address_id,
    Address: address,
    Postcode: postcode,
    City: city,
    Flat: flat,
  });
};
export const resetPasswordService = async (shop_id, login_key, email) => {
  return await axios.put(`${baseUrl}sitesapies/forgotPasswordCustomer/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    Email: email,
  });
};
export const verifyResetPasswordLinkService = async (
  shop_id,
  login_key,
  customer_id,
  token
) => {
  return await axios.put(`${baseUrl}sitesapies/verifyResetPasswordCustomer/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    CustomerId: customer_id,
    Token: token,
  });
};
export const sendForgetPasswordEmailService = async (
  shop_id,
  login_key,
  role_id,
  html_content,
  email,
  subject
) => {
  return await axios.put(`${baseUrl2}sitesapies/sendForgotPasswordEmail`, {
    ShopId: shop_id,
    LoginKey: login_key,
    RoleId: role_id,
    InvoiceHtml: html_content,
    CustomerEmail: email,
    EmailSubject: subject,
  });
};
export const getAddressesService = async (shop_id, login_key, customer_id) => {
  return await axios.put(`${baseUrl}sitesapies/customerAddressList/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    CustomerId: customer_id,
  });
};
export const sendOtpService = async (shop_id, send_to, customer_id) => {
  return await axios.put(`${baseUrl}senderdashboard/sendOtpCode/`, {
    ShopId: shop_id,
    RoleId: 7,
    SendTo: send_to,
    WorkstationId: 0,
    CustomerId: customer_id,
  });
};
export const sendVerificationCodeService = async (otp_code, mobile) => {
  return await axios.put(`${baseUrl}senderdashboard/verifyOtpCode/`, {
    OtpCode: String(otp_code),
    RoleId: 7,
    Mobile: mobile,
  });
};
export const getCustomerOrdersListService = async (
  shop_id,
  login_key,
  customer_id
) => {
  return await axios.put(`${baseUrl}sitesapies/orderListByCustomer/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    CustomerId: customer_id,
  });
};
export const getCustomerOrderDetailsService = async (
  shop_id,
  login_key,
  order_id
) => {
  return await axios.put(`${baseUrl}sitesapies/orderDetailListByCustomer/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    OrderId: order_id,
  });
};
export const checkPostcodeService = async (shop_id, login_key, postcode) => {
  return await axios.put(`${baseUrl}sitesapies/searchPostcode/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    CustomerPostcode: postcode,
  });
};
export const getGoogleUserInfoService = async (access_token) => {
  return await axios.get(
    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
        Accept: "application/json",
      },
    }
  );
};
