export const english = {
  add: "Add",
  add_address: "Add address",
  add_new_address: "Add new address",
  account: "Account",
  addresses: "Addresses",
  address_postcode: "Address / Postcode",
  addresses_list: "Addresses List",
  address: "Address",
  about_real_spice: "About Real Spice",
  about_us: "About us",
  all_fields_required: "All fields are required",
  basket_is_empty: "Basket is Empty",
  continue: "Continue",
  continue_with_google: "Continue with Google",
  continue_with_apple: "Continue with Apple",
  continue_with_facebook: "Continue with Facebook",
  categories: "Categoeies",
  choose_size: " Choose Size",
  check_out: " Check Out",
  create_account: "Create Account",
  collection: "Collection",
  collection_time: "Collection Time",
  confirm_order: "Confirm order",
  change_password: "Change password",
  current_password: "Current password",
  current_password_incorrect: "Current password is incorrect",
  customer_info: "Customer info",
  city: "City",
  cash_or_card: "Cash or Card",
  contact_us: "Contact us",
  copy_right: "Copyright @ 2023",
  details: "Details",
  discount: "Discount",
  delivey_fee: "Delivery fee",
  delivey_time: "Delivery time",
  delivery: "Delivery",
  delivering_to: "Delivering to",
  download_our_apps: "Download our apps",
  download_our_apps: "Download our apps",
  email: "Email",
  email_address: "Email address",
  edit_address: "Edit address",
  enter_5_digit_code: "Enter 5 digit code sent to:",
  enter_your_email_address: "Enter your email address",
  first_name: "First name",
  forgot_pass: "Forgot password?",
  feed_back: "Feedback",
  follow_us: "Follow us",
  flat: "Flat",
  home_road: " 6Home Road ,Cubernauld.G67 1AP",
  help_us: "Help us improve our website reviews",
  title: "Title",
  total: "Total",
  the_phone_number_must_be_11_digit: "The phone number must be 11 digit number",
  the_phone_number_havero_start_by_07: "The phone number have to start by 07",
  go_to_payment: "Go to payment",
  cache: "Cache $25",
  mins_delivery: "50 Mins delivery",
  mins_collection: "Mins collection ",
  min_select: "Min Select",
  max_select: "Max Select",
  my_orders: "My orders",
  menu: "Menu",
  mobile_number: "Mobile number",
  optional: " Optional",
  last_name: "Last name",
  post_code: "Postcode",
  password: "Password",
  password_atleast_4_characters: "Password must be at least 4 characters long",
  popular: "Popular",
  profile: "Profile",
  phone_number: "Phone number",
  please_complate_postcode: "Please complate the postcode",
  pay_by_card: "Pay by credit or debit card",
  pay_by_card2: "Pay by card",
  pay_by_cash: "Pay by cash",
  payment_method: "Payment Method",
  log_in: "Log in",
  login_successfull: "Login successfull",
  login_failed: "Username or password incorrect",
  logout: "Logout",
  new_password: "New password",
  new_password_must_not_be_same_old_password:
    "Your new password must not be the same as your current password",
  new_password_is_not_same_repeating:
    "A new password is not the same as repeating it",
  no_address_found: "Please add an address",
  order_failed_message: "Due to some errors your order failed",
  order_id: "Order Id",
  order_date: "Order Date",
  order_amount: "Order Amount",
  order_now: "Order Now",
  remember_me: "Remember me",
  have_account: "Dont have an account?",
  home: "Home",
  required: "Required",
  request_new_link: "Request new link",
  re_order: "Reorder",
  reset_password: "Reset Password",
  reset_password_message: "We will send you an email contain a reset link",
  reset_password_successfull_message:
    "Your new password has been successfully saved",
  real_spice_cubernauld: " Real Spice Cubernauld",
  repeat_new_password: "Repeat new password",
  results_for: "results for",
  result_for: "result for",
  rejected_message: "Unfortunately your order was rejected by the shop",
  sign_up: "Sign up",
  sign_in: "Sign in",
  subtotal: "Sub total",
  suggested: "Suggested",
  save: "Save",
  sale_method: "Sale Method",
  send: "Send",
  special_note: "Special note",
  user_details: "User details",
  update_your_password: "Update your password",
  update_password_message: "Password must be at least 6 characters long",
  icorrect_email_format: "The email format is incorrect",
  our_location: "Our location",
  view_basket: "View basket",
  your_order: "Your order",
  your_order_has_been_palced: "Your order has been placed",
};
export const turkish = {};
export const pound_sign = "€";
