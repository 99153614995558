import * as action_types from "../ActionType";

// DROP DOWN CLICKED
export const setDropDownClickedAction = (data) => {
  return {
    type: action_types.DROPDOWN_CLICKED,
    payload: data,
  };
};
export const createAccountAction = (
  login_key,
  shop_id,
  first_name,
  last_name,
  email,
  address,
  password,
  account_type
) => {
  return {
    type: action_types.CREATE_ACCOUNT,
    payload: {
      login_key,
      shop_id,
      first_name,
      last_name,
      email,
      address,
      password,
      account_type,
    },
  };
};
export const createAccountSuccessfullAction = (user_info, message) => {
  return {
    type: action_types.CREATE_ACCOUNT_SUCCESSFULL,
    payload: { user_info, message },
  };
};
export const createAccountFailedAction = (data) => {
  return {
    type: action_types.CREATE_ACCOUNT_FAILED,
    payload: data,
  };
};
// EDIT ACCOUNT
export const editAccountAction = (
  shop_id,
  login_key,
  customer_id,
  first_name,
  last_name,
  mobile,
  addetional,
  email,
  password,
  is_verified
) => {
  return {
    type: action_types.EDIT_ACCOUNT,
    payload: {
      shop_id,
      login_key,
      customer_id,
      first_name,
      last_name,
      mobile,
      addetional,
      email,
      password,
      is_verified,
    },
  };
};
export const editAccountSuccessfull = (customer_info, result_message) => {
  return {
    type: action_types.EDIT_ACCOUNT_SUCCESSFULL,
    payload: { customer_info, result_message },
  };
};
export const editAccountFailed = (data) => {
  return {
    type: action_types.EDIT_ACCOUNT_FAILED,
    payload: data,
  };
};
// *****
// ADD ADDRESS
export const addAddressAction = (
  shop_id,
  login_key,
  customer_id,
  address,
  postcode,
  city,
  flat
) => {
  return {
    type: action_types.ADD_ADDRESS,
    payload: {
      shop_id,
      login_key,
      customer_id,
      address,
      postcode,
      city,
      flat,
    },
  };
};
export const addAddressSuccessfull = (list, message) => {
  return {
    type: action_types.ADD_ADDRESS_SUCCESSFULL,
    payload: { list, message },
  };
};
export const addAddressFailed = (data) => {
  return {
    type: action_types.ADD_ADDRESS_FAILED,
    payload: data,
  };
};
// *****
// UPDATE ADDRESS
export const updateAddressAction = (
  shop_id,
  login_key,
  customer_id,
  customer_address_id,
  address,
  postcode,
  city,
  flat
) => {
  return {
    type: action_types.UPDATE_ADDRESS,
    payload: {
      shop_id,
      login_key,
      customer_id,
      customer_address_id,
      address,
      postcode,
      city,
      flat,
    },
  };
};
export const updateAddressSuccessfull = (list, message) => {
  return {
    type: action_types.UPDATE_ADDRESS_SUCCESSFULL,
    payload: { list, message },
  };
};
export const updateAddressFailed = (data) => {
  return {
    type: action_types.UPDATE_ADDRESS_FAILED,
    payload: data,
  };
};
// RESET PASSWORD
export const resetPasswordAction = (shop_id, login_key, email, shop_domain) => {
  return {
    type: action_types.RESET_PASSWORD,
    payload: {
      shop_id,
      login_key,
      email,
      shop_domain,
    },
  };
};
export const resetPasswordSuccessfull = (data) => {
  return {
    type: action_types.RESET_PASSWORD_SUCCESSFULL,
    payload: data,
  };
};
export const resetPasswordFailed = (data) => {
  return {
    type: action_types.RESET_PASSWORD_FAILED,
    payload: data,
  };
};
//VERIFY RESET PASSWORD LINK
export const verifyResetPasswordLinkAction = (
  shop_id,
  login_key,
  customer_id,
  token
) => {
  return {
    type: action_types.VERIFY_RESET_PASSWORD_LINK,
    payload: {
      shop_id,
      login_key,
      customer_id,
      token,
    },
  };
};
export const verifyResetPasswordLinkSuccessfull = (data) => {
  return {
    type: action_types.VERIFY_RESET_PASSWORD_LINK_SUCCESSFULL,
    payload: data,
  };
};
export const verifyResetPasswordLinkFailed = (data) => {
  return {
    type: action_types.VERIFY_RESET_PASSWORD_LINK_FAILED,
    payload: data,
  };
};
// SEND FORGET  PASSWORD EMAIL
export const sendForgetPasswordEmailAction = (
  shop_id,
  login_key,
  role_id,
  html_content,
  email,
  subject
) => {
  return {
    type: action_types.SEND_FORGET_PASSWORD_EMAIL,
    payload: {
      shop_id,
      login_key,
      role_id,
      html_content,
      email,
      subject,
    },
  };
};
export const sendForgetPasswordEmailSuccessfull = (data) => {
  return {
    type: action_types.SEND_FORGET_PASSWORD_EMAIL_SUCCESSFULL,
    payload: data,
  };
};
export const sendForgetPasswordEmailFailed = (data) => {
  return {
    type: action_types.SEND_FORGET_PASSWORD_EMAIL_FAILED,
    payload: data,
  };
};
// *****
// GET CUSTOMER ORDERS LIST
export const getCustomerOrdersListAction = (
  shop_id,
  login_key,
  customer_id
) => {
  return {
    type: action_types.GET_CUSTOMER_ORDERS_LIST,
    payload: {
      shop_id,
      login_key,
      customer_id,
    },
  };
};
export const getCustomerOrdersListSuccessfull = (data) => {
  return {
    type: action_types.GET_CUSTOMER_ORDERS_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getCustomerOrdersListFailed = (data) => {
  return {
    type: action_types.GET_CUSTOMER_ORDERS_LIST_FAILED,
    payload: data,
  };
};
// GET CUSTOMER ORDERS LIST
export const getCustomerOrderDetailsAction = (shop_id, login_key, order_id) => {
  return {
    type: action_types.GET_CUSTOMER_ORDER_DETAILS,
    payload: {
      shop_id,
      login_key,
      order_id,
    },
  };
};
export const getCustomerOrderDetailsSuccessfull = (data) => {
  return {
    type: action_types.GET_CUSTOMER_ORDER_DETAILS_SUCCESSFULL,
    payload: data,
  };
};
export const getCustomerOrderDetailsFailed = (data) => {
  return {
    type: action_types.GET_CUSTOMER_ORDER_DETAILS_FAILED,
    payload: data,
  };
};
// GET ADDRESSES LIST
export const getAddressesListAction = (shop_id, login_key, customer_id) => {
  return {
    type: action_types.GET_ADDRESSES,
    payload: {
      shop_id,
      login_key,
      customer_id,
    },
  };
};
export const getAddressesListSuccessfull = (data) => {
  return {
    type: action_types.GET_ADDRESSES_SUCCESSFULL,
    payload: data,
  };
};
export const getAddressesListFailed = (data) => {
  return {
    type: action_types.GET_ADDRESSES_FAILED,
    payload: data,
  };
};
// *****
// SEND OTP
export const sendOtpAction = (shop_id, number, customer_id) => {
  return {
    type: action_types.SEND_OTP,
    payload: {
      shop_id,
      number,
      customer_id,
    },
  };
};
export const sendOtpSuccessfull = (data) => {
  return {
    type: action_types.SEND_OTP_SUCCESSFULL,
    payload: data,
  };
};
export const sendOtpFailed = (data) => {
  return {
    type: action_types.SEND_OTP_FAILED,
    payload: data,
  };
};
// *****

// LOGIN
export const loginAction = (
  login_key,
  shop_id,
  email,
  password,
  account_type
) => {
  return {
    type: action_types.LOGIN,
    payload: { login_key, shop_id, email, password, account_type },
  };
};
export const loginSuccessfullAction = (data, isFromSaga) => {
  return {
    type: action_types.LOGIN_SUCCESSFULL,
    payload: { data, isFromSaga },
  };
};
export const loginFailedAction = (data, isOldCustomer) => {
  return {
    type: action_types.LOGIN_FAILED,
    payload: { data, isOldCustomer },
  };
};
// GET GOOGLE USER INFO
export const getGoogleUserInfoAction = (access_token, login_key, shop_id) => {
  return {
    type: action_types.GET_GOOGLE_USER_INFO,
    payload: { access_token, login_key, shop_id },
  };
};
export const getGoogleUserInfoSuccessful = (data) => {
  return {
    type: action_types.GET_GOOGLE_USER_INFO_SUCCESSFULL,
    payload: data,
  };
};
export const getGoogleUserInfoFailed = (data) => {
  return {
    type: action_types.GET_GOOGLE_USER_INFO_FAILED,
    payload: data,
  };
};
// GET FACEBBOK USER INFO
export const getFacebookUserInfoAction = (access_token, login_key, shop_id) => {
  return {
    type: action_types.GET_FACEBOOK_USER_INFO,
    payload: { access_token, login_key, shop_id },
  };
};
export const getFacebookUserInfoSuccessful = (data) => {
  return {
    type: action_types.GET_FACEBOOK_USER_INFO_SUCCESSFULL,
    payload: data,
  };
};
export const getFacebookUserInfoFailed = (data) => {
  return {
    type: action_types.GET_FACEBOOK_USER_INFO_FAILED,
    payload: data,
  };
};

export const setDefaultAction = () => {
  return {
    type: action_types.SET_DEFAULT,
  };
};
export const emptyLoginInfoAction = () => {
  return {
    type: action_types.EMPTY_LOGIN_INFO,
  };
};
// SEND VERIFICATION CODE
export const sendVerificationCodeAction = (otp_code, mobile) => {
  return {
    type: action_types.SEND_VERIFICATION_CODE,
    payload: {
      otp_code,
      mobile,
    },
  };
};
export const sendVerificationCodeSuccessfull = (data) => {
  return {
    type: action_types.SEND_VERIFICATION_CODE_SUCCESSFULL,
    payload: data,
  };
};
export const sendVerificationCodeFailed = (data) => {
  return {
    type: action_types.SEND_VERIFICATION_CODE_FAILED,
    payload: data,
  };
};
// *****
// CHECK POSTCODE
export const checkPostcodeAction = (shop_id, login_key, postcode) => {
  return {
    type: action_types.CHECK_POSTCODE,
    payload: {
      shop_id,
      login_key,
      postcode,
    },
  };
};
export const checkPostcodeSuccessful = (data) => {
  return {
    type: action_types.CHECK_POSTCODE_SUCCESSFULL,
    payload: data,
  };
};
export const checkPostcodeFailed = (data) => {
  return {
    type: action_types.CHECK_POSTCODE_FAILED,
    payload: data,
  };
};
// *****

export const orderDetailsOpenAction = (data) => {
  return {
    type: action_types.ORDER_DETAILS_OPENED,
    payload: data,
  };
};
export const selectedCustomerOrderAction = (data) => {
  return {
    type: action_types.SELECTED_CUSTOMER_ORDER,
    payload: data,
  };
};
