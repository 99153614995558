import * as action_type from "../ActionType";
import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import store from "store2";
import {
  createAccountFailedAction,
  createAccountSuccessfullAction,
  loginSuccessfullAction,
  loginFailedAction,
  editAccountSuccessfull,
  editAccountFailed,
  addAddressSuccessfull,
  addAddressFailed,
  getAddressesListSuccessfull,
  getAddressesListFailed,
  updateAddressSuccessfull,
  updateAddressFailed,
  sendOtpSuccessfull,
  sendOtpFailed,
  sendVerificationCodeSuccessfull,
  sendVerificationCodeFailed,
  getGoogleUserInfoFailed,
  createAccountAction,
  getCustomerOrdersListSuccessfull,
  getCustomerOrdersListFailed,
  getCustomerOrderDetailsSuccessfull,
  getCustomerOrderDetailsFailed,
  getAddressesListAction,
  resetPasswordSuccessfull,
  resetPasswordFailed,
  sendForgetPasswordEmailSuccessfull,
  sendForgetPasswordEmailFailed,
  sendForgetPasswordEmailAction,
  verifyResetPasswordLinkSuccessfull,
  verifyResetPasswordLinkFailed,
  checkPostcodeSuccessful,
  checkPostcodeFailed,
} from "./Actions";
import {
  getDeliveryFeeSuccessfull,
  sendOrderEmailAction,
  getDeliveryFeeAction,
} from "../settings/Actions";
import {
  signUpServices,
  loginServices,
  editAccountService,
  getAddressesService,
  updateAddressService,
  addAddressService,
  sendOtpService,
  sendVerificationCodeService,
  getGoogleUserInfoService,
  getCustomerOrdersListService,
  getCustomerOrderDetailsService,
  resetPasswordService,
  sendForgetPasswordEmailService,
  verifyResetPasswordLinkService,
  checkPostcodeService,
} from "../../services/ProfileServices";

function* signUp({ payload }) {
  try {
    const response = yield call(
      signUpServices,
      payload.login_key,
      payload.shop_id,
      payload.first_name,
      payload.last_name,
      payload.email,
      payload.address,
      payload.password,
      payload.account_type
    );
    // console.log("Response signup: ", response);

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        store.set("user_info", response.data.Customer_info[0]);
        yield put(
          createAccountSuccessfullAction(
            response.data.Customer_info[0],
            response.data.ResultMessage
          )
        );
      } else {
        yield put(createAccountFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(createAccountFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    // console.log("Catch signup: ", error.message);

    yield put(createAccountFailedAction(error.message));
  }
}
function* editAccount({ payload }) {
  try {
    const response = yield call(
      editAccountService,
      payload.shop_id,
      payload.login_key,
      payload.customer_id,
      payload.first_name,
      payload.last_name,
      payload.mobile,
      payload.addetional,
      payload.email,
      payload.password,
      payload.is_verified
    );
    // console.log("response edit account");
    // console.log(response);
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.CustomerInfo) {
          if (response.data.CustomerInfo.length > 0) {
            store.set("user_info", response.data.CustomerInfo[0]);
            yield put(
              editAccountSuccessfull(
                response.data.CustomerInfo[0],
                response.data.ResultMessage
              )
            );
          } else {
            yield put(editAccountFailed(response.data.ResultMessage));
          }
        } else {
          yield put(editAccountFailed(response.data.ResultMessage));
        }
      } else {
        yield put(editAccountFailed(response.data.ResultMessage));
      }
    } else {
      yield put(editAccountFailed(response.data.ResultMessage));
    }
  } catch (error) {
    // console.log("Catch edit account");
    // console.log(error.message);
    yield put(editAccountFailed(error.message));
  }
}
function* login({ payload }) {
  try {
    const response = yield call(
      loginServices,
      payload.login_key,
      payload.shop_id,
      payload.email,
      payload.password,
      payload.account_type
    );
    // console.log("login response: ", response);

    if (response.status == 200) {
      if (response.data.Customer_Login) {
        if (response.data.Customer_Login[0].IsConvert) {
          yield put(loginFailedAction("", true));
        } else {
          yield put(
            loginSuccessfullAction(response.data.Customer_Login[0], true)
          );
          yield put(
            getAddressesListAction(
              payload.shop_id,
              payload.login_key,
              response.data.Customer_Login[0].CustomerId
            )
          );
        }
      } else {
        yield put(loginFailedAction("Username or password incorrect", false));
      }
    } else {
      yield put(loginFailedAction("Username or password incorrect", false));
    }
  } catch (error) {
    // console.log("Catch login", error.message);
    yield put(loginFailedAction(error.message));
  }
}
function* getCustomerOrdersList({ payload }) {
  try {
    const response = yield call(
      getCustomerOrdersListService,
      payload.shop_id,
      payload.login_key,
      payload.customer_id
    );
    // console.log("response get customer orders list", response);
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.order_list) {
          if (response.data.order_list.length > 0) {
            yield put(
              getCustomerOrdersListSuccessfull(
                response.data.order_list.reverse()
              )
            );
          } else {
            yield put(getCustomerOrdersListFailed(response.data.ResultMessage));
          }
        } else {
          yield put(getCustomerOrdersListFailed(response.data.ResultMessage));
        }
      } else {
        yield put(getCustomerOrdersListFailed(response.data.ResultMessage));
      }
    } else {
      yield put(getCustomerOrdersListFailed(response.data.ResultMessage));
    }
  } catch (error) {
    // console.log("catch get customer orders list");
    // console.log(error.message);
    yield put(getCustomerOrdersListFailed(error.message));
  }
}
function* getCustomerOrderDetails({ payload }) {
  try {
    const response = yield call(
      getCustomerOrderDetailsService,
      payload.shop_id,
      payload.login_key,
      payload.order_id
    );
    // console.log("response get customer order details", response);
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Order_Detail_List) {
          if (response.data.Order_Detail_List.length > 0) {
            let temp_details = response.data.Order_Detail_List;
            let final_details = [];
            for (let i = 0; i < temp_details.length; i++) {
              let final_sub_item = [];

              // GET SUBITEM LIST
              if (temp_details[i].order_detail_subitem_list) {
                if (temp_details[i].order_detail_subitem_list.length > 0) {
                  let temp_sub_item = temp_details[i].order_detail_subitem_list;
                  for (let j = 0; j < temp_sub_item.length; j++) {
                    final_sub_item.push(temp_sub_item[j]);
                  }
                }
              }
              final_details.push({
                id: temp_details[i].Id,
                title: temp_details[i].OptionName
                  ? `${temp_details[i].OptionName}${temp_details[i].ProductName}`
                  : temp_details[i].ProductName,
                price: temp_details[i].ProudctAmount
                  ? temp_details[i].ProudctAmount
                  : temp_details[i].OptionAmount,
                default_price: temp_details[i].ProudctAmount
                  ? temp_details[i].ProudctAmount
                  : temp_details[i].OptionAmount,
                total_basket_items_price: temp_details[i].SubTotal,
                // 5
                product_amount: temp_details[i].ProudctAmount,
                number: temp_details[i].Count,
                sub_list: final_sub_item,
                product_group: temp_details[i].ProductGroup,

                // 10
                crust_id: temp_details[i].CrustId,
                crust_amount: temp_details[i].CrustAmount,
                crust_title: temp_details[i].CrustName,
                option_amount: temp_details[i].OptionAmount,
                option_id: temp_details[i].OptionId,
                // 15
                print_label: temp_details[i].PrintLabel,
                vat_id: temp_details[i].VatProduct,
                vat_value: temp_details[i].VatProduct,
                final_vat: temp_details[i].TotalVat,
                total_vat: temp_details[i].TotalVat,
                // 20
                option_vat: temp_details[i].VatOption,
                product_vat: temp_details[i].VatProduct,
                custom_title_in_last: temp_details[i].CustomTitleInLast,
                title_in_last: temp_details[i].TitleInLast,
                printer_id: temp_details[i].PrinterId,
                // 25
                report_section: temp_details[i].ReportSection,
                product_id: temp_details[i].ProudctId,
              });
            }
            // console.log("Final details", final_details);
            yield put(getCustomerOrderDetailsSuccessfull(final_details));
          }
        } else {
          yield put(getCustomerOrderDetailsFailed(null));
        }
      } else {
        yield put(getCustomerOrderDetailsFailed(null));
      }
    } else {
      yield put(getCustomerOrderDetailsFailed(null));
    }
  } catch (error) {
    // console.log("catch get customer order details", error.message);

    yield put(getCustomerOrderDetailsFailed(error.message));
  }
}
function* addAddress({ payload }) {
  try {
    const response = yield call(
      addAddressService,
      payload.shop_id,
      payload.login_key,
      payload.customer_id,
      payload.address,
      payload.postcode,
      payload.city,
      payload.flat
    );
    // console.log("response add address");
    // console.log(response);
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          addAddressSuccessfull(
            response.data.customerAddress_list,
            response.data.ResultMessage
          )
        );
        // yield put(
        //   getDeliveryFeeSuccessfull(
        //     response.data.customerAddress_list[0].PostcodeDeliveryCharge
        //   )
        // );
      } else {
        yield put(addAddressFailed(response.data.ResultMessage));
      }
    } else {
      yield put(addAddressFailed(response.data.ResultMessage));
    }
  } catch (error) {
    // console.log("catch add address");
    // console.log(error.message);
    yield put(addAddressFailed(error.message));
  }
}
function* updateAddress({ payload }) {
  try {
    const response = yield call(
      updateAddressService,
      payload.shop_id,
      payload.login_key,
      payload.customer_id,
      payload.customer_address_id,
      payload.address,
      payload.postcode,
      payload.city,
      payload.flat
    );
    // console.log("response update address");
    // console.log(response);
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateAddressSuccessfull(
            response.data.customerAddress_list,
            response.data.ResultMessage
          )
        );
      } else {
        yield put(updateAddressFailed(response.data.ResultMessage));
      }
    } else {
      yield put(updateAddressFailed(response.data.ResultMessage));
    }
  } catch (error) {
    // console.log("catch add address");
    // console.log(error.message);
    yield put(updateAddressFailed(error.message));
  }
}
function* resetPassword({ payload }) {
  try {
    const response = yield call(
      resetPasswordService,
      payload.shop_id,
      payload.login_key,
      payload.email
    );
    // console.log("Response reset password: ", response);

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Customer_info) {
          if (response.data.Customer_info.length > 0) {
            let customer_id = response.data.Customer_info[0].CustomerId;

            let subject = "Forget Password";
            let jjj = "<h1>kk</h1>";
            let html_content = `<!DOCTYPE html>
        <html lang="en">
        
        <head>
            <title></title>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <link href="css/style.css" rel="stylesheet">
        </head>
        
        <body>
            <div style="width:80%;background-color: #EF9A9A;padding: 100px 0;margin:auto;text-align:center;">
            <div style="margin: auto;width:400px">
                <div style="margin: auto;border: 1px solid gray; border-radius: 10px;width:400px;
                text-align: center;background-color: #fcfcfc;height:300px;display: table-cell;vertical-align: middle;">
                    <h1>Forget your password?</h1>
                    <h5>Click on the button below to reset your password</h5>
                    <a href="http://${payload.shop_domain}/UpdatePassword/${payload.shop_id}/${customer_id}/${response.data.ResultMessage}" target="_blank"><img src='https://www.clker.com/cliparts/U/I/J/a/d/z/reset-password-png-hi.png' style="width: 50%;"></a>
        
                </div>
                </div>
        
            </div>
        
        </body>
        
        </html>`;
            yield put(
              sendOrderEmailAction(
                payload.shop_id,
                payload.login_key,
                0,
                2,
                html_content,
                payload.email,
                subject
              )
            );
          } else {
            yield put(resetPasswordSuccessfull(response.data.ResultMessage));
          }
        } else {
          yield put(resetPasswordSuccessfull(response.data.ResultMessage));
        }
      } else if (response.data.ResultCode == 0) {
        yield put(resetPasswordSuccessfull(response.data.ResultMessage));
      }
    } else {
      yield put(resetPasswordFailed("Failed, please try again"));
    }
  } catch (error) {
    // console.log("Catch reset password: ", error.message);
    yield put(resetPasswordFailed(error.message));
  }
}
function* sendForgetPasswordEmail({ payload }) {
  try {
    const response = yield call(
      sendForgetPasswordEmailService,
      payload.shop_id,
      payload.login_key,
      payload.role_id,
      payload.html_content,
      payload.email,
      payload.subject
    );
    // console.log("Response send email: ", response);

    // if (response.status == 200) {
    //   if (response.data.ResultCode == 1) {

    //   } else {
    //     yield put(updateAddressFailed(response.data.ResultMessage));
    //   }
    // } else {
    //   yield put(updateAddressFailed(response.data.ResultMessage));
    // }
  } catch (error) {
    // console.log("Catch send forget password email: ", error.message);
    yield put(sendForgetPasswordEmailFailed(error.message));
  }
}
function* verifyResetPasswordLink({ payload }) {
  try {
    const response = yield call(
      verifyResetPasswordLinkService,
      payload.shop_id,
      payload.login_key,
      payload.customer_id,
      payload.token
    );
    // console.log("Response verify reset password link: ", response);

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Customer_Login) {
          if (response.data.Customer_Login.length > 0) {
            yield put(
              loginSuccessfullAction(response.data.Customer_Login[0], false)
            );
            yield put(verifyResetPasswordLinkSuccessfull(null));
          } else {
            yield put(verifyResetPasswordLinkFailed(null));
          }
        } else {
          yield put(verifyResetPasswordLinkFailed(null));
        }
      } else {
        yield put(verifyResetPasswordLinkFailed(null));
      }
    } else {
      yield put(verifyResetPasswordLinkFailed(null));
    }
  } catch (error) {
    // console.log("Catch verify reset password link: ", error.message);
    yield put(verifyResetPasswordLinkFailed(error.message));
  }
}
function* getAddresses({ payload }) {
  try {
    const response = yield call(
      getAddressesService,
      payload.shop_id,
      payload.login_key,
      payload.customer_id
    );
    // console.log("response get address");
    // console.log(response);
    if (response.status == 200) {
      if (response.data.customerAddress_list) {
        if (response.data.customerAddress_list.length > 0) {
          store.set("postcode", response.data.customerAddress_list[0].PostCode);
          yield put(
            getDeliveryFeeAction(
              payload.shop_id,
              payload.login_key,
              payload.customer_id,
              response.data.customerAddress_list[0].PostCode
            )
          );
          let tempList = [];
          for (let i = 0; i < response.data.customerAddress_list.length; i++) {
            if (i == 0) {
              tempList.push({
                ...response.data.customerAddress_list[i],
                checked: true,
              });
            } else {
              tempList.push({
                ...response.data.customerAddress_list[i],
                checked: false,
              });
            }
          }
          yield put(getAddressesListSuccessfull(tempList));
        } else {
          yield put(getAddressesListSuccessfull([]));
        }
      } else {
        yield put(getAddressesListSuccessfull([]));
      }
    } else {
      yield put(getAddressesListFailed("An error accured please try again"));
    }
  } catch (error) {
    // console.log("catch get address");
    // console.log(error.message);
    yield put(getAddressesListFailed(error.message));
  }
}
function* sendOtp({ payload }) {
  try {
    const response = yield call(
      sendOtpService,
      payload.shop_id,
      payload.number,
      payload.customer_id
    );
    // console.log("response send otp");
    // console.log(response);
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(sendOtpSuccessfull("ok"));
      } else {
        yield put(sendOtpFailed("An error accured please try again"));
      }
    } else {
      yield put(sendOtpFailed("An error accured please try again"));
    }
  } catch (error) {
    // console.log("catch send otp");
    // console.log(error.message);
    yield put(sendOtpFailed(error.message));
  }
}
function* sendOtpCode({ payload }) {
  try {
    const response = yield call(
      sendVerificationCodeService,
      payload.otp_code,
      payload.mobile
    );
    // console.log("response send otp code");
    // console.log(response);
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(sendVerificationCodeSuccessfull("ok"));
      } else {
        yield put(sendVerificationCodeFailed(response.data[0].ResultMessage));
      }
    } else {
      yield put(
        sendVerificationCodeFailed("An error accured please try again")
      );
    }
  } catch (error) {
    // console.log("catch send otp code");
    // console.log(error.message);
    yield put(sendVerificationCodeFailed(error.message));
  }
}
function* getGoogleUserInfo({ payload }) {
  try {
    const response = yield call(getGoogleUserInfoService, payload.access_token);
    // console.log("response get google user info");
    // console.log(response);
    if (response.status == 200) {
      if (response.data) {
        yield put(
          createAccountAction(
            payload.login_key,
            payload.shop_id,
            response.data.given_name,
            response.data.family_name,
            response.data.email,
            "",
            "1234",
            1
          )
        );
      } else {
        yield put(getGoogleUserInfoFailed("An error accured please try again"));
      }
    } else {
      yield put(getGoogleUserInfoFailed("An error accured please try again"));
    }
  } catch (error) {
    // console.log("catch get google access token");
    // console.log(error.message);
    yield put(getGoogleUserInfoFailed(error.message));
  }
}
function* checkPostcode({ payload }) {
  try {
    const response = yield call(
      checkPostcodeService,
      payload.shop_id,
      payload.login_key,
      payload.postcode
    );
    // console.log("Response check postcode: ", response);

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(checkPostcodeSuccessful(response.data.ResultMessage));
      } else {
        yield put(checkPostcodeFailed(response.data.ResultMessage));
      }
    } else {
      yield put(checkPostcodeFailed(response.data.ResultMessage));
    }
  } catch (error) {
    // console.log("Catch check postcode: ", error.message);

    yield put(checkPostcodeFailed(error.message));
  }
}
export function* watchLogin() {
  yield takeLatest(action_type.LOGIN, login);
}
export function* watchSignUp() {
  yield takeLatest(action_type.CREATE_ACCOUNT, signUp);
}
export function* watchEditAccount() {
  yield takeLatest(action_type.EDIT_ACCOUNT, editAccount);
}
export function* watchAddAddress() {
  yield takeLatest(action_type.ADD_ADDRESS, addAddress);
}
export function* watchGetAddresses() {
  yield takeLatest(action_type.GET_ADDRESSES, getAddresses);
}
export function* watchUpdateAddress() {
  yield takeLatest(action_type.UPDATE_ADDRESS, updateAddress);
}
export function* watchSendOtp() {
  yield takeLatest(action_type.SEND_OTP, sendOtp);
}
export function* watchSendOtpCode() {
  yield takeLatest(action_type.SEND_VERIFICATION_CODE, sendOtpCode);
}
export function* watchGetGoogleUserInfo() {
  yield takeLatest(action_type.GET_GOOGLE_USER_INFO, getGoogleUserInfo);
}
export function* watchGetCustomerOrdersList() {
  yield takeLatest(action_type.GET_CUSTOMER_ORDERS_LIST, getCustomerOrdersList);
}
export function* watchGetCustomerOrderDetails() {
  yield takeLatest(
    action_type.GET_CUSTOMER_ORDER_DETAILS,
    getCustomerOrderDetails
  );
}
export function* watchResetPassword() {
  yield takeLatest(action_type.RESET_PASSWORD, resetPassword);
}
export function* watchSendForgetPasswordEmail() {
  yield takeLatest(
    action_type.SEND_FORGET_PASSWORD_EMAIL,
    sendForgetPasswordEmail
  );
}
export function* watchVerifyResetPasswordLink() {
  yield takeLatest(
    action_type.VERIFY_RESET_PASSWORD_LINK,
    verifyResetPasswordLink
  );
}
export function* watchCheckPostcode() {
  yield takeLatest(action_type.CHECK_POSTCODE, checkPostcode);
}
export default function* rootPrfile() {
  yield all([
    fork(watchSignUp),
    fork(watchLogin),
    fork(watchEditAccount),
    fork(watchAddAddress),
    fork(watchGetAddresses),
    fork(watchUpdateAddress),
    fork(watchSendOtp),
    fork(watchSendOtpCode),
    fork(watchGetGoogleUserInfo),
    fork(watchGetCustomerOrdersList),
    fork(watchGetCustomerOrderDetails),
    fork(watchResetPassword),
    fork(watchSendForgetPasswordEmail),
    fork(watchVerifyResetPasswordLink),
    fork(watchCheckPostcode),
  ]);
}
