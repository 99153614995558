import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_type from "../ActionType";

import { categoryListServices } from "../../services/CategoriesServices";
import {
  categoryListAction,
  categoryListSuccessfullAction,
  categoryListFailedAction,
  selectedCategoryAction,
} from "../Categories/Actions";
import { productsListAction } from "../Products/Actions";
function* categoryList({ payload }) {
  try {
    const response = yield call(
      categoryListServices,
      payload.login_key,
      payload.shop_id
    );
    // console.log("Response category list: ", response);
    if (response.status == 200) {
      if (response.data.Category_List.length > 0) {
        let promotion_list = [];

        let sunday_promotions = [];
        let monday_promotions = [];
        let tuesday_promotions = [];
        let wednesday_promotions = [];
        let thursday_promotions = [];
        let friday_promotions = [];
        let saturday_promotions = [];

        if (response.data.promotion_list) {
          if (response.data.promotion_list.length > 0) {
            let temp_p_list = response.data.promotion_list;
            for (let i = 0; i < temp_p_list.length; i++) {
              if (temp_p_list[i].DayOfWeek0) {
                monday_promotions.push({
                  Description: temp_p_list[i].Description,
                  ExcludeMealDeals: temp_p_list[i].ExcludeMealDeals,
                  MinOrder: temp_p_list[i].MinOrder,
                  Rate: temp_p_list[i].Rate,
                  RateOption: temp_p_list[i].RateOption,
                  SaleMethod: temp_p_list[i].SaleMethod,
                  ShowInSite: temp_p_list[i].ShowInSite,
                  UsesPerCustomer: temp_p_list[i].UsesPerCustomer,
                  UsesPerPromotion: temp_p_list[i].UsesPerPromotion,
                });
              }
              if (temp_p_list[i].DayOfWeek1) {
                tuesday_promotions.push({
                  Description: temp_p_list[i].Description,
                  ExcludeMealDeals: temp_p_list[i].ExcludeMealDeals,
                  MinOrder: temp_p_list[i].MinOrder,
                  Rate: temp_p_list[i].Rate,
                  RateOption: temp_p_list[i].RateOption,
                  SaleMethod: temp_p_list[i].SaleMethod,
                  ShowInSite: temp_p_list[i].ShowInSite,
                  UsesPerCustomer: temp_p_list[i].UsesPerCustomer,
                  UsesPerPromotion: temp_p_list[i].UsesPerPromotion,
                });
              }
              if (temp_p_list[i].DayOfWeek2) {
                wednesday_promotions.push({
                  Description: temp_p_list[i].Description,
                  ExcludeMealDeals: temp_p_list[i].ExcludeMealDeals,
                  MinOrder: temp_p_list[i].MinOrder,
                  Rate: temp_p_list[i].Rate,
                  RateOption: temp_p_list[i].RateOption,
                  SaleMethod: temp_p_list[i].SaleMethod,
                  ShowInSite: temp_p_list[i].ShowInSite,
                  UsesPerCustomer: temp_p_list[i].UsesPerCustomer,
                  UsesPerPromotion: temp_p_list[i].UsesPerPromotion,
                });
              }
              if (temp_p_list[i].DayOfWeek3) {
                thursday_promotions.push({
                  Description: temp_p_list[i].Description,
                  ExcludeMealDeals: temp_p_list[i].ExcludeMealDeals,
                  MinOrder: temp_p_list[i].MinOrder,
                  Rate: temp_p_list[i].Rate,
                  RateOption: temp_p_list[i].RateOption,
                  SaleMethod: temp_p_list[i].SaleMethod,
                  ShowInSite: temp_p_list[i].ShowInSite,
                  UsesPerCustomer: temp_p_list[i].UsesPerCustomer,
                  UsesPerPromotion: temp_p_list[i].UsesPerPromotion,
                });
              }
              if (temp_p_list[i].DayOfWeek4) {
                friday_promotions.push({
                  Description: temp_p_list[i].Description,
                  ExcludeMealDeals: temp_p_list[i].ExcludeMealDeals,
                  MinOrder: temp_p_list[i].MinOrder,
                  Rate: temp_p_list[i].Rate,
                  RateOption: temp_p_list[i].RateOption,
                  SaleMethod: temp_p_list[i].SaleMethod,
                  ShowInSite: temp_p_list[i].ShowInSite,
                  UsesPerCustomer: temp_p_list[i].UsesPerCustomer,
                  UsesPerPromotion: temp_p_list[i].UsesPerPromotion,
                });
              }
              if (temp_p_list[i].DayOfWeek5) {
                saturday_promotions.push({
                  Description: temp_p_list[i].Description,
                  ExcludeMealDeals: temp_p_list[i].ExcludeMealDeals,
                  MinOrder: temp_p_list[i].MinOrder,
                  Rate: temp_p_list[i].Rate,
                  RateOption: temp_p_list[i].RateOption,
                  SaleMethod: temp_p_list[i].SaleMethod,
                  ShowInSite: temp_p_list[i].ShowInSite,
                  UsesPerCustomer: temp_p_list[i].UsesPerCustomer,
                  UsesPerPromotion: temp_p_list[i].UsesPerPromotion,
                });
              }
              if (temp_p_list[i].DayOfWeek6) {
                sunday_promotions.push({
                  Description: temp_p_list[i].Description,
                  ExcludeMealDeals: temp_p_list[i].ExcludeMealDeals,
                  MinOrder: temp_p_list[i].MinOrder,
                  Rate: temp_p_list[i].Rate,
                  RateOption: temp_p_list[i].RateOption,
                  SaleMethod: temp_p_list[i].SaleMethod,
                  ShowInSite: temp_p_list[i].ShowInSite,
                  UsesPerCustomer: temp_p_list[i].UsesPerCustomer,
                  UsesPerPromotion: temp_p_list[i].UsesPerPromotion,
                });
              }
              // promotion_list.push({
              //   ...temp_p_list[i],
              //   DayOfWeek0: { label: 0, value: temp_p_list[i].DayOfWeek0 },
              //   DayOfWeek1: { label: 1, value: temp_p_list[i].DayOfWeek1 },
              //   DayOfWeek2: { label: 2, value: temp_p_list[i].DayOfWeek2 },
              //   DayOfWeek3: { label: 3, value: temp_p_list[i].DayOfWeek3 },
              //   DayOfWeek4: { label: 4, value: temp_p_list[i].DayOfWeek4 },
              //   DayOfWeek5: { label: 5, value: temp_p_list[i].DayOfWeek5 },
              //   DayOfWeek6: { label: 6, value: temp_p_list[i].DayOfWeek6 },
              // });
            }
          }
        }
        let final_promotions = [
          { day_id: 0, day_promotions: sunday_promotions },
          { day_id: 1, day_promotions: monday_promotions },
          { day_id: 2, day_promotions: tuesday_promotions },
          { day_id: 3, day_promotions: wednesday_promotions },
          { day_id: 4, day_promotions: thursday_promotions },
          { day_id: 5, day_promotions: friday_promotions },
          { day_id: 6, day_promotions: saturday_promotions },
        ];
        yield put(
          categoryListSuccessfullAction(
            response.data.Category_List,
            final_promotions
          )
        );
        yield put(selectedCategoryAction(response.data.Category_List[0]));
        yield put(
          productsListAction(
            payload.login_key,
            payload.shop_id,
            response.data.Category_List[0].Id
          )
        );
      } else {
        yield put(categoryListFailedAction());
      }
    } else {
      yield put(categoryListFailedAction());
    }
  } catch (error) {
    // console.log("Catch category list: ", error);
    yield put(categoryListFailedAction(error.message));
  }
}

export function* watchCategoryList() {
  yield takeLatest(action_type.CATEGORY_LIST, categoryList);
}

export default function* rootMenu() {
  yield all([fork(watchCategoryList)]);
}
