import React, { useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import store from "store2";
import { Navigate, useNavigate, redirect } from "react-router-dom";

import MainHeader from "../../Componenets/MainHeader";
import LoaderMini from "../../Componenets/LoderMini";
import { pound_sign } from "../../constants/String";
import { primary_color, white } from "../../constants/Colors";
import { setDefaultAction } from "../../redux/Profile/Actions";
import {
  sendOrderAction,
  setDefaultOrderPlaceStatusAction,
} from "../../redux/settings/Actions";
import { setBasketListAction } from "../../redux/basket/Actions";
import { delivery, collection } from "../../constants/SaleMethodsId";
import { EUR } from "../../constants/Currency";

const FailedPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [basket, setBasket] = useState([]);
  const [name, setName] = useState("");
  const [mobile, setMObile] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [invoiceId, setInvoiceId] = useState(0);
  const [footerContent, setFooterContent] = useState(null);
  const [shopInfo, setShopInfo] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [carrierBagVisible, setCarrierBagVisible] = useState(false);
  const [carrierBagLable, setCarrierBagLable] = useState("");
  const [carrierBagAmount, setCarrierBagAmount] = useState(0);
  const [adminFeeVisible, setAdminFeeVisible] = useState(false);
  const [adminFeeLable, setAdminFeeLable] = useState("");
  const [adminFeeAmount, setAdminFeeAmount] = useState(0);
  const [saleMethod, setSaleMethod] = useState(0);
  const [shopId, setShopId] = useState(0);
  const [loginKey, setLoginKey] = useState("");
  const [orderId, setOrderId] = useState(0);

  const { lang } = useSelector((state) => state.selectedLanguage);

  const remove_cookies_ref = useRef(false);
  useEffect(() => {
    store.remove("temp_basket");
    store.remove("temp_sub_total");
    dispatch(setBasketListAction([], 0, 0, [], 0, 0, 0));
    const basket_temp = store.get("basket");
    const customer_info_temp = store.get("customer_info");
    const invoice_id_temp = store.get("invoice_id");
    const order_id_temp = store.get("server_orderid");
    const footer_content_temp = store.get("footer_content");
    const shop_info_temp = store.get("shop_info");
    if (shop_info_temp) {
      setShopInfo(shop_info_temp);
    }
    if (footer_content_temp) {
      setDeliveryFee(footer_content_temp.delivery_fee);
      setCarrierBagVisible(footer_content_temp.carrier_bag_visible);
      setCarrierBagLable(footer_content_temp.carrier_bag_label);
      setCarrierBagAmount(footer_content_temp.carrier_bag_amount);
      setAdminFeeVisible(footer_content_temp.adminfee_visible);
      setAdminFeeLable(footer_content_temp.adminfee_label);
      setAdminFeeAmount(footer_content_temp.adminfee_amount);
      setSaleMethod(footer_content_temp.sale_method);
      setSubTotal(footer_content_temp.sub_total);
      setShopId(footer_content_temp.shop_id);
      setLoginKey(footer_content_temp.login_key);
      setFooterContent(footer_content_temp);
    }
    if (customer_info_temp) {
      setEmail(customer_info_temp.email);
      setName(customer_info_temp.name);
      setMObile(customer_info_temp.mobile);
      setAddress(
        customer_info_temp.address ? customer_info_temp.address.Address : ""
      );
    }
    if (invoice_id_temp) {
      setInvoiceId(invoice_id_temp);
    }
    if (order_id_temp) {
      setOrderId(order_id_temp);
    }
    if (basket_temp) {
      setBasket(basket_temp);
    }
    if (remove_cookies_ref.current) {
      return () => {
        store.remove("basket");
        store.remove("customer_info");
        store.remove("invoice_id");
        store.remove("server_orderid");
        store.remove("shop_info");
        store.remove("footer_content");
        dispatch(setDefaultOrderPlaceStatusAction());
      };
    } else {
      remove_cookies_ref.current = true;
    }
  }, []);
  return (
    <div className="menu-container-div">
      <MainHeader
        show_all_items={true}
        show_basket={false}
        show_profile={true}
        show_menu_title={true}
      />
      <div className="review_container">
        <div
          className="review_content_container"
          style={{ alignItems: "center", height: "95%" }}
        >
          {/* <div className="successfull_result_message_container">
            <div className="successfull_message_container">
              <i
                class="fa fa-times-circle"
                aria-hidden="true"
                style={{ color: "red" }}
              ></i>
              <h6>{lang.order_failed_message}</h6>
            </div>
            <div className="customer_info_container">
              <h5>
                <i class="fa fa-user-o" aria-hidden="true"></i>
                {lang.customer_info}
              </h5>
              <h6>{name}</h6>
              <h6>{mobile}</h6>
              <h6>{address}</h6>
            </div>
          </div> */}
          {/* BASKET */}

          <div className="successfull_basket">
            <div className="successful_basket_header_container">
              <h6>{`${lang.your_order} #${invoiceId}`}</h6>
            </div>

            <div className="successfull_basket_list_container">
              {basket.map((item, index) => (
                <div>
                  <div style={{ marginBottom: 25, display: "flex" }}>
                    <div className="mobile_basket_trash_container">
                      <div id="product_number_container">
                        <h6>{item.number}</h6>
                      </div>
                    </div>
                    <div className="basket_product_subitem_container">
                      <div className="basket_product_container">
                        <div className="basket_product_title_container">
                          <h6>{item.title}</h6>
                        </div>
                        <h6>
                          {EUR}
                          {item.price.toFixed(2)}
                        </h6>
                      </div>
                      {item.sub_list.length > 0 ? (
                        <div className="basket_sublist_container">
                          {item.sub_list.map((sub_item) => (
                            <div>
                              <h6>{sub_item.WebTitle}</h6>
                              <h6>
                                {EUR}
                                {sub_item.AmountMain.toFixed(2)}
                              </h6>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    {basket.length - 1 > index ? <hr></hr> : null}
                  </div>
                </div>
              ))}
            </div>

            {/* FOOTER */}
            <div className="footer_successful_page">
              <div className="basket_footer_container">
                <div>
                  <h6>{lang.subtotal}</h6>
                  <h6>
                    {EUR}
                    {subTotal.toFixed(2)}
                  </h6>
                </div>
                {/* CARRIER BAG*/}
                {carrierBagVisible ? (
                  <div>
                    <h6>{carrierBagLable}</h6>
                    <h6>
                      {EUR}
                      {carrierBagAmount.toFixed(2)}
                    </h6>
                  </div>
                ) : null}
                {/* ****** */}
                {/* ADMIN FEE*/}
                {adminFeeVisible ? (
                  <div>
                    <h6>{adminFeeLable}</h6>
                    <h6>
                      {EUR}
                      {adminFeeAmount.toFixed(2)}
                    </h6>
                  </div>
                ) : null}
                {/* DELIVERY FEE */}
                {saleMethod == delivery ? (
                  <div>
                    <h6>{lang.delivey_fee}</h6>
                    <h6>
                      {EUR}
                      {deliveryFee.toFixed(2)}
                    </h6>
                  </div>
                ) : null}
                <div>
                  <h5>{lang.total}</h5>
                  <h5>
                    {EUR}
                    {saleMethod == delivery
                      ? adminFeeVisible
                        ? carrierBagVisible
                          ? (
                              carrierBagAmount +
                              adminFeeAmount +
                              deliveryFee +
                              subTotal
                            ).toFixed(2)
                          : (adminFeeAmount + deliveryFee + subTotal).toFixed(2)
                        : carrierBagVisible
                        ? (carrierBagAmount + deliveryFee + subTotal).toFixed(2)
                        : (deliveryFee + subTotal).toFixed(2)
                      : adminFeeVisible
                      ? carrierBagVisible
                        ? (
                            carrierBagAmount +
                            adminFeeAmount +
                            subTotal
                          ).toFixed(2)
                        : (adminFeeAmount + subTotal).toFixed(2)
                      : carrierBagVisible
                      ? (carrierBagAmount + subTotal).toFixed(2)
                      : subTotal.toFixed(2)}
                  </h5>
                </div>
              </div>
              <div
                className="successful_basket_footer_container"
                style={{ backgroundColor: "red" }}
              >
                <h6>{lang.order_failed_message}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailedPayment;
