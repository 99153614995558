import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, Toaster } from "react-hot-toast";
import Select from "react-select";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "@mui/material/Button";
import store from "store2";
import moment from "moment";

import MainHeader from "../../Componenets/MainHeader";
import LoderMini from "../../Componenets/LoderMini";
import Addresses from "../../Componenets/Addresses";
import VerificationCode from "../../Componenets/VerificationCode";

import { setBasketListAction } from "../../redux/basket/Actions";
import { getAllProductsSuccessfull } from "../../redux/Products/Actions";
import {
  setDefaultAction,
  getAddressesListAction,
  editAccountAction,
  sendOtpAction,
  checkPostcodeAction,
} from "../../redux/Profile/Actions";
import {
  getDeliveryTimeAction,
  setOrderRequiredInfoAction,
  getDeliveryFeeSuccessfull,
  getDeliveryFeeAction,
} from "../../redux/settings/Actions";
import {
  primary_color,
  light_border_color,
  dark_border_color,
} from "../../constants/Colors";
import { delivery, collection } from "../../constants/SaleMethodsId";

const ttt = [
  { value: 1, label: "bir" },
  { value: 2, label: "iki" },
  { value: 3, label: "uch" },
  { value: 4, label: "dord" },
  { value: 1, label: "bir" },
  { value: 2, label: "iki" },
  { value: 3, label: "uch" },
  { value: 4, label: "dord" },
  { value: 1, label: "bir" },
  { value: 2, label: "iki" },
  { value: 3, label: "uch" },
  { value: 4, label: "dord" },
  { value: 1, label: "bir" },
  { value: 2, label: "iki" },
  { value: 3, label: "uch" },
  { value: 4, label: "dord" },
  { value: 1, label: "bir" },
  { value: 2, label: "iki" },
  { value: 3, label: "uch" },
  { value: 4, label: "dord" },
  { value: 1, label: "bir" },
  { value: 2, label: "iki" },
  { value: 3, label: "uch" },
  { value: 4, label: "dord" },
  { value: 1, label: "bir" },
  { value: 2, label: "iki" },
  { value: 3, label: "uch" },
  { value: 4, label: "dord" },
];

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMounted = useRef(false);

  const {
    user_info,
    sign_up_loading,
    addresses_list,
    result_successfull,
    result_failed,
    result_message,
    address_result_successfull,
    address_result_failed,
  } = useSelector((state) => state.profileReduser);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { user_entered_in, loading } = useSelector(
    (state) => state.shopSettingsReduser
  );
  const { shop_id, login_key, delivery_time_list, delivery_fee } = useSelector(
    (state) => state.shopSettingsReduser
  );
  const { basket_content, basket_item_id, sub_total, sale_method } =
    useSelector((state) => state.basketReduser);

  const [mobile, setMobile] = useState("");
  const [showAddressesList, setShowAddressesList] = useState(false);
  const [show_address_list, set_show_address_list] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState("");
  const [speccialNote, setSpeccialNote] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [postcodeErrorMessage, setPostcodeErrorMessage] = useState("");
  const [mobileErrorMessage, setMobileErrorMessage] = useState("");
  const [mobileBorderColor, setMobileBorderColor] =
    useState(light_border_color);
  const [color, setColor] = useState("blue");
  const [muiInputFontSize, setMuiInputFontSize] = useState(16);
  const [addressListIsOpened, setAddressListIsOpened] = useState(false);
  const [addAddressButtonClicked, setAddAddressButtonClicked] = useState(false);

  const customStyles = {
    dropdownIndicator: (base) => ({
      ...base,
      // display: "none",
      color: "red",
    }),
    control: (base, state) => ({
      ...base,

      minHeight: 30,
      // maxHeight: 50,
      fontSize: muiInputFontSize,

      padding: 0,
      boxShadow: "none",
      height: "100%",
      border: state.isFocused
        ? `1px solid ${primary_color}`
        : `1px solid ${light_border_color}`,
      borderRadius: 5,
      "&:hover": {
        border: `1px solid ${primary_color}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 200,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      // "::-webkit-scrollbar-track": {
      //   background: "#f1f1f1",
      // },
      "::-webkit-scrollbar-thumb": {
        background: primary_color,
      },
      // "::-webkit-scrollbar-thumb:hover": {
      //   background: "#555",
      // },
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primary_color : "white",

      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primary_color : light_border_color,
      },
    }),
  };

  useEffect(() => {
    if (user_info) {
      setMobile(user_info.Mobile);
    }
  }, [user_info]);

  useEffect(() => {
    setAddAddressButtonClicked(false);
    if (result_failed) {
      setPostcodeErrorMessage(result_message);
      dispatch(setDefaultAction());
    } else if (result_successfull) {
      setPostcodeErrorMessage("");
      dispatch(setDefaultAction());
    }
  }, [result_failed, result_message, result_successfull]);

  useEffect(() => {
    if (selectedAddress) {
      dispatch(
        checkPostcodeAction(shop_id, login_key, selectedAddress.PostCode)
      );
      dispatch(
        getDeliveryFeeAction(
          shop_id,
          login_key,
          selectedAddress.CustomerId,
          selectedAddress.PostCode
        )
      );
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (user_entered_in) {
      if (sale_method == delivery) {
        dispatch(
          getAddressesListAction(shop_id, login_key, user_info.CustomerId)
        );
      }
      dispatch(getDeliveryTimeAction(shop_id, login_key, sale_method));
      dispatch(setDefaultAction());
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (addresses_list && !addressListIsOpened) {
      dispatch(setDefaultAction());
      if (addresses_list.length > 0) {
        setSelectedAddress(addresses_list[0]);
      }
    } else {
      dispatch(setDefaultAction());
    }
  }, [addresses_list]);

  useEffect(() => {
    if (delivery_time_list.length > 0) {
      setSelectedDeliveryTime(delivery_time_list[0]);
    }
  }, [delivery_time_list]);

  const handleAddressClicked = () => {
    setShowAddressesList(true);
  };

  const handleGoToPayment = (next_path) => {
    // NEW CODES
    const saved_basket_time = store.get("saved_basket_time");
    if (
      moment(saved_basket_time).add(59, "minutes").format("DD-MM-YYYY HH:mm") >
      moment(new Date()).format("DD-MM-YYYY HH:mm")
    ) {
      setMobileBorderColor(light_border_color);
      setMobileErrorMessage("");
      if (sale_method == delivery) {
        if (
          mobile &&
          selectedAddress &&
          selectedDeliveryTime &&
          !postcodeErrorMessage
        ) {
          store.set("postcode", selectedAddress.PostCode);

          dispatch(
            setOrderRequiredInfoAction(
              selectedDeliveryTime,
              speccialNote,
              selectedAddress
            )
          );
          if (mobile.split("").length == 11) {
            let mobile_split = mobile.split("");
            if (mobile_split[0] == "0" && mobile_split[1] == "7") {
              if (user_info.IsVerified) {
                if (user_info.Mobile != mobile) {
                  dispatch(
                    sendOtpAction(shop_id, mobile, user_info.CustomerId)
                  );
                  setShowVerification(true);
                } else {
                  navigate(`/${next_path}`);
                }
              } else {
                dispatch(sendOtpAction(shop_id, mobile, user_info.CustomerId));
                setShowVerification(true);
              }
            } else {
              setMobileBorderColor("red");
              setMobileErrorMessage(lang.the_phone_number_havero_start_by_07);
            }
          } else {
            setMobileBorderColor("red");
            setMobileErrorMessage(lang.the_phone_number_must_be_11_digit);
          }
        } else {
          toast.error(
            <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
            {
              position: "top-center",
              duration: 3000,
              style: { backgroundColor: "red", color: "white" },
              icon: (
                <i
                  style={{ fontSize: 40 }}
                  class="fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              ),
            }
          );
        }
      } else {
        if (mobile && selectedDeliveryTime) {
          dispatch(
            setOrderRequiredInfoAction(selectedDeliveryTime, speccialNote, null)
          );

          if (mobile.split("").length == 11) {
            // alert(`is verified ${String(user_info.IsVerified)}`);
            if (user_info.IsVerified) {
              if (user_info.Mobile != mobile) {
                dispatch(sendOtpAction(shop_id, mobile, user_info.CustomerId));
                setShowVerification(true);
              } else {
                navigate(`/${next_path}`);
              }
            } else {
              dispatch(sendOtpAction(shop_id, mobile, user_info.CustomerId));
              setShowVerification(true);
            }
          } else {
            toast.error(
              <h5>
                {" "}
                &nbsp;&nbsp;{
                  lang.the_phone_number_must_be_11_digit
                }&nbsp;&nbsp;{" "}
              </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    class="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          }
        } else {
          toast.error(
            <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
            {
              position: "top-center",
              duration: 3000,
              style: { backgroundColor: "red", color: "white" },
              icon: (
                <i
                  style={{ fontSize: 40 }}
                  class="fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              ),
            }
          );
        }
      }
    } else {
      dispatch(setBasketListAction([], 0, null, [], 0, 0, 0));
      dispatch(getAllProductsSuccessfull([]));
      store.remove("temp_basket");
      store.remove("temp_sub_total");
      store.remove("basket_last_item_id");
      store.remove("temp_basket_product_id_list");
      store.remove("temp_no_mealdeal_sub_total");
      store.remove("discount");
      store.remove("discount_no_mealdeal");
      navigate("/");
    }
    // *****

    // OLD CODES

    // setMobileBorderColor(light_border_color);
    // setMobileErrorMessage("");
    // if (sale_method == delivery) {
    //   if (
    //     mobile &&
    //     selectedAddress &&
    //     selectedDeliveryTime &&
    //     !postcodeErrorMessage
    //   ) {
    //     store.set("postcode", selectedAddress.PostCode);

    //     dispatch(
    //       setOrderRequiredInfoAction(
    //         selectedDeliveryTime,
    //         speccialNote,
    //         selectedAddress
    //       )
    //     );
    //     if (mobile.split("").length == 11) {
    //       // alert(`is verified ${String(user_info.IsVerified)}`);
    //       let mobile_split = mobile.split("");
    //       if (mobile_split[0] == "0" && mobile_split[1] == "7") {
    //         if (user_info.IsVerified) {
    //           if (user_info.Mobile != mobile) {
    //             dispatch(sendOtpAction(shop_id, mobile, user_info.CustomerId));
    //             setShowVerification(true);
    //           } else {
    //             navigate(`/${next_path}`);
    //           }
    //         } else {
    //           dispatch(sendOtpAction(shop_id, mobile, user_info.CustomerId));
    //           setShowVerification(true);
    //         }
    //       } else {
    //         setMobileBorderColor("red");
    //         setMobileErrorMessage(lang.the_phone_number_havero_start_by_07);
    //       }
    //     } else {
    //       setMobileBorderColor("red");
    //       setMobileErrorMessage(lang.the_phone_number_must_be_11_digit);
    //       // toast.error(
    //       //   <h5>
    //       //     {" "}
    //       //     &nbsp;&nbsp;{
    //       //       lang.the_phone_number_must_be_11_digit
    //       //     }&nbsp;&nbsp;{" "}
    //       //   </h5>,
    //       //   {
    //       //     position: "top-center",
    //       //     duration: 3000,
    //       //     style: { backgroundColor: "red", color: "white" },
    //       //     icon: (
    //       //       <i
    //       //         style={{ fontSize: 40 }}
    //       //         class="fa fa-times-circle"
    //       //         aria-hidden="true"
    //       //       ></i>
    //       //     ),
    //       //   }
    //       // );
    //     }
    //   } else {
    //     toast.error(
    //       <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
    //       {
    //         position: "top-center",
    //         duration: 3000,
    //         style: { backgroundColor: "red", color: "white" },
    //         icon: (
    //           <i
    //             style={{ fontSize: 40 }}
    //             class="fa fa-times-circle"
    //             aria-hidden="true"
    //           ></i>
    //         ),
    //       }
    //     );
    //   }
    // } else {
    //   if (mobile && selectedDeliveryTime) {
    //     dispatch(
    //       setOrderRequiredInfoAction(selectedDeliveryTime, speccialNote, null)
    //     );

    //     if (mobile.split("").length == 11) {
    //       // alert(`is verified ${String(user_info.IsVerified)}`);
    //       if (user_info.IsVerified) {
    //         if (user_info.Mobile != mobile) {
    //           dispatch(sendOtpAction(shop_id, mobile, user_info.CustomerId));
    //           setShowVerification(true);
    //         } else {
    //           navigate(`/${next_path}`);
    //         }
    //       } else {
    //         dispatch(sendOtpAction(shop_id, mobile, user_info.CustomerId));
    //         setShowVerification(true);
    //       }
    //     } else {
    //       toast.error(
    //         <h5>
    //           {" "}
    //           &nbsp;&nbsp;{
    //             lang.the_phone_number_must_be_11_digit
    //           }&nbsp;&nbsp;{" "}
    //         </h5>,
    //         {
    //           position: "top-center",
    //           duration: 3000,
    //           style: { backgroundColor: "red", color: "white" },
    //           icon: (
    //             <i
    //               style={{ fontSize: 40 }}
    //               class="fa fa-times-circle"
    //               aria-hidden="true"
    //             ></i>
    //           ),
    //         }
    //       );
    //     }
    //   } else {
    //     toast.error(
    //       <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
    //       {
    //         position: "top-center",
    //         duration: 3000,
    //         style: { backgroundColor: "red", color: "white" },
    //         icon: (
    //           <i
    //             style={{ fontSize: 40 }}
    //             class="fa fa-times-circle"
    //             aria-hidden="true"
    //           ></i>
    //         ),
    //       }
    //     );
    //   }
    // }
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 761) {
        setMuiInputFontSize(12);
      } else {
        setMuiInputFontSize(15);
      }
    }

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 761) {
      setMuiInputFontSize(13);
    } else {
      setMuiInputFontSize(15);
    }
  }, []);

  useEffect(() => {
    if (result_successfull && showAddressesList && addAddressButtonClicked) {
      setAddAddressButtonClicked(false);
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
      dispatch(setDefaultAction());
    } else if (result_failed && showAddressesList && addAddressButtonClicked) {
      setAddAddressButtonClicked(false);
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
      dispatch(setDefaultAction());
    }
  }, [result_successfull, result_failed]);

  console.log("delivery_time_list: ", delivery_time_list);
  console.log("selectedDeliveryTime: ", selectedDeliveryTime);

  return (
    <div className="menu-container-div">
      <MainHeader
        show_all_items={true}
        show_basket={false}
        show_profile={true}
        show_menu_title={window.innerWidth < 760 ? false : true}
      />
      <div className="profile-container">
        <div className="profile-create-acount" style={{ height: "90%" }}>
          <div className="checkout_input_container">
            <div className="checkout_mobile_number_container">
              <h6>{lang.mobile_number}</h6>

              <input
                style={{ border: `1px solid ${mobileBorderColor}` }}
                type="text"
                name="name"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value.replace(/\D/g, ""));
                  setMobileBorderColor(light_border_color);
                  setMobileErrorMessage("");
                }}
                maxLength={11}
                placeholder="07123456789"
              />
              <div id="mobile_number_error_container">
                <p>{mobileErrorMessage ? mobileErrorMessage : ""}</p>
              </div>
            </div>
            {/* ADDRESS */}
            {sale_method == delivery ? (
              <div style={{ width: "100%", marginBottom: 10 }}>
                <h6>{lang.address}</h6>

                <div
                  className="checkout_address_container"
                  onClick={handleAddressClicked}
                  style={{ margin: 0 }}
                >
                  <div className="checkout_address_text_container">
                    <p>
                      {selectedAddress
                        ? selectedAddress.Flat
                          ? `${selectedAddress.Flat} ${selectedAddress.Address}`
                          : selectedAddress.Address
                        : lang.no_address_found}
                    </p>
                  </div>
                  <div className="checkout_arrow_icon_container">
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="checkout_error_message_container">
                  <p>{postcodeErrorMessage}</p>
                </div>
              </div>
            ) : null}
            {/* DELIVERY TIME */}
            <h6>
              {sale_method == delivery
                ? lang.delivey_time
                : lang.collection_time}
            </h6>
            <div className="checkout_delivery_time_container">
              {!loading ? (
                <Select
                  value={selectedDeliveryTime}
                  defaultValue={selectedDeliveryTime}
                  onChange={(list, item) => {
                    setSelectedDeliveryTime(list);
                  }}
                  options={delivery_time_list}
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={false}
                  components={{
                    DropdownIndicator: () => (
                      <div className="checkout_arrow_icon_container">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    ),
                    IndicatorSeparator: () => null,
                  }}
                />
              ) : (
                <div className="checkout_delivery_time_loader_container">
                  <LoderMini color="red" />
                </div>
              )}
            </div>

            <div className="checkout_special_note_container">
              <h6>{lang.special_note}</h6>
              <textarea
                value={speccialNote}
                onChange={(e) => setSpeccialNote(e.target.value)}
                placeholder={lang.special_note}
              />
            </div>
            {/* BUTTON */}
            <div
              className="go_to_payment_button_container"
              style={{ padding: 0 }}
            >
              <Button
                style={{ backgroundColor: primary_color }}
                onClick={() => handleGoToPayment("review")}
              >
                {lang.go_to_payment}
              </Button>
            </div>
            {/*MOBILE BUTTON */}
            <div
              className="go_to_payment_button_container_mobile"
              style={{ padding: 0 }}
            >
              <Button
                style={{ backgroundColor: primary_color }}
                onClick={() => handleGoToPayment("ReviewMobile")}
              >
                {lang.go_to_payment}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {showAddressesList ? (
        <Addresses
          hide_addresses_list={() => {
            setShowAddressesList(false);
            dispatch(setDefaultAction());
          }}
          show_address_list={show_address_list}
          set_selected_address={(i) => {
            setSelectedAddress(i);
            setPostcodeErrorMessage("");
            setAddressListIsOpened(true);
          }}
          isCheckout={true}
          selected_address={selectedAddress}
          add_address_button_clicked={() => setAddAddressButtonClicked(true)}
        />
      ) : null}
      {showVerification ? (
        <VerificationCode
          mobile={mobile}
          hide_verification={() => {
            setShowVerification(false);
          }}
          update_profile={() =>
            dispatch(
              editAccountAction(
                shop_id,
                login_key,
                user_info.CustomerId,
                user_info.FirstName,
                user_info.LastName,
                mobile,
                "",
                user_info.Mail,
                user_info.Pass,
                1
              )
            )
          }
        />
      ) : null}
      <Toaster />
    </div>
  );
};

export default Checkout;
