import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import moment from "moment";
import store from "store2";

import MainHeader from "../../Componenets/MainHeader";
import LoaderMini from "../../Componenets/LoderMini";
import { pound_sign } from "../../constants/String";
import { primary_color, white } from "../../constants/Colors";
import { setBasketListAction } from "../../redux/basket/Actions";
import { getAllProductsSuccessfull } from "../../redux/Products/Actions";
import { setDefaultAction } from "../../redux/Profile/Actions";
import {
  sendOrderAction,
  setDefaultOrderPlaceStatusAction,
} from "../../redux/settings/Actions";
import { delivery, collection } from "../../constants/SaleMethodsId";
import { baseUrlForImagesPrefix } from "../../constants/BaseUrl";
import { EUR } from "../../constants/Currency";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const MobileReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user"]);

  const [selectedPayMethod, setSelectedPayMethod] = useState(0);

  const { shop_id, login_key, loading, shop_name, shop_info } = useSelector(
    (state) => state.shopSettingsReduser
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    selected_delivery_time,
    special_note,
    selected_address,
    stripe_payment_link,
    order_placed_successfull,
    order_placed_failed,
    delivery_fee,
    carrier_bag_visible,
    carrier_bag_amount,
    carrier_bag_label,
    adminfee_visible,
    adminfee_amount,
    adminfee_label,
    cash_in_collection,
    cash_in_delivery,
    stripe_in_collection,
    stripe_in_delivery,
    paypal_in_collection,
    paypal_in_delivery,
    user_entered_in,
    server_order_id,
  } = useSelector((state) => state.shopSettingsReduser);
  const {
    basket_content,
    basket_item_id,
    sub_total,
    sale_method,
    discount_with_mealdeals,
    discount_without_mealdeals,
  } = useSelector((state) => state.basketReduser);
  const {
    user_info,
    sign_up_loading,
    result_successfull,
    result_failed,
    result_message,
    address_result_successfull,
    address_result_failed,
    address_loading,
    addresses_list,
  } = useSelector((state) => state.profileReduser);

  useEffect(() => {
    if (sale_method) {
      if (sale_method == delivery) {
        if (stripe_in_delivery) {
          setSelectedPayMethod(2000105);
        } else if (cash_in_delivery) {
          setSelectedPayMethod(2000102);
        }
      } else {
        if (stripe_in_collection) {
          setSelectedPayMethod(2000105);
        } else if (cash_in_collection) {
          setSelectedPayMethod(2000102);
        }
      }
    }
  }, [
    sale_method,
    stripe_in_delivery,
    cash_in_delivery,
    cash_in_collection,
    stripe_in_collection,
  ]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
    } else if (result_failed) {
      dispatch(setDefaultAction());
    }
  }, [result_successfull, result_failed]);

  const handleSendOrder = () => {
    let total_for_pay = 0;
    if (sale_method == delivery) {
      if (adminfee_visible) {
        if (carrier_bag_visible) {
          total_for_pay =
            sub_total + delivery_fee + adminfee_amount + carrier_bag_amount;
        } else {
          total_for_pay = sub_total + delivery_fee + adminfee_amount;
        }
      } else {
        if (carrier_bag_visible) {
          total_for_pay = sub_total + delivery_fee + carrier_bag_amount;
        } else {
          total_for_pay = sub_total + delivery_fee;
        }
      }
    } else {
      if (adminfee_visible) {
        if (carrier_bag_visible) {
          total_for_pay = sub_total + adminfee_amount + carrier_bag_amount;
        } else {
          total_for_pay = sub_total + adminfee_amount;
        }
      } else {
        if (carrier_bag_visible) {
          total_for_pay = sub_total + carrier_bag_amount;
        } else {
          total_for_pay = sub_total;
        }
      }
    }

    let customer_info = {
      name: `${user_info.FirstName} ${user_info.LastName}`,
      mobile: user_info.Mobile,
      email: user_info.Mail,
      sub_total: sub_total,
      address: selected_address ? selected_address : "",
    };

    let total_vat = 0;
    let total = 0;

    store.set("basket", basket_content);
    store.set("customer_info", customer_info);
    store.set("shop_info", shop_info);
    store.set("footer_content", {
      sub_total,
      delivery_fee,
      carrier_bag_visible,
      carrier_bag_amount,
      carrier_bag_label,
      adminfee_visible,
      adminfee_amount,
      adminfee_label,
      selected_delivery_time,
      sale_method,
      shop_name,
      shop_id,
      login_key,
    });

    let OrderDetailsList = [];
    let OrderSubmenuItemList = [];

    let OrderPaymentList = [
      {
        ShopId: shop_id,
        MachineOrderId: 0,
        MachinePaymentId: 0,
        CustomerId: 1,
        DateS: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),

        TotalPayed: sub_total + delivery_fee,
        ChangePay: 0,
        UserId: 0,
        PayMethod: 2000101,
        Notes: "",

        WorkStationId: 0,
        HostIP: "",
        IsDeleted: 0,
        TransactionAmount: 0,
        TransactionStatus: 0,

        TransactionCode: "0",
        TransactionToken: "",
        StripeSessionId: "",
        StripeSessionStatus: 0,
        StripeDescription: "",

        StripeDate: "2022-08-19 09:47:11",
        CheckBank: 0,
        StripeDateCheck: "2022-08-19 09:47:11",
        Deposit: 0,
        ServerOrderPaymentId: 0,

        ServerOrderId: 0,
      },
    ];
    let html_product = "";

    for (let i = 0; i < basket_content.length; i++) {
      let sub_list = "";

      total_vat = total_vat + basket_content[i].total_vat;
      total = total + basket_content[i].price;
      OrderDetailsList.push({
        ShopId: shop_id,
        MachineOrderId: 0,
        MachineOrderDetailId: basket_content[i].id,
        Count: basket_content[i].number,
        Discount: 0,
        // 5
        ProudctId: basket_content[i].product_id,
        OptionId: basket_content[i].option_id,
        CrustId: basket_content[i].crust_id,
        HostIP: "",
        TotalDiscount: 0,
        // 10
        SubTotal: basket_content[i].price,
        TotalVat: basket_content[i].total_vat,
        Total: basket_content[i].price,
        ManualDiscount: 0,
        PromotionDiscount: 0,
        // 15
        GeneralItemAmount: 0,
        HalfAmount: 0,
        CrustAmount: basket_content[i].crust_amount,
        OptionAmount: basket_content[i].option_amount,
        ProudctAmount: basket_content[i].product_amount,
        //  20
        ProductDiscount: 0,
        OptionDiscount: 0,
        CrustDiscount: 0,
        IsGift: 0,
        VatProduct: basket_content[i].product_vat * basket_content[i].number,
        // 25
        VatOption: basket_content[i].option_vat * basket_content[i].number,
        VatCrust: 0,
        SortId: 0,
        DiscReson: 0,
        EditedTitle: "",
        //  30
        BeforEditAmount: 0,
        IsGeneralItem: 0,
        HalfNo: 0,
        CustomTitle: 0,
        PrinterId: basket_content[i].printer_id,
        //  35
        ReportSection: basket_content[i].report_section,
        Qty: 0,
        ProductGroup: basket_content[i].product_group,
        CustomTitleInLast: basket_content[i].custom_title_in_last,
        TitleInLast: basket_content[i].title_in_last,
        // 40
        GroupId: 0,
        IsHalfTotal: 0,
        ManualDiscountVal: "1",
        EditStatus: 0,
        InKitchenMonitor: 0,
        //  45
        PrintLabel: basket_content[i].print_label,
        FreeItem: 0,
        SwOptionTitlePosition: 0,
        HalfRealtedId: 0,
        ExOfer: 0,
        // 50
        ServerOrderDetailId: 0,
        ServerOrderId: 0,
        IsDeleted: 0,
      });
      if (basket_content[i].sub_list.length > 0) {
        for (let j = 0; j < basket_content[i].sub_list.length; j++) {
          sub_list = sub_list.concat(`\n
            <tr style="margin:5px 10px 0 10px;">
            <td style="margin:0; font-size:17px">${
              basket_content[i].sub_list[j].WebTitle
            }</td>
            <td style="margin:0;font-size:17px;float:right">${EUR}${basket_content[
            i
          ].sub_list[j].AmountMain.toFixed(2)}</td>
        </tr>
            `);
          OrderSubmenuItemList.push({
            ShopId: shop_id,
            MachineOrderId: 0,
            MachineOrderDetailId: basket_content[i].id,
            MachineOrderSubItemId: 0,
            SubMenuId: basket_content[i].sub_list[j].SubMenuId,
            // 5
            SubMenuItemId: basket_content[i].sub_list[j].Id,
            Count:
              basket_content[i].sub_list[j].number * basket_content[i].number,
            ItemAmount: basket_content[i].sub_list[j].AmountMain,
            Vat:
              basket_content[i].sub_list[j].vat_item *
              basket_content[i].sub_list[j].number *
              basket_content[i].number,
            ManualDiscount: 0,
            // 10
            TotalDiscount: 0,
            TotalVat:
              basket_content[i].sub_list[j].vat_item *
              basket_content[i].sub_list[j].number *
              basket_content[i].number,
            SubTotal:
              basket_content[i].sub_list[j].AmountMain *
              basket_content[i].sub_list[j].number *
              basket_content[i].number,
            Total:
              basket_content[i].sub_list[j].AmountMain *
              basket_content[i].sub_list[j].number *
              basket_content[i].number,
            ProductSubMenuId: 0,
            // 15
            CustomTitle: "",
            IsNot: 0,
            Qty: 0,
            PrinterId: basket_content[i].sub_list[j].PrinterId,
            ReportSection: basket_content[i].sub_list[j].ReportSection,
            // 20
            ProductGroup: basket_content[i].sub_list[j].ProductGroup,
            TopingQ: 1,
            CountTxt: "1",
            IsFrist: 0,
            IsAfter: 0,
            // 25
            FreeItem: 0,
            ServerOrderSubItemId: 0,
            ServerOrderId: 0,
            ServerOrderDetailId: 0,
          });
        }
      }
      html_product = html_product.concat(
        `\n
          <div style="display:flex;margin-bottom:10px">
          <div style="padding-top:10px">
          <div style="border: 1px solid #ccc; border-radius: 300px;width:30px;height:30px;
          text-align:center;background-color:#ccc">
              <p style="margin:0;font-size:20px;font-weight:bold">${
                basket_content[i].number
              }</p>
  
          </div>
         
      </div>
      <table style="padding-top:10px; width:100%">
      <tr style="margin:0px 10px">
      <td style="margin:0; font-size:20px;font-weight:bold">${
        basket_content[i].title
      }</td>
          <td style="margin:0;font-size:20px;font-weight:bold;float:right">${EUR}${basket_content[
          i
        ].price.toFixed(2)}</td>
          </tr>
          ${sub_list}
          </table>
          </div>
          `
      );
    }
    let OrderList = [
      {
        DeviceInvoiceId: 0,
        MachineOrderId: 0,
        ShopId: shop_id,
        WorkstationId: 0,
        MachineCustomerId: 0,
        // 5
        ServerCustomerId: user_info.CustomerId,
        ServerOrderId: 0,
        ServerInvoiceId: 0,
        MachineCustomerAddressId: 0,
        ServerCustomerAddressId:
          sale_method == delivery ? selected_address.CustomerAddressId : 0,
        // 10
        TotalDiscount: parseFloat(
          (discount_with_mealdeals + discount_without_mealdeals).toFixed(2)
        ),
        DeliveryCharge: sale_method == delivery ? delivery_fee : 0,
        OrderDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        OrderStatus: selectedPayMethod == 2000102 ? 1000403 : 1000402,
        SaleMethod: sale_method,
        // 15
        HostIp: "",
        SubTotal: sub_total,
        CouponCode: "",
        DeliveryTime: selected_delivery_time.Date,
        SpecialNote: special_note,
        // 20

        PayMethod: selectedPayMethod == 2000105 ? 2000101 : selectedPayMethod,
        PaymentStatus: 0,
        LayoultyiUsedAmount: 0,
        Surcharge: 0,
        HeadDiscount: 0,
        // 25
        DeliveryDiscount: 0,
        IsFreeDelivery: 0,
        TotalVat: total_vat,
        Total: total_for_pay,
        ForPay: parseFloat(
          (
            total_for_pay -
            discount_with_mealdeals -
            discount_without_mealdeals
          ).toFixed(2)
        ),

        // 30
        ASAP: selected_delivery_time.WeekDays == "ASAP" ? 1 : 0,
        ComplateUserId: 0,
        ComplateDescription: "",
        DateResult: null,
        DateSend: null,
        // 35
        DateComplate: null,
        CheckBankDash: 0,
        CarrierBag: carrier_bag_amount,
        DriverId: 0,
        DeviceType: 6,
        // 40
        IsReject: 0,
        TableId: 0,
        GuestNo: 0,
        ServiceCharge: 0,
        ManualDiscount: 0,
        // 45
        FoodDiscount: 0,
        DrinkDiscount: 0,
        CancelReason: 0,
        DiscountReason: 0,
        UserId: 0,
        // 50
        DriverAssignDate: "2022-08-18 08:57:30",
        ServiceChargeTxt: "",
        CustomerTelephone: user_info.Mobile,
        BookId: 0,
        SurchargeLable: "",
        // 55
        AdminFee: adminfee_amount,
        IsSmsForPay: 0,
      },
    ];

    let html_order_list = `
      <!DOCTYPE html>
  <html lang="en">
  
  <head>
      <title></title>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <link href="css/style.css" rel="stylesheet">
  </head>
  
  <body>
  <div style="background-color:pink; padding:20px;border-radius:10px;margin-bottom:50px">
  <table style="width:100%">
      <h1 style="margin:auto;text-align:center;margin-bottom:50px">Shop name</h1>
      <tr>
          <td style="font-size:20px;font-weight:bold">
              order
          </td>
          <td style="float:right;font-size:20px;font-weight:bold">
              #1
          </td>
      </tr>
      <tr>
          <td style="font-size:20px;font-weight:bold">
              Shop address
          </td>
          <td style="float:right;font-size:20px;font-weight:bold">
              hghghghg
          </td>
      </tr>
      <tr>
          <td style="font-size:20px;font-weight:bold">
              Phone number
          </td>
          <td style="float:right;font-size:20px;font-weight:bold">
              +56565
          </td>
      </tr>
  </table>
  
  </div>
      <div style="background-color:aqua; padding:20px;border-radius:10px">
  ${html_product}
      </div>
      <hr style="margin:30px 0 0 0;color:black" />
      <div style=" padding:20px;border-radius:10px">
          <table style="width:100%;  border-collapse:separate; 
          border-spacing:0 20px ;">
  
  
              <tr>
                  <td style="font-size:20px;font-weight:bold">
                      ${lang.subtotal}
                  </td>
                  <td style="float:right;font-size:20px;font-weight:bold">
                      ${EUR}
                      ${sub_total.toFixed(2)}
                  </td>
              </tr>
              ${
                carrier_bag_visible
                  ? `              <tr>
                <td style="font-size:20px;font-weight:bold">
                    ${carrier_bag_label}
                </td>
                <td style="float:right;font-size:20px;font-weight:bold">
                    ${EUR}
                    ${carrier_bag_amount.toFixed(2)}
                </td>
            </tr>`
                  : null
              }
  
              ${
                adminfee_visible
                  ? `
                <tr>
                <td style="font-size:20px;font-weight:bold">
                    ${adminfee_label}
                </td>
                <td style="float:right;font-size:20px;font-weight:bold">
                    ${EUR}
                    ${adminfee_amount.toFixed(2)}
                </td>
            </tr>
                `
                  : null
              }
  
               ${
                 sale_method == delivery
                   ? `
                <tr>
                <td style="font-size:20px;font-weight:bold">
                    ${lang.delivey_fee}
                </td>
                <td style="float:right;font-size:20px;font-weight:bold">
                    ${EUR}
                    ${delivery_fee.toFixed(2)}
                </td>
            </tr>
                `
                   : null
               }
  
              <tr>
                  <td style="font-size:20px;font-weight:bold">
                      ${lang.total}
                  </td>
                  <td style="float:right;font-size:30px;font-weight:bold">
                      ${EUR}
                      ${
                        sale_method == delivery
                          ? adminfee_visible
                            ? carrier_bag_visible
                              ? (
                                  carrier_bag_amount +
                                  adminfee_amount +
                                  delivery_fee +
                                  sub_total
                                ).toFixed(2)
                              : (
                                  adminfee_amount +
                                  delivery_fee +
                                  sub_total
                                ).toFixed(2)
                            : carrier_bag_visible
                            ? (
                                carrier_bag_amount +
                                delivery_fee +
                                sub_total
                              ).toFixed(2)
                            : (delivery_fee + sub_total).toFixed(2)
                          : adminfee_visible
                          ? carrier_bag_visible
                            ? (
                                carrier_bag_amount +
                                adminfee_amount +
                                sub_total
                              ).toFixed(2)
                            : (adminfee_amount + sub_total).toFixed(2)
                          : carrier_bag_visible
                          ? (carrier_bag_amount + sub_total).toFixed(2)
                          : sub_total.toFixed(2)
                      }
                  </td>
              </tr>
          </table>
  
      </div>
  </body>
  
  </html>
      `;

    let order_details = {
      Order_List: OrderList,
      Order_Detail_List: OrderDetailsList,
      Order_SubmenuItem_List: OrderSubmenuItemList,
      Order_Payment_List: OrderPaymentList,
    };

    // NEW CODES
    const saved_basket_time = store.get("saved_basket_time");
    if (
      moment(saved_basket_time).add(59, "minutes").format("DD-MM-YYYY HH:mm") >
      moment(new Date()).format("DD-MM-YYYY HH:mm")
    ) {
      if (selectedPayMethod) {
        dispatch(
          sendOrderAction(
            shop_id,
            login_key,
            order_details,
            selectedPayMethod,
            parseInt(
              parseFloat(
                (
                  total_for_pay -
                  discount_with_mealdeals -
                  discount_without_mealdeals
                ).toFixed(2)
              ) * 100
            ),
            shop_info.Title,
            `${baseUrlForImagesPrefix}${shop_info.Domain}/paymentsuccessful/${shop_id}/`,
            `${baseUrlForImagesPrefix}${shop_info.Domain}/`,
            user_info.Mail
          )
        );
      } else {
        alert("No pay method selected");
      }
    } else {
      dispatch(setBasketListAction([], 0, null, [], 0, 0, 0));
      dispatch(getAllProductsSuccessfull([]));
      store.remove("temp_basket");
      store.remove("temp_sub_total");
      store.remove("basket_last_item_id");
      store.remove("temp_basket_product_id_list");
      store.remove("temp_no_mealdeal_sub_total");
      store.remove("discount");
      store.remove("discount_no_mealdeal");
      navigate("/");
    }
    // *****

    // OLD CODES
    // if (selectedPayMethod) {
    //   dispatch(
    //     sendOrderAction(
    //       shop_id,
    //       login_key,
    //       order_details,
    //       selectedPayMethod,
    //       parseInt(parseFloat(total_for_pay.toFixed(2)) * 100),
    //       shop_info.Title,
    //       `${baseUrlForImagesPrefix}${shop_info.Domain}/paymentsuccessful/${shop_id}/`,
    //       `${baseUrlForImagesPrefix}${shop_info.Domain}/`,
    //       user_info.Mail
    //     )
    //   );
    // } else {
    //   alert("No pay method selected");
    // }
  };

  useEffect(() => {
    if (user_info && cookies.userInfo) {
      setCookie("userInfo", JSON.stringify(user_info), {
        path: "/",
        maxAge: 31536000,
      });
    }
  }, [user_info]);

  const pay_page_ref = useRef(false);
  useEffect(() => {
    if (stripe_payment_link && pay_page_ref.current) {
      window.open(stripe_payment_link, "_self");
    } else {
      pay_page_ref.current = true;
    }
  }, [stripe_payment_link]);

  useEffect(() => {
    if (selectedPayMethod == 2000102) {
      if (order_placed_successfull && server_order_id) {
        dispatch(setDefaultOrderPlaceStatusAction());
        let crypto_res = "";
        var CryptoJS = require("crypto-js");

        crypto_res = CryptoJS.AES.encrypt(
          String(server_order_id),
          "2023$1403@@_"
        )
          .toString()
          .replaceAll("/", "=+MmNn+=");

        if (crypto_res) {
          navigate(`/paymentsuccessful/${shop_id}/${crypto_res}`);
        }
      } else if (order_placed_failed) {
        navigate("/paymentfailed");
      }
    } else if (selectedPayMethod == 2000105 && order_placed_failed) {
      // navigate("/");
    }
  }, [order_placed_successfull, order_placed_failed]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!user_entered_in) {
      navigate("/");
    }
  }, []);

  return (
    <div className="mobile_basket_main_container">
      {/* HEADER */}
      <div
        className="basket_header_container"
        style={{ height: 80, padding: "10px 2%" }}
      >
        {/* PAY METHOD MOBILE */}
        <div className="mobile_pay_method_container">
          {/* CARD */}
          {sale_method == delivery && stripe_in_delivery ? (
            <div className="review_checkbox_container">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel
                  value={2000105}
                  control={
                    <Radio
                      checked={selectedPayMethod == 2000105 ? true : false}
                      onChange={() => setSelectedPayMethod(2000105)}
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      id="mobile_paymethod_checkbox"
                      // style={{
                      //   fontSize: window.innerWidth > 1400 ? 16 : 14,
                      // }}
                    >
                      {lang.pay_by_card2}
                    </Typography>
                  }
                />
              </RadioGroup>
            </div>
          ) : sale_method == collection && stripe_in_collection ? (
            <div className="review_checkbox_container">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel
                  value={2000105}
                  control={
                    <Radio
                      checked={selectedPayMethod == 2000105 ? true : false}
                      onChange={() => setSelectedPayMethod(2000105)}
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      id="mobile_paymethod_checkbox"
                      // style={{
                      //   fontSize: window.innerWidth > 1400 ? 16 : 14,
                      // }}
                    >
                      {lang.pay_by_card2}
                    </Typography>
                  }
                />
              </RadioGroup>
            </div>
          ) : null}
          {/* CASH */}
          {sale_method == delivery && cash_in_delivery ? (
            <div className="review_checkbox_container">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel
                  value={2000102}
                  control={
                    <Radio
                      checked={selectedPayMethod == 2000102 ? true : false}
                      onChange={() => setSelectedPayMethod(2000102)}
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography id="mobile_paymethod_checkbox">
                      {lang.pay_by_cash}
                    </Typography>
                  }
                />
              </RadioGroup>
            </div>
          ) : sale_method == collection && cash_in_collection ? (
            <div className="review_checkbox_container">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel
                  value={2000102}
                  control={
                    <Radio
                      checked={selectedPayMethod == 2000102 ? true : false}
                      onChange={() => setSelectedPayMethod(2000102)}
                      sx={{
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography id="mobile_paymethod_checkbox">
                      {lang.pay_by_cash}
                    </Typography>
                  }
                />
              </RadioGroup>
            </div>
          ) : null}
        </div>
        <div
          className="mobile_basket_close_button"
          onClick={() => navigate(-1)}
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>
      {/* BASKET */}
      <div className="mobile_basket_list_container">
        <div>
          {basket_content.length > 0 ? (
            <div>
              {basket_content.map((item, index) => (
                // <div>
                //   <div style={{ marginBottom: 25, display: "flex" }}>
                //     <div className="mobile_basket_trash_container">
                //       <div id="product_number_container">
                //         <h6>{item.number}</h6>
                //       </div>
                //     </div>
                //     <div className="basket_product_subitem_container">
                //       <div className="basket_product_container">
                //         <h6>{item.title}</h6>
                //         <h6>
                //           {EUR}
                //           {item.price.toFixed(2)}
                //         </h6>
                //       </div>
                //       {item.crust_title ? (
                //         <div className="basket_product_container">
                //           <h6>{item.crust_title}</h6>
                //           <h6>
                //             {EUR}
                //             {item.crust_amount.toFixed(2)}
                //           </h6>
                //         </div>
                //       ) : null}
                //       {item.sub_list.length > 0 ? (
                //         <div className="basket_sublist_container">
                //           {item.sub_list.map((sub_item) => (
                //             <div>
                //               <h6>{sub_item.WebTitle}</h6>
                //               <h6>
                //                 {EUR}
                //                 {sub_item.AmountMain.toFixed(2)}
                //               </h6>
                //             </div>
                //           ))}
                //         </div>
                //       ) : null}
                //     </div>
                //   </div>
                //   {basket_content.length - 1 > index ? <hr></hr> : null}
                // </div>
                <div>
                  <div style={{ display: "flex" }}>
                    <div
                      className="mobile_basket_trash_container"
                      style={{
                        width: 31,
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: 6,
                      }}
                    >
                      <div id="product_number_container">
                        <h6>{item.number}</h6>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div className="basket_product_container">
                        <div className="basket_product_title_container">
                          <p>{item.title}</p>
                        </div>
                        <p>
                          {EUR}
                          {item.price.toFixed(2)}
                        </p>
                      </div>
                      {item.crust_title ? (
                        <div className="basket_product_container">
                          <div className="basket_product_title_container">
                            <p>{item.crust_title}</p>
                          </div>
                          <p>
                            {EUR}
                            {item.crust_amount.toFixed(2)}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {item.sub_list.length > 0 ? (
                    <div>
                      {item.sub_list.map((sub_item) => (
                        <div
                          style={{
                            display: "flex",

                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 31,
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: 12,
                              height: "100%",
                            }}
                          >
                            {sub_item.number > 1 ? (
                              <p style={{ margin: 0 }}>{sub_item.number}</p>
                            ) : null}
                          </div>
                          <p style={{ margin: 0 }}>{sub_item.WebTitle}</p>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {basket_content.length - 1 > index ? <hr></hr> : null}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      {/* FOOTER */}
      <div className="mobile_basket_footter_container">
        <div className="mobile_basket_footer_content_container">
          <div>
            <h6>{lang.subtotal}</h6>
            <h6>
              {EUR}
              {sub_total.toFixed(2)}
            </h6>
          </div>
          {/* CARRIER BAG*/}
          {carrier_bag_visible ? (
            <div>
              <h6>{carrier_bag_label}</h6>
              <h6>
                {EUR}
                {carrier_bag_amount.toFixed(2)}
              </h6>
            </div>
          ) : null}
          {/* ****** */}
          {/* ADMIN FEE*/}
          {adminfee_visible ? (
            <div>
              <h6>{adminfee_label}</h6>
              <h6>
                {EUR}
                {adminfee_amount.toFixed(2)}
              </h6>
            </div>
          ) : null}
          {/* ****** */}
          {/* DELIVERY FEE */}
          {sale_method == delivery ? (
            <div>
              <h6>{lang.delivey_fee}</h6>
              <h6>
                {EUR}
                {delivery_fee.toFixed(2)}
              </h6>
            </div>
          ) : null}
          {/* DISCOUNT */}
          {discount_with_mealdeals > 0 || discount_without_mealdeals > 0 ? (
            <div>
              <h6>{lang.discount}</h6>
              <h6>
                {EUR}
                {(discount_without_mealdeals + discount_with_mealdeals).toFixed(
                  2
                )}
              </h6>
            </div>
          ) : null}
          {/* ****** */}
          <div>
            <h5>{lang.total}</h5>
            <h5>
              {EUR}
              {sale_method == delivery
                ? adminfee_visible
                  ? carrier_bag_visible
                    ? (
                        carrier_bag_amount +
                        adminfee_amount +
                        delivery_fee +
                        sub_total -
                        discount_without_mealdeals -
                        discount_with_mealdeals
                      ).toFixed(2)
                    : (
                        adminfee_amount +
                        delivery_fee +
                        sub_total -
                        discount_without_mealdeals -
                        discount_with_mealdeals
                      ).toFixed(2)
                  : carrier_bag_visible
                  ? (
                      carrier_bag_amount +
                      delivery_fee +
                      sub_total -
                      discount_without_mealdeals -
                      discount_with_mealdeals
                    ).toFixed(2)
                  : (
                      delivery_fee +
                      sub_total -
                      discount_without_mealdeals -
                      discount_with_mealdeals
                    ).toFixed(2)
                : adminfee_visible
                ? carrier_bag_visible
                  ? (
                      carrier_bag_amount +
                      adminfee_amount +
                      sub_total -
                      discount_without_mealdeals -
                      discount_with_mealdeals
                    ).toFixed(2)
                  : (
                      adminfee_amount +
                      sub_total -
                      discount_without_mealdeals -
                      discount_with_mealdeals
                    ).toFixed(2)
                : carrier_bag_visible
                ? (
                    carrier_bag_amount +
                    sub_total -
                    discount_without_mealdeals -
                    discount_with_mealdeals
                  ).toFixed(2)
                : (
                    sub_total -
                    discount_without_mealdeals -
                    discount_with_mealdeals
                  ).toFixed(2)}
            </h5>
          </div>
        </div>
        <Button
          disabled={basket_content.length > 0 ? (loading ? true : false) : true}
          onClick={handleSendOrder}
          style={{
            backgroundColor: primary_color,
            color: white,
            height: (75 * 55) / 100,
            marginBottom: (125 * 55) / 1000,
          }}
        >
          {loading ? <LoaderMini /> : lang.confirm_order}
        </Button>
      </div>
    </div>
  );
};

export default MobileReview;
