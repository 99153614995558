import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import store from "store2";
import { Navigate, useNavigate, redirect, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles, withStyles } from "@mui/styles";
import { StepConnector } from "@mui/material";
import moment from "moment";

import LoaderMini from "../../Componenets/LoderMini";
import Loader from "../../Componenets/Loader";

import MainHeader from "../../Componenets/MainHeader";

import { pound_sign } from "../../constants/String";
import { primary_color, white } from "../../constants/Colors";
import { setDefaultAction } from "../../redux/Profile/Actions";
import { setBasketListAction } from "../../redux/basket/Actions";
import {
  sendOrderAction,
  setDefaultOrderPlaceStatusAction,
  sendOrderEmailAction,
  getSixStepAction,
  getSixStepSuccessfull,
  getSuccessfulOrderDetailsAction,
  getSuccessfulOrderDetailsSuccessful,
  getShopInfoAction,
} from "../../redux/settings/Actions";
import { delivery, collection } from "../../constants/SaleMethodsId";
import RejectedMessage from "./components/RejectedMessage";
import { EUR } from "../../constants/Currency";

const stepsDelivery = [
  {
    label: "Order placed",
    description: `Awaiting confirmation`,
  },
  {
    label: "Order confirmed",
    description: "Preparing your order",
  },
  {
    label: "Order prepared",
    description: `Assigning driver`,
  },
  {
    label: "Driver assigned",
    description: ``,
  },
  {
    label: "On it's way",
    description: ``,
  },
  {
    label: "Enjoy your food",
    description: ``,
  },
];
const stepsDeliveryMobile = [
  "Order placed",

  "Order confirmed",

  "Order prepared",

  "Driver assigned",

  "On it's way",

  "Enjoy your food",
];
const stepsCollection = [
  {
    label: "Order placed",
    description: `Awaiting confirmation`,
  },
  {
    label: "Order confirmed",
    description: "Preparing your order",
  },
  {
    label: "Order prepared",
    description: ``,
  },

  {
    label: "Enjoy your food",
    description: ``,
  },
];
const stepsCollectionMobile = [
  "Order placed",

  "Order confirmed",

  "Order prepared",

  "Enjoy your food",
];
const steps = [
  "Select ",
  "Create an ad group",
  "Create an ad",
  "Create an ad",
  "Create an ad",
  "Create an ad",
];

const styles = (theme) => ({
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0,
    },
  },
  step: {
    "& $completed": {
      color: "lightgreen",
    },
    "& $active": {
      color: "pink",
    },
    "& $disabled": {
      color: "red",
    },
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0,
    },
  },
});

const CustomizedConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-40% + 6px)",
    right: "calc(60% + 7px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles({
  step_label_root: {
    fontSize: "15px",
  },
});

const StepperSx = {
  "& .MuiStepConnector-root": {
    left: "calc(-50% + 40px)",
    right: "calc(50% + 40px)",
  },
  "& .MuiStepConnector-line": {
    marginTop: "2px",
    marginBottom: "5px",
  },
};

const SuccessfullPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    six_step_status,
    successful_order_details,
    shop_info,
    loading_successful_order,
  } = useSelector((state) => state.shopSettingsReduser);

  const [basket, setBasket] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState("");
  const [mobile, setMObile] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [forPay, setForPay] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [invoiceId, setInvoiceId] = useState(0);
  const [footerContent, setFooterContent] = useState(null);
  const [shopInfo, setShopInfo] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [carrierBagVisible, setCarrierBagVisible] = useState(false);
  const [carrierBagLable, setCarrierBagLable] = useState("");
  const [carrierBagAmount, setCarrierBagAmount] = useState(0);
  const [adminFeeVisible, setAdminFeeVisible] = useState(false);
  const [adminFeeLable, setAdminFeeLable] = useState("");
  const [adminFeeAmount, setAdminFeeAmount] = useState(0);
  const [saleMethod, setSaleMethod] = useState(0);
  const [saleMethodName, setSaleMethodName] = useState("");
  const [shopId, setShopId] = useState(0);
  const [loginKey, setLoginKey] = useState("");
  const [orderId, setOrderId] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [orderDate, setOrderDate] = useState(null);
  const [showRejectedMessage, setShowRejectedMessage] = useState(false);

  const [getSixStepStatusFromServer, setGetSixStepStatusFromServer] =
    useState(true);

  const remove_cookies_ref = useRef(false);

  useEffect(() => {
    dispatch(setDefaultOrderPlaceStatusAction());
    setActiveStep(0);
    setShowRejectedMessage(false);
    store.remove("temp_basket");
    store.remove("temp_sub_total");
    store.remove("temp_basket_product_id_list");
    store.remove("temp_no_mealdeal_sub_total");
    store.remove("discount");
    store.remove("discount_no_mealdeal");
    dispatch(setBasketListAction([], 0, 0, [], 0, 0, 0));
    if (params) {
      let bytes = null;
      let originalText = "";
      var CryptoJS = require("crypto-js");

      bytes = CryptoJS.AES.decrypt(
        params.OrderId.replaceAll("=+MmNn+=", "/"),
        "2023$1403@@_"
      );
      originalText = bytes.toString(CryptoJS.enc.Utf8);

      if (bytes && originalText) {
        setOrderId(parseInt(originalText));
        dispatch(
          getSuccessfulOrderDetailsAction(
            params.ShopId,
            "",
            parseInt(originalText)
          )
        );
        dispatch(getShopInfoAction(params.ShopId, ""));
      }
    }
  }, []);

  useEffect(() => {
    if (successful_order_details) {
      dispatch(getSuccessfulOrderDetailsSuccessful(null));
      setDeliveryFee(successful_order_details.DeliveryCharge);
      setCarrierBagVisible(successful_order_details.CarrierBag ? true : false);
      setCarrierBagLable("Regular Bag");
      setCarrierBagAmount(successful_order_details.CarrierBag);
      setAdminFeeVisible(successful_order_details.AdminFee ? true : false);
      setAdminFeeLable("Admin Fee");
      setAdminFeeAmount(successful_order_details.AdminFee);
      setSaleMethod(successful_order_details.SaleMethod);
      setSaleMethodName(successful_order_details.SaleMethodName);
      setSubTotal(successful_order_details.SubTotal);
      setTotal(successful_order_details.Total);
      setDiscount(successful_order_details.TotalDiscount);
      setForPay(successful_order_details.ForPay);
      setShopId(successful_order_details.ShopId);
      setBasket(successful_order_details.OrderDetail);
      setEmail(successful_order_details.Mail);
      setName(successful_order_details.CustomerFullName);
      setInvoiceId(successful_order_details.InvoiceId);
      setDeliveryDate(successful_order_details.DeliveryTime);
      setOrderDate(successful_order_details.OrderDate);
      // setMObile(successful_order_details.OrderDetail);
      setLoginKey("");
    }
  }, [successful_order_details]);

  // useEffect(() => {
  //   dispatch(getSixStepSuccessfull(1000403));
  //   dispatch(setBasketListAction([], 0));
  //   const basket_temp = store.get("basket");
  //   const customer_info_temp = store.get("customer_info");
  //   const invoice_id_temp = store.get("invoice_id");
  //   const order_id_temp = store.get("server_orderid");
  //   const footer_content_temp = store.get("footer_content");
  //   const shop_info_temp = store.get("shop_info");
  //   if (shop_info_temp) {
  //     setShopInfo(shop_info_temp);
  //   }

  //   if (footer_content_temp) {
  //     setDeliveryFee(footer_content_temp.delivery_fee);
  //     setCarrierBagVisible(footer_content_temp.carrier_bag_visible);
  //     setCarrierBagLable(footer_content_temp.carrier_bag_label);
  //     setCarrierBagAmount(footer_content_temp.carrier_bag_amount);
  //     setAdminFeeVisible(footer_content_temp.adminfee_visible);
  //     setAdminFeeLable(footer_content_temp.adminfee_label);
  //     setAdminFeeAmount(footer_content_temp.adminfee_amount);
  //     setSaleMethod(footer_content_temp.sale_method);
  //     setSubTotal(footer_content_temp.sub_total);
  //     setShopId(footer_content_temp.shop_id);
  //     setLoginKey(footer_content_temp.login_key);
  //     setFooterContent(footer_content_temp);
  //   }
  //   if (customer_info_temp) {
  //     setEmail(customer_info_temp.email);
  //     setName(customer_info_temp.name);
  //     setMObile(customer_info_temp.mobile);
  //     setAddress(
  //       customer_info_temp.address ? customer_info_temp.address.Address : ""
  //     );
  //   }
  //   if (invoice_id_temp) {
  //     setInvoiceId(invoice_id_temp);
  //   }
  //   if (order_id_temp) {
  //     setOrderId(order_id_temp);
  //   }
  //   if (basket_temp) {
  //     setBasket(basket_temp);
  //   }
  //   if (remove_cookies_ref.current) {
  //     return () => {
  //       store.remove("basket");
  //       store.remove("customer_info");
  //       store.remove("invoice_id");
  //       store.remove("server_orderid");
  //       store.remove("shop_info");
  //       store.remove("footer_content");
  //       dispatch(setDefaultOrderPlaceStatusAction());
  //     };
  //   } else {
  //     remove_cookies_ref.current = true;
  //   }
  //   store.remove("temp_basket");
  //   store.remove("temp_sub_total");
  //   store.remove("basket_last_item_id");
  // }, []);

  // SEND EMAIL
  //   useEffect(() => {
  //     if (basket.length > 0 && shopInfo && saleMethodName) {
  //       let html_product = "";
  //       for (let i = 0; i < basket.length; i++) {
  //         let sub_list = "";
  //         if (basket[i].sub_list.length > 0) {
  //           for (let j = 0; j < basket[i].sub_list.length; j++) {
  //             sub_list = sub_list.concat(`\n
  //             <tr class="list_item_subitem_row">
  //             <td class="list_item_subitem_title">${
  //               basket[i].sub_list[j].ItemName
  //             }</td>
  //             <td class="list_item_subitem_price">${EUR}${basket[
  //               i
  //             ].sub_list[j].ItemAmount.toFixed(2)}</td>
  //         </tr>
  //             `);
  //           }
  //         }
  //         html_product = html_product.concat(
  //           `\n
  //           <div class="list_item_container">
  //           <div class="list_item_number_container">
  //               <p>${basket[i].number}</p>
  //       </div>
  //       <table class="list_item_content_container">
  //       <tr class="list_item_product_row">
  //       <td class="list_item_product_title">${basket[i].title}</td>
  //           <td class="list_item_product_price">${EUR}${basket[
  //             i
  //           ].price.toFixed(2)}</td>
  //           </tr>
  //           ${sub_list}
  //           </table>
  //           </div>
  //           `
  //         );
  //       }

  //       let html_order_list = `
  //       <!DOCTYPE html>
  //   <html lang='en'>

  //   <head>
  //       <title></title>
  //       <meta charset='UTF-8'>
  //       <meta name='viewport' content='width=device-width, initial-scale=1'>
  //       <link href='css/style.css' rel='stylesheet'>
  //       <style>
  //       .main_container {
  //           background-color: #E7E2DE;
  //           padding: 50px 120px;
  //           width: 80%;
  //           margin: auto;
  //       }

  //       .shop_name_mobile {
  //           display: block;
  //           margin: auto;
  //           text-align: center;
  //           margin-bottom: 50px;
  //       }

  //       .header_main_container {
  //           background-color: #fff;
  //           padding: 40px;
  //           border-radius: 10px;
  //           margin-bottom: 30px
  //       }

  //       .header_container {
  //           width: 100%;
  //           margin: auto;
  //           border-collapse: separate;
  //           border-spacing: 0 1em;
  //       }

  //       .header_main_container h1 {
  //           display: block;
  //           margin: auto;
  //           text-align: center;
  //           margin-bottom: 50px;
  //       }

  //       .header_key {
  //           font-size: 20px;
  //           font-weight: bold
  //       }

  //       .header_value {
  //           font-size: 20px;
  //           font-weight: bold;
  //           float: right;
  //       }

  //       .list_item_container {
  //           display: flex;
  //           margin-bottom: 10px
  //       }

  //       .list_item_number_container {
  //           padding-top: 12px;
  //           margin-right: 15px;
  //       }

  //       .list_item_number_container p {
  //           margin: 0;
  //           font-size: 20px;
  //           font-weight: bold
  //       }

  //       .list_item_content_container {
  //           padding-top: 10px;
  //           width: 100%
  //       }

  //       .list_item_product_row {
  //           margin: 0px 10px
  //       }

  //       .list_item_subitem_row {
  //           margin: 5px 10px 0 10px;
  //       }

  //       .list_item_product_title {
  //           margin: 0;
  //           font-size: 20px;
  //           font-weight: bold
  //       }

  //       .list_item_subitem_title {
  //           margin: 0;
  //           font-size: 18px
  //       }

  //       .list_item_subitem_price {
  //           margin: 0;
  //           font-size: 18px;
  //           float: right
  //       }

  //       .list_item_product_price {
  //           margin: 0;
  //           font-size: 20px;
  //           font-weight: bold;
  //           float: right
  //       }

  //       .seperator {
  //           margin: 30px 0 0 0;
  //           color: black
  //       }

  //       .footer_container {
  //           padding: 40px;
  //           border-radius: 10px
  //       }

  //       .footer_content_container {
  //           width: 100%;
  //           border-collapse: separate;
  //           border-spacing: 0 20px;
  //       }

  //       #header_title {
  //           padding: 50px 0;
  //       }

  //       #header_title h1 {
  //           margin: 0;
  //           color: #4CAF50;
  //       }

  //       @media screen and (max-width: 768px) {
  //         .header_main_container {
  //           padding: 20px;
  //       }
  //       .footer_container {
  //           padding: 20px;
  //       }
  //         .main_container {
  //           padding: 50px;
  //       }
  //           .list_item_number_container {
  //               padding-top: 11px;
  //               margin-right: 10px;
  //           }
  //           .main_container {
  //               width: 93%;
  //           }
  //           .main_container {
  //               padding: 10px;
  //           }
  //           .header_container {
  //               width: 100%;
  //           }
  //           .header_main_container h1 {
  //               font-size: 18px;
  //               margin-bottom: 30px;
  //           }
  //           .header_key {
  //               font-size: 15px;
  //           }
  //           .header_value {
  //               font-size: 15px;
  //           }
  //           .list_item_number_container p {
  //               font-size: 12px;
  //           }
  //           .list_item_product_title {
  //               font-size: 12px;
  //           }
  //           .list_item_product_price {
  //               font-size: 12px;
  //           }
  //           .list_item_subitem_title {
  //               font-size: 11px
  //           }
  //           .list_item_subitem_price {
  //               font-size: 11px;
  //           }
  //       }
  //   </style>
  //   </head>

  //   <body>
  //   <div class="main_container">
  //   <div class="header_main_container" id="header_title">
  //       <h1>
  //           Your order has been confirmed
  //       </h1>
  //   </div>
  //   <div class="header_main_container">
  //   <h1>${shopInfo.Title}</h1>
  //   <table class="header_container">
  //       <tr>
  //           <td class="header_key">
  //               Order
  //           </td>
  //           <td class="header_value">
  //               #${invoiceId}
  //           </td>
  //       </tr>
  //       <tr>
  //           <td class="header_key">
  //               Shop address
  //           </td>
  //           <td class="header_value">
  //               ${shopInfo.Address}
  //           </td>
  //       </tr>
  //       <tr>
  //           <td class="header_key">
  //               Phone number
  //           </td>
  //           <td class="header_value">
  //           ${shopInfo.PhoneNumber}
  //           </td>
  //       </tr>
  //       <tr>
  //       <td class="header_key">
  //           Sale method
  //       </td>
  //       <td class="header_value">
  //           ${saleMethodName}
  //       </td>
  //   </tr>
  //   <tr>
  //   <td class="header_key">
  //       Ordered at
  //   </td>
  //   <td class="header_value">
  //   ${moment(orderDate).format("YYYY-MM-DD HH:mm")}
  //   </td>
  // </tr>
  // <tr>
  //   <td class="header_key">
  //       Wanted at
  //   </td>
  //   <td class="header_value">
  //   ${moment(deliveryDate).format("YYYY-MM-DD HH:mm")}
  //   </td>
  // </tr>
  //   </table>

  //   </div>
  //       <div class="header_main_container">
  //   ${html_product}
  //       </div>
  //       <hr class="seperator"/>
  //       <div class="footer_container">
  //           <table class="footer_content_container">

  //               <tr>
  //                   <td class="header_key">
  //                       ${lang.subtotal}
  //                   </td>
  //                   <td class="header_value">
  //                       ${EUR}${subTotal.toFixed(2)}

  //                   </td>
  //               </tr>
  //               ${
  //                 carrierBagVisible
  //                   ? `              <tr>
  //                 <td class="header_key">
  //                     ${carrierBagLable}
  //                 </td>
  //                 <td class="header_value">
  //                     ${EUR}${carrierBagAmount.toFixed(2)}

  //                 </td>
  //             </tr>`
  //                   : ""
  //               }

  //               ${
  //                 adminFeeVisible
  //                   ? `
  //                 <tr>
  //                 <td class="header_key">
  //                     ${adminFeeLable}
  //                 </td>
  //                 <td class="header_value">
  //                     ${EUR}${adminFeeAmount.toFixed(2)}

  //                 </td>
  //             </tr>
  //                 `
  //                   : ""
  //               }

  //                ${
  //                  saleMethod == delivery
  //                    ? `
  //                 <tr>
  //                 <td class="header_key">
  //                     ${lang.delivey_fee}
  //                 </td>
  //                 <td class="header_value">
  //                     ${EUR}${deliveryFee.toFixed(2)}
  //                 </td>
  //             </tr>
  //                 `
  //                    : ``
  //                }

  //               <tr>
  //                   <td class="header_key">
  //                       ${lang.total}
  //                   </td>
  //                   <td class="header_value">

  //                   ${EUR}${
  //         saleMethod == delivery
  //           ? adminFeeVisible
  //             ? carrierBagVisible
  //               ? (
  //                   carrierBagAmount +
  //                   adminFeeAmount +
  //                   deliveryFee +
  //                   subTotal
  //                 ).toFixed(2)
  //               : (adminFeeAmount + deliveryFee + subTotal).toFixed(2)
  //             : carrierBagVisible
  //             ? (carrierBagAmount + deliveryFee + subTotal).toFixed(2)
  //             : (deliveryFee + subTotal).toFixed(2)
  //           : adminFeeVisible
  //           ? carrierBagVisible
  //             ? (carrierBagAmount + adminFeeAmount + subTotal).toFixed(2)
  //             : (adminFeeAmount + subTotal).toFixed(2)
  //           : carrierBagVisible
  //           ? (carrierBagAmount + subTotal).toFixed(2)
  //           : subTotal.toFixed(2)
  //       }
  //                   </td>
  //               </tr>
  //           </table>

  //       </div>
  //       </div>
  //   </body>

  //   </html>
  //       `;
  //       const email_subject = "Order";

  //       dispatch(
  //         sendOrderEmailAction(
  //           shopId,
  //           loginKey,
  //           orderId,
  //           1,
  //           html_order_list,
  //           email,
  //           email_subject
  //         )
  //       );
  //     }
  //   }, [basket, shopInfo, saleMethodName]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (shopId && orderId) {
        dispatch(getSixStepAction(shopId, loginKey, orderId));
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [shopId, loginKey, orderId]);

  useEffect(() => {
    if (six_step_status && getSixStepStatusFromServer) {
      if (six_step_status == 1000403 || six_step_status == 1000407) {
        setActiveStep(0);
      } else if (six_step_status == 1000404) {
        setActiveStep(1);
        setGetSixStepStatusFromServer(false);
      } else if (six_step_status == 1000405) {
        setShowRejectedMessage(true);
      }
    }
  }, [six_step_status, getSixStepStatusFromServer]);

  useEffect(() => {
    if (shop_info) {
      setShopInfo(shop_info);
    }
  }, [shop_info]);

  useEffect(() => {
    if (activeStep == 1) {
      if (deliveryDate) {
        let dif = moment(deliveryDate).diff(orderDate);
        let time_for_preparing = 0;
        if (saleMethod == collection) {
          if (dif > 120000) {
            time_for_preparing = dif - 120000;
          } else {
            time_for_preparing = dif;
          }
        } else if (saleMethod == delivery) {
          if (dif > 600000) {
            time_for_preparing = dif - 600000;
          } else {
            time_for_preparing = dif;
          }
        }

        setTimeout(() => {
          setActiveStep(2);
        }, time_for_preparing);
      }
    } else if (activeStep == 2) {
      setTimeout(() => {
        setActiveStep(3);
      }, 120000);
    } else if (activeStep == 3) {
      if (saleMethod == delivery) {
        setTimeout(() => {
          setActiveStep(4);
        }, 120000);
      } else if (saleMethod == collection) {
        setActiveStep(4);
      }
    } else if (activeStep == 4) {
      if (saleMethod == delivery) {
        setTimeout(() => {
          setActiveStep(5);
        }, 360000);
      }
    } else if (activeStep == 5) {
      setTimeout(() => {
        setActiveStep(6);
      }, 10000);
    }
  }, [activeStep, deliveryDate, orderDate, saleMethod]);

  return (
    <div className="menu-container-div">
      <MainHeader
        show_all_items={true}
        show_basket={false}
        show_profile={true}
        show_menu_title={true}
      />

      <div className="review_container">
        <div
          className="six_step_mobile_container"
          style={{ marginBottom: 15, marginTop: 15 }}
        >
          <Box sx={{ width: "95%", margin: "auto" }}>
            {saleMethod == collection ? (
              <Stepper activeStep={activeStep} alternativeLabel>
                {stepsDeliveryMobile.map((label) => (
                  <Step
                    key={label}
                    sx={{
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: primary_color, // circle color (COMPLETED)
                      },
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                        {
                          color: "grey.500",
                          // Just text label (COMPLETED)
                        },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: primary_color,
                        // circle color (ACTIVE)
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                        {
                          color: primary_color, // Just text label (ACTIVE)
                        },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: "white", // circle's number (ACTIVE)
                      },
                    }}
                  >
                    <StepLabel>
                      <Typography variant="caption" style={{ fontSize: 9 }}>
                        {label}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            ) : (
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                // sx={StepperSx}
                // connector={<CustomizedConnector />}
              >
                {stepsDeliveryMobile.map((label) => (
                  <Step
                    key={label}
                    sx={{
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: primary_color, // circle color (COMPLETED)
                      },
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                        {
                          color: "grey.500", // Just text label (COMPLETED)
                        },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: primary_color, // circle color (ACTIVE)
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                        {
                          color: primary_color, // Just text label (ACTIVE)
                        },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: "white", // circle's number (ACTIVE)
                      },
                    }}
                  >
                    <StepLabel>
                      <Typography variant="caption" style={{ fontSize: 9 }}>
                        {label}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
          </Box>
        </div>
        <div className="six_step_container">
          <Box sx={{ width: "100%" }}>
            {saleMethod == collection ? (
              <Stepper activeStep={activeStep} orientation="vertical">
                {stepsCollection.map((step, index) => (
                  <Step
                    key={step.label}
                    sx={{
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: primary_color, // circle color (COMPLETED)
                      },
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                        {
                          color: "grey.500", // Just text label (COMPLETED)
                        },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: primary_color, // circle color (ACTIVE)
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                        {
                          color: primary_color, // Just text label (ACTIVE)
                        },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: "white", // circle's number (ACTIVE)
                      },
                    }}
                  >
                    <StepLabel
                      optional={
                        index === 5 ? (
                          <Typography variant="caption">Last step</Typography>
                        ) : null
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      {/* <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1 ? "Finish" : "Continue"}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </div>
                    </Box> */}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            ) : (
              <Stepper activeStep={activeStep} orientation="vertical">
                {stepsDelivery.map((step, index) => (
                  <Step
                    key={step.label}
                    sx={{
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: primary_color, // circle color (COMPLETED)
                      },
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                        {
                          color: "grey.500", // Just text label (COMPLETED)
                        },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: primary_color, // circle color (ACTIVE)
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                        {
                          color: primary_color, // Just text label (ACTIVE)
                        },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: "white", // circle's number (ACTIVE)
                      },
                    }}
                  >
                    <StepLabel
                      optional={
                        index === 5 ? (
                          <Typography variant="caption">Last step</Typography>
                        ) : null
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      {/* <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {index === steps.length - 1 ? "Finish" : "Continue"}
                          </Button>
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Back
                          </Button>
                        </div>
                      </Box> */}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            )}
          </Box>
        </div>
        <div className="successful_content_container">
          <div
            className="successfull_basket"
            id="successfull_basket"
            style={{ width: "100%" }}
          >
            <div className="successful_basket_header_container">
              <h6>
                {lang.your_order} #{invoiceId}
              </h6>
            </div>

            <div className="successfull_basket_list_container">
              {basket.map((item, index) => (
                // <div>
                //   <div style={{ marginBottom: 25, display: "flex" }}>
                //     <div className="mobile_basket_trash_container">
                //       <div id="product_number_container">
                //         <h6>{item.number}</h6>
                //       </div>
                //     </div>
                //     <div className="basket_product_subitem_container">
                //       <div className="basket_product_container">
                //         <div className="basket_product_title_container">
                //           <h6>{item.title}</h6>
                //         </div>
                //         <h6>
                //           {EUR}
                //           {item.price.toFixed(2)}
                //         </h6>
                //       </div>
                //       {item.crust_title ? (
                //         <div className="basket_product_container">
                //           <h6>{item.crust_title}</h6>
                //           <h6>
                //             {EUR}
                //             {item.crust_amount.toFixed(2)}
                //           </h6>
                //         </div>
                //       ) : null}
                //       {item.sub_list.length > 0 ? (
                //         <div className="basket_sublist_container">
                //           {item.sub_list.map((sub_item) => (
                //             <div>
                //               <h6>{sub_item.ItemName}</h6>
                //               <h6>
                //                 {EUR}
                //                 {sub_item.ItemAmount.toFixed(2)}
                //               </h6>
                //             </div>
                //           ))}
                //         </div>
                //       ) : null}
                //     </div>
                //   </div>

                //   {basket.length - 1 > index ? <hr></hr> : null}
                // </div>
                <div>
                  <div style={{ display: "flex" }}>
                    <div
                      className="mobile_basket_trash_container"
                      style={{
                        width: 48,
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: 6,
                      }}
                    >
                      <div id="product_number_container">
                        <h6>{item.number}</h6>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div className="basket_product_container">
                        <div className="basket_product_title_container">
                          <p>{item.title}</p>
                        </div>
                        <p>
                          {EUR}
                          {item.total_basket_items_price.toFixed(2)}
                        </p>
                      </div>
                      {item.crust_title ? (
                        <div className="basket_product_container">
                          <div className="basket_product_title_container">
                            <p>{item.crust_title}</p>
                          </div>
                          <p>
                            {EUR}
                            {item.crust_amount.toFixed(2)}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {item.sub_list.length > 0 ? (
                    <div>
                      {item.sub_list.map((sub_item) => (
                        <div
                          style={{
                            display: "flex",

                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 48,
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: 12,
                              height: "100%",
                            }}
                          >
                            {sub_item.Count / item.number > 1 ? (
                              <p style={{ margin: 0 }}>
                                {sub_item.Count / item.number}
                              </p>
                            ) : null}
                          </div>
                          <p style={{ margin: 0 }}>{sub_item.ItemName}</p>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {basket.length - 1 > index ? <hr></hr> : null}
                </div>
              ))}
              {loading_successful_order ? <Loader /> : null}
            </div>

            {/* FOOTER */}
            <div className="footer_successful_page">
              <div className="basket_footer_container">
                <div>
                  <h6>{lang.subtotal}</h6>
                  <h6>
                    {EUR}
                    {subTotal.toFixed(2)}
                  </h6>
                </div>
                {/* CARRIER BAG*/}
                {carrierBagVisible ? (
                  <div>
                    <h6>{carrierBagLable}</h6>
                    <h6>
                      {EUR}
                      {carrierBagAmount.toFixed(2)}
                    </h6>
                  </div>
                ) : null}
                {/* ****** */}
                {/* ADMIN FEE*/}
                {adminFeeVisible ? (
                  <div>
                    <h6>{adminFeeLable}</h6>
                    <h6>
                      {EUR}
                      {adminFeeAmount.toFixed(2)}
                    </h6>
                  </div>
                ) : null}
                {/* DELIVERY FEE */}
                {saleMethod == delivery ? (
                  <div>
                    <h6>{lang.delivey_fee}</h6>
                    <h6>
                      {EUR}
                      {deliveryFee.toFixed(2)}
                    </h6>
                  </div>
                ) : null}
                {/* DISCOUNT */}
                {discount ? (
                  <div>
                    <h6>{lang.discount}</h6>
                    <h6>
                      {EUR}
                      {discount.toFixed(2)}
                    </h6>
                  </div>
                ) : null}
                {/* ****** */}
                <div>
                  <h5>{lang.total}</h5>
                  <h5>
                    {EUR}
                    {forPay}
                    {/* {saleMethod == delivery
                      ? adminFeeVisible
                        ? carrierBagVisible
                          ? (
                              carrierBagAmount +
                              adminFeeAmount +
                              deliveryFee +
                              subTotal
                            ).toFixed(2)
                          : (adminFeeAmount + deliveryFee + subTotal).toFixed(2)
                        : carrierBagVisible
                        ? (carrierBagAmount + deliveryFee + subTotal).toFixed(2)
                        : (deliveryFee + subTotal).toFixed(2)
                      : adminFeeVisible
                      ? carrierBagVisible
                        ? (
                            carrierBagAmount +
                            adminFeeAmount +
                            subTotal
                          ).toFixed(2)
                        : (adminFeeAmount + subTotal).toFixed(2)
                      : carrierBagVisible
                      ? (carrierBagAmount + subTotal).toFixed(2)
                      : subTotal.toFixed(2)} */}
                  </h5>
                </div>
              </div>
              <div className="successful_basket_footer_container">
                <h6>{lang.your_order_has_been_palced}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showRejectedMessage ? (
        <RejectedMessage
          pd_hide_rejected_message={() => setShowRejectedMessage(false)}
        />
      ) : null}
    </div>
  );
};

export default SuccessfullPayment;
