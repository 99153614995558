import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Menu from "./pages/menu";
import ProductSubmenuPopup from "./Componenets/ProductSubmenuPopup";
import ProductDetails from "./Componenets/ProductDetails";
import { category_list } from "./constants/List";
import { Helmet } from "react-helmet";

import "./style/styles.css";
import "./style/Responsive.css";

import Login from "./pages/profile/Login";
import SignUp from "./pages/profile/SignUp";
// import Basket from "./Componenets/Basket";
import Profile from "./pages/profile/Profile";
import MyOrdersMobile from "./pages/profile/MyOrdersMobile";
import Checkout from "./pages/checkout/Checkout";
import Pay from "./pages/checkout/Pay";
import Review from "./pages/checkout/Review";
import ReviewMobile from "./pages/checkout/ReviewMobile";
import SuccessfullPayment from "./pages/checkout/SuccessfullPayment";
import FailedPayment from "./pages/checkout/FailedPayment";
import Footer from "./Componenets/Footer";
import Basket from "./pages/basket/Basket";
import ForgetPassword from "./pages/profile/ForgetPassword";
import UpdatePassword from "./pages/profile/UpdatePassword";
import ResetPassword from "./pages/profile/ResetPassword";
import DrawerMenu from "./Componenets/DrawerMenu";
import OrderDetails from "./Componenets/OrderDetails";

import MaintenanceModePopup from "./Componenets/MaintenanceModePopup";

import { showMaintenanceModePopup } from "./redux/settings/Actions";
import { orderDetailsOpenAction } from "./redux/Profile/Actions";
import { baseUrlForImagesPrefix } from "./constants/BaseUrl";

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { show_product_submenu_popup, show_cache_popup, products_list } =
    useSelector((state) => state.productReduser);
  const {
    maintenance_mode,
    show_maintenance_mode_popup,
    preorder_pressed,
    preorder_status,
    shop_info,
  } = useSelector((state) => state.shopSettingsReduser);
  const { show_basket } = useSelector((state) => state.basketReduser);
  const { order_details, order_details_is_open } = useSelector(
    (state) => state.profileReduser
  );

  const [searchInput, setSearchInput] = useState("");
  const [passName, setPassName] = useState("");
  const [showMaintenanceNotification, setShowMaintenanceNotification] =
    useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  if (searchInput.length > 0) {
    category_list.filter((item) => {
      return item.title.match(searchInput);
    });
  }

  useLayoutEffect(() => {
    setPassName(location.pathname);

    window.scrollTo(0, 0);
  }, [location.pathname, products_list]);
  useEffect(() => {
    if (show_product_submenu_popup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show_product_submenu_popup]);
  useEffect(() => {
    if (show_maintenance_mode_popup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show_maintenance_mode_popup]);
  useEffect(() => {
    if (order_details_is_open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [order_details_is_open]);

  useEffect(() => {
    if (show_maintenance_mode_popup) {
      if (maintenance_mode) {
        setShowMaintenanceNotification(true);
      } else if (preorder_status) {
        if (!preorder_pressed) {
          setShowMaintenanceNotification(true);
        } else {
          setShowMaintenanceNotification(false);
          dispatch(showMaintenanceModePopup(false));
        }
      } else {
        setShowMaintenanceNotification(true);
      }
    } else {
      setShowMaintenanceNotification(false);
    }
  }, [show_maintenance_mode_popup]);

  return (
    <div
      style={{
        position: "relative",
        paddingRight: show_maintenance_mode_popup ? 17 : 0,
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{shop_info ? shop_info.Title : ``}</title>
        <link
          id="favicon"
          rel="icon"
          href={
            shop_info
              ? `${baseUrlForImagesPrefix}${shop_info.Domain}/${shop_info.FavIcom}`
              : ``
          }
          type="image/x-icon"
        />
      </Helmet>
      <Routes>
        <Route path="*" element={<Navigate to={"/"} />} />
        <Route path="/" element={<Menu />} />
        <Route path="/signup/:ID" element={<SignUp />} />

        <Route path="/login/:ID" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/MyOrdersMobile" element={<MyOrdersMobile />} />
        <Route path="/ForgetPassword" element={<ForgetPassword />} />
        {/* <Route path="/UpdatePassword" element={<UpdatePassword />} /> */}
        <Route
          path="/UpdatePassword/:ShopId/:CustomerId/:Token"
          element={<UpdatePassword />}
        />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/pay" element={<Pay />} />
        <Route path="/review" element={<Review />} />
        <Route path="/ReviewMobile" element={<ReviewMobile />} />
        <Route
          path="/paymentsuccessful/:ShopId/:OrderId"
          element={<SuccessfullPayment />}
        />
        <Route path="/paymentfailed" element={<FailedPayment />} />
        <Route path="/basket" element={<Basket />} />
      </Routes>
      {passName == "/basket" || passName == "/ReviewMobile" ? null : <Footer />}
      {show_product_submenu_popup ? <ProductSubmenuPopup /> : null}
      {/* {show_basket ? <Basket /> : null} */}

      {showMaintenanceNotification ? <MaintenanceModePopup /> : null}
      {order_details_is_open ? (
        <OrderDetails
          hide_order_dateils={() => {
            dispatch(orderDetailsOpenAction(false));
          }}
        />
      ) : null}
    </div>
  );
}
