import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import validator from "validator";
import { toast, Toaster } from "react-hot-toast";

import MainHeader from "../../Componenets/MainHeader";
import { primary_color, white } from "../../constants/Colors";
import {
  resetPasswordAction,
  setDefaultAction,
} from "../../redux/Profile/Actions";
import LoaderMini from "../../Componenets/LoderMini";

const ForgetPassword = () => {
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_loading, result_successfull, result_failed, result_message } =
    useSelector((state) => state.profileReduser);
  const { user_entered_in, shop_id, login_key, shop_info } = useSelector(
    (state) => state.shopSettingsReduser
  );

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailSentSuccessful, setEmailSentSuccessful] = useState(false);

  const resetPassword = () => {
    if (shop_info) {
      if (email) {
        if (validator.isEmail(String(email))) {
          dispatch(
            resetPasswordAction(shop_id, login_key, email, shop_info.Domain)
          );
        } else {
          setErrorMessage("Email format is incorrect");
        }
      } else {
        setErrorMessage("Email is required");
      }
    }
  };

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      setEmailSentSuccessful(true);
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  return (
    <div className="menu-container-div">
      <MainHeader
        show_all_items={true}
        show_basket={false}
        show_profile={false}
        show_menu_title={true}
      />

      <div className="forget_password_main_container">
        <div className="forget_password_main_container2">
          {emailSentSuccessful ? (
            // SUCCESSFULL MESSAGE
            <div className="forget_password_content_container">
              <i class="fa fa-check" aria-hidden="true"></i>
              <h6>
                If your email is linked to Everything Takeaway
                <br />
                you will receive an email include reset password link
              </h6>
            </div>
          ) : (
            // ENTER EMAIL
            <div className="forget_password_content_container">
              <h3>{lang.reset_password}</h3>
              <h6>{lang.reset_password_message}</h6>
              <div className="forget_password_input_container">
                <div className="forget_password_input_title_container">
                  <h5>{lang.email_address}</h5>
                  <p>{errorMessage}</p>
                </div>
                <input
                  value={email}
                  onChange={(i) => {
                    setEmail(i.target.value);
                    setErrorMessage("");
                  }}
                  placeholder={lang.enter_your_email_address}
                />
                <Button
                  className="profile-create-account-button"
                  onClick={resetPassword}
                  style={{
                    backgroundColor: primary_color,
                    color: white,
                    margin: "15px 0",
                    textTransform: "none",
                  }}
                >
                  {login_loading ? <LoaderMini /> : lang.continue}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default ForgetPassword;
